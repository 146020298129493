import "./AboutTheCodeSection.css";

import { CrossCircleDarkIcon, RightArrowOrangeIcon, SuccessIcon } from "../../../../../../assets";
import { getPublicCodeDetailsRoute } from "../../../../../../utils/routes";
import { CodeStatusKPI, Column, Divider, Row } from "../../../../../../widget";
import { ImageButton } from "../../../../../../widget/layout/ExpandableCard/components";
import { useAboutTheCodeSection } from "./useAboutTheCodeSection";

interface AboutTheCodeSectionProps {
  organisationUuid: string;
  projectUuid: string;
  isPreview?: boolean;
}

export const AboutTheCodeSection = ({
  organisationUuid,
  projectUuid,
  isPreview = false,
}: AboutTheCodeSectionProps): JSX.Element => {
  const { dataIsLoading, codeDetailsData } = useAboutTheCodeSection(organisationUuid);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!dataIsLoading && codeDetailsData && (
        <div className="AboutTheCodeSection">
          <Row spacingV="ll">
            <Column span={12}>
              <div className="AboutTheCodeHeader">
                <h2>About the {codeDetailsData?.displayName}</h2>
                {!isPreview && (
                  <ImageButton
                    icon={<RightArrowOrangeIcon />}
                    route={`${getPublicCodeDetailsRoute(organisationUuid)}?projectUuid=${projectUuid}`}
                    linkText="Learn more about the code"
                  />
                )}
              </div>
            </Column>
          </Row>
          {/* TODO Maybe re-add this in the future. Removed as per
          https://linear.app/kana-earth/issue/ENG-719/temporarily-remove-pc-and-wcc-logos */}
          {/* <Row spacingV="ml">
            <Column span={12}>
              <img
                className="CodeLogo"
                src={codeDetailsData?.logo || undefined}
                alt="Code logo"
              />
            </Column>
          </Row> */}
          <Row spacingV="l">
            <Column span={12}>
              <p className="body1">{codeDetailsData?.subtitle}</p>
            </Column>
          </Row>
          <Row spacingV="l">
            <Column span={12}>
              {codeDetailsData?.ukEnvironmentalReporting ? (
                <div className="ReportingStandards True">
                  <SuccessIcon />
                  <h3>Approved for net emissions reporting under the UK&apos;s Environmental Reporting Guidelines</h3>
                </div>
              ) : (
                <div className="ReportingStandards False">
                  <CrossCircleDarkIcon />
                  <h3>
                    Not approved for net emissions reporting under the UK&apos;s Environmental Reporting Guidelines
                  </h3>
                </div>
              )}
            </Column>
          </Row>
          {codeDetailsData?.codeCharacteristics && (
            <>
              <Row spacingV="l">
                <Column span={12}>
                  <Divider type="horizontal" />
                </Column>
              </Row>
              <Row spacingV="l">
                <Column span={12}>
                  <CodeStatusKPI codeCharacteristics={codeDetailsData.codeCharacteristics} isProjectPage />
                </Column>
              </Row>
            </>
          )}
        </div>
      )}
    </>
  );
};
