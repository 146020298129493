import "./StatusCard.css";

interface StatusCardProps {
  status: string;
}

export const StatusCard = ({ status }: StatusCardProps): JSX.Element => {
  let classNames = "StatusCard";

  // eslint-disable-next-line default-case
  switch (status) {
    case "Validated":
    case "Verified":
      classNames += " StatusCard_color_green";
      break;
    case "Under Development":
      classNames += " StatusCard_color_coral";
      break;
    case "Not Delivered":
      classNames += " StatusCard_color_grey";
      break;
  }

  return (
    <div className={classNames}>
      <h3>Status</h3>
      <h2>{status}</h2>
    </div>
  );
};
