import { RadioButtonGroupData } from "../../../../models";
import { wordCount } from "../../../../utils";

interface AlignmentInterface {
  direction: "horizontal" | "vertical" | undefined;
}

interface UseRadioButtonGroupReturnData {
  alignment: AlignmentInterface;
}

export function useRadioButtonGroup(data: RadioButtonGroupData): UseRadioButtonGroupReturnData {
  let alignment: AlignmentInterface = {
    direction: "horizontal",
  };

  if (data.length > 2 || data.find((option) => wordCount(String(option.key)) > 1)) {
    alignment = {
      direction: "vertical",
    };
  }

  return {
    alignment,
  };
}
