import { Dispatch, FormEvent, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";

import { RadioButtonGroupData } from "../../../../../../models";
import { updateOrganisationUser } from "../../../../../../service/organisation";
import { ServiceError, Status } from "../../../../../../service/Shared";
import { useAuth } from "../../../../../../useAuth";
import { getUserSettingsRoute } from "../../../../../../utils/routes";
import { Toast } from "../../../../../../widget";

interface UseEditFormReturnData {
  radioValues: RadioButtonGroupData;
  role: string;
  active: boolean;
  errors: ServiceError[] | undefined;
  setRole: Dispatch<SetStateAction<string>>;
  setActive: Dispatch<SetStateAction<boolean>>;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  handleCancel: () => void;
}

export function useEditForm(
  userUuid: string | undefined,
  defaultRoleValue: string,
  defaultActiveValue: boolean,
  rowVersion: number
): UseEditFormReturnData {
  const navigate = useNavigate();
  const { currentOrganisationUuid } = useAuth();

  const [role, setRole] = useState(defaultRoleValue);
  const [active, setActive] = useState(defaultActiveValue);
  const [errors, setErrors] = useState<ServiceError[] | undefined>();
  const [actualRowVersion, setActualRowVersion] = useState(rowVersion);

  const radioValues = [
    { key: true, value: "Active" },
    { key: false, value: "Inactive" },
  ];

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setErrors([]);

    if (userUuid && currentOrganisationUuid) {
      const res = await updateOrganisationUser({
        userUuid,
        organisationUuid: currentOrganisationUuid,
        role,
        active,
        rowVersion: actualRowVersion,
      });

      if (res.status === Status.Success && res.data) {
        Toast.success({ message: "User details changed successfully" });
        setActualRowVersion(res.data?.rowVersion);
      }

      if (res.status === Status.Error) {
        setErrors(res.errors);
      }
    }
  };

  const handleCancel = (): void => {
    navigate(getUserSettingsRoute());
  };

  return {
    radioValues,
    role,
    active,
    errors,
    setRole,
    setActive,
    handleSubmit,
    handleCancel,
  };
}
