// TODO temporarily disable eslint on this file until v1.0 Unwired is complete/services are used
/* eslint unused-imports/no-unused-vars: "off" */
/* eslint @typescript-eslint/no-explicit-any: "off" */
/* eslint @typescript-eslint/no-non-null-assertion: "off" */
// QueryService
import { getValueFromFlattenedKey } from "../../utils";
import { BaseResponseWithMultiple, BaseResponseWithSingle, Status } from "../Shared";
import {
  identityAppUsers,
  organisationOrganisations,
  organisationOrganisationUsers,
  projectProjects,
} from "../TempData";
import {
  DownloadProjectDocumentRequest,
  DownloadProjectDocumentResponse,
  GetCurrentUserResponse,
  GetOrganisationDetailsRequest,
  GetOrganisationDetailsResponse,
  GetOrganisationEmbeddingPreferencesRequest,
  GetOrganisationEmbeddingPreferencesResponse,
  GetOrganisationUserDetailsRequest,
  GetOrganisationUserDetailsResponse,
  GetProjectAggregateRequest,
  GetProjectAggregateResponse,
  GetProjectDetailsRequest,
  GetProjectDetailsResponse,
  GetProjectIssuancesRequest,
  GetProjectIssuancesResponse,
  GetStatsAggregateRequest,
  GetStatsAggregateResponse,
  LookupCountriesResponse,
  LookupRolesResponse,
  SearchOrganisationUsersRequest,
  SearchOrganisationUsersResponse,
  SearchProjectDocumentsRequest,
  SearchProjectDocumentsResponse,
  SearchProjectsRequest,
  SearchProjectsResponse,
} from "./Types";

export async function getOrganisationEmbeddingPreferences(
  req: GetOrganisationEmbeddingPreferencesRequest
): Promise<BaseResponseWithMultiple<GetOrganisationEmbeddingPreferencesResponse>> {
  const organisation = organisationOrganisations.find((o) => o.uuid === req.organisationUuid)!;

  const data: GetOrganisationEmbeddingPreferencesResponse[] = organisation.embeddingPreferences;

  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return {
    errors: [],
    status: Status.Success,
    data,
  };
}

export async function downloadProjectDocument(
  req: DownloadProjectDocumentRequest
): Promise<BaseResponseWithSingle<DownloadProjectDocumentResponse>> {
  const data: DownloadProjectDocumentResponse = {
    file: new File([], `${req.projectDocumentUuid}.txt`),
  };
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return {
    errors: [],
    status: Status.Success,
    data,
  };
}

export async function getProjectIssuances(
  req: GetProjectIssuancesRequest
): Promise<BaseResponseWithMultiple<GetProjectIssuancesResponse>> {
  const data: GetProjectIssuancesResponse[] = projectProjects.find((p) => p.uuid === req.projectUuid)?.issuances || [];
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return {
    errors: [],
    status: Status.Success,
    data,
  };
}

export async function searchOrganisationUsers(
  req: SearchOrganisationUsersRequest
): Promise<BaseResponseWithSingle<SearchOrganisationUsersResponse>> {
  const organisation = organisationOrganisations.find((o) => o.uuid === req.organisationUuid)!;
  const organisationId = organisation.id;
  let results = organisationOrganisationUsers
    .filter((ou) => ou.organisationId === organisationId)
    .map((ou) => ({
      role: ou.role,
      active: ou.active,
      user: identityAppUsers.find((u) => u.uuid === ou.userUuid)!,
    }));
  if (req.sort && req.sort.length > 0) {
    const currentSort = req.sort[0];
    results = results.sort((a, b) => {
      const a1 = getValueFromFlattenedKey(currentSort.key, a);
      const b1 = getValueFromFlattenedKey(currentSort.key, b);
      const [first] = [a1, b1].sort();
      if (first === a1 && currentSort.direction === "asc") return -1;
      if (first === a1 && currentSort.direction === "desc") return 1;
      if (first === b1 && currentSort.direction === "asc") return 1;
      if (first === b1 && currentSort.direction === "desc") return -1;
      return 0;
    });
  }

  if (req.filter?.results?.user?.email?.value)
    results = results.filter(
      (v) =>
        v.user?.email
          .toLowerCase()
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .indexOf(req.filter.results.user.email.value.toLowerCase()) >= 0
    );
  if (req.filter?.results?.user?.firstName?.value) {
    results = results.filter(
      (v) =>
        v.user?.firstName
          ?.toLowerCase()
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .indexOf(req.filter.results.user.firstName.value.toLowerCase()) >= 0
    );
  }
  if (req.filter?.results?.user?.lastName?.value)
    results = results.filter(
      (v) =>
        v.user?.lastName
          .toLowerCase()
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .indexOf(req.filter.results.user.lastName.value.toLowerCase()) >= 0
    );
  if (req.filter?.results?.role?.value)
    results = results.filter(
      (v) =>
        v.role
          ?.toLowerCase()
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .indexOf(req.filter.results.role.value.toLowerCase()) >= 0
    );
  const totalCount = results.length;

  /*
  if (req.paging) {
    results = results.slice(
      ((req.paging.startAt || 1) - 1) * req.paging.limit,
      (req.paging.startAt || 1) * req.paging.limit
    );
  }
  */
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return {
    errors: [],
    status: Status.Success,
    data: {
      results,
      paging: {
        startCursor: "TEMP",
        endCursor: "TEMP",
        hasNextPage: true,
        hasPreviousPage: true,
        total: totalCount,
      },
    },
  };
}

export async function searchProjectDocuments(
  req: SearchProjectDocumentsRequest
): Promise<BaseResponseWithSingle<SearchProjectDocumentsResponse>> {
  const project = projectProjects.find((p) => p.uuid === req.projectUuid)!;
  const user = identityAppUsers[1];
  let results = project.documents.map((pd) => ({
    uuid: pd.uuid,
    type: pd.type,
    visibility: pd.visibility,
    activityName: pd.activityName,
    cachedCreatedAt: pd.cachedCreatedAt,
    file: {
      url: pd.cached.url,
    },
    cachedCreatedByUser: {
      avatar: {
        url: user.avatar!.url,
      },
      firstName: user.firstName,
      lastName: user.lastName,
      calculatedFullName: `${user.firstName} ${user.lastName}`,
    },
  }));

  if (req.sort && req.sort.length > 0) {
    const currentSort = req.sort[0];
    results = results.sort((a, b) => {
      const a1 = getValueFromFlattenedKey(currentSort.key, a);
      const b1 = getValueFromFlattenedKey(currentSort.key, b);
      const [first] = [a1, b1].sort();
      if (first === a1 && currentSort.direction === "asc") return -1;
      if (first === a1 && currentSort.direction === "desc") return 1;
      if (first === b1 && currentSort.direction === "asc") return 1;
      if (first === b1 && currentSort.direction === "desc") return -1;
      return 0;
    });
  }

  results = results.filter((v) => {
    return (
      !req ||
      !req.filter ||
      !req.filter.results ||
      Object.keys(req.filter.results).length === 0 ||
      (req.filter?.results?.type?.value &&
        v.type?.toLowerCase().indexOf(req!.filter!.results!.type!.value.toLowerCase()) >= 0) ||
      (req.filter?.results?.activityName?.value &&
        v.activityName?.toLowerCase().indexOf(req!.filter!.results!.activityName!.value.toLowerCase()) >= 0) ||
      (req.filter?.results?.visibility?.value &&
        v.visibility?.toLowerCase().indexOf(req!.filter!.results!.visibility!.value.toLowerCase()) >= 0)
    );
  });

  const totalCount = results.length;
  /*
  if (req.paging) {
    results = results.slice(
      ((req.paging.current || 1) - 1) * req.paging.limit,
      (req.paging.current || 1) * req.paging.limit
    );
  }
   */
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return {
    errors: [],
    status: Status.Success,
    // data: undefined,
    data: {
      results,
      paging: {
        startCursor: "TEMP",
        endCursor: "TEMP",
        hasNextPage: true,
        hasPreviousPage: true,
        total: totalCount,
      },
    },
  };
}

export async function searchProjects(
  req: SearchProjectsRequest
): Promise<BaseResponseWithSingle<SearchProjectsResponse>> {
  let results = projectProjects.map((project) => ({
    addressCountryCode: project.addressCountryCode,
    cachedPiuQuantity: project.cachedPiuQuantity,
    cachedVcuQuantity: project.cachedVcuQuantity,
    developer: project.developer,
    displayName: project.displayName,
    uuid: project.uuid,
    standard: project.standard,
    status: project.status,
    validator: project.validator,
  }));

  if (req.sort && req.sort.length > 0) {
    const currentSort = req.sort[0];
    results = results.sort((a, b) => {
      const a1 = getValueFromFlattenedKey(currentSort.key, a);
      const b1 = getValueFromFlattenedKey(currentSort.key, b);
      const [first] = [a1, b1].sort();
      if (first === a1 && currentSort.direction === "asc") return -1;
      if (first === a1 && currentSort.direction === "desc") return 1;
      if (first === b1 && currentSort.direction === "asc") return 1;
      if (first === b1 && currentSort.direction === "desc") return -1;
      return 0;
    });
  }

  results = results.filter((v) => {
    return (
      !req ||
      !req.filter ||
      !req.filter.results ||
      Object.keys(req.filter.results).length === 0 ||
      (req.filter.results.developer?.uuid?.value &&
        v.developer.uuid?.toLowerCase().indexOf(req.filter.results.developer.uuid.value.toLowerCase()) >= 0) ||
      (req.filter.results.standard?.uuid?.value &&
        v.standard.uuid?.toLowerCase().indexOf(req.filter.results.standard.uuid.value.toLowerCase()) >= 0) ||
      (req.filter.results.displayName?.value &&
        v.displayName?.toLowerCase().indexOf(req.filter.results.displayName.value.toLowerCase()) >= 0) ||
      (req.filter.results.standard?.displayName?.value &&
        v.standard.displayName?.toLowerCase().indexOf(req.filter.results.standard?.displayName?.value?.toLowerCase()) >=
          0) ||
      (req.filter.results.validator?.displayName?.value &&
        v.validator.displayName
          ?.toLowerCase()
          .indexOf(req.filter.results.validator?.displayName?.value?.toLowerCase()) >= 0) ||
      (req.filter.results.status?.value &&
        v.status?.toLowerCase().indexOf(req.filter.results.status?.value?.toLowerCase()) >= 0)
    );
  });

  const totalCount = results.length;

  /*
  if (req.paging) {
    results = results.slice(
      ((req.paging.current || 1) - 1) * req.paging.limit,
      (req.paging.current || 1) * req.paging.limit
    );
  }
   */

  const data: SearchProjectsResponse = {
    results,
    paging: {
      startCursor: "TEMP",
      endCursor: "TEMP",
      hasNextPage: true,
      hasPreviousPage: true,
      total: totalCount,
    },
  };
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return {
    errors: [],
    status: Status.Success,
    data,
  };
}

export async function getOrganisationUserDetails(
  req: GetOrganisationUserDetailsRequest
): Promise<BaseResponseWithSingle<GetOrganisationUserDetailsResponse>> {
  const user = identityAppUsers.find((u) => {
    return u.uuid === req.userUuid;
  })!;
  const organisationUser = organisationOrganisationUsers.find((ou) => ou.userUuid === req.userUuid)!;

  const data: GetOrganisationUserDetailsResponse = {
    user,
    active: organisationUser.active,
    role: organisationUser.role,
    rowVersion: organisationUser.rowVersion,
  };
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return {
    errors: [],
    status: Status.Success,
    data,
  };
}

export async function getProjectDetails(
  req: GetProjectDetailsRequest
): Promise<BaseResponseWithSingle<GetProjectDetailsResponse>> {
  const project = projectProjects.find((p) => p.uuid === req.projectUuid)!;
  const data: GetProjectDetailsResponse = {
    addressCity: project.addressCity,
    addressCountryCode: project.addressCountryCode,
    addressLine1: project.addressLine1,
    addressLine2: project.addressLine2,
    addressLine3: project.addressLine3,
    addressLine4: project.addressLine4,
    uuid: project.uuid,
    displayName: project.displayName,
    validator: project.validator,
    tags: project.tags,
    listing: {
      content: JSON.parse(project.listing.cachedContent),
      availableForSale: project.listing.cachedAvailableForSale || true,
      showPriceInDirectory: project.listing.cachedShowPriceInDirectory || true,
      contactAlternativeEmail: project.listing.cachedContactAlternativeEmail,
      contactOptOut: project.listing.cachedContactOptOut || false,
      hidden: project.listing.cachedHidden || false,
      rowVersion: project.listing.rowVersion,
    },
    addressPostcode: project.addressPostcode,
    areaNetHa: project.areaNetHa,
    standard: project.standard,
    developer: project.developer,
    cachedPiuQuantity: project.cachedPiuQuantity,
    cachedVcuQuantity: project.cachedVcuQuantity,
    standardSpecificData: JSON.parse(project.standardSpecificData),
    listingFiles: project.listingFiles,
    locationCoordinates: project.locationCoordinates,
    locationGridReference: project.locationGridreference,
    status: project.status,
    dataScore: project.dataScore,
    rowVersion: project.rowVersion,
  };
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return {
    errors: [],
    status: Status.Success,
    data,
  };
}

export async function getOrganisationDetails(
  req: GetOrganisationDetailsRequest
): Promise<BaseResponseWithSingle<GetOrganisationDetailsResponse>> {
  const organisation = organisationOrganisations.find((o) => o.uuid === req.organisationUuid)!;

  const data: GetOrganisationDetailsResponse = {
    listing: {
      content: organisation.listing == null ? null : JSON.parse(organisation.listing.cachedContent),
      rowVersion: 1,
    },
    addresses: organisation.addresses,
    contactEmail: organisation.contactEmail,
    contactPhone: organisation.contactPhone,
    files: organisation.files,
    displayName: organisation.displayName,
    listingFiles: organisation.listingFiles,
    socialMediaInstagramUsername: organisation.socialMediaInstagramUsername,
    socialMediaTwitterUsername: organisation.socialMediaTwitterUsername,
    socialMediaYoutubeChannel: organisation.socialMediaYoutubeChannel,
    socialMediaLinkedInUrl: organisation.socialMediaLinkedinUrl,
    socialMediaFacebookUrl: organisation.socialMediaFacebookUrl,
    websiteUrl: organisation.websiteUrl,
    rowVersion: 1,
  };
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return {
    errors: [],
    status: Status.Success,
    data,
  };
}

const groupBy = <T>(arr: T[], keys: (keyof T)[]): { [key: string]: T[] } => {
  // from https://gist.github.com/robmathers/1830ce09695f759bf2c4df15c29dd22d
  return arr.reduce((storage, item) => {
    const objKey = keys
      .map((key) => {
        if ((key as string).includes(".name")) {
          const x = <T[keyof T] & { name: string }>item["standard" as keyof T];
          return x.name;
        }
        return `${item[key]}`;
      })
      .join(":");

    if (storage[objKey]) {
      storage[objKey].push(item);
    } else {
      // eslint-disable-next-line no-param-reassign
      storage[objKey] = [item];
    }
    return storage;
  }, {} as { [key: string]: T[] });
};

export async function getProjectAggregate(
  req: GetProjectAggregateRequest
): Promise<BaseResponseWithMultiple<GetProjectAggregateResponse>> {
  let data: GetProjectAggregateResponse[] = [];
  // we only support filtering by developer & organisation currently
  if (
    req.filterBy !== "developer" &&
    req.filterBy !== "developerOrganisationUuid" &&
    req.filterBy !== "standard" &&
    req.filterBy !== "standardOrganisationUuid"
  )
    throw new Error(
      `Unsupported filterBy value - must be one of 'developer', 'developerOrganisationUuid', but received '${req.filterBy}'`
    );

  if (req.filterBy === "developer" || req.filterBy === "developerOrganisationUuid") {
    const developerProjects = projectProjects.filter((p) => p.developer.uuid === req.filterValue);

    switch (req.aggregate) {
      case "status": {
        const groupByStatus = groupBy(developerProjects, ["status"]);
        data = Object.keys(groupByStatus).map((key) => ({
          key,
          value: groupByStatus[key].length,
        }));
        break;
      }
      case "units": {
        data = [
          {
            key: "Verified carbon units (VCUs)",
            value: developerProjects.reduce<number>(
              (previousValue, currentValue) => previousValue + currentValue.cachedVcuQuantity,
              0
            ),
          },
          {
            key: "Pending issuance units (PIUs)",
            value: developerProjects.reduce<number>(
              (previousValue, currentValue) => previousValue + currentValue.cachedPiuQuantity,
              0
            ),
          },
        ];
        break;
      }
      case "id": {
        const groupByClause = req.groupBy ? (req.groupBy as any) : "";
        const groupByStatus = groupBy(developerProjects, [groupByClause]);

        data = Object.keys(groupByStatus).map((key) => ({
          key,
          value: groupByStatus[key].length,
        }));
        break;
      }
      case "cachedVcuQuantity": {
        data = [
          {
            key: "VCUs",
            value: developerProjects.reduce<number>(
              (previousValue, currentValue) => previousValue + currentValue.cachedVcuQuantity,
              0
            ),
          },
        ];
        break;
      }
      case "cachedPiuQuantity": {
        data = [
          {
            key: "PIUs",
            value: developerProjects.reduce<number>(
              (previousValue, currentValue) => previousValue + currentValue.cachedPiuQuantity,
              0
            ),
          },
        ];
        break;
      }
      default: {
        throw new Error(`Aggregation '${req.aggregate}' not supported - only 'status' and 'units' are supported`);
      }
    }
  }

  if (req.filterBy === "standard" || req.filterBy === "standardOrganisationUuid") {
    const organisationProjects = projectProjects.filter((p) => p.standard.uuid === req.filterValue);

    switch (req.aggregate) {
      case "status": {
        const groupByStatus = groupBy(organisationProjects, ["status"]);
        data = Object.keys(groupByStatus)
          .map((key) => ({
            key,
            value: groupByStatus[key].length,
          }))
          .reverse();
        break;
      }
      case "cachedPiuQuantity": {
        data = [
          {
            key: "PIUs",
            value: organisationProjects.reduce<number>(
              (previousValue, currentValue) => previousValue + currentValue.cachedPiuQuantity,
              0
            ),
          },
        ];
        break;
      }
      case "cachedVcuQuantity": {
        data = [
          {
            key: "VCUs",
            value: organisationProjects.reduce<number>(
              (previousValue, currentValue) => previousValue + currentValue.cachedVcuQuantity,
              0
            ),
          },
        ];
        break;
      }
      case "id": {
        const groupByClause = req.groupBy ? (req.groupBy as any) : "";
        const groupByStatus = groupBy(organisationProjects, [groupByClause]);

        data = Object.keys(groupByStatus).map((key) => ({
          key,
          value: groupByStatus[key].length,
        }));
        break;
      }
      case "totalCarbonSequestrationByCountry": {
        const groupByClause = req.groupBy ? (req.groupBy as any) : "";
        const groupByStatus = groupBy(organisationProjects, [groupByClause]);

        data = Object.keys(groupByStatus).map((key) => ({
          key,
          value: groupByStatus[key].reduce<number>(
            (previousValue, currentValue) =>
              previousValue + JSON.parse(currentValue.standardSpecificData).totalPredictedCarbonSequestration,
            0
          ),
        }));
        break;
      }
      default: {
        throw new Error(`Aggregation '${req.aggregate}' not supported - only 'status' and 'units' are supported`);
      }
    }
  }
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return {
    errors: [],
    status: Status.Success,
    data,
  };
}

export async function getCurrentUser(): Promise<BaseResponseWithSingle<GetCurrentUserResponse>> {
  // TODO: refactor to remove use of global variables once we have a server-side session
  const currentUserUuid = window.kanaCurrentUserUuid;
  const user = identityAppUsers.find((u) => u.uuid === currentUserUuid)!;
  const userOrganisations = organisationOrganisationUsers
    .filter((ou) => ou.userUuid === currentUserUuid)
    .map((ou) => ({
      organisationUuid: organisationOrganisations.find((o) => o.id === ou.organisationId)!.uuid,
      role: ou.role,
    }));

  const data: GetCurrentUserResponse = {
    firstName: user.firstName,
    lastName: user.lastName,
    userUuid: user.uuid,
    email: user.email,
    avatar: user.avatar
      ? {
          url: user.avatar.url,
          uuid: user.avatar.uuid,
        }
      : null,
    organisations: userOrganisations,
    rowVersion: user.rowVersion,
  };
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return {
    errors: [],
    status: Status.Success,
    data,
  };
}

export async function lookupRoles(): Promise<BaseResponseWithMultiple<LookupRolesResponse>> {
  const data: LookupRolesResponse[] = [
    {
      key: "Owner",
      valueString: "Owner",
    },
    {
      key: "Admin",
      valueString: "Admin",
    },
    {
      key: "Staff",
      valueString: "Staff",
    },
  ];
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return {
    errors: [],
    status: Status.Success,
    data,
  };
}

export async function lookupCountries(): Promise<BaseResponseWithMultiple<LookupCountriesResponse>> {
  const data: LookupCountriesResponse[] = [
    {
      key: "GB-SCT",
      valueString: "Scotland",
    },
    {
      key: "GB-ENG",
      valueString: "England",
    },
    {
      key: "GB-WLS",
      valueString: "Wales",
    },
    {
      key: "GB-NIR",
      valueString: "Northern Ireland",
    },
  ];
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return {
    errors: [],
    status: Status.Success,
    data,
  };
}

export async function getStatsAggregate(
  req: GetStatsAggregateRequest
): Promise<BaseResponseWithMultiple<GetStatsAggregateResponse>> {
  const data: GetStatsAggregateResponse[] = [
    {
      objectName: "Claymore Hill",
      objectUuid: "171c26f0-d5ce-11ec-9d64-0242ac120004",
      value: 5,
    },
  ];
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return {
    errors: [],
    status: Status.Success,
    data,
  };
}
