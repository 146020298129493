import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { PublicCodeDetailsParams } from "../../../models";
import { searchProjects } from "../../../service/publicquery";
import { Status } from "../../../service/Shared";
import { getPublicCodeComparisonRoute, getPublicProjectOverviewRoute } from "../../../utils/routes";
import { DisplayHorizontalProject } from "../../../widget/cards/HorizontalProjectCards/models";

interface UsePublicCodeDetailsPageTemplateReturnData {
  organisationUuid: string | undefined;
  organisationProjects: DisplayHorizontalProject[] | undefined;
  navigateBackToProject: () => void;
  navigateBackToCodeComparison: () => void;
}

export const usePublicCodeDetailsPageTemplate = (): UsePublicCodeDetailsPageTemplateReturnData => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { organisationUuid } = useParams<PublicCodeDetailsParams>();

  const [organisationProjects, setOrganisationProjects] = useState<DisplayHorizontalProject[] | undefined>();

  const fetchOrganisationProjects = useCallback(async (uuid: string) => {
    const response = await searchProjects({
      paging: {
        limit: 4,
      },
      filter: {
        results: {
          standard: {
            uuid: { operator: "in", value: uuid },
          },
        },
      },
    });

    if (response.status === Status.Success) {
      const projects = response.data?.results.map((el) => ({
        uuid: el.uuid,
        imageSource: el.listingFiles?.length > 0 ? el.listingFiles[0]?.file?.url : null,
        displayName: el.displayName,
        addressCountryCode: el.addressCountryCode || "",
        developerSmallLogoFileUrl: el.developer.files.find((file) => file.type === "SmallLogo")?.file.url || null,
        developerDisplayName: el.developer?.displayName || undefined,
        codeName: el.standard.displayName || undefined,
      }));

      if (projects) {
        setOrganisationProjects(projects.slice(0, 5));
      }
    }
  }, []);

  useEffect(() => {
    if (organisationUuid) {
      fetchOrganisationProjects(organisationUuid);
    }
  }, [fetchOrganisationProjects, organisationUuid]);

  const navigateBackToProject = (): void => {
    const projectUuid = searchParams.get("projectUuid");
    if (projectUuid) {
      navigate(getPublicProjectOverviewRoute(projectUuid));
    }
  };

  const navigateBackToCodeComparison = (): void => {
    navigate(getPublicCodeComparisonRoute());
  };

  return {
    organisationUuid,
    organisationProjects,
    navigateBackToProject,
    navigateBackToCodeComparison,
  };
};
