import "./DeveloperSingleCardTemplate.css";

import { ReactNode, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ArrowFullLeftDarkIcon } from "../../assets";
import { NavigationActiveElement } from "../../models";
import { getUserSettingsRoute } from "../../utils/routes";
import { Button, Column, DeveloperHeader, FullPageCard, LeftNavigation, Row } from "../../widget";

interface DeveloperSingleCardTemplateProps {
  activeElement?: NavigationActiveElement;
  pageTitle: string | undefined;
  children: ReactNode;
}

export const DeveloperSingleCardTemplate = ({
  activeElement,
  pageTitle,
  children,
}: DeveloperSingleCardTemplateProps): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();

  const isEditUserPage = useMemo(
    () => location.pathname.includes("users") && location.pathname.includes("edit"),
    [location]
  );

  return (
    <div className="DeveloperSingleCardTemplate">
      <div className="DeveloperSingleCardTemplateNavigation">
        <LeftNavigation activeElement={activeElement} />
      </div>
      <div className="DeveloperSingleCardTemplateContainer">
        {isEditUserPage ? (
          <div className="HeaderContainer">
            <Button
              text="Back to users"
              variant="secondary"
              icon={<ArrowFullLeftDarkIcon />}
              iconPosition="left"
              onClick={() => navigate(getUserSettingsRoute())}
            />
            <DeveloperHeader />
          </div>
        ) : (
          <DeveloperHeader />
        )}
        <Row spacingV="ll">
          <Column span={12}>
            <h1>{pageTitle}</h1>
          </Column>
        </Row>
        <FullPageCard>{children}</FullPageCard>
      </div>
    </div>
  );
};
