// adapted from https://github.com/ant-design/ant-design/blob/master/components/notification/index.tsx
import "./Toast.css";

import Notification from "rc-notification";
import type { NotificationInstance as RCNotificationInstance } from "rc-notification/lib/Notification";
import * as React from "react";

import { AlertIcon, ErrorIcon, InformationIcon, SuccessIcon } from "../../../assets";
import createUseNotification from "./useToast";

export type NotificationPlacement = "top" | "topLeft" | "topRight" | "bottom" | "bottomLeft" | "bottomRight";

export type IconType = "success" | "info" | "error" | "warning";

const notificationInstance: {
  [key: string]: Promise<RCNotificationInstance>;
} = {};
let defaultDuration = 4.5;
let defaultTop = 24;
let defaultBottom = 24;
let defaultPlacement: NotificationPlacement = "top";
let defaultGetContainer: () => HTMLElement;
let maxCount: number;

export interface ConfigProps {
  top?: number;
  bottom?: number;
  duration?: number;
  prefixCls?: string;
  placement?: NotificationPlacement;
  getContainer?: () => HTMLElement;
  closeIcon?: React.ReactNode;
  rtl?: boolean;
  maxCount?: number;
}

function setNotificationConfig(options: ConfigProps): void {
  const { duration, placement, bottom, top, getContainer } = options;
  if (duration !== undefined) {
    defaultDuration = duration;
  }
  if (placement !== undefined) {
    defaultPlacement = placement;
  }
  if (bottom !== undefined) {
    defaultBottom = bottom;
  }
  if (top !== undefined) {
    defaultTop = top;
  }
  if (getContainer !== undefined) {
    defaultGetContainer = getContainer;
  }
  if (options.maxCount !== undefined) {
    maxCount = options.maxCount;
  }
}

function getPlacementStyle(
  placement: NotificationPlacement,
  top: number = defaultTop,
  bottom: number = defaultBottom
): React.CSSProperties {
  let style;
  switch (placement) {
    case "top":
      style = {
        left: "50%",
        transform: "translateX(-50%)",
        right: "auto",
        top,
        bottom: "auto",
      };
      break;
    case "topLeft":
      style = {
        left: 0,
        top,
        bottom: "auto",
      };
      break;
    case "topRight":
      style = {
        right: 0,
        top,
        bottom: "auto",
      };
      break;
    case "bottom":
      style = {
        left: "50%",
        transform: "translateX(-50%)",
        right: "auto",
        top: "auto",
        bottom,
      };
      break;
    case "bottomLeft":
      style = {
        left: 0,
        top: "auto",
        bottom,
      };
      break;
    default:
      style = {
        right: 0,
        top: "auto",
        bottom,
      };
      break;
  }
  return style;
}

function getNotificationInstance(
  args: ArgsProps,
  callback: (info: { prefixCls: string; instance: RCNotificationInstance }) => void
): void {
  const { placement = defaultPlacement, top, bottom, getContainer = defaultGetContainer } = args;
  const cacheKey = `Toast-${placement}`;
  const cacheInstance = notificationInstance[cacheKey];

  if (cacheInstance) {
    Promise.resolve(cacheInstance).then((instance) => {
      callback({ prefixCls: `Toast-notice`, instance });
    });

    return;
  }

  const notificationClass = `Toast-${placement}`;

  notificationInstance[cacheKey] = new Promise((resolve) => {
    Notification.newInstance(
      {
        prefixCls: "Toast",
        className: notificationClass,
        style: getPlacementStyle(placement, top, bottom),
        getContainer,
        maxCount,
        animation: "move-up",
      },
      (notification) => {
        resolve(notification);
        callback({
          prefixCls: `Toast-notice`,
          instance: notification,
        });
      }
    );
  });
}

const typeToIcon = {
  success: SuccessIcon,
  info: InformationIcon,
  error: ErrorIcon,
  warning: AlertIcon,
};

export interface ArgsProps {
  message: React.ReactNode;
  description?: React.ReactNode;
  btn?: React.ReactNode;
  key?: string;
  onClose?: () => void;
  duration?: number | null;
  icon?: React.ReactNode;
  placement?: NotificationPlacement;
  maxCount?: number;
  style?: React.CSSProperties;
  // prefixCls?: string;
  className?: string;
  readonly type?: IconType;
  onClick?: () => void;
  top?: number;
  bottom?: number;
  getContainer?: () => HTMLElement;
  closeIcon?: React.ReactNode;
}

function getRCNoticeProps(
  { duration: durationArg, icon, type, description, message, btn, onClose, onClick, key, style, className }: ArgsProps,
  prefixCls: string
): {
  content: JSX.Element;
  duration: number | null;
  closable: boolean | undefined;
  onClose: (() => void) | undefined;
  onClick: (() => void) | undefined;
  key: string | undefined;
  style: React.CSSProperties;
  className: string;
} {
  const duration = durationArg === undefined ? defaultDuration : durationArg;

  let iconNode: React.ReactNode = null;
  if (icon) {
    iconNode = <span className={`${prefixCls}-icon`}>{icon}</span>;
  } else if (type) {
    iconNode = React.createElement(typeToIcon[type] || null, {
      className: `${prefixCls}-icon ${prefixCls}-icon-${type}`,
    });
  }

  const autoMarginTag =
    !description && iconNode ? <span className={`${prefixCls}-message-single-line-auto-margin`} /> : null;

  return {
    content: (
      <div className={iconNode ? `${prefixCls}-with-icon` : ""} role="alert">
        {iconNode}
        <div className={`${prefixCls}-message body2`}>
          {autoMarginTag}
          {message}
        </div>
        <div className={`${prefixCls}-description`}>{description}</div>
        {btn ? <span className={`${prefixCls}-btn`}>{btn}</span> : null}
      </div>
    ),
    duration,
    closable: true,
    onClose,
    onClick,
    key,
    style: style || {},
    className: `${className} ${prefixCls}-${type}`,
  };
}

function notice(args: ArgsProps): void {
  getNotificationInstance(args, ({ prefixCls, instance }) => {
    instance.notice(getRCNoticeProps(args, prefixCls));
  });
}

const Toast: NotificationApi = {
  open: notice,
  close(key: string) {
    Object.keys(notificationInstance).forEach((cacheKey) =>
      Promise.resolve(notificationInstance[cacheKey]).then((instance) => {
        instance.removeNotice(key);
      })
    );
  },
  config: setNotificationConfig,
  destroy() {
    Object.keys(notificationInstance).forEach((cacheKey) => {
      Promise.resolve(notificationInstance[cacheKey]).then((instance) => {
        instance.destroy();
      });
      delete notificationInstance[cacheKey]; // lgtm[js/missing-await]
    });
  },
  error: (args: ArgsProps) =>
    Toast.open({
      ...args,
      type: "error",
    }),
  warning: (args: ArgsProps) =>
    Toast.open({
      ...args,
      type: "warning",
    }),
  warn: (args: ArgsProps) =>
    Toast.open({
      ...args,
      type: "warning",
    }),
  success: (args: ArgsProps) =>
    Toast.open({
      ...args,
      type: "success",
    }),
  info: (args: ArgsProps) =>
    Toast.open({
      ...args,
      type: "info",
    }),
  useNotification: createUseNotification(getNotificationInstance, getRCNoticeProps),
};

export interface NotificationInstance {
  success(args: ArgsProps): void;
  error(args: ArgsProps): void;
  info(args: ArgsProps): void;
  warning(args: ArgsProps): void;
  open(args: ArgsProps): void;
}

export interface NotificationApi extends NotificationInstance {
  warn(args: ArgsProps): void;
  close(key: string): void;
  config(options: ConfigProps): void;
  destroy(): void;

  // Hooks
  useNotification: () => NotificationInstance;
}

export default Toast as NotificationApi;
