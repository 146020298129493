import { useCallback, useEffect, useState } from "react";

import { getProjectAggregate, searchProjects, SearchProjectsResponse } from "../../../service/query";
import { ResultData, Status } from "../../../service/Shared";
// import { getAggregate } from "../../../service/stats";  // TODO ENG-557 hidden until post-launch
import { useAuth } from "../../../useAuth";
import { flattenObject } from "../../../utils";
import { getProjectDetailsByUuid } from "../../../utils/routes";
import {
  ChartData,
  DataGridColumnDefinition,
  DataGridCursorDataLoadEventHandler,
  DataGridCursorDataLoadProps,
  DataGridLinkCellFormatterData,
} from "../../../widget";

interface UseDashboardReturnData {
  statusChartData: ChartData;
  unitsChartData: ChartData;
  metricsChartData: ChartData;
  tableColumnsTopProjects: DataGridColumnDefinition[];
  isLoading: boolean;
  isLoadingProjects: boolean;
  onTopProjectsDataLoad: DataGridCursorDataLoadEventHandler;
}

interface ResultType {
  resultData: ResultData[];
  paging: {
    startCursor: string;
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    pageSize: number;
    totalCount: number;
  };
}

export function useDashboard(): UseDashboardReturnData {
  const { currentOrganisationUuid } = useAuth();
  const [statusChartData, setStatusChartData] = useState<ChartData>({});
  const [unitsChartData, setUnitsChartData] = useState<ChartData>({});
  const [metricsChartData, setMetricsChartData] = useState<ChartData>({});
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingProjects, setIsLoadingProjects] = useState(true);
  const tableColumnsTopProjects: DataGridColumnDefinition[] = [
    {
      key: "link",
      name: "Project",
      dataType: "string",
      formatter: "link",
    },
    { key: "standard.displayName", name: "Code", dataType: "string" },
    {
      name: "Status",
      key: "status",
      dataType: "string",
      formatter: "projectStatusPill",
      minWidth: 200,
    },
    {
      key: "cachedPiuQuantity",
      name: "PIUs",
      dataType: "string",
      formatter: "align",
      alignment: "right",
    },
    {
      key: "cachedVcuQuantity",
      name: "VCUs",
      dataType: "string",
      formatter: "align",
      alignment: "right",
    },
  ];

  const fetchData = useCallback(async () => {
    const [statusAggregateResponse, piuUnitsAggregateResponse, vcuUnitsAggregateResponse, metricsAggregateResponse] =
      await Promise.all([
        getProjectAggregate({
          filterBy: "developer",
          filterOperator: "eq",
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filterValue: currentOrganisationUuid!,
          groupBy: "status",
          aggregation: "count",
          aggregate: "id",
        }),
        getProjectAggregate({
          filterBy: "developer",
          filterOperator: "eq",
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filterValue: currentOrganisationUuid!,
          aggregation: "sum",
          aggregate: "cachedPiuQuantity",
        }),
        getProjectAggregate({
          filterBy: "developer",
          filterOperator: "eq",
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filterValue: currentOrganisationUuid!,
          aggregation: "sum",
          aggregate: "cachedVcuQuantity",
        }),
        // TODO ENG-557 hidden until post-launch
        Promise.resolve({
          status: Status.Error,
          data: [{ key: "remove", objectName: "remove", value: 1 }],
        }),
        // getAggregate({
        //   periodicity: "AllTime",
        //   metric: "PageViews",
        //   objectType: "project",
        //   objectUuid: null,
        //   timestamp: new Date(),
        // }),
      ]);

    if (statusAggregateResponse.status === Status.Success && statusAggregateResponse.data) {
      setStatusChartData(
        statusAggregateResponse.data.reduce<ChartData>((previous, current) => {
          // eslint-disable-next-line no-param-reassign
          previous[current.key] = current.value;

          return previous;
        }, {})
      );
    }

    if (
      piuUnitsAggregateResponse.status === Status.Success &&
      piuUnitsAggregateResponse.data &&
      vcuUnitsAggregateResponse.status === Status.Success &&
      vcuUnitsAggregateResponse.data
    ) {
      setUnitsChartData({
        "Verified carbon units (VCUs)": vcuUnitsAggregateResponse.data[0].value,
        "Pending issuance units (PIUs)": piuUnitsAggregateResponse.data[0].value,
      });
    }

    if (metricsAggregateResponse.status === Status.Success && metricsAggregateResponse.data) {
      setMetricsChartData(
        metricsAggregateResponse.data.reduce<ChartData>((previous, current) => {
          // eslint-disable-next-line no-param-reassign
          previous[current.key] = current.value;

          return previous;
        }, {})
      );
    }
  }, []);

  useEffect(() => {
    fetchData().finally(() => {
      setIsLoading(false);
    });
  }, [fetchData]);

  const formatData = useCallback(
    (responseData: SearchProjectsResponse | undefined): ResultData[] =>
      responseData?.results?.map((el) => {
        const result = flattenObject(el);

        result.link = <DataGridLinkCellFormatterData>{
          text: el.displayName,
          to: getProjectDetailsByUuid(el.uuid),
        };

        return result;
      }) || [],
    []
  );

  const onTopProjectsDataLoad: DataGridCursorDataLoadEventHandler = async ({ paging }: DataGridCursorDataLoadProps) => {
    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize: 5,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };
    setIsLoadingProjects(true);

    await searchProjects({
      filter: {
        results: {
          developer: {
            uuid: {
              operator: "eq",
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              value: currentOrganisationUuid!,
            },
          },
        },
      },
      paging: {
        limit: 10,
      },
      sort: [
        {
          key: "cachedPiuQuantity" as "results.cachedPiuQuantity",
          direction: "desc",
        },
      ],
    })
      .then((response) => {
        data = {
          resultData: formatData(response.data),
          paging: {
            startCursor: response.data?.paging?.startCursor || "",
            endCursor: response.data?.paging?.endCursor || "",
            hasNextPage: false,
            hasPreviousPage: false,
            pageSize: paging.pageSize || 10,
            totalCount: response.data?.paging?.total || response.data?.results?.length || 0,
          },
        };
      })
      .finally(() => {
        setIsLoadingProjects(false);
      });
    return data;
  };

  return {
    statusChartData,
    unitsChartData,
    metricsChartData,
    tableColumnsTopProjects,
    isLoading,
    isLoadingProjects,
    onTopProjectsDataLoad,
  };
}
