// Note - commented code relates to avatar section of the form which has been temporarily removed, will be re-added later
import { GetCurrentUserResponse } from "../../../../../service/query";
import { ActionButtons, Column, ErrorBox, Row, TextInput } from "../../../../../widget";
import { useChangePersonalDetailsForm } from "./useChangePersonalDetailsForm";

interface ChangePersonalDetailsFormProps {
  defaultValues: GetCurrentUserResponse;
}

/* export type FileUploadHandle = {
  clearInput: () => void;
}; */

export const ChangePersonalDetailsForm = ({ defaultValues }: ChangePersonalDetailsFormProps): JSX.Element => {
  // TEMP: commented values are only used for avatar section
  const {
    // avatarUploadRef,
    // currentUserUuid,
    lastName,
    email,
    firstName,
    errors,
    setFirstName,
    setLastName,
    setEmail,
    // setProfilePicture,
    handleSubmit,
    onCancel,
  } = useChangePersonalDetailsForm({
    firstName: defaultValues.firstName,
    lastName: defaultValues.lastName,
    email: defaultValues.email,
    rowVersion: defaultValues.rowVersion,
  });

  return (
    <>
      {errors && errors.length > 0 && (
        <Row spacingV="ml">
          <Column span={8}>
            <ErrorBox title="Please solve the below issues before saving" text={errors.map((error) => error.message)} />
          </Column>
        </Row>
      )}
      <form onSubmit={(e) => handleSubmit(e)}>
        <Row spacingV="ll">
          <Column span={4}>
            <TextInput
              label="First name"
              name="firstName"
              value={firstName}
              onChange={(value) => setFirstName(value)}
            />
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={4}>
            <TextInput label="Last name" name="lastName" value={lastName} onChange={(value) => setLastName(value)} />
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={4}>
            <TextInput
              label="Email address"
              name="email"
              type="email"
              value={email}
              onChange={(value) => setEmail(value)}
            />
          </Column>
        </Row>
        {
          // TEMP: comment out avatar section
          /* <Row spacingV="ll">
          <Column span={4}>
            <SingleFileUpload
              ref={avatarUploadRef}
              onChange={(el) => setProfilePicture(el)}
              label="Profile picture"
              informationLabel="The image should be a square, maximum 500px x 500px"
            />
          </Column>
          <Column span={2} offset={1}>
            <Row spacingV="m">
              <Column span={12}>
                <h3>Current profile picture</h3>
              </Column>
            </Row>
            <Avatar
              userUuid={currentUserUuid}
              imageSource={defaultValues.avatar?.url}
              name={`${defaultValues.firstName} ${defaultValues.lastName}`}
            />
          </Column>
        </Row> */
        }
        <Row>
          <Column span={2}>
            <ActionButtons onCancel={onCancel} />
          </Column>
        </Row>
      </form>
    </>
  );
};
