import { DeveloperDetailsData } from "../../../route/public/developer/details/page/useDetails";
import { PublicDeveloperDetails } from "../components/PublicDeveloperDetails";
import { usePublicDeveloperDetailsPageTemplate } from "./usePublicDeveloperDetailsPageTemplate";

interface PublicDeveloperDetailsPageTemplateProps {
  developerDetailsData?: DeveloperDetailsData;
}

export const PublicDeveloperDetailsPageTemplate = ({
  developerDetailsData,
}: PublicDeveloperDetailsPageTemplateProps): JSX.Element => {
  const {
    dataIsLoading,
    projectsCoordinates,
    organisationUuid,
    navigateBackToProject,
    showContactUsModal,
    setShowContactUsModal,
  } = usePublicDeveloperDetailsPageTemplate();
  return (
    <div>
      {!dataIsLoading && developerDetailsData && (
        <PublicDeveloperDetails
          projectsCoordinates={projectsCoordinates}
          organisationUuid={organisationUuid}
          isPreview={false}
          developerDetailsData={developerDetailsData}
          navigateBackToProject={navigateBackToProject}
          showContactUsModal={showContactUsModal}
          setShowContactUsModal={setShowContactUsModal}
        />
      )}
    </div>
  );
};
