import { OverviewComponent } from "../components";
import { usePreview } from "./usePreview";

const Preview = (): JSX.Element => {
  const { isLoading, projectDetails, chartData, tags } = usePreview();
  return (
    <div>
      {!isLoading && <OverviewComponent projectDetails={projectDetails} chartData={chartData} tags={tags} isPreview />}
    </div>
  );
};

export default Preview;
