import { DeveloperErrorTemplate } from "../../../templates/DeveloperErrorTemplate/DeveloperErrorTemplate";

export const DeveloperError404 = (): JSX.Element => {
  return (
    <DeveloperErrorTemplate
      errorText="We can’t seem to find the page you were looking for. It may no longer exist, have changed location or be temporarily unavailable."
      errorCode="404"
    />
  );
};
