import "./Tabs.css";

import { Link, Outlet, useLocation } from "react-router-dom";

import { TabsList } from "../../../models";

interface TabsProps {
  tabsList: TabsList;
  size?: "medium";
  className?: string;
}

export const Tabs = ({ size = "medium", tabsList, className = "" }: TabsProps): JSX.Element => {
  const location = useLocation();

  return (
    <div>
      <nav className="Tabs">
        {tabsList.map((tab) => (
          <Link
            key={tab.label}
            to={tab.route}
            onClick={() => {
              // Required in order to not trigger the effect in the `useScrollToTop` custom hook
              window.isTab = true;
            }}
            className={`body2 TabElement TabElement_${size}${
              location.pathname.includes(tab.route.split("?")[0]) ? " TabElement_active" : ""
            }`}
          >
            {tab.label}
          </Link>
        ))}
      </nav>
      <div className={`TabsContent ${className}`}>
        <Outlet />
      </div>
    </div>
  );
};
