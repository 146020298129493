import { Dispatch, FormEvent, SetStateAction, useState } from "react";

import { acceptInvitation } from "../../../../service/identity";
import { ServiceError, Status } from "../../../../service/Shared";

interface UseAcceptInvitationFormReturnData {
  newPassword: string;
  newPasswordConfirmation: string;
  errors: ServiceError[] | undefined;
  setNewPassword: Dispatch<SetStateAction<string>>;
  setNewPasswordConfirmation: Dispatch<SetStateAction<string>>;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
}

export function useAcceptInvitationForm(
  urlToken: string,
  email: string,
  onSuccess: (e: boolean) => void
): UseAcceptInvitationFormReturnData {
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const [errors, setErrors] = useState<ServiceError[] | undefined>([]);

  async function handleSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault();
    setErrors([]);

    if (newPassword !== newPasswordConfirmation) {
      setErrors([
        {
          code: "NOT_MATCHING_PASSWORDS",
          message: "The passwords do not match",
        },
      ]);
      return;
    }

    const res = await acceptInvitation({
      email,
      token: urlToken,
      password: newPassword,
    });

    if (res.status === Status.Success) {
      onSuccess(true);
    }

    if (res.status === Status.Error) {
      setErrors(res.errors);
    }
  }

  return {
    newPassword,
    newPasswordConfirmation,
    errors,
    setNewPassword,
    setNewPasswordConfirmation,
    handleSubmit,
  };
}
