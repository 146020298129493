import { Navigate, Route, Routes } from "react-router-dom";

import {
  PublicCodeDetailsParams,
  PublicDeveloperDetailsParams,
  PublicDeveloperDetailsPreviewParams,
  PublicProjectOverviewPreviewParams,
  PublicProjectParams,
  PublicProjectTypeParams,
  PublicVerifierDetailsParams,
} from "./models";
import {
  AcceptInvitationPage,
  ForgottenPasswordCompletePage,
  ForgottenPasswordPage,
  LogonPage,
  RegisterPage,
} from "./route/authentication";
import { DashboardPage } from "./route/developer/dashboard";
import { DeveloperError403, DeveloperError404, DeveloperError500 } from "./route/developer/errors";
import { MarketingStatsPage } from "./route/developer/marketing/marketing-stats";
import { ChangePassword, ChangePersonalDetails } from "./route/developer/profile";
import {
  DetailsTab,
  DocumentsTab,
  IssuancesTab,
  MarketingSettingsTab,
  OverviewTab,
  ProjectPage,
  ProjectProvider,
  ProjectsPage,
} from "./route/developer/projects";
import { SettingsPage } from "./route/developer/settings";
import { EmbeddingTab } from "./route/developer/settings/embedding";
import { DeveloperMarketingAssetsTab } from "./route/developer/settings/marketing-assets";
import { OrganisationTab } from "./route/developer/settings/organisation";
import { UsersEditPage, UsersListPage, UsersSendInvitationPage } from "./route/developer/settings/users";
import { PublicPage } from "./route/public";
import { PublicCodeDetailsPage } from "./route/public/code/details";
import { PublicCodeComparisonPage } from "./route/public/code-comparison";
import { PublicDeveloperDetailsPage, PublicDeveloperDetailsPreviewPage } from "./route/public/developer/details";
import { PublicError404, PublicError500 } from "./route/public/errors";
import {
  PublicProjectDetailsTab,
  PublicProjectDocumentsTab,
  PublicProjectIssuancesTab,
  PublicProjectOverviewPreviewPage,
  PublicProjectOverviewTab,
  PublicProjectPageContainer,
  PublicProjectPreviewContainer,
} from "./route/public/project";
import { PublicProjectTypePage } from "./route/public/project-type";
import { PublicProjectTypesPage } from "./route/public/project-types";
import { PublicProjectsPage } from "./route/public/projects";
import { PublicVerifierDetailsPage } from "./route/public/verifier";
import { useAuth } from "./useAuth";
import { useScrollToTop } from "./useScrollToTop";

const RequireAuth = ({ children }: { children: JSX.Element }): JSX.Element => {
  const auth = useAuth();
  if (!auth.isAuthenticated()) {
    return <Navigate to="/logon" replace />;
  }

  return children;
};

const AppRouter = (): JSX.Element => {
  useScrollToTop();

  return (
    <Routes>
      <Route path="/" element={<Navigate to="logon" />} />
      <Route path="logon" element={<LogonPage />} />
      <Route path="register" element={<RegisterPage />} />
      <Route path="accept-invitation/:token" element={<AcceptInvitationPage />} />
      <Route path="accept-invitation" element={<AcceptInvitationPage />} />
      <Route path="forgotten-password" element={<ForgottenPasswordPage />} />
      <Route path="forgotten-password-complete/:token" element={<ForgottenPasswordCompletePage />} />
      <Route path="forgotten-password-complete" element={<ForgottenPasswordCompletePage />} />
      <Route path="/*" element={<PublicError404 />} />
      <Route
        path="/d/dashboard"
        element={
          <RequireAuth>
            <DashboardPage />
          </RequireAuth>
        }
      />
      <Route
        path="/d/projects"
        element={
          <RequireAuth>
            <ProjectsPage />
          </RequireAuth>
        }
      />
      <Route
        path="/d/projects/:projectUuid/*"
        element={
          <RequireAuth>
            <ProjectProvider>
              <ProjectPage />
            </ProjectProvider>
          </RequireAuth>
        }
      >
        <Route path="overview" element={<OverviewTab />} />
        <Route path="details" element={<DetailsTab />} />
        <Route path="issuances" element={<IssuancesTab />} />
        <Route path="documents" element={<DocumentsTab />} />
        <Route path="marketing-settings" element={<MarketingSettingsTab />} />
      </Route>
      <Route
        path="/d/settings/*"
        element={
          <RequireAuth>
            <SettingsPage />
          </RequireAuth>
        }
      >
        <Route path="users" element={<UsersListPage />} />
        <Route path="organisation" element={<OrganisationTab />} />
        <Route path="marketing-assets" element={<DeveloperMarketingAssetsTab />} />
        <Route path="marketing-assets/:previewUuid" element={<DeveloperMarketingAssetsTab />} />
        <Route path="embedding" element={<EmbeddingTab />} />
        <Route path="" element={<Navigate to="users" replace />} />
      </Route>
      <Route
        path="/d/settings/users/send-invitation"
        element={
          <RequireAuth>
            <UsersSendInvitationPage />
          </RequireAuth>
        }
      />
      <Route
        path="/d/settings/users/:userUuid/edit"
        element={
          <RequireAuth>
            <UsersEditPage />
          </RequireAuth>
        }
      />
      <Route
        path="/d/marketing/marketing-stats"
        element={
          <RequireAuth>
            <MarketingStatsPage />
          </RequireAuth>
        }
      />
      <Route
        path="/d/profile/change-password"
        element={
          <RequireAuth>
            <ChangePassword />
          </RequireAuth>
        }
      />
      <Route
        path="/d/profile/change-personal-details"
        element={
          <RequireAuth>
            <ChangePersonalDetails />
          </RequireAuth>
        }
      />
      <Route
        path={`d/developer/details/preview/:${PublicDeveloperDetailsPreviewParams.previewUuid}`}
        element={
          <RequireAuth>
            <PublicDeveloperDetailsPreviewPage />
          </RequireAuth>
        }
      />
      <Route path="d/project/*" element={<PublicPage />}>
        <Route
          path={`:${PublicProjectOverviewPreviewParams.uuid}/preview/:${PublicProjectOverviewPreviewParams.previewUuid}`}
          element={
            <RequireAuth>
              <PublicProjectPreviewContainer />
            </RequireAuth>
          }
        >
          <Route path="overview" element={<PublicProjectOverviewPreviewPage />} />
        </Route>
      </Route>
      <Route
        path="/d/403/access-denied"
        element={
          <RequireAuth>
            <DeveloperError403 />
          </RequireAuth>
        }
      />
      <Route
        path="/d/500/application-error"
        element={
          <RequireAuth>
            <DeveloperError500 />
          </RequireAuth>
        }
      />
      <Route
        path="/d/*"
        element={
          <RequireAuth>
            <DeveloperError404 />
          </RequireAuth>
        }
      />
      <Route path="/p/*" element={<PublicPage />}>
        <Route path="404/page-not-found" element={<PublicError404 />} />
        <Route path="500/application-error" element={<PublicError500 />} />
        <Route path="projects" element={<PublicProjectsPage />} />
        <Route path={`project/:${PublicProjectParams.uuid}/*`} element={<PublicProjectPageContainer />}>
          <Route path="overview" element={<PublicProjectOverviewTab />} />
          <Route path="details" element={<PublicProjectDetailsTab />} />
          <Route path="documents" element={<PublicProjectDocumentsTab />} />
          <Route path="issuances" element={<PublicProjectIssuancesTab />} />
          <Route path="" element={<Navigate to="overview" replace />} />
        </Route>
        <Route path={`code/:${PublicCodeDetailsParams.organisationUuid}/details`} element={<PublicCodeDetailsPage />} />
        <Route
          path={`developer/:${PublicDeveloperDetailsParams.organisationUuid}/details`}
          element={<PublicDeveloperDetailsPage />}
        />
        <Route
          path={`verifier/:${PublicVerifierDetailsParams.organisationUuid}/details`}
          element={<PublicVerifierDetailsPage />}
        />
        <Route
          path={`project-type/:${PublicProjectTypeParams.projectTypeIdentifier}`}
          element={<PublicProjectTypePage />}
        />
        <Route path="project-types" element={<PublicProjectTypesPage />} />
        <Route path="code-comparison" element={<PublicCodeComparisonPage />} />
        <Route path="*" element={<PublicError404 />} />
      </Route>
    </Routes>
  );
};

export default AppRouter;
