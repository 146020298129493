import { FormEvent, useCallback, useContext, useEffect, useState } from "react";

import { logError } from "../../../../../../service/error";
import { setProjectIssuances, updateIssuancePrices } from "../../../../../../service/project";
import { getProjectIssuances } from "../../../../../../service/query";
import { ResultData, Status } from "../../../../../../service/Shared";
import { flattenObject, parseDate } from "../../../../../../utils";
import {
  DataGridButtonCellFormatterData,
  DataGridColumnCurrency,
  DataGridColumnDefinition,
  DataGridInputCellFormatterData,
  DataGridSelectCellFormatterData,
  Toast,
} from "../../../../../../widget";
import { ProjectContext } from "../../ProjectContext";

type Issuance = {
  uuid: string;
  vintageStartDate: Date | null;
  vintageEndDate: Date | null;
  quantity: number | null;
  quantityType: string | null;
  price: number | null;
  verifier?: string;
  rowVersion: number;
};

interface UseIssuancesFormReturnData {
  chartData: { [key: string]: number };
  dataGridColumns: DataGridColumnDefinition[];
  dataGridData: ResultData[];
  isLoading: boolean;
  onSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
}

export const useIssuancesForm = (): UseIssuancesFormReturnData => {
  const { projectDetails } = useContext(ProjectContext);
  const readOnly = true;
  // TODO replace "readOnly = true" with commented code below in future
  // !projectDetails?.standardSpecificData?.missingPDD &&
  // projectDetails?.status !== "Under Development" &&
  // projectDetails?.status !== "Draft";

  const dataGridColumns: DataGridColumnDefinition[] = readOnly
    ? [
        {
          name: "Vintage",
          key: "vintage",
          dataType: "string",
          formatter: "align",
          alignment: "left",
        },
        {
          name: "Units",
          key: "quantity",
          dataType: "string",
          formatter: "align",
          alignment: "left",
        },
        {
          name: "Unit type",
          key: "quantityType",
          dataType: "string",
          formatter: "align",
          alignment: "left",
        },
        {
          name: "Verifier",
          key: "verifier",
          dataType: "string",
          alignment: "left",
        },
        {
          name: "Price",
          key: "priceInput",
          dataType: "string",
          formatter: "input",
          alignment: "left",
        },
      ]
    : [
        {
          name: "Vintage start",
          key: "vintageStartDateInput",
          dataType: "string",
          formatter: "input",
          alignment: "center",
        },
        {
          name: "Vintage end",
          key: "vintageEndDateInput",
          dataType: "string",
          formatter: "input",
          alignment: "center",
        },
        {
          name: "Type",
          key: "quantityTypeSelect",
          dataType: "string",
          formatter: "select",
          alignment: "center",
        },
        {
          name: "Quantity",
          key: "quantityInput",
          dataType: "string",
          formatter: "input",
          alignment: "center",
        },
        {
          name: "Price",
          key: "price",
          currency: DataGridColumnCurrency.Gbp,
          dataType: "number",
          formatter: "currency",
          alignment: "center",
        },
        {
          name: "",
          key: "clearButton",
          dataType: "string",
          formatter: "button",
          alignment: "center",
        },
      ];

  const [chartData, setChartData] = useState<{ [key: string]: number }>({});
  const [dataGridData, setDataGridData] = useState<ResultData[]>([]);
  const [issuances, setIssuances] = useState<Issuance[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [submittedIssuances, setSubmittedIssuances] = useState<Issuance[]>([]);

  useEffect(() => {
    let projectIssuancesGridData: ResultData[] = [];
    if (readOnly) {
      projectIssuancesGridData = issuances.map((d, index) => {
        const result = flattenObject({
          ...d,
          vintage: `${d.vintageStartDate?.getFullYear()}-${d.vintageEndDate?.getFullYear()}`,
        });

        result.priceInput = <DataGridInputCellFormatterData>{
          name: `price_${d.uuid}`,
          type: "number",
          value: d.price?.toString(),
          decimalPlaces: 2,
          onChange: (value) => {
            issuances[index].price = parseFloat(value);
          },
        };

        return result;
      });
    } else {
      projectIssuancesGridData = issuances.map((i, index) => ({
        vintageStartDate: i.vintageStartDate,
        vintageEndDate: i.vintageEndDate,
        vintageStartDateInput: <DataGridInputCellFormatterData>{
          name: `vintageStartDate_${index}`,
          type: "text",
          value: i.vintageStartDate?.toLocaleDateString("en-GB"),
          placeholder: "dd/mm/yyyy",
          onChange: (value) => {
            issuances[index].vintageStartDate = parseDate(value);
          },
        },
        vintageEndDateInput: <DataGridInputCellFormatterData>{
          name: `vintageEndDate_${index}`,
          type: "text",
          value: i.vintageEndDate?.toLocaleDateString("en-GB"),
          placeholder: "dd/mm/yyyy",
          onChange: (value) => {
            issuances[index].vintageEndDate = parseDate(value);
          },
        },
        quantityTypeSelect: <DataGridSelectCellFormatterData>{
          data: [
            { key: "PIU", value: "PIU" },
            { key: "VCU", value: "VCU" },
          ],
          initialKey: i.quantityType,
          onChange: (value) => {
            issuances[index].quantityType = value;
          },
        },
        quantityInput: <DataGridInputCellFormatterData>{
          name: `quantity_${index}`,
          type: "number",
          value: i.quantity?.toString(),
          onChange: (value) => {
            issuances[index].quantity = parseFloat(value);
          },
        },
        priceInput: <DataGridInputCellFormatterData>{
          name: `price_${index}`,
          type: "number",
          value: i.price?.toString(),
          decimalPlaces: 2,
          onChange: (value) => {
            issuances[index].price = parseFloat(value);
          },
        },
        clearButton: <DataGridButtonCellFormatterData>{
          text: "Clear",
          variant: "secondary",
          onClick: () => {
            issuances[index] = {
              uuid: "",
              vintageStartDate: null,
              vintageEndDate: null,
              quantity: null,
              quantityType: null,
              price: null,
              rowVersion: 1,
            };
            setIssuances([...issuances]);
          },
        },
      }));
    }

    setDataGridData(projectIssuancesGridData);

    const projectIssuancesChartData = issuances
      .filter((i) => i.vintageStartDate && i.vintageEndDate)
      .reduce((tmp: { [key: string]: number }, value) => {
        const vintageStartDate = value.vintageStartDate as Date;
        const vintageEndDate = value.vintageEndDate as Date;

        const period = `${vintageStartDate.getFullYear()}-${vintageEndDate.getFullYear()}`;

        if (!tmp[period]) {
          // eslint-disable-next-line no-param-reassign
          tmp[period] = 0;
        }

        // eslint-disable-next-line no-param-reassign
        tmp[period] += value.quantity as number;

        return tmp;
      }, {});

    setChartData(projectIssuancesChartData);
  }, [issuances]);

  const fetchData = useCallback(async () => {
    if (!projectDetails) return;

    const res = await getProjectIssuances({
      projectUuid: projectDetails.uuid,
    });

    if (res.status !== Status.Success) throw new Error("getProjectIssuances returned not success status");

    let projectIssuances: Issuance[] = [];

    if (res.data) {
      projectIssuances = res.data.map((d) => ({
        uuid: d.uuid,
        vintageStartDate: d.vintageStartDate,
        vintageEndDate: d.vintageEndDate,
        quantity: d.quantity,
        quantityType: d.quantityType,
        price: d.price,
        verifier: d.verifier?.displayName || undefined,
        rowVersion: d.rowVersion,
      }));
    }

    if (!readOnly) {
      const newIssuancesLength = 16 - projectIssuances.length;

      for (let i = 0; i < newIssuancesLength; i++) {
        projectIssuances.push({
          uuid: "",
          vintageStartDate: null,
          vintageEndDate: null,
          quantity: null,
          quantityType: null,
          price: null,
          rowVersion: 1,
        });
      }
    }

    setIssuances(projectIssuances);
    setSubmittedIssuances(projectIssuances);
  }, [projectDetails]);

  useEffect(() => {
    fetchData()
      .catch(async (error) => {
        Toast.error({
          message: "No project issuances could be found for this Uuid",
        });
        await logError({ error });
      })
      .finally(() => setIsLoading(false));
  }, [fetchData]);

  const onSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    if (!projectDetails) return;

    if (readOnly) {
      const issuancePrices = issuances.map((i) => ({
        uuid: i.uuid,
        price: i.price,
        rowVersion: i.rowVersion,
      }));

      const response = await updateIssuancePrices(issuancePrices);

      if (response.status === Status.Success && response.data) {
        Toast.success({ message: "Issuances changed successfully" });
        const updatedIssuances = issuances.map((i) => ({
          ...i,
          rowVersion: response.data?.data?.find((el) => el.uuid === i.uuid)?.rowVersion || i.rowVersion,
        }));
        setIssuances(updatedIssuances);
        setSubmittedIssuances(updatedIssuances);
      }
    }

    if (!readOnly) {
      const response = await setProjectIssuances({
        projectUuid: projectDetails.uuid,
        data: issuances
          .filter((i) => Object.values(i).some((value) => !!value))
          .map((i) => ({
            vintageStartDate: i.vintageStartDate,
            vintageEndDate: i.vintageEndDate,
            quantity: i.quantity,
            quantityType: i.quantityType,
            price: i.price,
          })),
      });

      if (response.status === Status.Success && response.data) {
        Toast.success({ message: "Issuances changed successfully" });
        setIssuances([...issuances]);
        setSubmittedIssuances([...issuances]);
      }
    }
  };

  return {
    onSubmit,
    chartData,
    dataGridColumns,
    dataGridData,
    isLoading,
  };
};

// import {
//   Dispatch,
//   FormEvent,
//   useCallback,
//   useContext,
//   useEffect,
//   useState,
// } from "react";

// import { logError } from "../../../../../../../service/error";
// import {
//   setProjectIssuances,
//   updateIssuancePrices,
// } from "../../../../../../../service/project";
// import { getProjectIssuances } from "../../../../../../../service/query";
// import {
//   BaseResponse,
//   ResultData,
//   Status,
// } from "../../../../../../../service/Shared";
// import { flattenObject, parseDate } from "../../../../../../../utils";
// import {
//   DataGridButtonCellFormatterData,
//   DataGridColumnDefinition,
//   DataGridInputCellFormatterData,
//   DataGridSelectCellFormatterData,
//   Toast,
// } from "../../../../../../../widget";
// import { ProjectContext } from "../../../ProjectContext";
// import {
//   IssuancesAndPricingFormChangedAction,
//   IssuancesAndPricingFormChangedEnum,
// } from "../../models";

// type Issuance = {
//   uuid: string;
//   vintageStartDate: Date | null;
//   vintageEndDate: Date | null;
//   quantity: number | null;
//   quantityType: string | null;
//   price: number | null;
//   verifier?: string;
// };

// interface UseIssuancesFormReturnData {
//   chartData: { [key: string]: number };
//   dataGridColumns: DataGridColumnDefinition[];
//   dataGridData: ResultData[];
//   onSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
// }

// export const useIssuancesForm = (
//   dispatch: Dispatch<IssuancesAndPricingFormChangedAction>
// ): UseIssuancesFormReturnData => {
//   const projectDetails = useContext(ProjectContext);
//   const readOnly =
//     !projectDetails?.standardSpecificData?.missingPDD &&
//     projectDetails?.status !== "Under Development";

//   const dataGridColumns: DataGridColumnDefinition[] = readOnly
//     ? [
//         {
//           name: "Vintage",
//           key: "vintage",
//           dataType: "string",
//         },
//         {
//           name: "Units",
//           key: "quantity",
//           dataType: "string",
//         },
//         {
//           name: "Unit type",
//           key: "quantityType",
//           dataType: "string",
//         },
//         {
//           name: "Verifier",
//           key: "verifier",
//           dataType: "string",
//         },
//         {
//           name: "Price",
//           key: "priceInput",
//           dataType: "string",
//           formatter: "input",
//         },
//       ]
//     : [
//         {
//           name: "Vintage start",
//           key: "vintageStartDateInput",
//           dataType: "string",
//           formatter: "input",
//         },
//         {
//           name: "Vintage end",
//           key: "vintageEndDateInput",
//           dataType: "string",
//           formatter: "input",
//         },
//         {
//           name: "Type",
//           key: "quantityTypeSelect",
//           dataType: "string",
//           formatter: "select",
//         },
//         {
//           name: "Quantity",
//           key: "quantityInput",
//           dataType: "string",
//           formatter: "input",
//         },
//         {
//           name: "Price",
//           key: "priceInput",
//           dataType: "string",
//           formatter: "input",
//         },
//         {
//           name: "",
//           key: "clearButton",
//           dataType: "string",
//           formatter: "button",
//         },
//       ];

//   const [chartData, setChartData] = useState<{ [key: string]: number }>({});
//   const [dataGridData, setDataGridData] = useState<ResultData[]>([]);
//   const [issuances, setIssuances] = useState<Issuance[]>([]);
//   // eslint-disable-next-line unused-imports/no-unused-vars
//   const [submittedIssuances, setSubmittedIssuances] = useState<Issuance[]>([]);

//   useEffect(() => {
//     let projectIssuancesGridData: ResultData[] = [];

//     if (readOnly) {
//       projectIssuancesGridData = issuances.map((d, index) => {
//         const result = flattenObject({
//           ...d,
//           vintage: `${d.vintageStartDate?.getFullYear()}-${d.vintageEndDate?.getFullYear()}`,
//         });

//         result.priceInput = <DataGridInputCellFormatterData>{
//           name: `price_${d.uuid}`,
//           type: "number",
//           value: d.price?.toString(),
//           decimalPlaces: 2,
//           onChange: (value) => {
//             issuances[index].price = parseFloat(value);
//           },
//         };

//         return result;
//       });
//     } else {
//       projectIssuancesGridData = issuances.map((i, index) => ({
//         vintageStartDate: i.vintageStartDate,
//         vintageEndDate: i.vintageEndDate,
//         vintageStartDateInput: <DataGridInputCellFormatterData>{
//           name: `vintageStartDate_${index}`,
//           type: "text",
//           value: i.vintageStartDate?.toLocaleDateString("en-GB"),
//           placeholder: "dd/mm/yyyy",
//           onChange: (value) => {
//             issuances[index].vintageStartDate = parseDate(value);
//           },
//         },
//         vintageEndDateInput: <DataGridInputCellFormatterData>{
//           name: `vintageEndDate_${index}`,
//           type: "text",
//           value: i.vintageEndDate?.toLocaleDateString("en-GB"),
//           placeholder: "dd/mm/yyyy",
//           onChange: (value) => {
//             issuances[index].vintageEndDate = parseDate(value);
//           },
//         },
//         quantityTypeSelect: <DataGridSelectCellFormatterData>{
//           data: [
//             { key: "PIU", value: "PIU" },
//             { key: "VCU", value: "VCU" },
//           ],
//           initialValue: i.quantityType,
//           onChange: (value) => {
//             issuances[index].quantityType = value;
//           },
//         },
//         quantityInput: <DataGridInputCellFormatterData>{
//           name: `quantity_${index}`,
//           type: "number",
//           value: i.quantity?.toString(),
//           onChange: (value) => {
//             issuances[index].quantity = parseFloat(value);
//           },
//         },
//         priceInput: <DataGridInputCellFormatterData>{
//           name: `price_${index}`,
//           type: "number",
//           value: i.price?.toString(),
//           decimalPlaces: 2,
//           onChange: (value) => {
//             issuances[index].price = parseFloat(value);
//           },
//         },
//         clearButton: <DataGridButtonCellFormatterData>{
//           text: "Clear",
//           variant: "secondary",
//           onClick: () => {
//             issuances[index] = {
//               uuid: "",
//               vintageStartDate: null,
//               vintageEndDate: null,
//               quantity: null,
//               quantityType: null,
//               price: null,
//             };
//             setIssuances([...issuances]);
//           },
//         },
//       }));
//     }

//     setDataGridData(projectIssuancesGridData);

//     const projectIssuancesChartData = issuances
//       .filter((i) => i.vintageStartDate && i.vintageEndDate)
//       .reduce((tmp: { [key: string]: number }, value) => {
//         const vintageStartDate = value.vintageStartDate as Date;
//         const vintageEndDate = value.vintageEndDate as Date;

//         const period = `${vintageStartDate.getFullYear()}-${vintageEndDate.getFullYear()}`;

//         if (!tmp[period]) {
//           // eslint-disable-next-line no-param-reassign
//           tmp[period] = 0;
//         }

//         // eslint-disable-next-line no-param-reassign
//         tmp[period] += value.quantity as number;

//         return tmp;
//       }, {});

//     setChartData(projectIssuancesChartData);
//   }, [issuances]);

//   const fetchData = useCallback(async () => {
//     if (!projectDetails) return;

//     const res = await getProjectIssuances({
//       projectUuid: projectDetails.uuid,
//     });

//     if (res.status !== Status.Success)
//       throw new Error("getProjectIssuances returned not success status");

//     let projectIssuances: Issuance[] = [];

//     if (res.data) {
//       projectIssuances = res.data.map((d) => ({
//         uuid: d.uuid,
//         vintageStartDate: d.vintageStartDate,
//         vintageEndDate: d.vintageEndDate,
//         quantity: d.quantity,
//         quantityType: d.quantityType,
//         price: d.price,
//         verifier: d.verifier.name,
//       }));
//     }

//     if (!readOnly) {
//       const newIssuancesLength = 16 - projectIssuances.length;

//       for (let i = 0; i < newIssuancesLength; i++) {
//         projectIssuances.push({
//           uuid: "",
//           vintageStartDate: null,
//           vintageEndDate: null,
//           quantity: null,
//           quantityType: null,
//           price: null,
//         });
//       }
//     }

//     setIssuances(projectIssuances);
//     setSubmittedIssuances(projectIssuances);
//     setSubmittedIssuances(
//       projectIssuances.map((i) => ({
//         ...i,
//       }))
//     );
//   }, [projectDetails]);

//   useEffect(() => {
//     fetchData().catch(async (error) => {
//       Toast.error({
//         message: "No project issuances could be found for this Uuid",
//       });
//       await logError({ error });
//     });
//   }, [fetchData]);

//   async function onSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
//     e.preventDefault();

//     if (!projectDetails) return;

//     let response: BaseResponse;

//     if (readOnly) {
//       const issuancePrices = issuances.map((i) => ({
//         uuid: i.uuid,
//         price: i.price,
//       }));

//       response = await updateIssuancePrices(issuancePrices);
//     } else {
//       response = await setProjectIssuances({
//         projectUuid: projectDetails.uuid,
//         data: issuances
//           .filter((i) => Object.values(i).some((value) => !!value))
//           .map((i) => ({
//             vintageStartDate: i.vintageStartDate,
//             vintageEndDate: i.vintageEndDate,
//             quantity: i.quantity,
//             quantityType: i.quantityType,
//             price: i.price,
//           })),
//       });
//     }

//     if (response.status === Status.Success) {
//       Toast.success({ message: "Issuances changed successfully" });
//       setIssuances([...issuances]);
//       setSubmittedIssuances(
//         issuances.map((i) => ({
//           ...i,
//         }))
//       );
//       dispatch({
//         type: IssuancesAndPricingFormChangedEnum.SET_ISSUANCES_FORM_CHANGED,
//         value: false,
//       });
//     }
//   }

//   return {
//     onSubmit,
//     chartData,
//     dataGridColumns,
//     dataGridData,
//   };
// };
