import "./PublicProjectTemplateComponent.css";

import { Link } from "react-router-dom";

import { ArrowFullLeftDarkIcon } from "../../../../assets";
import { CODE_PILL_VARIANT_MAP } from "../../../../constants";
import { TabsList } from "../../../../models";
import { GetProjectDetailsResponse } from "../../../../service/publicquery";
import { getPublicCodeDetailsRoute, getPublicDeveloperDetailsRoute } from "../../../../utils/routes";
import { Button, Card, Column, DeveloperIcon, Gallery, LocationFlag, Map, Pill, Row, Tabs } from "../../../../widget";
import { DeveloperProject } from "../../usePublicProjectTemplate";
import { AboutDeveloper } from "../AboutDeveloper";

interface PublicProjectTemplateComponentProps {
  project: GetProjectDetailsResponse | undefined;
  tabsList: TabsList;
  searchParams: string | undefined;
  developerIcon: string | undefined;
  images: string[] | undefined;
  otherDeveloperProjects: DeveloperProject[] | undefined;
  onBackToSearchResults?: () => void;
  onRegisterInterest?: () => void;
  isPreview?: boolean;
}

export const PublicProjectTemplateComponent = ({
  project,
  tabsList,
  searchParams,
  developerIcon,
  images,
  otherDeveloperProjects,
  onBackToSearchResults,
  onRegisterInterest,
  isPreview = false,
}: PublicProjectTemplateComponentProps): JSX.Element => {
  return (
    <div className="PublicProjectTemplateComponent">
      <Row spacingV="ml">
        <Column span={12}>
          <div className="PublicProjectHeader">
            {!isPreview && (
              <Button
                variant="secondary"
                text={searchParams ? "Back to search results" : "View all projects"}
                icon={<ArrowFullLeftDarkIcon />}
                iconPosition="left"
                onClick={onBackToSearchResults}
              />
            )}
            <Row spacingV="s" justify="between">
              <Column span={12}>
                <div className="PublicProjectHeader_Details">
                  <div>
                    <h1>{project?.displayName}</h1>
                    {project?.addressCountryCode && <LocationFlag addressCountryCode={project?.addressCountryCode} />}
                  </div>
                  {!isPreview && project?.calculatedContactable && (
                    <Button text="Register interest" onClick={onRegisterInterest} />
                  )}
                </div>
              </Column>
            </Row>
            <Row justify="between">
              <Column span={12}>
                <div className="PublicProjectHeader_DeveloperDetails">
                  {project?.developer && (
                    <Link
                      to={{
                        pathname: getPublicDeveloperDetailsRoute(project.developer.uuid),
                        search: `?projectUuid=${project.uuid}`,
                      }}
                    >
                      {developerIcon && <DeveloperIcon developerIcon={developerIcon} />}
                      <h3>{project.developer.displayName}</h3>
                    </Link>
                  )}
                  {project?.standard && (
                    <Link
                      to={{
                        pathname: getPublicCodeDetailsRoute(project.standard.uuid),
                        search: `?projectUuid=${project.uuid}`,
                      }}
                    >
                      <Pill label={project?.standard.displayName || ""} variantMap={CODE_PILL_VARIANT_MAP} />
                    </Link>
                  )}
                </div>
              </Column>
            </Row>
          </div>
        </Column>
      </Row>
      <Row spacingV="ll">
        <Column span={12}>
          {(images && images?.length) || (project?.listing?.content && project?.listing?.content.videoUrl) ? (
            <Gallery imageSources={images || []} videoUrl={project?.listing.content.videoUrl} />
          ) : (
            project?.locationCoordinates && (
              <Map fullWidth mode="satellite" height={544} coordinates={[project.locationCoordinates]} />
            )
          )}
        </Column>
      </Row>
      <Row>
        <Column span={9}>
          <Tabs className="TemplateTabs" tabsList={tabsList} />
        </Column>
        <Column span={3}>
          {project?.locationCoordinates && (
            <Row spacingV="ml">
              <Column span={12}>
                <Map height={320} coordinates={[project.locationCoordinates]} />
              </Column>
            </Row>
          )}
          {project?.developer && (
            <Row spacingV="ll">
              <Column span={12}>
                <Card>
                  <AboutDeveloper
                    developerUuid={project.developer.uuid}
                    developerName={project.developer.displayName}
                    developerDescription={project.developer.listing?.content?.ourValues?.content}
                    developerIcon={developerIcon}
                    projects={otherDeveloperProjects}
                    projectUuid={project.uuid}
                    isPreview={isPreview}
                  />
                </Card>
              </Column>
            </Row>
          )}
        </Column>
      </Row>
    </div>
  );
};
