import { GetProjectDetailsResponse } from "../../../../service/publicquery";
import { getFormattedDate } from "../../../../utils";
import { usePublicContext } from "../../usePublicContext";

interface UseDetailsReturnData {
  projectDetails: GetProjectDetailsResponse | undefined;
  implementationDate: string | null | undefined;
  startDate: string | null | undefined;
  locationGridReference: string | null | undefined;
  addressCountryCode: string | null | undefined;
  firstAssessmentDate: string | null | undefined;
  secondAssessmentDate: string | null | undefined;
  frequency: string | null | undefined;
  isPcc: boolean;
}

export const useDetails = (): UseDetailsReturnData => {
  const { projectDetails } = usePublicContext();

  const isPcc = projectDetails?.standard.displayName === "Peatland Code";
  const implementationDate = getFormattedDate(projectDetails?.standardSpecificData?.implementationDate);
  const startDate = getFormattedDate(projectDetails?.standardSpecificData?.startDate);
  const locationGridReference = projectDetails?.locationGridReference;
  const firstAssessmentDate = getFormattedDate(projectDetails?.standardSpecificData?.monitoring?.assessmentDates[0]);
  const secondAssessmentDate = getFormattedDate(projectDetails?.standardSpecificData?.monitoring?.assessmentDates[1]);
  const frequency = projectDetails?.standardSpecificData?.monitoring?.frequency;
  const addressCountryCode = projectDetails?.addressCountryCode;

  return {
    projectDetails,
    implementationDate,
    startDate,
    locationGridReference,
    addressCountryCode,
    firstAssessmentDate,
    secondAssessmentDate,
    frequency,
    isPcc,
  };
};
