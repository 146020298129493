export const getLogonRoute = (params?: { origin: string }): string => {
  return `/logon${params?.origin ? `?origin=${params.origin}` : ""}`;
};

export const getRegisterRoute = (): string => {
  return "/register";
};

export const getForgottenPasswordRoute = (): string => {
  return "/forgotten-password";
};

export const getDashboardRoute = (): string => {
  return "/d/dashboard";
};

export const getUserSettingsRoute = (): string => {
  return "/d/settings/users";
};

export const getDeveloperSettingsMarketingAssetsRoute = (
  params?: {
    origin: string;
  },
  uuid: string | undefined = undefined
): string => {
  return `/d/settings/marketing-assets${uuid ? `/${uuid}` : ""}${params?.origin ? `?origin=${params.origin}` : ""}`;
};

export const getProjectsRoute = (): string => {
  return "/d/projects";
};

export const getMarketingRoute = (): string => {
  return "/d/marketing/marketing-stats";
};

export const getProjectDetailsByUuid = (uuid: string): string => {
  return `/d/projects/${uuid}/overview`;
};

export const getProjectMarketingSettingsRoute = (uuid: string, queryParams?: string): string => {
  return `/d/projects/${uuid}/marketing-settings${queryParams ? `?${queryParams}` : ""}`;
};

export const getUserEditRouteByUuid = (uuid: string): string => {
  return `/d/settings/users/${uuid}/edit`;
};

export const getUserSettingsSendInvitationRoute = (): string => {
  return "/d/settings/users/send-invitation";
};

export const getProfileChangePasswordRoute = (): string => "/d/profile/change-password";

export const getProfileChangePersonalDetailsRoute = (): string => "/d/profile/change-personal-details";

export const getPublicCodeDetailsRoute = (uuid: string): string => `/p/code/${uuid}/details`;

export const getPublicDeveloperDetailsRoute = (uuid: string): string => `/p/developer/${uuid}/details`;

export const getPublicDeveloperDetailsPreviewRoute = (uuid: string): string => `/d/developer/details/preview/${uuid}`;
export const getPublicProjectsRoute = (codeUuid?: string, notCodeUuids?: string[]): string => {
  const falseCodeUrls = notCodeUuids?.map((uuid) => `%2C${uuid}%3Afalse`);
  return codeUuid && notCodeUuids ? `/p/projects?code=${codeUuid}%3Atrue${falseCodeUrls}` : "/p/projects";
};

export const getPublicProjectOverviewRoute = (uuid: string): string => {
  return `/p/project/${uuid}/overview`;
};

export const getPublicProjectDetailsRoute = (uuid: string): string => {
  return `/p/project/${uuid}/details`;
};

export const getPublicProjectDocumentsRoute = (uuid: string): string => {
  return `/p/project/${uuid}/documents`;
};

export const getPublicProjectIssuancesRoute = (uuid: string): string => {
  return `/p/project/${uuid}/issuances`;
};

export const getPublicProjectOverviewPreviewRoute = (uuid: string, previewUuid: string): string => {
  return `/d/project/${uuid}/preview/${previewUuid}/overview`;
};

export const getPublicValidatorRoute = (uuid: string): string => {
  return `/p/verifier/${uuid}/details`;
};

export const getPublicProjectTypeRoute = (code: string): string => {
  return `/p/project-type/${code}`;
};

export const getPublicCodeComparisonRoute = (): string => {
  return "/p/code-comparison";
};

export const getPublicProjectTypesRoute = (): string => {
  return `/p/project-types`;
};
