import { PillVariant } from "../../widget";

export const CODE_PILL_VARIANT_MAP: { [key: string]: PillVariant } = {
  "Woodland Carbon Code": "green",
  "Peatland Code": "coral",
};

export const PROJECT_TYPES_PILL_VARIANT_MAP: { [key: string]: PillVariant } = {
  "Green project": "green",
  "Brown project": "coral",
  "Grey project": "grey",
  "Blue project": "cyan",
};
