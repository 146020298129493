import "./Logon.css";

import { Link } from "react-router-dom";

import bgImage from "../../../assets/images/Mountain.jpg";
import { AuthenticationTemplate } from "../../../templates";
import { getRegisterRoute } from "../../../utils/routes";
import { Column, Row } from "../../../widget";
import { LogonForm } from "./form";

export const Logon = (): JSX.Element => {
  return (
    <AuthenticationTemplate imageSrc={bgImage}>
      <div>
        <Row spacingV="ll">
          <Column span={12}>
            <h1>Log In</h1>
          </Column>
        </Row>
        <LogonForm />
        <span className="body1 LogonRegisterAccount">
          Don&apos;t have an account?
          <Link to={getRegisterRoute()}>Register</Link>
        </span>
      </div>
    </AuthenticationTemplate>
  );
};
