// TODO temporarily disable eslint on this file until v1.0 Unwired is complete/services are used
/* eslint unused-imports/no-unused-vars: "off" */
/* eslint @typescript-eslint/no-explicit-any: "off" */
/* eslint @typescript-eslint/no-non-null-assertion: "off" */
// StatsService
import { BaseResponse, Status } from "../Shared";
import { SaveEventRequest } from "./Types";

export async function saveEvent(req: SaveEventRequest): Promise<BaseResponse> {
  // do nothing
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return {
    status: Status.Success,
  };
}
