import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";

import {
  Button,
  DataGridButtonCellFormatterData,
  DataGridInputCellFormatterData,
  DataGridLinkCellFormatterData,
  DataGridSelectCellFormatterData,
  Pill,
  Select,
  TextInput,
} from "../../..";
import { CellFormatter, ColumnCurrency, ColumnDefinition } from "../models/DataGrid.types";

export type CellFormatterNames =
  | "number"
  | "percentage"
  | "currency"
  | "dateOnly"
  | "timestamp"
  | "yesNo"
  | "projectStatusPill"
  | "userStatusPill"
  | "link"
  | "button"
  | "input"
  | "select"
  | "custom"
  | "align";

const NumberCellFormatter: CellFormatter<number> = (columnDefinition: ColumnDefinition) => {
  return (value: number) => (
    <div className={`DataGridNumber ${columnDefinition.alignment || ""}`}>
      <NumberFormat value={value} displayType="text" thousandSeparator />
    </div>
  );
};

const PercentageCellFormatter: CellFormatter<number> = (columnDefinition: ColumnDefinition) => {
  return (value) => (
    <div className={`DataGridNumber ${columnDefinition.alignment || ""}`}>
      <NumberFormat value={value} displayType="text" thousandSeparator suffix="%" />
    </div>
  );
};

const CurrencyCellFormatter: CellFormatter<number> = (columnDefinition: ColumnDefinition) => {
  switch (columnDefinition.currency) {
    case ColumnCurrency.Usd:
      return (value) => (
        <div className={`DataGridNumber ${columnDefinition.alignment || ""}`}>
          <NumberFormat
            value={value}
            displayType="text"
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
            prefix="$"
          />
        </div>
      );
    case ColumnCurrency.Gbp:
    case undefined:
    default:
      return (value) => (
        <div className={`DataGridNumber ${columnDefinition.alignment || ""}`}>
          <NumberFormat
            value={value}
            displayType="text"
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
            prefix="£"
          />
        </div>
      );
  }
};

const DateOnlyCellFormatter: CellFormatter<Date> = (columnDefinition: ColumnDefinition) => {
  return (value) =>
    (value && (
      <div className={`DataGridDateOnly ${columnDefinition.alignment || ""}`}>
        {/* NCU: required as destructuring assignment here results in a compilation error */}
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {value.getDate().toString().padStart(2, "0")}/{/* eslint-disable-next-line react/destructuring-assignment */}
        {(value.getMonth() + 1).toString().padStart(2, "0")}/
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {value.getFullYear()}{" "}
      </div>
    )) ||
    "";
};

const TimestampCellFormatter: CellFormatter<Date> = (columnDefinition: ColumnDefinition) => {
  return (value) =>
    (value && (
      <div className={`DataGridDateOnly ${columnDefinition.alignment || ""}`}>
        {/* NCU: required as destructuring assignment here results in a compilation error */}
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {value.getDate().toString().padStart(2, "0")}/{/* eslint-disable-next-line react/destructuring-assignment */}
        {(value.getMonth() + 1).toString().padStart(2, "0")}/
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {value.getFullYear()} {/* eslint-disable-next-line react/destructuring-assignment */}
        {value.getHours().toString().padStart(2, "0")}:{/* eslint-disable-next-line react/destructuring-assignment */}
        {value.getMinutes().toString().padStart(2, "0")}:{/* eslint-disable-next-line react/destructuring-assignment */}
        {value.getSeconds().toString().padStart(2, "0")}
      </div>
    )) ||
    "";
};

const BooleanYesNoCellFormatter: CellFormatter<boolean> = (columnDefinition: ColumnDefinition) => {
  return (value) =>
    value !== undefined ? (
      <div className={`DataGridYesNo ${columnDefinition.alignment || ""}`}>{value ? "Yes" : "No"}</div>
    ) : (
      <div />
    );
};

const ProjectStatusPillCellFormatter: CellFormatter<string | null | undefined> = () => {
  return (value) =>
    value === undefined || value === null ? (
      <div />
    ) : (
      <Pill
        label={value}
        variantMap={{
          Verified: "green",
          Validated: "green",
          "Under Development": "coral",
        }}
      />
    );
};

const UserStatusPillCellFormatter: CellFormatter<string | null | undefined> = () => {
  return (value) =>
    value === undefined || value === null ? (
      <div />
    ) : (
      <Pill
        label={value}
        variantMap={{
          Active: "green",
          Disabled: "red",
        }}
      />
    );
};

const LinkCellFormatter: CellFormatter<DataGridLinkCellFormatterData | null | undefined> = (
  columnDefinition: ColumnDefinition
) => {
  return (value) => {
    if (value === undefined || value === null) return <div />;

    // eslint-disable-next-line react/destructuring-assignment
    const { to, text } = value;

    return (
      <div className={`DataGridLink ${columnDefinition.alignment || ""}`}>
        <span className="body1">
          <Link to={to}>{text}</Link>
        </span>
      </div>
    );
  };
};

const ButtonCellFormatter: CellFormatter<DataGridButtonCellFormatterData | null | undefined> = (
  columnDefinition: ColumnDefinition
) => {
  return (value) => {
    if (value === undefined || value === null) return <div />;

    return (
      <div className={`DataGridButton ${columnDefinition.alignment || ""}`}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Button {...value} />
      </div>
    );
  };
};

const InputCellFormatter: CellFormatter<DataGridInputCellFormatterData> = (columnDefinition: ColumnDefinition) => {
  return (data) => {
    if (data === undefined || data === null) return <div />;

    return (
      <div className={`DataGridInput ${columnDefinition.alignment || ""}`}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <TextInput {...data} />
      </div>
    );
  };
};

const SelectCellFormatter: CellFormatter<DataGridSelectCellFormatterData | null | undefined> = (
  columnDefinition: ColumnDefinition
) => {
  return (data) => {
    if (data === undefined || data === null) return <div />;

    return (
      <div className={`DataGridSelect ${columnDefinition.alignment || ""}`}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Select {...data} />
      </div>
    );
  };
};

const AlignmentCellFormatter: CellFormatter<string> = (columnDefinition: ColumnDefinition) => {
  return (data) => <div className={`${columnDefinition.alignment || ""}`}>{data}</div>;
};

export const cellFormatters = {
  number: NumberCellFormatter,
  percentage: PercentageCellFormatter,
  currency: CurrencyCellFormatter,
  dateOnly: DateOnlyCellFormatter,
  timestamp: TimestampCellFormatter,
  yesNo: BooleanYesNoCellFormatter,
  projectStatusPill: ProjectStatusPillCellFormatter,
  userStatusPill: UserStatusPillCellFormatter,
  link: LinkCellFormatter,
  button: ButtonCellFormatter,
  input: InputCellFormatter,
  select: SelectCellFormatter,
  align: AlignmentCellFormatter,
  custom: undefined,
};
