// TODO temporarily disable eslint on this file until v1.0 Unwired is complete/services are used
/* eslint unused-imports/no-unused-vars: "off" */
/* eslint @typescript-eslint/no-explicit-any: "off" */
/* eslint @typescript-eslint/no-non-null-assertion: "off" */
// PublicService
import { BaseResponse, Status } from "../Shared";
import { ContactDeveloperRequest, RegisterProjectInterestRequest } from "./Types";

export async function contactDeveloper(req: ContactDeveloperRequest): Promise<BaseResponse> {
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return { status: Status.Success };
}

export async function registerProjectInterest(req: RegisterProjectInterestRequest): Promise<BaseResponse> {
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return { status: Status.Success };
}
