import { Column, Divider, Row, UnsavedChangesModal } from "../../../../widget";
import { AddressForm, DetailsForm, IconForm, LogoForm } from "./form";
import { useOrganisation } from "./useOrganisation";

const Organisation = (): JSX.Element => {
  const {
    dataIsLoading,
    organisationDefaultDetails,
    organisationDefaultAddress,
    organisationDefaultIcon,
    organisationDefaultLogo,
    countries,
    hasUnsavedChanges,
    dispatch,
  } = useOrganisation();

  return (
    <div>
      {!dataIsLoading && (
        <>
          <Row spacingV="ll">
            <Column span={12}>
              <h2>Organisation details</h2>
            </Column>
          </Row>
          {organisationDefaultDetails && (
            <DetailsForm organisationDefaultDetails={organisationDefaultDetails} dispatch={dispatch} />
          )}
          <Row spacingV="2xl">
            <Column span={12}>
              <Divider type="horizontal" />
            </Column>
          </Row>
          <Row spacingV="ll">
            <Column span={5}>
              <h2>Address</h2>
            </Column>
          </Row>
          {organisationDefaultAddress && (
            <AddressForm
              countries={countries}
              organisationDefaultAddress={organisationDefaultAddress}
              dispatch={dispatch}
            />
          )}
          <Row spacingV="2xl">
            <Column span={12}>
              <Divider type="horizontal" />
            </Column>
          </Row>
          <Row spacingV="ll">
            <Column span={5}>
              <h2>Icon</h2>
            </Column>
          </Row>
          <IconForm organisationDefaultIcon={organisationDefaultIcon} dispatch={dispatch} />
          <Row spacingV="2xl">
            <Column span={12}>
              <Divider type="horizontal" />
            </Column>
          </Row>
          <Row spacingV="ll">
            <Column span={5}>
              <h2>Logo</h2>
            </Column>
          </Row>
          <LogoForm organisationDefaultLogo={organisationDefaultLogo} dispatch={dispatch} />
          {/* Temporarily disabling UnsavedChangesModal ENG-664 - by adding false check */}
          {false && (
            <UnsavedChangesModal hasUnsavedChanges={Object.values(hasUnsavedChanges).some((value) => value === true)} />
          )}
        </>
      )}
    </div>
  );
};

export default Organisation;
