import "./TextArea.css";

import { ChangeEvent, ReactNode } from "react";

import { FormLabel } from "../../FormLabel";

interface TextAreaProps {
  name: string;
  label: string;
  value: string;
  placeholder?: string;
  disabled?: boolean;
  resizable?: boolean;
  optional?: boolean;
  hasResize?: boolean;
  maxLength?: number;
  displayCharacterCount?: boolean;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  tooltip?: ReactNode;
}

export const TextArea = ({
  name,
  label,
  value,
  placeholder,
  disabled = false,
  resizable = false,
  optional = false,
  hasResize = false,
  maxLength = 500,
  displayCharacterCount = false,
  tooltip,
  onChange,
}: TextAreaProps): JSX.Element => {
  return (
    <div
      className={`form_field TextArea ${disabled ? `TextArea_disabled` : ""} ${resizable ? `TextArea_resizable` : ""}`}
    >
      <FormLabel label={label} optional={optional} tooltip={tooltip}>
        <textarea
          style={{ resize: hasResize ? "both" : "none" }}
          className="body1"
          id={name}
          name={name}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          maxLength={maxLength}
        />
        {displayCharacterCount && (
          <div className="body2 TextAreaCharacterCount">
            {value.length || 0}/{maxLength}
          </div>
        )}
      </FormLabel>
    </div>
  );
};
