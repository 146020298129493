import type { Blocker, Transition } from "history";
import { useCallback, useContext, useEffect, useState } from "react";
import { Navigator, UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";

import { ConfirmationDialog } from "../ConfirmationDialog";

type BlockerNavigator = Navigator & {
  location: Location;
  block(blocker: Blocker): () => void;
};

type UnsavedChangesModalProps = {
  hasUnsavedChanges: boolean;
};

export const UnsavedChangesModal = ({ hasUnsavedChanges }: UnsavedChangesModalProps): JSX.Element => {
  const { navigator } = useContext(NavigationContext);

  const [displayAlert, setDisplayAlert] = useState(false);
  const [transition, setTransition] = useState<Transition>();

  useEffect(() => {
    if (!hasUnsavedChanges) {
      return undefined;
    }

    const {
      block,
      location: { pathname },
    } = navigator as BlockerNavigator;

    const unblock = block((nextTransition: Transition): void => {
      const {
        location: { pathname: targetPathname },
      } = nextTransition;

      // Note: We don't want to show the alert if the user is navigating to the same page.
      if (targetPathname === pathname) {
        unblock();
        nextTransition.retry();
        return;
      }

      setDisplayAlert(true);

      setTransition({
        ...nextTransition,
        retry() {
          unblock();
          nextTransition.retry();
        },
      });
    });

    return unblock;
  }, [navigator, hasUnsavedChanges]);

  const leavePage = useCallback(() => {
    transition?.retry();
    setDisplayAlert(false);
  }, [transition]);

  const stayOnPage = useCallback(() => {
    setDisplayAlert(false);
  }, [setDisplayAlert]);

  return <ConfirmationDialog show={displayAlert} onClose={stayOnPage} onConfirm={leavePage} />;
};
