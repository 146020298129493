import "./ActionButtons.css";

import { Button } from "../Button";

interface FormActionButtonsProps {
  submitButtonLabel?: string;
  onCancel: () => void;
}

export const ActionButtons = ({ submitButtonLabel = "Save", onCancel }: FormActionButtonsProps): JSX.Element => {
  return (
    <div className="ActionButtons">
      <Button text={submitButtonLabel} type="submit" />
      <Button variant="secondary" text="Cancel" onClick={onCancel} />
    </div>
  );
};
