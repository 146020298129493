// TODO Commented column values were the original spacing values,
// they were changed so that Location, Dates and Monitoring sections are always aligned
import { getCountryNameByCode } from "../../../../utils";
import { Column, ReadonlyText, Row, TextInput } from "../../..";

interface LocationProps {
  gridReference: string | null | undefined;
  addressCountryCode: string | null | undefined;
  onGridReferenceChange?: (value: string) => void;
  readOnly?: boolean;
  isUnderDevelopment?: boolean;
}

export const Location = ({
  gridReference,
  addressCountryCode,
  onGridReferenceChange,
  readOnly = true,
  isUnderDevelopment = false,
}: LocationProps): JSX.Element => {
  return (
    <>
      <Row spacingV="ll">
        <Column span={12}>
          <h2>Location</h2>
          {readOnly && isUnderDevelopment && !(gridReference || addressCountryCode) && (
            <p className="SectionText body1">
              This project is under development so some data might not be available yet
            </p>
          )}
        </Column>
      </Row>
      {readOnly ? (
        <Row spacingV="ll">
          {/* <Column span={5}> */}
          <Column span={6}>
            <ReadonlyText
              label="Grid reference"
              value={gridReference || "No grid reference available"}
              noDataAvailable={!gridReference}
            />
          </Column>
          {/* <Column span={5} offset={gridReference ? 1 : 0}> */}
          <Column span={6}>
            <ReadonlyText
              label="Country"
              value={getCountryNameByCode(addressCountryCode) || "No location available"}
              noDataAvailable={!addressCountryCode}
            />
          </Column>
        </Row>
      ) : (
        <Row spacingV="ll">
          {/* <Column span={5}> */}
          <Column span={6}>
            <TextInput
              label="Grid reference"
              name="locationGridReference"
              value={gridReference}
              onChange={onGridReferenceChange}
            />
          </Column>
          {addressCountryCode && (
            // <Column span={5} offset={1}>
            <Column span={6}>
              <ReadonlyText label="Country" value={getCountryNameByCode(addressCountryCode)} />
            </Column>
          )}
        </Row>
      )}
    </>
  );
};
