import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { logout } from "./service/identity";
import { getCurrentUser, GetCurrentUserResponse } from "./service/query";
import { memoizedRefreshAccessToken } from "./service/refreshAccessToken";
import { ServiceError, Status } from "./service/Shared";
import { getLogonRoute } from "./utils/routes";
import { Toast } from "./widget";

interface AuthContextType {
  user?: GetCurrentUserResponse;
  currentOrganisationUuid?: string;
  isAuthenticated: () => boolean;
  loading: boolean;
  setCurrentUser: (userUuid: string | undefined) => Promise<GetCurrentUserResponse | null>;
  setCurrentUserRowVersion: (newRowVersion: number) => void;
  updateCurrentUserPersonalDetails: (updatedUser: GetCurrentUserResponse) => void;
  clearCurrentSession: () => void;
  logoutUser: () => void;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const navigate = useNavigate();
  const [user, setUser] = useState<GetCurrentUserResponse>();
  const [currentOrganisationUuid, setCurrentOrganisationUuid] = useState<string | undefined>(undefined);
  const [errors, setErrors] = useState<ServiceError[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  const location = useLocation();

  // If we change page, reset the error state.
  useEffect(() => {
    if (errors) setErrors([]);
  }, [location.pathname]);

  const fetchCurrentUserDetails = async (): Promise<GetCurrentUserResponse | null> => {
    const userDetailsRes = await getCurrentUser();
    if (userDetailsRes.status === Status.Success && userDetailsRes.data) {
      setUser(userDetailsRes.data);
      if (userDetailsRes.data?.organisations && userDetailsRes.data.organisations.length > 0)
        setCurrentOrganisationUuid(userDetailsRes.data.organisations[0].organisationUuid);
      return userDetailsRes.data;
    }
    setErrors(userDetailsRes.errors);

    return null;
  };

  useEffect(() => {
    setLoadingInitial(true);
    const rehydrateData = async (): Promise<void> => {
      const result = await memoizedRefreshAccessToken();
      window.kanaCurrentUserUuid = result.data?.userUuid;
      await fetchCurrentUserDetails();
      setLoadingInitial(false);
    };

    // Rehydrate data if navigating to a developer site route.
    if (
      window.ACCESS_TOKEN === undefined &&
      // TODO Refactor-HotList Checking if we are on a route that requires Authentication.
      location.pathname.startsWith("/d")
    ) {
      rehydrateData();
    } else {
      setLoadingInitial(false);
    }
  }, []);

  // Check if user is authenticated by making sure all the necessary data is set.
  const isAuthenticated = (): boolean => {
    return !!user && !!currentOrganisationUuid;
  };

  const clearCurrentSession = (): void => {
    window.ACCESS_TOKEN = undefined;
    window.kanaCurrentUserUuid = undefined;
    setUser(undefined);
    setCurrentOrganisationUuid(undefined);
  };

  const setCurrentUser = async (userUuid: string | undefined): Promise<GetCurrentUserResponse | null> => {
    if (!userUuid) {
      clearCurrentSession();
      return null;
    }
    setLoading(true);
    setErrors(undefined);
    const res = await fetchCurrentUserDetails();
    setLoading(false);
    return res;
  };

  const setCurrentUserRowVersion = (newRowVersion: number): void => {
    setUser(
      (prevState) =>
        ({
          ...prevState,
          rowVersion: newRowVersion,
        } as GetCurrentUserResponse)
    );
  };

  const updateCurrentUserPersonalDetails = (updatedUser: GetCurrentUserResponse): void => {
    setUser(updatedUser);
  };

  const logoutUser = async (): Promise<void> => {
    logout().then((res) => {
      if (res.status === Status.Success) {
        clearCurrentSession();
        navigate(getLogonRoute());
      } else {
        Toast.error({
          message: "Logging out failed!",
        });
      }
    });
  };

  const memoedValue = useMemo(
    () => ({
      user,
      currentOrganisationUuid,
      isAuthenticated,
      loading,
      errors,
      setCurrentUser,
      setCurrentUserRowVersion,
      updateCurrentUserPersonalDetails,
      clearCurrentSession,
      logoutUser,
    }),
    [user, currentOrganisationUuid, loading, errors]
  );

  return <AuthContext.Provider value={memoedValue}>{!loadingInitial && children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextType => {
  return useContext(AuthContext);
};
