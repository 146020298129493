import { useCallback, useEffect, useState } from "react";

import { getCurrentUser, GetCurrentUserResponse } from "../../../../service/query";
import { Status } from "../../../../service/Shared";

interface UseChangePersonalDetailsReturnData {
  isLoading: boolean;
  data: GetCurrentUserResponse | undefined;
}

export function useChangePersonalDetails(): UseChangePersonalDetailsReturnData {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<GetCurrentUserResponse | undefined>(undefined);

  const fetchData = useCallback(async () => {
    const res = await getCurrentUser();
    if (res.status === Status.Success) {
      setData(res.data);
    }
  }, []);

  useEffect(() => {
    fetchData().then(() => setIsLoading(false));
  }, []);

  return {
    isLoading,
    data,
  };
}
