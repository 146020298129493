import { useCallback, useState } from "react";
import { SortColumn } from "react-data-grid";

import { searchProjects, SearchProjectsResponse } from "../../../service/query";
import { ResultData } from "../../../service/Shared";
import { useAuth } from "../../../useAuth";
import { flattenObject } from "../../../utils";
import { getProjectDetailsByUuid } from "../../../utils/routes";
import { DataGridColumnDefinition, DataGridLinkCellFormatterData, dataGridMapFilterCriteria } from "../../../widget";
import { CursorChangeProps } from "../../../widget/data/DataGrid/models/DataGrid.types";

interface UseProjectsReturnData {
  columns: DataGridColumnDefinition[];
  isLoading: boolean;
  defaultSortingCriteria: SortColumn[];
  onChange: ({ filtering, paging, sorting }: CursorChangeProps) => Promise<{
    resultData: ResultData[];
    paging: {
      pageSize: number;
      totalCount: number;
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  }>;
}

interface ResultType {
  resultData: ResultData[];
  paging: {
    startCursor: string;
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    pageSize: number;
    totalCount: number;
  };
}

export function useProjects(): UseProjectsReturnData {
  const { currentOrganisationUuid } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const columns: DataGridColumnDefinition[] = [
    {
      name: "Project",
      key: "link",
      dataType: "string",
      formatter: "link",
    },
    {
      name: "Code",
      key: "standard.displayName",
      dataType: "string",
    },
    {
      name: "Validator",
      key: "validator.displayName",
      dataType: "string",
    },
    {
      name: "Status",
      key: "status",
      dataType: "string",
      formatter: "projectStatusPill",
      alignment: "center",
      minWidth: 200,
    },
    {
      name: "PIUs",
      key: "cachedPiuQuantity",
      dataType: "string",
      filterable: false,
    },
    {
      name: "VCUs",
      key: "cachedVcuQuantity",
      dataType: "string",
      filterable: false,
    },
  ];
  const pageSize = 10;

  const defaultSortingCriteria: SortColumn[] = [{ columnKey: "link", direction: "ASC" }];

  const formatData = useCallback(
    (responseData: SearchProjectsResponse | undefined): ResultData[] =>
      responseData?.results?.map((el) => {
        const result = flattenObject(el);

        result.link = <DataGridLinkCellFormatterData>{
          text: el.displayName,
          to: getProjectDetailsByUuid(el.uuid),
        };

        return result;
      }) || [],
    []
  );

  const onChange = async ({ filtering, paging, sorting }: CursorChangeProps): Promise<ResultType> => {
    const filterCriteria = dataGridMapFilterCriteria(filtering);

    if (filterCriteria.link) {
      filterCriteria.displayName = filterCriteria.link;
      filterCriteria.link = undefined;
    }

    filterCriteria.developer = {
      uuid: {
        operator: "eq",
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        value: currentOrganisationUuid!,
      },
    };

    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };
    await searchProjects({
      paging: {
        beforeCursor: paging.beforeCursor,
        afterCursor: paging.afterCursor,
        limit: pageSize,
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      sort: sorting.map((s: { key: any; direction: any }) => ({
        key: s.key !== "link" ? s.key : "displayName",
        direction: s.direction,
      })),
      /* eslint-enable */
      filter: { results: filterCriteria },
    })
      .then((response) => {
        data = {
          resultData: formatData(response.data),
          paging: {
            startCursor: response.data?.paging?.startCursor || "",
            endCursor: response.data?.paging?.endCursor || "",
            pageSize,
            totalCount: response.data?.paging?.total || 0,
            hasNextPage: response.data?.paging?.hasNextPage || false,
            hasPreviousPage: response.data?.paging?.hasPreviousPage || false,
          },
        };
      })
      .finally(() => {
        setIsLoading(false);
      });

    return data;
  };

  return {
    columns,
    isLoading,
    defaultSortingCriteria,
    onChange,
  };
}
