import "./RadioButtonGroup.css";

import { ReactNode } from "react";

import { RadioButtonGroupData, RadioButtonGroupKey } from "../../../../models";
import { FormLabel } from "../../FormLabel";
import { RadioChangeEvent } from "./components";
import AntdRadioGroup from "./components/Group";
import AntdRadio from "./components/Radio";
import { useRadioButtonGroup } from "./useRadioButtonGroup";

interface RadioButtonGroupProps {
  data: RadioButtonGroupData;
  onChange: (e: RadioChangeEvent) => void;
  currentKey?: RadioButtonGroupKey;
  label?: string;
  tooltip?: ReactNode;
}

export const RadioButtonGroup = ({
  data,
  onChange,
  currentKey,
  label,
  tooltip,
}: RadioButtonGroupProps): JSX.Element => {
  const { alignment } = useRadioButtonGroup(data);
  const className = alignment.direction === "vertical" ? "Radio-group-vertical" : "";

  return (
    <div className="RadioButtonGroup">
      <FormLabel labelAlignment="left" label={label} tooltip={tooltip} />
      <AntdRadioGroup value={currentKey} onChange={onChange} className={className}>
        {data.map((radioButton) => (
          <AntdRadio key={radioButton.value} value={radioButton.key}>
            <span className="body1">{radioButton.value}</span>
          </AntdRadio>
        ))}
      </AntdRadioGroup>
    </div>
  );
};
