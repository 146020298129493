import { OverviewComponent } from "../components";
import { useOverview } from "./useOverview";

const Overview = (): JSX.Element => {
  const { isLoading, projectDetails, chartData, tags } = useOverview();
  return (
    <div>{!isLoading && <OverviewComponent projectDetails={projectDetails} chartData={chartData} tags={tags} />}</div>
  );
};

export default Overview;
