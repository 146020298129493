import "./FormLabel.css";

import { ReactNode } from "react";

export interface FormLabelProps {
  label?: string;
  labelAlignment?: "left" | "right" | "top" | "bottom";
  optional?: boolean;
  tooltip?: ReactNode;
  className?: string;
  children?: ReactNode;
}

export const FormLabel = ({
  label,
  labelAlignment = "top",
  optional,
  tooltip,
  className,
  children,
}: FormLabelProps): JSX.Element => {
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={`Label Label-${labelAlignment}`}>
      {label && (
        <div className="LabelValue">
          <div className={className}>
            {label}
            {optional && <span className="body2 Optional">(optional)</span>}
          </div>
          {tooltip}
        </div>
      )}
      {children}
    </label>
  );
};
