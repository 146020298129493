import { Dispatch, ForwardedRef, forwardRef, SetStateAction } from "react";

import { ActionButtons, Column, ErrorBox, KanaTooltip, Row, TextArea, Toggle } from "../../../../../../widget";
import { FormOurValuesData, MarketingAssetsFormChangedAction, MarketingAssetsSubmitHandler } from "../../models";
import { MaxLengthConstants } from "../constants";
import { useOurValuesForm } from "./useOurValuesForm";

interface ValuesFormProps {
  ourValuesDefaultValues: FormOurValuesData;
  ourValuesOldDefaultValues: FormOurValuesData;
  listingRowVersion: number;
  setListingRowVersion: Dispatch<SetStateAction<number>>;
  dispatch: Dispatch<MarketingAssetsFormChangedAction>;
  getCurrentFormsData: (formWhichWasSaved: string) => string;
}

export const OurValuesForm = forwardRef(
  (
    {
      ourValuesDefaultValues,
      ourValuesOldDefaultValues,
      listingRowVersion,
      setListingRowVersion,
      dispatch,
      getCurrentFormsData,
    }: ValuesFormProps,
    forwardedRef: ForwardedRef<MarketingAssetsSubmitHandler>
  ): JSX.Element => {
    const { enabled, content, errors, setEnabled, setContent, handleSubmit, handleCancel } = useOurValuesForm(
      ourValuesDefaultValues,
      ourValuesOldDefaultValues,
      listingRowVersion,
      setListingRowVersion,
      dispatch,
      forwardedRef,
      getCurrentFormsData
    );

    return (
      <>
        <Row spacingV="ll">
          <Column span={4}>
            <h2>Our values</h2>
          </Column>
        </Row>
        {errors && errors.length > 0 && (
          <Row spacingV="ml">
            <Column span={4}>
              <ErrorBox
                title="Please solve the below issues before saving"
                text={errors.map((error) => error.message)}
              />
            </Column>
          </Row>
        )}
        <form onSubmit={(e) => handleSubmit(e)}>
          <Row spacingV="ll">
            <Column span={12}>
              <Toggle label="Show this section on organisation profile" defaultValue={enabled} onChange={setEnabled} />
            </Column>
          </Row>
          <Row spacingV="ll">
            <Column span={10}>
              <TextArea
                name="ourValues-content"
                label="Content"
                disabled={!enabled}
                tooltip={
                  <KanaTooltip
                    tooltipHeader="What is the values content used for?"
                    tooltipText="This content will be displayed in the first section on your developer profile, and should outline your company values and goals."
                  />
                }
                value={content || ""}
                onChange={(e) => {
                  setContent(e.currentTarget.value);
                }}
                displayCharacterCount
                maxLength={MaxLengthConstants.OUR_VALUES_CONTENT_MAX_LENGTH}
              />
            </Column>
          </Row>
          <Row spacingV="2xl">
            <Column span={4}>
              <ActionButtons onCancel={handleCancel} />
            </Column>
          </Row>
        </form>
      </>
    );
  }
);
