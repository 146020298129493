import "../styles/DataGrid.css";

import ReactDataGrid from "react-data-grid";

import { ResultData } from "../../../../service/Shared";
import { Column, Row } from "../../../layout";
import { Pagination } from "../../../wayfinding";
import { DataGridConstants } from "../constants";
import { FilterContext } from "../context";
import { ColumnDefinition, OnChangeEventHandler } from "../models/DataGrid.types";
import { useComputeDataGridHeight } from "../utils";
import { useDataGrid } from "./useDataGrid";

export interface DataGridProps {
  data: ResultData[];
  columns: ColumnDefinition[];
  // client-side filtering & sorting
  clientSide: boolean;
  // grid-level sorting enabled?
  sortable?: boolean;
  // grid-level filtering enabled?
  filterable?: boolean;
  // paging enabled?
  pageable?: boolean;
  /*
   Used for server-side sorting/filtering/paging.
   Only raised if `clientSide = false`.
   */
  onChange?: OnChangeEventHandler;
  /*
    Configuration for the pagination
   */
  pagination?: {
    // mandatory for server-side
    totalCount?: number;
    siblingCount?: number;
    currentPage?: number;
    pageSize?: number;
  };
  noDataMessage?: string;
  dataIsLoading?: boolean;
}

export const DataGrid = ({
  data,
  columns,
  filterable = false,
  sortable = true,
  clientSide = false,
  pageable = true,
  onChange,
  pagination = { currentPage: 1, pageSize: 10, siblingCount: 2 },
  noDataMessage,
  dataIsLoading,
}: DataGridProps): JSX.Element => {
  const {
    filterCriteria,
    resultData,
    rdgColumns,
    hasFilters,
    sortColumns,
    setSortColumns,
    dgCurrentPage,
    setDgCurrentPage,
    siblingCount,
    pageSize,
    dgTotalCount,
  } = useDataGrid({
    data,
    columns,
    filterable,
    sortable,
    clientSide,
    pageable,
    onChange,
    pagination,
  });

  const { computedHeight } = useComputeDataGridHeight(resultData.length, DataGridConstants.rowHeight);

  return (
    <div className="DataGridContainer">
      {resultData && (!!resultData.length || (!resultData.length && !!filterCriteria.length)) ? (
        <div className="DataGrid">
          <Row spacingV={pageable ? "ll" : undefined}>
            <Column span={12}>
              <FilterContext.Provider value={filterCriteria}>
                <ReactDataGrid
                  style={{
                    height: computedHeight,
                  }}
                  rows={resultData}
                  columns={rdgColumns}
                  defaultColumnOptions={{
                    sortable,
                  }}
                  className="rdg-light"
                  rowHeight={DataGridConstants.rowHeight}
                  headerRowHeight={hasFilters ? 2 * DataGridConstants.rowHeight : undefined}
                  sortColumns={sortColumns}
                  onSortColumnsChange={setSortColumns}
                />
              </FilterContext.Provider>
            </Column>
          </Row>
          <Row>
            <Column span={12}>
              {pageable && (
                <Pagination
                  onPageChange={setDgCurrentPage}
                  totalCount={dgTotalCount}
                  siblingCount={siblingCount || 2}
                  currentPage={dgCurrentPage}
                  pageSize={pageSize}
                />
              )}
            </Column>
          </Row>
        </div>
      ) : (
        <p className="body2 NoData">{dataIsLoading ? "Loading..." : noDataMessage}</p>
      )}
    </div>
  );
};
