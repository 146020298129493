import { Button, Column, ErrorBox, InformationBox, Password, Row, TextInput } from "../../../../widget";
import { useRegisterForm } from "./useRegisterForm";

interface RegisterFormProps {
  onSuccess: (e: boolean) => void;
}

export const RegisterForm = ({ onSuccess }: RegisterFormProps): JSX.Element => {
  const {
    firstName,
    lastName,
    email,
    password,
    errors,
    passwordConfirmation,
    setFirstName,
    setLastName,
    setEmail,
    setPassword,
    setPasswordConfirmation,
    handleSubmit,
  } = useRegisterForm(onSuccess);

  return (
    <>
      {errors && errors?.length > 0 && (
        <Row spacingV="ll">
          <Column span={12}>
            <ErrorBox text={errors.map((error) => error.message)} />
          </Column>
        </Row>
      )}
      <form onSubmit={(e) => handleSubmit(e)}>
        <Row spacingV="ll">
          <Column span={12}>
            <TextInput
              label="First name"
              name="firstName"
              value={firstName}
              onChange={(value) => setFirstName(value)}
            />
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={12}>
            <TextInput label="Last name" name="lastName" value={lastName} onChange={(value) => setLastName(value)} />
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={12}>
            <TextInput
              label="Email address"
              type="email"
              name="email"
              value={email}
              onChange={(value) => setEmail(value)}
            />
          </Column>
        </Row>
        <Row spacingV="ml">
          <Column span={12}>
            <span className="body1">Please enter a new password for your account.</span>
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={12}>
            <InformationBox variant="info" title="Password requirements">
              <ul>
                <li>Be at least 8 characters long</li>
                <li>
                  Not be a commonly used password such as <q>Password1</q>
                </li>
              </ul>
            </InformationBox>
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={12}>
            <Password
              label="New password"
              name="newPassword"
              value={password}
              onChange={(e) => {
                setPassword(e.currentTarget.value);
              }}
            />
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={12}>
            <Password
              label="Confirm new password"
              name="newPasswordConfirmation"
              value={passwordConfirmation}
              onChange={(e) => {
                setPasswordConfirmation(e.currentTarget.value);
              }}
            />
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={12}>
            <Button fullWidth type="submit" text="Register" />
          </Column>
        </Row>
      </form>
    </>
  );
};
