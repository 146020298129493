import { Column, CursorDataGrid, Row } from "../../../../../widget";
import { useDocuments } from "./useDocuments";

const Documents = (): JSX.Element => {
  const { columns, defaultSortingCriteria, dataIsLoading, onChange } = useDocuments();

  return (
    <>
      <Row spacingV="ll">
        <Column span={12}>
          <h2>Documents</h2>
        </Column>
      </Row>
      <CursorDataGrid
        columns={columns}
        clientSide={false}
        filterable
        onChange={onChange}
        data={[]}
        noDataMessage="No documents to display"
        dataIsLoading={dataIsLoading}
        defaultSortingCriteria={defaultSortingCriteria}
      />
    </>
  );
};

export default Documents;
