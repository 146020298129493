import "./Config";
import "./Globals";

import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";

import Router from "./Router";
import { apolloPrivate } from "./service/apolloPrivate";
import { AuthProvider } from "./useAuth";

const App = (): JSX.Element | null => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <ApolloProvider client={apolloPrivate}>
          <Router />
        </ApolloProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
