import { DeveloperSingleCardTemplate } from "../../../../templates";
import { ChangePersonalDetailsForm } from "./form";
import { useChangePersonalDetails } from "./useChangePersonalDetails";

export const ChangePersonalDetails = (): JSX.Element => {
  const { data, isLoading } = useChangePersonalDetails();

  return (
    <div>
      {!isLoading && (
        <DeveloperSingleCardTemplate pageTitle="Change Personal Details">
          {data && <ChangePersonalDetailsForm defaultValues={data} />}
        </DeveloperSingleCardTemplate>
      )}
    </div>
  );
};
