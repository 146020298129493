import { DeveloperSingleCardWithTabsTemplate } from "../../../templates";

const Settings = (): JSX.Element => {
  return (
    <div>
      <DeveloperSingleCardWithTabsTemplate
        activeElement="Settings"
        pageTitle="Settings"
        tabsList={[
          { route: "/d/settings/users", label: "Users" },
          { route: "/d/settings/organisation", label: "Organisation" },
          {
            route: "/d/settings/marketing-assets",
            label: "Marketing Assets",
          },
          // { route: "/d/settings/embedding", label: "Embedding" },
        ]}
      />
    </div>
  );
};

export default Settings;
