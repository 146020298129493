import { Dispatch, FormEvent, SetStateAction, useContext, useEffect, useState } from "react";

import { updateProjectDetails } from "../../../../../service/project";
import { GetProjectDetailsResponse } from "../../../../../service/query";
import { Status } from "../../../../../service/Shared";
import { getFormattedDate } from "../../../../../utils";
import { Toast } from "../../../../../widget";
import { ProjectContext } from "../ProjectContext";

interface UseDetailsReturnData {
  projectDetails: GetProjectDetailsResponse | undefined;
  implementationDate: string | null | undefined;
  startDate: string | null | undefined;
  locationGridReference: string | null | undefined;
  addressCountryCode: string | null | undefined;
  firstAssessmentDate: string | null | undefined;
  secondAssessmentDate: string | null | undefined;
  frequency: string | null | undefined;
  readOnly: boolean;
  isPcc: boolean;
  setImplementationDate: Dispatch<SetStateAction<string | null | undefined>>;
  setStartDate: Dispatch<SetStateAction<string | null | undefined>>;
  setLocationGridReference: Dispatch<SetStateAction<string | null | undefined>>;
  setFirstAssessmentDate: Dispatch<SetStateAction<string | null | undefined>>;
  setSecondAssessmentDate: Dispatch<SetStateAction<string | null | undefined>>;
  setFrequency: Dispatch<SetStateAction<string | null | undefined>>;
  onSubmit(e: FormEvent<HTMLFormElement>): Promise<void>;
  onCancel(): void;
}

export type DetailsData = {
  implementationDate: string | null | undefined;
  startDate: string | null | undefined;
  locationGridReference: string | null | undefined;
  firstAssessmentDate: string | null | undefined;
  secondAssessmentDate: string | null | undefined;
  frequency: string | null | undefined;
  rowVersion: number;
};

export const useDetails = (): UseDetailsReturnData => {
  const { projectDetails } = useContext(ProjectContext);
  const [implementationDate, setImplementationDate] = useState<string | null>();
  const [startDate, setStartDate] = useState<string | null>();
  const [locationGridReference, setLocationGridReference] = useState<string | null>();
  const [firstAssessmentDate, setFirstAssessmentDate] = useState<string | null>();
  const [secondAssessmentDate, setSecondAssessmentDate] = useState<string | null>();
  const [frequency, setFrequency] = useState<string | null>();
  const [addressCountryCode, setAddressCountryCode] = useState<string | null>();
  const [rowVersion, setRowVersion] = useState<number>(1);
  const [submittedValues, setSubmittedValues] = useState<DetailsData>({
    implementationDate,
    startDate,
    locationGridReference,
    firstAssessmentDate,
    secondAssessmentDate,
    frequency,
    rowVersion,
  });

  // remove these comments and set default to false when setReadOnly is used
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [readOnly, setReadOnly] = useState<boolean>(true);

  const isPcc = projectDetails?.standard.displayName === "Peatland Code";

  const initializeData = (projectData: GetProjectDetailsResponse): void => {
    setImplementationDate(getFormattedDate(projectData.standardSpecificData?.implementationDate));
    setStartDate(getFormattedDate(projectData.standardSpecificData?.startDate));
    setLocationGridReference(projectData.locationGridReference);
    setAddressCountryCode(projectData.addressCountryCode);
    setFirstAssessmentDate(getFormattedDate(projectData.standardSpecificData?.monitoring?.assessmentDates[0]));
    setSecondAssessmentDate(getFormattedDate(projectData.standardSpecificData?.monitoring?.assessmentDates[1]));
    setFrequency(projectData.standardSpecificData?.monitoring?.frequency);
    // TODO This will change to allow project details to be edited in the future
    // setReadOnly(
    //   projectData.standardSpecificData.missingPdd ||
    //     projectData.status === "Draft" ||
    //     projectData.status !== "Under Development"
    // );
    setRowVersion(projectData.rowVersion);
  };

  useEffect(() => {
    if (projectDetails) {
      initializeData(projectDetails);
    }
  }, [projectDetails]);

  async function onSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault();

    if (!projectDetails) return;

    const updateRes = await updateProjectDetails({
      projectUuid: projectDetails.uuid,
      locationGridReference: locationGridReference || null,
      standardSpecificData: JSON.stringify({
        implementationDate,
        startDate,
        monitoring: {
          assessmentDates: [firstAssessmentDate, secondAssessmentDate],
          frequency,
        },
      }),
      rowVersion,
    });

    if (updateRes.status === Status.Success && updateRes.data) {
      Toast.success({ message: "Project details changed successfully" });
      setSubmittedValues({
        implementationDate,
        startDate,
        locationGridReference,
        firstAssessmentDate,
        secondAssessmentDate,
        frequency,
        rowVersion: updateRes.data.rowVersion,
      });
      setRowVersion(updateRes.data.rowVersion);
    }
  }

  function onCancel(): void {
    setImplementationDate(submittedValues.implementationDate);
    setStartDate(submittedValues.startDate);
    setLocationGridReference(submittedValues.locationGridReference);
    setFirstAssessmentDate(submittedValues.firstAssessmentDate);
    setSecondAssessmentDate(submittedValues.secondAssessmentDate);
    setFrequency(submittedValues.frequency);
  }

  return {
    projectDetails,
    implementationDate,
    startDate,
    locationGridReference,
    addressCountryCode,
    firstAssessmentDate,
    secondAssessmentDate,
    frequency,
    readOnly,
    isPcc,
    setImplementationDate,
    setStartDate,
    setLocationGridReference,
    setFirstAssessmentDate,
    setSecondAssessmentDate,
    setFrequency,
    onSubmit,
    onCancel,
  };
};

// Old code for useDetails.tsx
// import {
//   Dispatch,
//   FormEvent,
//   SetStateAction,
//   useContext,
//   useEffect,
//   useState,
// } from "react";

// import { updateProjectDetails } from "../../../../../service/project";
// import { GetProjectDetailsResponse } from "../../../../../service/query";
// import { Status } from "../../../../../service/Shared";
// import { Toast } from "../../../../../widget";
// import { ProjectContext } from "../ProjectContext";

// interface UseDetailsReturnData {
//   projectDetails: GetProjectDetailsResponse | undefined;
//   implementationDate: string | null | undefined;
//   startDate: string | null | undefined;
//   locationGridReference: string | null | undefined;
//   addressCountryCode: string | null | undefined;
//   firstAssessmentDate: string | null | undefined;
//   secondAssessmentDate: string | null | undefined;
//   frequency: string | null | undefined;
//   readOnly: boolean;
//   showLocationSection: boolean;
//   showDatesSection: boolean;
//   showMonitoringSection: boolean;
//   setImplementationDate: Dispatch<SetStateAction<string | null | undefined>>;
//   setStartDate: Dispatch<SetStateAction<string | null | undefined>>;
//   setLocationGridReference: Dispatch<SetStateAction<string | null | undefined>>;
//   setFirstAssessmentDate: Dispatch<SetStateAction<string | null | undefined>>;
//   setSecondAssessmentDate: Dispatch<SetStateAction<string | null | undefined>>;
//   setFrequency: Dispatch<SetStateAction<string | null | undefined>>;
//   onSubmit(e: FormEvent<HTMLFormElement>): Promise<void>;
//   onCancel(): void;
// }

// export type DetailsData = {
//   implementationDate: string | null | undefined;
//   startDate: string | null | undefined;
//   locationGridReference: string | null | undefined;
//   firstAssessmentDate: string | null | undefined;
//   secondAssessmentDate: string | null | undefined;
//   frequency: string | null | undefined;
// };

// export const useDetails = (): UseDetailsReturnData => {
//   const projectDetails = useContext(ProjectContext);

//   const [implementationDate, setImplementationDate] = useState<string | null>();
//   const [startDate, setStartDate] = useState<string | null>();
//   const [locationGridReference, setLocationGridReference] = useState<
//     string | null
//   >();
//   const [firstAssessmentDate, setFirstAssessmentDate] = useState<
//     string | null
//   >();
//   const [secondAssessmentDate, setSecondAssessmentDate] = useState<
//     string | null
//   >();
//   const [frequency, setFrequency] = useState<string | null>();
//   const [addressCountryCode, setAddressCountryCode] = useState<string | null>();
//   const [submittedValues, setSubmittedValues] = useState<DetailsData>({
//     implementationDate,
//     startDate,
//     locationGridReference,
//     firstAssessmentDate,
//     secondAssessmentDate,
//     frequency,
//   });

//   const [readOnly, setReadOnly] = useState<boolean>(false);

//   const showLocationSection =
//     !readOnly ||
//     (readOnly &&
//       (!!locationGridReference || !!projectDetails?.addressCountryCode));

//   const showDatesSection =
//     !readOnly ||
//     (readOnly &&
//       (!!implementationDate ||
//         !!startDate ||
//         !!projectDetails?.standardSpecificData.duration));

//   const showMonitoringSection =
//     !readOnly ||
//     (readOnly &&
//       (!!firstAssessmentDate || !!secondAssessmentDate || !!frequency));

//   const initializeData = (): void => {
//     setImplementationDate(
//       projectDetails?.standardSpecificData?.implementationDate
//     );
//     setStartDate(projectDetails?.standardSpecificData?.startDate);
//     setLocationGridReference(projectDetails?.locationGridReference);
//     setAddressCountryCode(projectDetails?.addressCountryCode);
//     setFirstAssessmentDate(
//       projectDetails?.standardSpecificData?.monitoring?.assessmentDates[0]
//     );
//     setSecondAssessmentDate(
//       projectDetails?.standardSpecificData?.monitoring?.assessmentDates[1]
//     );
//     setFrequency(projectDetails?.standardSpecificData?.monitoring?.frequency);
//     setReadOnly(
//       projectDetails?.standardSpecificData.missingPdd ||
//         projectDetails?.status !== "Under Development"
//     );
//   };

//   useEffect(() => {
//     if (projectDetails) {
//       initializeData();
//     }
//   }, [projectDetails]);

//   async function onSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
//     e.preventDefault();

//     if (!projectDetails) return;

//     const issuancePricesRes = await updateProjectDetails({
//       projectUuid: projectDetails.uuid,
//       locationGridReference: locationGridReference || null,
//       standardSpecificData: {
//         implementationDate,
//         startDate,
//         monitoring: {
//           assessmentDates: [firstAssessmentDate, secondAssessmentDate],
//           frequency,
//         },
//       },
//     });

//     if (issuancePricesRes.status === Status.Success) {
//       Toast.success({ message: "Project details changed successfully" });
//       setSubmittedValues({
//         implementationDate,
//         startDate,
//         locationGridReference,
//         firstAssessmentDate,
//         secondAssessmentDate,
//         frequency,
//       });
//     }
//   }

//   function onCancel(): void {
//     setImplementationDate(submittedValues.implementationDate);
//     setStartDate(submittedValues.startDate);
//     setLocationGridReference(submittedValues.locationGridReference);
//     setFirstAssessmentDate(submittedValues.firstAssessmentDate);
//     setSecondAssessmentDate(submittedValues.secondAssessmentDate);
//     setFrequency(submittedValues.frequency);
//   }

//   return {
//     projectDetails,
//     implementationDate,
//     startDate,
//     locationGridReference,
//     addressCountryCode,
//     firstAssessmentDate,
//     secondAssessmentDate,
//     frequency,
//     readOnly,
//     showLocationSection,
//     showDatesSection,
//     showMonitoringSection,
//     setImplementationDate,
//     setStartDate,
//     setLocationGridReference,
//     setFirstAssessmentDate,
//     setSecondAssessmentDate,
//     setFrequency,
//     onSubmit,
//     onCancel,
//   };
// };
