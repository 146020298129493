import "./ProjectsSearch.css";

import { Column, CursorPagination, DataFilter, ProjectCard, Row, Select } from "../../../../../widget";
import { useProjectsSearch } from "./useProjectsSearch";

interface ProjectsSearchProps {
  developerUuid?: string;
}

export const ProjectsSearch = ({ developerUuid }: ProjectsSearchProps): JSX.Element => {
  const {
    filters,
    currentFilter,
    totalCount,
    currentSort,
    sorting,
    projects,
    onFiltersChange,
    onSortingChange,
    onProjectClick,
    onPrevious,
    onNext,
    hasNext,
    hasPrevious,
  } = useProjectsSearch(developerUuid);

  return (
    <DataFilter filters={filters} value={currentFilter} onChange={onFiltersChange}>
      <Row spacingV="ml">
        <Column span={12}>
          <div className="ProjectsSearchContentHeader">
            <span className="body2">
              {totalCount} result{totalCount !== 1 ? "s" : ""}
            </span>
            <div className="ProjectsSearchContentHeaderSortingContainer">
              <span className="body2">Order by:</span>
              <Select
                className="ProjectsSearchContentHeaderSortingContainerSelect"
                value={currentSort}
                data={sorting}
                dropdownMatchSelectWidth={false}
                onChange={onSortingChange}
              />
            </div>
          </div>
        </Column>
      </Row>
      {projects.map((p) => (
        <Row key={p.name} spacingV="ml">
          <Column span={12}>
            <ProjectCard
              className="ProjectsSearchContentProjectCard"
              imageSource={p.imageSource}
              hasUnits={p.hasUnits}
              hasPriceInDirectory={p.hasPriceInDirectory}
              name={p.name}
              addressCountryCode={p.addressCountryCode}
              developerSmallLogoFileUrl={p.developerSmallLogoFileUrl}
              developerDisplayName={p.developerDisplayName}
              standardDisplayName={p.standardDisplayName}
              listingContentShortDescription={p.listingContentShortDescription}
              tags={p.tags}
              calculatedIssuancePriceMin={p.calculatedIssuancePriceMin}
              calculatedIssuancePriceMax={p.calculatedIssuancePriceMax}
              calculatedTotalQuantity={p.calculatedTotalQuantity}
              cachedPiuQuantity={p.cachedPiuQuantity}
              cachedVcuQuantity={p.cachedVcuQuantity}
              onClick={() => onProjectClick(p)}
            />
          </Column>
        </Row>
      ))}
      <CursorPagination onPrevious={onPrevious} onNext={onNext} hasNext={hasNext} hasPrevious={hasPrevious} />
    </DataFilter>
  );
};
