import { Dispatch, FormEvent, SetStateAction, useCallback, useEffect, useState } from "react";

import { updateOrganisationDetails } from "../../../../../../service/organisation";
import { ServiceError, Status } from "../../../../../../service/Shared";
import { useAuth } from "../../../../../../useAuth";
import { areObjectsDeepEqual } from "../../../../../../utils";
import { Toast } from "../../../../../../widget";
import { OrganisationDetailsData, OrganisationFormChangedAction, OrganisationFormChangedEnum } from "../../models";

interface UseDetailsFormReturnData {
  displayName: string | null;
  phoneNumber: string | null;
  contactEmail: string | null;
  website: string | null;
  linkedinURL: string | null;
  twitterUsername: string | null;
  instagramUsername: string | null;
  youtubeChannel: string | null;
  facebookURL: string | null;
  errors: ServiceError[] | undefined;
  setDisplayName: Dispatch<SetStateAction<string | null>>;
  setPhoneNumber: Dispatch<SetStateAction<string | null>>;
  setContactEmail: Dispatch<SetStateAction<string | null>>;
  setWebsite: Dispatch<SetStateAction<string | null>>;
  setLinkedinURL: Dispatch<SetStateAction<string | null>>;
  setTwitterUsername: Dispatch<SetStateAction<string | null>>;
  setInstagramUsername: Dispatch<SetStateAction<string | null>>;
  setYoutubeChannel: Dispatch<SetStateAction<string | null>>;
  setFacebookURL: Dispatch<SetStateAction<string | null>>;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  handleCancel: () => void;
}

export const useDetailsForm = (
  organisationDefaultDetails: OrganisationDetailsData,
  dispatch: Dispatch<OrganisationFormChangedAction>
): UseDetailsFormReturnData => {
  const { currentOrganisationUuid } = useAuth();
  const [submittedValues, setSubmittedValues] = useState<OrganisationDetailsData>(organisationDefaultDetails);

  const [displayName, setDisplayName] = useState(organisationDefaultDetails.displayName);
  const [phoneNumber, setPhoneNumber] = useState(organisationDefaultDetails.phoneNumber);
  const [contactEmail, setContactEmail] = useState(organisationDefaultDetails.contactEmail);
  const [website, setWebsite] = useState(organisationDefaultDetails.website);
  const [linkedinURL, setLinkedinURL] = useState(organisationDefaultDetails.linkedinURL);
  const [twitterUsername, setTwitterUsername] = useState(organisationDefaultDetails.twitterUsername);
  const [instagramUsername, setInstagramUsername] = useState(organisationDefaultDetails.instagramUsername);
  const [youtubeChannel, setYoutubeChannel] = useState(organisationDefaultDetails.youtubeChannel);
  const [facebookURL, setFacebookURL] = useState(organisationDefaultDetails.facebookURL);
  const [errors, setErrors] = useState<ServiceError[] | undefined>();

  const resetForm = (): void => {
    setDisplayName(submittedValues.displayName);
    setPhoneNumber(submittedValues.phoneNumber);
    setContactEmail(submittedValues.contactEmail);
    setWebsite(submittedValues.website);
    setLinkedinURL(submittedValues.linkedinURL);
    setTwitterUsername(submittedValues.twitterUsername);
    setInstagramUsername(submittedValues.instagramUsername);
    setYoutubeChannel(submittedValues.youtubeChannel);
    setFacebookURL(submittedValues.facebookURL);
    setErrors([]);
  };

  const getCurrentFormData = useCallback((): OrganisationDetailsData => {
    return {
      displayName: displayName || null,
      phoneNumber: phoneNumber || null,
      contactEmail: contactEmail || null,
      website: website || null,
      twitterUsername: twitterUsername || null,
      linkedinURL: linkedinURL || null,
      instagramUsername: instagramUsername || null,
      youtubeChannel: youtubeChannel || null,
      facebookURL: facebookURL || null,
      rowVersion: organisationDefaultDetails.rowVersion,
    };
  }, [
    displayName,
    phoneNumber,
    contactEmail,
    website,
    twitterUsername,
    linkedinURL,
    instagramUsername,
    youtubeChannel,
    facebookURL,
  ]);

  useEffect(() => {
    dispatch({
      type: OrganisationFormChangedEnum.SET_DETAILS_FORM_CHANGED,
      value: !areObjectsDeepEqual(submittedValues, getCurrentFormData()),
    });
  }, [
    displayName,
    phoneNumber,
    contactEmail,
    website,
    twitterUsername,
    linkedinURL,
    instagramUsername,
    youtubeChannel,
    facebookURL,
    submittedValues,
  ]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setErrors([]);

    if (currentOrganisationUuid) {
      const detailsRes = await updateOrganisationDetails({
        organisationUuid: currentOrganisationUuid,
        displayName,
        contactPhone: phoneNumber,
        contactEmail,
        websiteUrl: website,
        socialMediaTwitterUsername: twitterUsername,
        socialMediaLinkedInUrl: linkedinURL,
        socialMediaInstagramUsername: instagramUsername,
        socialMediaYoutubeChannel: youtubeChannel,
        socialMediaFacebookUrl: facebookURL,
        rowVersion: submittedValues.rowVersion,
      });

      if (detailsRes.status === Status.Success && detailsRes.data) {
        Toast.success({ message: "Organisation details changed successfully" });

        setSubmittedValues({
          displayName,
          phoneNumber,
          contactEmail,
          website,
          twitterUsername,
          linkedinURL,
          instagramUsername,
          youtubeChannel,
          facebookURL,
          rowVersion: detailsRes.data.rowVersion,
        });
      }

      if (detailsRes.status === Status.Error) {
        setErrors(detailsRes.errors);
      }
    }
  };

  const handleCancel = (): void => {
    resetForm();
  };

  return {
    displayName,
    phoneNumber,
    contactEmail,
    website,
    linkedinURL,
    twitterUsername,
    instagramUsername,
    youtubeChannel,
    facebookURL,
    errors,
    setDisplayName,
    setContactEmail,
    setPhoneNumber,
    setWebsite,
    setLinkedinURL,
    setTwitterUsername,
    setInstagramUsername,
    setYoutubeChannel,
    setFacebookURL,
    handleSubmit,
    handleCancel,
  };
};
