import { Origin } from "../../../../models";
import { Button, Column, ErrorBox, InformationBox, Password, Row, TextInput } from "../../../../widget";
import { useLogonForm } from "./useLogonForm";

export const LogonForm = (): JSX.Element => {
  const { origin, email, password, errors, setEmail, setPassword, handleSubmit } = useLogonForm();

  // TODO - change the commented section to display with session timeout
  return (
    <>
      {origin && origin === Origin.PasswordReset && (
        <Row spacingV="ll">
          <Column span={12}>
            <InformationBox variant="success" title="Password reset successful">
              You can now log in with your new password
            </InformationBox>
          </Column>
        </Row>
      )}
      {origin && origin === Origin.SessionTimeout && (
        <Row spacingV="ll">
          <Column span={12}>
            <InformationBox variant="alert" title="Timed out">
              Your session timed out. Please log in again to pick up where you left off.
            </InformationBox>
          </Column>
        </Row>
      )}
      {/* {true && (
        <Row spacingV="ll">
          <Column span={12}>
            <InformationBox variant="info" title="Session expired">
              To keep your information safe, we logged you out due to
              inactivity. Please log in again to continue where you left off.
            </InformationBox>
          </Column>
        </Row>
      )} */}
      {errors && errors.length > 0 && (
        <Row spacingV="ll">
          <Column span={12}>
            <ErrorBox text={errors.map((error) => error.message)} />
          </Column>
        </Row>
      )}
      <form onSubmit={(e) => handleSubmit(e)}>
        <Row spacingV="ll">
          <Column span={12}>
            <TextInput type="email" name="email" value={email} label="Email" onChange={(value) => setEmail(value)} />
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={12}>
            <Password
              name="password"
              value={password}
              label="Password"
              hasForgottenPassword
              onChange={(e) => setPassword(e.currentTarget.value)}
            />
          </Column>
        </Row>
        <Row spacingV="ml">
          <Column span={12}>
            <Button fullWidth text="Log in" type="submit" />
          </Column>
        </Row>
      </form>
    </>
  );
};
