import "./RegisterInterestModalForm.css";

import { Button, Column, ErrorBox, InformationBox, Password, Row, TextArea, TextInput } from "../../../../../widget";
import { useRegisterInterestModalForm } from "./useRegisterInterestModalForm";

interface RegisterInterestModalFormProps {
  developerName: string;
  closeModal: () => void;
}

export const RegisterInterestModalForm = ({
  developerName,
  closeModal,
}: RegisterInterestModalFormProps): JSX.Element => {
  const {
    firstName,
    lastName,
    email,
    message,
    password,
    errors,
    displaySecondStepSuccessBox,
    firstStepComplete,
    secondStepComplete,
    setFirstName,
    setLastName,
    setEmail,
    setMessage,
    setPassword,
    handleFirstStepSubmit,
    handleSecondStepSubmit,
  } = useRegisterInterestModalForm();

  if (secondStepComplete) {
    return (
      <div id="registerInterestThirdStep">
        <Row spacingV="ll" justify="center">
          <Column span={6}>
            <InformationBox variant="success" title="Account created" />
          </Column>
        </Row>
        <div className="RegisterInterestThirdStepSubheader">
          <Row justify="center">
            <Column span={8}>
              <p className="body1">Thank you for registering.</p>
              <p className="body1">
                Kana is currently in closed beta; you have been placed on the waitlist to get early access to new
                features as they become available.
              </p>
            </Column>
          </Row>
        </div>
        <Row spacingV="2xl" justify="center">
          <Column span={6}>
            <Button fullWidth text="Close" onClick={closeModal} />
          </Column>
        </Row>
      </div>
    );
  }

  if (firstStepComplete) {
    return (
      <div id="registerInterestSecondStep">
        {displaySecondStepSuccessBox && (
          <Row spacingV="ll" justify="center">
            <Column span={6}>
              <InformationBox variant="success" title="Message sent">
                Thanks for getting in touch. {developerName} will get back to you as soon as possible
              </InformationBox>
            </Column>
          </Row>
        )}
        <div className="RegisterInterestSecondStepSubheader">
          <h3>Would you like to create a Kana account?</h3>
          <span className="body1">Get early access to new features as they become available</span>
        </div>
        {errors && errors.length > 0 && (
          <Row spacingV="ml" justify="center">
            <Column span={6}>
              <ErrorBox title="Please amend your password" text={errors.map((error) => error.message)} />
            </Column>
          </Row>
        )}
        <form onSubmit={(e) => handleSecondStepSubmit(e)}>
          <Row spacingV="2xl" justify="center">
            <Column span={6}>
              <Password
                label="Create password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.currentTarget.value)}
              />
            </Column>
          </Row>
          <Row spacingV="2xl" justify="center">
            <Column span={6}>
              <Button fullWidth text="Create account" type="submit" />
            </Column>
          </Row>
        </form>
      </div>
    );
  }

  return (
    <>
      <div className="RegisterInterestFirstStepSubheader">
        <Row justify="center">
          <Column span={10}>
            <p className="body1">
              When you register interest in a project you will receive updates from the developer about the progress of
              the project, and when units are available to buy.
            </p>
            <p className="body1">Please enter your email and {developerName} will get back to you.</p>
          </Column>
        </Row>
      </div>
      {errors && errors.length > 0 && (
        <Row spacingV="ml" justify="center">
          <Column span={6}>
            <ErrorBox title="Fields are required" text={errors.map((error) => error.message)} />
          </Column>
        </Row>
      )}
      <form onSubmit={(e) => handleFirstStepSubmit(e)}>
        <Row spacingV="ml" justify="center">
          <Column span={6}>
            <TextInput label="First name" name="firstName" value={firstName} onChange={(e) => setFirstName(e)} />
          </Column>
        </Row>
        <Row spacingV="ml" justify="center">
          <Column span={6}>
            <TextInput label="Last name" name="lastName" value={lastName} onChange={(e) => setLastName(e)} />
          </Column>
        </Row>
        <Row spacingV="ml" justify="center">
          <Column span={6}>
            <TextInput label="Email address" name="email" type="email" value={email} onChange={(e) => setEmail(e)} />
          </Column>
        </Row>
        <Row spacingV="2xl" justify="center">
          <Column span={6}>
            <TextArea
              optional
              label="Message"
              name="message"
              value={message}
              onChange={(e) => setMessage(e.currentTarget.value)}
            />
          </Column>
        </Row>
        <Row spacingV="2xl" justify="center">
          <Column span={6}>
            <Button fullWidth text="Send message" type="submit" />
          </Column>
        </Row>
      </form>
    </>
  );
};
