import "./ProjectCard.css";
import "./ProjectCardKeyData.css";

import { MouseEventHandler } from "react";
import NumberFormat from "react-number-format";

import { PriceTagOrangeIcon, UnitsGreenIcon, VerifiedBlueIcon } from "../../../assets";
import { CODE_PILL_VARIANT_MAP } from "../../../constants";
import { getPlaceholderImage } from "../../../utils";
import { LocationFlag, Tag } from "../../data";
import { Divider } from "../../general";
import { Pill } from "../../wayfinding";

interface ProjectCardProps {
  imageSource: string | null;
  hasUnits?: boolean;
  hasPriceInDirectory?: boolean;
  name: string;
  addressCountryCode: string;
  developerSmallLogoFileUrl: string | null;
  developerDisplayName?: string;
  standardDisplayName?: string;
  listingContentShortDescription: string;
  tags?: string[];
  calculatedIssuancePriceMin: number | null;
  calculatedIssuancePriceMax: number | null;
  calculatedTotalQuantity: number | null;
  cachedPiuQuantity: number;
  cachedVcuQuantity: number;
  className?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const getFormattedUnitPrice = (
  calculatedIssuancePriceMin: number | null,
  calculatedIssuancePriceMax: number | null
): JSX.Element | string => {
  if (calculatedIssuancePriceMin !== null && calculatedIssuancePriceMax !== null) {
    return (
      <>
        <NumberFormat
          value={calculatedIssuancePriceMin}
          displayType="text"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          prefix="£"
        />
        &nbsp; - &nbsp;
        <NumberFormat
          value={calculatedIssuancePriceMax}
          displayType="text"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          prefix="£"
        />
      </>
    );
  }
  if (calculatedIssuancePriceMin !== null) {
    return (
      <NumberFormat
        value={calculatedIssuancePriceMin}
        displayType="text"
        thousandSeparator
        decimalScale={2}
        fixedDecimalScale
        prefix="£"
      />
    );
  }
  if (calculatedIssuancePriceMax !== null) {
    return (
      <NumberFormat
        value={calculatedIssuancePriceMax}
        displayType="text"
        thousandSeparator
        decimalScale={2}
        fixedDecimalScale
        prefix="£"
      />
    );
  }
  return "-";
};

const getFormattedUnitType = (cachedPiuQuantity: number, cachedVcuQuantity: number): string => {
  if (cachedPiuQuantity > 0 && cachedVcuQuantity > 0) {
    return "PIU + VCU";
  }
  if (cachedPiuQuantity > 0) {
    return "PIU";
  }
  if (cachedVcuQuantity > 0) {
    return "VCU";
  }

  return "-";
};

export const ProjectCard = ({
  imageSource,
  hasUnits = true,
  hasPriceInDirectory = true,
  name,
  addressCountryCode,
  developerSmallLogoFileUrl,
  developerDisplayName,
  standardDisplayName,
  listingContentShortDescription,
  tags,
  calculatedIssuancePriceMin,
  calculatedIssuancePriceMax,
  calculatedTotalQuantity,
  cachedPiuQuantity,
  cachedVcuQuantity,
  className,
  onClick,
}: ProjectCardProps): JSX.Element => {
  return (
    <div className={`ProjectCard ${className || ""}`} onClick={onClick} aria-hidden="true">
      <div
        style={{
          backgroundImage: imageSource ? `url(${imageSource})` : `url(${getPlaceholderImage(standardDisplayName)})`,
        }}
        className="ProjectCardImageBlock"
      >
        {hasUnits && <span className="body2">Units available</span>}
      </div>
      <div className="ProjectCardContent">
        <div className="ProjectCardDetails">
          <div className="ProjectCardDetailsHeader">
            <h2>{name}</h2>
            {addressCountryCode && <LocationFlag addressCountryCode={addressCountryCode} />}
          </div>
          <div className="ProjectCardDetailsDeveloper">
            {developerSmallLogoFileUrl && <img src={developerSmallLogoFileUrl} alt="DeveloperLogo" />}
            <span className="body2">{developerDisplayName}</span>
            <Pill label={standardDisplayName} variantMap={CODE_PILL_VARIANT_MAP} />
          </div>
          <span className="body2">{listingContentShortDescription}</span>
          <div className="ProjectCardDetailsTags">
            {tags?.map((tagName) => (
              <Tag key={tagName} label={tagName} />
            ))}
          </div>
        </div>
        <div className="VerticalDivider">
          <Divider type="vertical" />
        </div>
        <div className="HorizontalDivider">
          <Divider type="horizontal" />
        </div>
        <div className="ProjectCardKeyDataContainer">
          <div className="ProjectCardKeyData ProjectCardKeyDataPrice">
            <div className="ProjectCardKeyDataLabel">
              <PriceTagOrangeIcon />
              <h4>Price</h4>
            </div>
            <span className="body4">
              {hasPriceInDirectory ? (
                getFormattedUnitPrice(calculatedIssuancePriceMin, calculatedIssuancePriceMax)
              ) : (
                <>&nbsp; - &nbsp;</>
              )}
            </span>
          </div>
          <div className="ProjectCardKeyData ProjectCardKeyDataNumber">
            <div className="ProjectCardKeyDataLabel">
              <UnitsGreenIcon />
              <h4>Units</h4>
            </div>
            <span className="body4">
              {calculatedTotalQuantity ? (
                <NumberFormat value={calculatedTotalQuantity} displayType="text" thousandSeparator />
              ) : (
                "-"
              )}
            </span>
          </div>
          <div className="ProjectCardKeyData ProjectCardKeyDataType">
            <div className="ProjectCardKeyDataLabel">
              <VerifiedBlueIcon />
              <h4>Type</h4>
            </div>
            <span className="body4">{getFormattedUnitType(cachedPiuQuantity, cachedVcuQuantity)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
