import {
  Dispatch,
  FormEvent,
  ForwardedRef,
  SetStateAction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import { updateOrganisationListing } from "../../../../../../service/organisation";
import { Status } from "../../../../../../service/Shared";
import { useAuth } from "../../../../../../useAuth";
import { areObjectsDeepEqual } from "../../../../../../utils";
import { Toast } from "../../../../../../widget";
import {
  FormAtAGlanceData,
  FormNames,
  MarketingAssetsFormChangedAction,
  MarketingAssetsFormChangedEnum,
  MarketingAssetsSubmitHandler,
} from "../../models";

interface UseAtAGlanceFormReturnData {
  enabled: boolean;
  setEnabled: Dispatch<SetStateAction<boolean>>;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  handleCancel: () => void;
}

export function useAtAGlanceForm(
  atAGlanceDefaultValues: FormAtAGlanceData,
  atAGlanceOldDefaultValues: FormAtAGlanceData,
  listingRowVersion: number,
  setListingRowVersion: Dispatch<SetStateAction<number>>,
  dispatch: Dispatch<MarketingAssetsFormChangedAction>,
  forwardedRef: ForwardedRef<MarketingAssetsSubmitHandler>,
  getCurrentFormsData: (formWhichWasSaved: string) => string
): UseAtAGlanceFormReturnData {
  const { currentOrganisationUuid } = useAuth();
  const [enabled, setEnabled] = useState(atAGlanceDefaultValues.enabled);

  const [submittedValues, setSubmittedValues] = useState<FormAtAGlanceData>(atAGlanceOldDefaultValues);

  useImperativeHandle(forwardedRef, () => ({
    getAtAGlanceData() {
      return {
        enabled,
      };
    },
    getAtAGlanceSubmittedData() {
      return {
        ...submittedValues,
      };
    },
  }));

  const getCurrentFormData = useCallback((): FormAtAGlanceData => {
    return {
      enabled,
    };
  }, [enabled]);

  useEffect(() => {
    dispatch({
      type: MarketingAssetsFormChangedEnum.SET_AT_A_GLANCE_FORM_CHANGED,
      value: !areObjectsDeepEqual(submittedValues, getCurrentFormData()),
    });
  }, [enabled, submittedValues]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (currentOrganisationUuid) {
      const detailsRes = await updateOrganisationListing({
        organisationUuid: currentOrganisationUuid,
        content: getCurrentFormsData(FormNames.AtAGlance),
        rowVersion: listingRowVersion,
      });

      if (detailsRes.status === Status.Success && detailsRes.data) {
        Toast.success({ message: "At a glance details changed successfully" });
        setSubmittedValues({
          enabled,
        });
        setListingRowVersion(detailsRes.data.rowVersion);
      }
    }
  };

  const handleCancel = (): void => {
    setEnabled(submittedValues.enabled);
  };

  return {
    enabled,
    setEnabled,
    handleSubmit,
    handleCancel,
  };
}
