export type CodePosition = {
  title: string;
  met: boolean;
  detail: string;
};

export type CoreCarbonPrinciple = {
  codeUrl?: string;
  codePositions: CodePosition[];
  met: boolean;
};

export enum ProjectStatus {
  Established = "Established",
  Emerging = "Emerging",
}

export type CodeCharacteristics = {
  enabled?: boolean;
  programGovernance?: CoreCarbonPrinciple;
  noDoubleCounting?: CoreCarbonPrinciple;
  registry?: CoreCarbonPrinciple;
  mitigationActivityInformation?: CoreCarbonPrinciple;
  additionality?: CoreCarbonPrinciple;
  robustQuantificationOfEmissionsReductionsAndRemovals?: CoreCarbonPrinciple;
  permanence?: CoreCarbonPrinciple;
  robustIndependentThirdPartyValidationAndVerification?: CoreCarbonPrinciple;
  transitionToNetZero?: CoreCarbonPrinciple;
  sustainableDevelopmentImpactsAndSafeguards?: CoreCarbonPrinciple;
};

export type CoreCarbonPrinciplesData = {
  characteristics: CodeCharacteristics;
  codeUrl?: string;
  status?: ProjectStatus;
};
