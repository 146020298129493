import "./Checkbox.css";

import RcCheckbox from "rc-checkbox";

import { FormLabel } from "../../FormLabel";

interface CheckboxProps {
  label?: string;
  checked?: boolean;
  onChange: (e: CheckboxChangeEvent) => void;
}

export interface CheckboxChangeEventTarget extends CheckboxProps {
  checked: boolean;
}

export interface CheckboxChangeEvent {
  target: CheckboxChangeEventTarget;
  stopPropagation: () => void;
  preventDefault: () => void;
  nativeEvent: MouseEvent;
}

export const Checkbox = ({ label, checked = false, onChange }: CheckboxProps): JSX.Element => {
  // NCU: The RcCheckbox `onChange` type definition doesn't match the actual type, hence the cast
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const typedOnChange = onChange as any;
  return (
    <div className="CheckboxWrapper">
      {/* NCU: the input for the label is nested within the Checkbox control */}
      <FormLabel label={label} labelAlignment="right" className="body1">
        <RcCheckbox prefixCls="Checkbox" checked={checked} onChange={typedOnChange} />
      </FormLabel>
    </div>
  );
};
