import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { GetCurrentUserResponse } from "../../../service/query";
import { useAuth } from "../../../useAuth";
import { getProfileChangePasswordRoute, getProfileChangePersonalDetailsRoute } from "../../../utils/routes";
import { ProfileMenuItemType } from "../ProfileMenuItem";

interface UseDeveloperHeaderData {
  showProfileMenu: boolean;
  setShowProfileMenu: (flag: boolean) => void;
  currentUserData: GetCurrentUserResponse | undefined;
  optionList: ProfileMenuItemType[];
  onOptionSelect: (option: ProfileMenuItemType) => void;
}

export const useDeveloperHeader = (): UseDeveloperHeaderData => {
  const navigate = useNavigate();
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const { user, logoutUser } = useAuth();
  const initialOptionList: ProfileMenuItemType[] = [
    {
      id: 1,
      value: "Change Password",
      route: getProfileChangePasswordRoute(),
    },
    {
      id: 2,
      value: "Change Personal Details",
      route: getProfileChangePersonalDetailsRoute(),
    },
    {
      id: 3,
      value: "Logout",
      action: () => logoutUser(),
    },
  ];
  const [optionList] = useState(initialOptionList);

  // eslint-disable-next-line unused-imports/no-unused-vars
  const onOptionSelect = (option: ProfileMenuItemType): void => {
    if (option.action) {
      option.action();
    }
    if (option.route) {
      navigate(option.route);
    }
    setShowProfileMenu(false);
  };

  return {
    showProfileMenu,
    setShowProfileMenu,
    currentUserData: user,
    optionList,
    onOptionSelect,
  };
};
