import { createContext, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { getProjectDetails, GetProjectDetailsResponse } from "../../../../service/query";
import { Status } from "../../../../service/Shared";

type ProjectPageRouteParams = "projectUuid";

interface ProjectProviderProps {
  children?: JSX.Element;
}

interface ProjectContextType {
  projectDetails: GetProjectDetailsResponse | undefined;
  setProjectDetails: Dispatch<SetStateAction<GetProjectDetailsResponse | undefined>>;
}

export const ProjectContext = createContext<ProjectContextType>({} as ProjectContextType);

export const ProjectProvider = ({ children }: ProjectProviderProps): JSX.Element => {
  const { projectUuid } = useParams<ProjectPageRouteParams>();
  const [isLoading, setIsLoading] = useState(true);

  const [projectDetails, setProjectDetails] = useState<GetProjectDetailsResponse | undefined>(undefined);

  const fetchData = useCallback(async () => {
    if (projectUuid) {
      const res = await getProjectDetails({ projectUuid });

      if (res.status === Status.Success && res.data) {
        setProjectDetails(res.data);
      }
    }
  }, []);

  useEffect(() => {
    fetchData().finally(() => setIsLoading(false));
  }, [fetchData]);

  const memoisedValue = useMemo(
    () => ({
      projectDetails,
      setProjectDetails,
    }),
    [projectDetails, fetchData]
  );

  return <ProjectContext.Provider value={memoisedValue}>{!isLoading && children}</ProjectContext.Provider>;
};
