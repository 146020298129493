import "./ReportingStandardsSection.css";

import {
  CheckCircleLightIcon,
  CrossCircleLightIcon,
  TooltipInfoLightShade2Icon,
  TooltipInfoLightShade3Icon,
} from "../../../../assets";
import { KanaTooltip } from "../../../../widget";

interface ReportingStandardsSectionProps {
  reportingStandards: boolean;
}

export const ReportingStandardsSection = ({ reportingStandards }: ReportingStandardsSectionProps): JSX.Element => {
  return (
    <div
      className={`ReportingStandardsSection ${
        reportingStandards ? "ReportingStandardsSectionApproved" : "ReportingStandardsSectionNotApproved"
      }`}
    >
      {reportingStandards ? (
        <div className="Approved">
          <CheckCircleLightIcon />
          <h3>Approved for use as part of the UK Environmental Reporting Net Emissions targets</h3>
          <div className="WhatIsThisWrapper">
            <KanaTooltip
              tooltipHeader="What are the UK Environmental Reporting Net Emissions targets?"
              tooltipText="The credits issued by this code can be used towards net emissions targets under the UK Government guidance, including mandatory greenhouse gas emissions reporting."
              linkText="What is this?"
              icon={<TooltipInfoLightShade2Icon />}
              fill={false}
            />
          </div>
        </div>
      ) : (
        <div className="NotApproved">
          <CrossCircleLightIcon />
          <h3>Not approved for use as part of the UK Environmental Reporting Net Emissions targets</h3>
          <div className="WhatIsThisWrapper">
            <KanaTooltip
              tooltipHeader="What are the UK Environmental Reporting Net Emissions targets?"
              tooltipText="The credits issued by this code can be used towards net emissions targets under the UK Government guidance, including mandatory greenhouse gas emissions reporting."
              linkText="What is this?"
              icon={<TooltipInfoLightShade3Icon />}
              fill={false}
            />
          </div>
        </div>
      )}
    </div>
  );
};
