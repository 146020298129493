import "./Slider.css";

import RcSlider from "rc-slider";

import { FormLabel } from "../../FormLabel";
import { HandleTooltipRender } from "./components";

interface SliderProps {
  value: number[] | undefined;
  min: number;
  max: number;
  label?: string;
  range?: boolean;
  onChange: (value: number | number[]) => void;
  formatValue?: (val: number | undefined) => string | undefined;
}

export const Slider = ({ value, min, max, label, range = false, onChange, formatValue }: SliderProps): JSX.Element => {
  return (
    <FormLabel label={label}>
      <div className="SliderContainer">
        <RcSlider
          range={range}
          min={min}
          max={max}
          value={value}
          onChange={(newValue) => onChange(newValue)}
          handleRender={(origin, props) => HandleTooltipRender(origin, props, formatValue)}
          className="Slider"
        />
      </div>
    </FormLabel>
  );
};
