import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getProjectIssuances } from "../../../../service/publicquery";
import { ResultData, Status } from "../../../../service/Shared";
import { flattenObject } from "../../../../utils";
import { DataGridColumnCurrency, DataGridColumnDefinition } from "../../../../widget";
import { usePublicContext } from "../../usePublicContext";

interface useIssuancesReturnData {
  isLoading: boolean;
  dataGridColumns: DataGridColumnDefinition[];
  dataGridData: ResultData[];
  chartData: { [key: string]: number } | undefined;
}

export function useIssuances(): useIssuancesReturnData {
  const { uuid } = useParams();
  const { projectDetails } = usePublicContext();

  const [isLoading, setIsLoading] = useState(true);
  const [dataGridData, setDataGridData] = useState<ResultData[]>([]);
  const [chartData, setChartData] = useState<{ [key: string]: number } | undefined>(undefined);
  const dataGridColumns: DataGridColumnDefinition[] = [
    {
      name: "Vintage",
      key: "vintage",
      dataType: "string",
    },
    {
      name: "Units",
      key: "quantity",
      dataType: "string",
      formatter: "align",
      alignment: "center",
    },
    {
      name: "Unit type",
      key: "quantityType",
      dataType: "string",
      formatter: "align",
      alignment: "center",
    },
    {
      name: "Verifier",
      key: "verifier.displayName",
      dataType: "string",
      minWidth: 140,
    },
  ];

  useEffect(() => {
    if (projectDetails && projectDetails.listing?.showPriceInDirectory) {
      dataGridColumns.push({
        name: "Price",
        key: "price",
        currency: DataGridColumnCurrency.Gbp,
        dataType: "number",
        formatter: "currency",
        alignment: "center",
      });
    }
  }, [projectDetails, dataGridData]);

  const fetchData = useCallback(async () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const response = await getProjectIssuances({ projectUuid: uuid! });
    if (response.status === Status.Success && response.data) {
      const projectIssuancesGridData = response.data.map((el) => {
        const result = flattenObject({
          ...el,
          vintage: `${el.vintageStartDate.getFullYear()}-${el.vintageEndDate.getFullYear()}`,
        });

        return result;
      });

      setDataGridData(projectIssuancesGridData);

      const projectIssuancesChartData = response.data.reduce((tmp: { [key: string]: number }, value) => {
        const period = `${value.vintageStartDate.getFullYear()}-${value.vintageEndDate.getFullYear()}`;

        if (!tmp[period]) {
          // eslint-disable-next-line no-param-reassign
          tmp[period] = 0;
        }

        // eslint-disable-next-line no-param-reassign
        tmp[period] += value.quantity;

        return tmp;
      }, {});

      setChartData(projectIssuancesChartData);
    }
  }, []);

  useEffect(() => {
    fetchData().finally(() => {
      setIsLoading(false);
    });
  }, []);

  return {
    isLoading,
    dataGridColumns,
    dataGridData,
    chartData,
  };
}
