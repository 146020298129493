import { VerifierDetailsData } from "../../../route/public/verifier/details/page/useDetails";
import { VerifierDetails } from "../components";
import { usePublicVerifierDetailsPageTemplate } from "./usePublicVerifierDetailsPageTemplate";

interface PublicVerifierDetailsPageTemplateProps {
  verifierDetailsData: VerifierDetailsData;
}

export const PublicVerifierDetailsPageTemplate = ({
  verifierDetailsData,
}: PublicVerifierDetailsPageTemplateProps): JSX.Element => {
  const { organisationUuid, navigateBackToProject, showContactUsModal, setShowContactUsModal } =
    usePublicVerifierDetailsPageTemplate();
  return (
    <div>
      <VerifierDetails
        data={verifierDetailsData}
        organisationUuid={organisationUuid}
        isPreview={false}
        navigateBackToProject={navigateBackToProject}
        showContactUsModal={showContactUsModal}
        setShowContactUsModal={setShowContactUsModal}
      />
    </div>
  );
};
