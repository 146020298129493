// TODO temporarily disable eslint on this file until v1.0 Unwired is complete/services are used
/* eslint unused-imports/no-unused-vars: "off" */
/* eslint @typescript-eslint/no-explicit-any: "off" */
/* eslint @typescript-eslint/no-non-null-assertion: "off" */
// ProjectService
import { BaseResponse, BaseResponseWithSingle, Status } from "../Shared";
import {
  DeleteListingFileRequest,
  GetProjectListingPreviewRequest,
  GetProjectListingPreviewResponse,
  SaveProjectListingPreviewRequest,
  SaveProjectListingPreviewResponse,
  SetProjectIssuancesRequest,
  SetProjectIssuancesResponse,
  UpdateIssuancePricesRequest,
  UpdateIssuancePricesResponse,
  UpdateProjectDetailsRequest,
  UpdateProjectDetailsResponse,
  UpdateProjectListingRequest,
  UpdateProjectListingResponse,
  UploadListingFileRequest,
  UploadListingFileResponse,
} from "./Types";

// TEMP save preview data
const savedPreviews: any = {};

export async function updateIssuancePrices(
  req: UpdateIssuancePricesRequest[]
): Promise<BaseResponseWithSingle<UpdateIssuancePricesResponse>> {
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return { status: Status.Success };
}

export async function deleteListingFile(req: DeleteListingFileRequest): Promise<BaseResponse> {
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return { status: Status.Success };
}

export async function updateProjectListing(
  req: UpdateProjectListingRequest
): Promise<BaseResponseWithSingle<UpdateProjectListingResponse>> {
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return { status: Status.Success, data: { rowVersion: req.rowVersion + 1 } };
}

export async function getProjectListingPreview(
  req: GetProjectListingPreviewRequest
): Promise<BaseResponseWithSingle<GetProjectListingPreviewResponse>> {
  const existingPreview = savedPreviews[req.previewUuid];
  const data: GetProjectListingPreviewResponse = {
    content: existingPreview,
    listingFiles: [],
  };
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return {
    errors: [],
    status: Status.Success,
    data,
  };
}

export async function saveProjectListingPreview(
  req: SaveProjectListingPreviewRequest
): Promise<BaseResponseWithSingle<SaveProjectListingPreviewResponse>> {
  const previewUuid = "a2be6c12-dbb5-11ec-8c9f-fb6f5850b16d";
  savedPreviews[previewUuid] = req.content;
  const data: SaveProjectListingPreviewResponse = { previewUuid };
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return {
    status: Status.Success,
    data,
  };
}

export async function uploadListingFile(
  req: UploadListingFileRequest
): Promise<BaseResponseWithSingle<UploadListingFileResponse>> {
  const randomNumber = Math.floor(Math.random() * 1000);
  const fileUrl = `https://picsum.photos/id/${randomNumber}/200/300`;
  const data: UploadListingFileResponse = {
    projectListingFileUuid: `a2be6af0-dbb5-11ec-8c9e-0feb5022496f${randomNumber}`,
    file: {
      uuid: `a2be6af0-dbb5-11ec-8c9e-0feb5022496e${randomNumber}`,
      url: fileUrl,
      filename: `Uploaded Image ${randomNumber}.jpg`,
      mimeType: "image/jpeg",
    },
  };
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return {
    status: Status.Success,
    data,
  };
}

export async function setProjectIssuances(
  req: SetProjectIssuancesRequest
): Promise<BaseResponseWithSingle<SetProjectIssuancesResponse>> {
  const uuid = "a2be6c12-dbb5-11ec-8c9f-fb6f5850b1"; // uuid missing the last 3 digits
  const data: SetProjectIssuancesResponse = {
    result: req.data
      .filter((d) => d.price && d.quantity && d.quantityType && d.vintageEndDate && d.vintageStartDate)
      .map((d, index) => {
        return {
          price: d.price,
          quantity: d.quantity!,
          quantityType: d.quantityType!,
          vintageEndDate: d.vintageEndDate!,
          vintageStartDate: d.vintageStartDate!,
          uuid: uuid + index.toString().padStart(3, "0"),
        };
      }),
  };
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return {
    status: Status.Success,
    data,
  };
}

export async function updateProjectDetails(
  req: UpdateProjectDetailsRequest
): Promise<BaseResponseWithSingle<UpdateProjectDetailsResponse>> {
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return { status: Status.Success, data: { rowVersion: req.rowVersion + 1 } };
}
