import "./PublicDeveloperDetails.css";

import { Dispatch, SetStateAction } from "react";
import { useSearchParams } from "react-router-dom";

import { ArrowFullLeftDarkIcon } from "../../../../assets";
import { ContactUsModal } from "../../../../route/public/developer/components";
import { DeveloperDetailsData } from "../../../../route/public/developer/details/page/useDetails";
import { ProjectsSearch } from "../../../../route/public/projects/components";
import { getHostFromURL } from "../../../../utils";
import { Button, Column, Logo, Map, Pill, Row } from "../../../../widget";
import { AboutUsSection, ContentSection, RichContentSection, SocialMediaSection } from "../../../components";
import { AtAGlanceSection } from "../AtAGlanceSection";

interface PublicDeveloperDetailsProps {
  developerDetailsData?: DeveloperDetailsData;
  projectsCoordinates: string[] | undefined;
  organisationUuid: string | undefined;
  isPreview: boolean;
  showContactUsModal?: boolean;
  setShowContactUsModal?: Dispatch<SetStateAction<boolean>>;
  navigateBackToProject?: () => void;
}

export const PublicDeveloperDetails = ({
  developerDetailsData,
  projectsCoordinates,
  organisationUuid,
  isPreview,
  showContactUsModal,
  setShowContactUsModal,
  navigateBackToProject,
}: PublicDeveloperDetailsProps): JSX.Element => {
  const [searchParams] = useSearchParams();
  const projectUuid = searchParams.get("projectUuid");
  const showMap = projectsCoordinates?.length;
  const showOurValues = developerDetailsData?.ourValues?.enabled;

  return (
    <div className="PublicDeveloperDetails">
      {developerDetailsData?.hero?.enabled && (
        <div
          className="HeroSectionOrganisationImage"
          style={{
            backgroundColor: `var(--color-light-grey)`,
            backgroundImage:
              developerDetailsData?.hero?.imageUrl !== undefined
                ? `url(${developerDetailsData?.hero?.imageUrl})`
                : undefined,
          }}
        />
      )}
      <div className="PublicDeveloperDetailsPageTemplate">
        {developerDetailsData?.hero?.enabled ? (
          <>
            {developerDetailsData?.logo && (
              <div className="HeroSectionOrganisationLogo">
                <Logo displayedLogo={developerDetailsData?.logo} />
              </div>
            )}
            {!isPreview && setShowContactUsModal && (
              <div className="PreviewButtonsContainer">
                <div className={projectUuid ? "" : "HideBackToProjectButton"}>
                  <Button
                    text="Back to project"
                    variant="secondary"
                    icon={<ArrowFullLeftDarkIcon />}
                    iconPosition="left"
                    onClick={navigateBackToProject}
                  />
                </div>
                {developerDetailsData.calculatedContactable && developerDetailsData?.getInTouch?.enabled && (
                  <Button text="Contact the developer" onClick={() => setShowContactUsModal(true)} />
                )}
              </div>
            )}
            <Row spacingV="ml" justify="center">
              <Column span={12}>
                <h1>{developerDetailsData?.displayName}</h1>
              </Column>
            </Row>
            <Row spacingV="m" justify="center">
              <Column span={10}>
                <h2>{developerDetailsData?.hero?.title}</h2>
              </Column>
            </Row>
            <Row spacingV="ml" justify="center">
              <Column span={12}>
                <span className="body1">{developerDetailsData?.hero?.subtitle}</span>
              </Column>
            </Row>
            {developerDetailsData?.websiteUrl && (
              <Row spacingV="ml" justify="center">
                <Column span={12}>
                  <Pill
                    variant="blue"
                    label={getHostFromURL(developerDetailsData?.websiteUrl)}
                    link={developerDetailsData?.websiteUrl}
                  />
                </Column>
              </Row>
            )}
            <SocialMediaSection isPreview={isPreview} socialMedia={developerDetailsData.socialMediaData} />
          </>
        ) : (
          <>
            {!isPreview && setShowContactUsModal && (
              <div
                className={` ${
                  developerDetailsData?.logo
                    ? "NoHeroSectionPreviewButtonsContainer"
                    : "NoHeroSectionNoDeveloperLogoPreviewButtonsContainer"
                }`}
              >
                <div className={projectUuid ? "" : "HideBackToProjectButton"}>
                  <Button
                    text="Back to project"
                    variant="secondary"
                    icon={<ArrowFullLeftDarkIcon />}
                    iconPosition="left"
                    onClick={navigateBackToProject}
                  />
                </div>
                {developerDetailsData?.calculatedContactable && developerDetailsData?.getInTouch?.enabled && (
                  <Button text="Contact the developer" onClick={() => setShowContactUsModal(true)} />
                )}
              </div>
            )}
            {developerDetailsData?.logo && (
              <div className="NoHeroSectionOrganisationLogo">
                <Logo displayedLogo={developerDetailsData?.logo} />
              </div>
            )}
            <Row spacingV="ml" justify="center">
              <Column span={12}>
                <h1>{developerDetailsData?.displayName}</h1>
              </Column>
            </Row>
            {developerDetailsData?.websiteUrl && (
              <Row spacingV="ml" justify="center">
                <Column span={12}>
                  <Pill
                    variant="blue"
                    label={getHostFromURL(developerDetailsData?.websiteUrl)}
                    link={developerDetailsData?.websiteUrl}
                  />
                </Column>
              </Row>
            )}
            {developerDetailsData?.socialMediaData && (
              <SocialMediaSection isPreview={isPreview} socialMedia={developerDetailsData.socialMediaData} />
            )}
          </>
        )}
        {(showMap || showOurValues) && (
          <Row spacingV="ml">
            {showOurValues && developerDetailsData?.ourValues?.content && (
              <Column span={showMap ? 8 : 12}>
                <ContentSection title="Our Values" content={developerDetailsData.ourValues.content} />
              </Column>
            )}
            {showMap && projectsCoordinates && (
              <Column span={showOurValues ? 4 : 12}>
                {showOurValues ? (
                  <Map coordinates={projectsCoordinates} height={360} />
                ) : (
                  <Map fullWidth coordinates={projectsCoordinates} height={515} />
                )}
              </Column>
            )}
          </Row>
        )}
        {developerDetailsData?.ourApproach?.enabled && (
          <Row spacingV="ml">
            <Column span={12}>
              <RichContentSection title="Our approach" sectionData={developerDetailsData.ourApproach} />
            </Column>
          </Row>
        )}
        {developerDetailsData?.atAGlance?.enabled && (
          <Row spacingV="ml">
            <Column span={12}>
              <AtAGlanceSection organisationUuid={organisationUuid} />
            </Column>
          </Row>
        )}
        {developerDetailsData?.ourServices?.enabled && (
          <Row spacingV="ml">
            <Column span={12}>
              <RichContentSection title="Our services" sectionData={developerDetailsData.ourServices} />
            </Column>
          </Row>
        )}
        <div className="OurProjectsSection">
          <Row spacingV="ll">
            <Column span={12}>
              <h2>Our projects</h2>
            </Column>
          </Row>
          <Row spacingV="2xl">
            <Column span={12}>
              <ProjectsSearch developerUuid={organisationUuid} />
            </Column>
          </Row>
        </div>
        {developerDetailsData?.aboutUs?.enabled &&
          developerDetailsData?.aboutUs?.content &&
          developerDetailsData?.aboutUs?.imageUrl && (
            <Row>
              <Column span={12}>
                <AboutUsSection
                  content={developerDetailsData.aboutUs.content}
                  imageUrl={developerDetailsData.aboutUs.imageUrl}
                />
              </Column>
            </Row>
          )}
      </div>
      {developerDetailsData?.displayName && setShowContactUsModal && showContactUsModal && (
        <ContactUsModal
          developerName={developerDetailsData?.displayName}
          developerPhoneNumber={developerDetailsData?.contactPhone}
          show={showContactUsModal}
          closeModal={() => {
            setShowContactUsModal(false);
          }}
        />
      )}
    </div>
  );
};
