import {
  Dispatch,
  FormEvent,
  ForwardedRef,
  SetStateAction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { FileUploadHandle } from "../../../../../../models";
import { updateOrganisationListing, uploadListingFile } from "../../../../../../service/organisation";
import { ServiceError, Status } from "../../../../../../service/Shared";
import { useAuth } from "../../../../../../useAuth";
import { areObjectsDeepEqual } from "../../../../../../utils";
import { Toast } from "../../../../../../widget";
import {
  FormAboutUsData,
  FormNames,
  MarketingAssetsFormChangedAction,
  MarketingAssetsFormChangedEnum,
  MarketingAssetsSubmitHandler,
} from "../../models";

interface UseAboutUsFormReturnData {
  teamPhotoUploadRef: ForwardedRef<FileUploadHandle>;
  enabled: boolean;
  content: string | null;
  errors: ServiceError[] | undefined;
  teamImageUrl: string | null | undefined;
  setEnabled: Dispatch<SetStateAction<boolean>>;
  setContent: Dispatch<SetStateAction<string | null>>;
  handleSetTeamPhoto: (f: File) => Promise<void>;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  handleCancel: () => void;
}

export function useAboutUsForm(
  aboutUsDefaultValues: FormAboutUsData,
  aboutUsOldDefaultValues: FormAboutUsData,
  listingRowVersion: number,
  setListingRowVersion: Dispatch<SetStateAction<number>>,
  dispatch: Dispatch<MarketingAssetsFormChangedAction>,
  forwardedRef: ForwardedRef<MarketingAssetsSubmitHandler>,
  getCurrentFormsData: (formWhichWasSaved: string) => string
): UseAboutUsFormReturnData {
  const { currentOrganisationUuid } = useAuth();

  const [submittedValues, setSubmittedValues] = useState<FormAboutUsData>(aboutUsOldDefaultValues);
  const teamPhotoUploadRef = useRef<FileUploadHandle>(null);

  const [enabled, setEnabled] = useState(aboutUsDefaultValues.enabled);
  const [content, setContent] = useState(aboutUsDefaultValues.content);
  const [teamPhotoUuid, setTeamPhotoUuid] = useState<string | null | undefined>(aboutUsDefaultValues.imageFileUuid);
  const [teamImageUrl, setTeamImageUrl] = useState(aboutUsDefaultValues.imageFileUrl ?? null);
  const [errors, setErrors] = useState<ServiceError[] | undefined>();

  useImperativeHandle(forwardedRef, () => ({
    getAboutUsData() {
      return {
        enabled,
        content,
        imageFileUuid: teamPhotoUuid,
        imageFileUrl: teamImageUrl,
      };
    },
    getAboutUsSubmittedData() {
      return {
        ...submittedValues,
      };
    },
    setAboutUsErrors(previewErrors: ServiceError[]) {
      setErrors(previewErrors);
    },
  }));

  const handleSetTeamPhoto = async (f: File): Promise<void> => {
    if (currentOrganisationUuid) {
      setErrors([]);
      const fileRes = await uploadListingFile({
        organisationUuid: currentOrganisationUuid,
        file: f,
      });

      if (fileRes.status === Status.Success && fileRes.data?.organisationListingFileUuid) {
        Toast.success({ message: "File uploaded successfully" });
        setTeamPhotoUuid(fileRes.data?.organisationListingFileUuid);
        setTeamImageUrl(fileRes.data?.file.url);
      }

      if (fileRes.status === Status.Error && fileRes.errors) {
        setErrors(fileRes.errors);
      }
    }
  };

  const getCurrentFormData = useCallback((): FormAboutUsData => {
    return {
      enabled,
      imageFileUuid: teamPhotoUuid,
      content: content || null,
      imageFileUrl: teamImageUrl,
    };
  }, [enabled, teamPhotoUuid, content, teamImageUrl]);

  useEffect(() => {
    dispatch({
      type: MarketingAssetsFormChangedEnum.SET_ABOUT_US_FORM_CHANGED,
      value: !areObjectsDeepEqual(submittedValues, getCurrentFormData()),
    });
  }, [enabled, teamPhotoUuid, content, submittedValues, teamImageUrl]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setErrors([]);

    if (currentOrganisationUuid) {
      const newAboutUsValues = {
        enabled,
        imageFileUuid: teamPhotoUuid,
        content: content || null,
        imageFileUrl: teamImageUrl,
      };

      const detailsRes = await updateOrganisationListing({
        organisationUuid: currentOrganisationUuid,
        content: getCurrentFormsData(FormNames.AboutUs),
        rowVersion: listingRowVersion,
      });

      if (detailsRes.status === Status.Success && detailsRes.data) {
        Toast.success({ message: "About us details changed successfully" });
        setSubmittedValues({
          ...newAboutUsValues,
        });
        setListingRowVersion(detailsRes.data?.rowVersion);
      }

      if (detailsRes.status === Status.Error) {
        setErrors(detailsRes.errors);
      }
    }
  };

  const handleCancel = (): void => {
    if (teamPhotoUploadRef.current) {
      teamPhotoUploadRef.current.clearInput();
      setTeamPhotoUuid(submittedValues.imageFileUuid);
      setTeamImageUrl(submittedValues.imageFileUrl ?? null);
    }
    setEnabled(submittedValues.enabled);
    setContent(submittedValues.content);
    setErrors([]);
  };

  return {
    teamPhotoUploadRef,
    enabled,
    content,
    errors,
    teamImageUrl,
    setEnabled,
    setContent,
    handleSetTeamPhoto,
    handleSubmit,
    handleCancel,
  };
}
