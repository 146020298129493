import {
  Dispatch,
  FormEvent,
  ForwardedRef,
  SetStateAction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { FileUploadHandle } from "../../../../../../models";
import { updateOrganisationListing, uploadListingFile } from "../../../../../../service/organisation";
import { ServiceError, Status } from "../../../../../../service/Shared";
import { useAuth } from "../../../../../../useAuth";
import { areObjectsDeepEqual } from "../../../../../../utils";
import { Toast } from "../../../../../../widget";
import {
  FormNames,
  FormOurApproachData,
  MarketingAssetsFormChangedAction,
  MarketingAssetsFormChangedEnum,
  MarketingAssetsSubmitHandler,
} from "../../models";

interface UseOurApproachFormReturnData {
  enabled: boolean;
  part1ImageUploadRef: ForwardedRef<FileUploadHandle>;
  part2ImageUploadRef: ForwardedRef<FileUploadHandle>;
  part3ImageUploadRef: ForwardedRef<FileUploadHandle>;
  part4ImageUploadRef: ForwardedRef<FileUploadHandle>;
  part1Title: string | null | undefined;
  part1Content: string | null | undefined;
  part1ImageUrl: string | null | undefined;
  part2Title: string | null | undefined;
  part2Content: string | null | undefined;
  part2ImageUrl: string | null | undefined;
  part3Title: string | null | undefined;
  part3Content: string | null | undefined;
  part3ImageUrl: string | null | undefined;
  part4Title: string | null | undefined;
  part4Content: string | null | undefined;
  part4ImageUrl: string | null | undefined;
  errors: ServiceError[] | undefined;
  setEnabled: Dispatch<SetStateAction<boolean>>;
  setPart1Title: Dispatch<SetStateAction<string | null | undefined>>;
  setPart1Content: Dispatch<SetStateAction<string | null | undefined>>;
  setPart2Title: Dispatch<SetStateAction<string | null | undefined>>;
  setPart2Content: Dispatch<SetStateAction<string | null | undefined>>;
  setPart3Title: Dispatch<SetStateAction<string | null | undefined>>;
  setPart3Content: Dispatch<SetStateAction<string | null | undefined>>;
  setPart4Title: Dispatch<SetStateAction<string | null | undefined>>;
  setPart4Content: Dispatch<SetStateAction<string | null | undefined>>;
  handleSetImage: (f: File, part: number) => Promise<void>;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  handleCancel: () => void;
}

export function useOurApproachForm(
  ourApproachDefaultValues: FormOurApproachData,
  ourApproachOldDefaultValues: FormOurApproachData,
  listingRowVersion: number,
  setListingRowVersion: Dispatch<SetStateAction<number>>,
  dispatch: Dispatch<MarketingAssetsFormChangedAction>,
  forwardedRef: ForwardedRef<MarketingAssetsSubmitHandler>,
  getCurrentFormsData: (formWhichWasSaved: string) => string
): UseOurApproachFormReturnData {
  const { currentOrganisationUuid } = useAuth();

  const part1ImageUploadRef = useRef<FileUploadHandle>(null);
  const part2ImageUploadRef = useRef<FileUploadHandle>(null);
  const part3ImageUploadRef = useRef<FileUploadHandle>(null);
  const part4ImageUploadRef = useRef<FileUploadHandle>(null);

  const [submittedValues, setSubmittedValues] = useState<FormOurApproachData>(ourApproachOldDefaultValues);

  const [enabled, setEnabled] = useState(ourApproachDefaultValues.enabled);
  const [part1Title, setPart1Title] = useState(ourApproachDefaultValues.part1?.title);
  const [part1Content, setPart1Content] = useState(ourApproachDefaultValues.part1?.content);
  const [part1ImageUuid, setPart1ImageUuid] = useState(ourApproachDefaultValues.part1?.imageFileUuid);
  const [part1ImageUrl, setPart1ImageUrl] = useState(ourApproachDefaultValues.part1?.imageFileUrl);

  const [part2Title, setPart2Title] = useState(ourApproachDefaultValues.part2?.title);
  const [part2Content, setPart2Content] = useState(ourApproachDefaultValues.part2?.content);
  const [part2ImageUuid, setPart2ImageUuid] = useState(ourApproachDefaultValues.part2?.imageFileUuid);
  const [part2ImageUrl, setPart2ImageUrl] = useState(ourApproachDefaultValues.part2?.imageFileUrl);

  const [part3Title, setPart3Title] = useState(ourApproachDefaultValues.part3?.title);
  const [part3Content, setPart3Content] = useState(ourApproachDefaultValues.part3?.content);
  const [part3ImageUuid, setPart3ImageUuid] = useState(ourApproachDefaultValues.part3?.imageFileUuid);
  const [part3ImageUrl, setPart3ImageUrl] = useState(ourApproachDefaultValues.part3?.imageFileUrl);

  const [part4Title, setPart4Title] = useState(ourApproachDefaultValues.part4?.title);
  const [part4Content, setPart4Content] = useState(ourApproachDefaultValues.part4?.content);
  const [part4ImageUuid, setPart4ImageUuid] = useState(ourApproachDefaultValues.part4?.imageFileUuid);
  const [part4ImageUrl, setPart4ImageUrl] = useState(ourApproachDefaultValues.part4?.imageFileUrl);
  const [errors, setErrors] = useState<ServiceError[] | undefined>();

  const getLatestData = (): {
    enabled: boolean;
  } => {
    const ourApproachData = { enabled };

    if (part1Title || part1Content || part1ImageUuid || part1ImageUrl) {
      Object.assign(ourApproachData, {
        part1: {
          title: part1Title,
          content: part1Content,
          imageFileUuid: part1ImageUuid,
          imageFileUrl: part1ImageUrl,
        },
      });
    }

    if (part2Title || part2Content || part2ImageUuid || part2ImageUrl) {
      Object.assign(ourApproachData, {
        part2: {
          title: part2Title,
          content: part2Content,
          imageFileUuid: part2ImageUuid,
          imageFileUrl: part2ImageUrl,
        },
      });
    }

    if (part3Title || part3Content || part3ImageUuid || part3ImageUrl) {
      Object.assign(ourApproachData, {
        part3: {
          title: part3Title,
          content: part3Content,
          imageFileUuid: part3ImageUuid,
          imageFileUrl: part3ImageUrl,
        },
      });
    }

    if (part4Title || part4Content || part4ImageUuid || part4ImageUrl) {
      Object.assign(ourApproachData, {
        part4: {
          title: part4Title,
          content: part4Content,
          imageFileUuid: part4ImageUuid,
          imageFileUrl: part4ImageUrl,
        },
      });
    }

    return ourApproachData;
  };

  useImperativeHandle(forwardedRef, () => ({
    getOurApproachData() {
      return getLatestData();
    },
    getOurApproachSubmittedData() {
      return {
        ...submittedValues,
      };
    },
    setOurApproachErrors(previewErrors: ServiceError[]) {
      setErrors(previewErrors);
    },
  }));

  const handleSetImage = async (f: File, part: number): Promise<void> => {
    if (currentOrganisationUuid) {
      setErrors([]);
      const fileRes = await uploadListingFile({
        organisationUuid: currentOrganisationUuid,
        file: f,
      });

      if (fileRes.status === Status.Success && fileRes.data?.organisationListingFileUuid) {
        Toast.success({ message: "File uploaded successfully" });

        switch (part) {
          case 1:
            setPart1ImageUuid(fileRes.data?.organisationListingFileUuid);
            setPart1ImageUrl(fileRes.data?.file?.url);
            return;
          case 2:
            setPart2ImageUuid(fileRes.data?.organisationListingFileUuid);
            setPart2ImageUrl(fileRes.data?.file?.url);
            return;
          case 3:
            setPart3ImageUuid(fileRes.data?.organisationListingFileUuid);
            setPart3ImageUrl(fileRes.data?.file?.url);
            return;
          case 4:
            setPart4ImageUuid(fileRes.data?.organisationListingFileUuid);
            setPart4ImageUrl(fileRes.data?.file?.url);
            return;
          default: {
            Toast.error({ message: "Couldn't set the image for that part" });
          }
        }
      }

      if (fileRes.status === Status.Error) {
        setErrors(fileRes.errors);
      }
    }
  };

  const getCurrentFormData = useCallback((): FormOurApproachData => {
    return {
      enabled,
      part1:
        part1Title || part1Content || part1ImageUuid
          ? {
              title: part1Title || null,
              content: part1Content || null,
              imageFileUuid: part1ImageUuid || null,
              imageFileUrl: part1ImageUrl || null,
            }
          : undefined,
      part2:
        part2Title || part2Content || part2ImageUuid
          ? {
              title: part2Title || null,
              content: part2Content || null,
              imageFileUuid: part2ImageUuid || null,
              imageFileUrl: part2ImageUrl || null,
            }
          : undefined,
      part3:
        part3Title || part3Content || part3ImageUuid
          ? {
              title: part3Title || null,
              content: part3Content || null,
              imageFileUuid: part3ImageUuid || null,
              imageFileUrl: part3ImageUrl || null,
            }
          : undefined,
      part4:
        part4Title || part4Content || part4ImageUuid
          ? {
              title: part4Title || null,
              content: part4Content || null,
              imageFileUuid: part4ImageUuid || null,
              imageFileUrl: part4ImageUrl || null,
            }
          : undefined,
    };
  }, [
    enabled,
    part1Title,
    part1Content,
    part1ImageUuid,
    part1ImageUrl,
    part2Title,
    part2Content,
    part2ImageUuid,
    part2ImageUrl,
    part3Title,
    part3Content,
    part3ImageUuid,
    part3ImageUrl,
    part4Title,
    part4Content,
    part4ImageUuid,
    part4ImageUrl,
  ]);

  useEffect(() => {
    dispatch({
      type: MarketingAssetsFormChangedEnum.SET_OUR_APPROACH_FORM_CHANGED,
      value: !areObjectsDeepEqual(submittedValues, getCurrentFormData()),
    });
  }, [
    enabled,
    part1Title,
    part1Content,
    part1ImageUuid,
    part1ImageUrl,
    part2Title,
    part2Content,
    part2ImageUuid,
    part2ImageUrl,
    part3Title,
    part3Content,
    part3ImageUuid,
    part3ImageUrl,
    part4Title,
    part4Content,
    part4ImageUuid,
    part4ImageUrl,
    submittedValues,
  ]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setErrors([]);

    if (currentOrganisationUuid) {
      const res = await updateOrganisationListing({
        organisationUuid: currentOrganisationUuid,
        content: getCurrentFormsData(FormNames.OurApproach),
        rowVersion: listingRowVersion,
      });

      if (res.status === Status.Success && res.data) {
        Toast.success({ message: "Our approach details changed successfully" });
        setSubmittedValues({
          enabled,
          part1: {
            title: part1Title,
            content: part1Content,
            imageFileUuid: part1ImageUuid,
            imageFileUrl: part1ImageUrl,
          },
          part2: {
            title: part2Title,
            content: part2Content,
            imageFileUuid: part2ImageUuid,
            imageFileUrl: part2ImageUrl,
          },
          part3: {
            title: part3Title,
            content: part3Content,
            imageFileUuid: part3ImageUuid,
            imageFileUrl: part3ImageUrl,
          },
          part4: {
            title: part4Title,
            content: part4Content,
            imageFileUuid: part4ImageUuid,
            imageFileUrl: part4ImageUrl,
          },
        });

        setListingRowVersion(res.data?.rowVersion);
      }
      if (res.status === Status.Error) {
        setErrors(res.errors);
      }
    }
  };

  const handleCancel = (): void => {
    setEnabled(submittedValues.enabled);

    setPart1Title(submittedValues.part1?.title);
    setPart1Content(submittedValues.part1?.content);
    if (part1ImageUploadRef.current) {
      part1ImageUploadRef.current.clearInput();
      setPart1ImageUuid(submittedValues.part1?.imageFileUuid);
      setPart1ImageUrl(submittedValues.part1?.imageFileUrl);
    }

    setPart2Title(submittedValues.part2?.title);
    setPart2Content(submittedValues.part2?.content);
    if (part2ImageUploadRef.current) {
      part2ImageUploadRef.current.clearInput();
      setPart2ImageUuid(submittedValues.part2?.imageFileUuid);
      setPart2ImageUrl(submittedValues.part2?.imageFileUrl);
    }

    setPart3Title(submittedValues.part3?.title);
    setPart3Content(submittedValues.part3?.content);
    if (part3ImageUploadRef.current) {
      part3ImageUploadRef.current.clearInput();
      setPart3ImageUuid(submittedValues.part3?.imageFileUuid);
      setPart3ImageUrl(submittedValues.part3?.imageFileUrl);
    }

    setPart4Title(submittedValues.part4?.title);
    setPart4Content(submittedValues.part4?.content);
    if (part4ImageUploadRef.current) {
      part4ImageUploadRef.current.clearInput();
      setPart4ImageUuid(submittedValues.part4?.imageFileUuid);
      setPart4ImageUrl(submittedValues.part4?.imageFileUrl);
    }
    setErrors([]);
  };

  return {
    enabled,
    part1ImageUploadRef,
    part2ImageUploadRef,
    part3ImageUploadRef,
    part4ImageUploadRef,
    part1Title,
    part1Content,
    part1ImageUrl,
    part2Title,
    part2Content,
    part2ImageUrl,
    part3Title,
    part3Content,
    part3ImageUrl,
    part4Title,
    part4Content,
    part4ImageUrl,
    errors,
    setEnabled,
    setPart1Title,
    setPart1Content,
    setPart2Title,
    setPart2Content,
    setPart3Title,
    setPart3Content,
    setPart4Title,
    setPart4Content,
    handleSetImage,
    handleSubmit,
    handleCancel,
  };
}
