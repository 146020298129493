import { useCallback, useEffect, useState } from "react";

import { EmbeddingPreferences } from "../../../../models";
import { getOrganisationEmbeddingPreferences } from "../../../../service/query";
import { Status } from "../../../../service/Shared";
import { useAuth } from "../../../../useAuth";

interface UseEmbeddingReturnData {
  dataIsLoading: boolean;
  defaultValues: EmbeddingPreferences[] | undefined;
}

export function useEmbedding(): UseEmbeddingReturnData {
  const { currentOrganisationUuid } = useAuth();
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(true);
  const [defaultValues, setDefaultValues] = useState<EmbeddingPreferences[] | undefined>(undefined);
  const fetchData = useCallback(async () => {
    const res = await getOrganisationEmbeddingPreferences({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      organisationUuid: currentOrganisationUuid!,
    });

    setDefaultValues(res.status === Status.Success ? res.data : []);
  }, []);

  useEffect(() => {
    fetchData().then(() => setDataIsLoading(false));
  }, [fetchData]);

  return {
    dataIsLoading,
    defaultValues,
  };
}
