import { DeveloperSingleCardTemplate } from "../../../../../templates";
import { SendInvitationForm } from "./form";
import { useSendInvitation } from "./useSendInvitation";

export const SendInvitation = (): JSX.Element => {
  const { roles } = useSendInvitation();
  return (
    <DeveloperSingleCardTemplate activeElement="Settings" pageTitle="Invite new user">
      <SendInvitationForm roles={roles} />
    </DeveloperSingleCardTemplate>
  );
};
