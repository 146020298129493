import "./PublicCodeDetails.css";

import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { ArrowFullLeftDarkIcon, InformationIcon } from "../../../../assets";
import { CodeDetailsData } from "../../../../route/public/code/details/page/useDetails";
import { CodeStatus } from "../../../../route/public/code/models";
import { getHostFromURL } from "../../../../utils";
import { Button, CodeStatusKPI, Column, HorizontalProjectCards, Logo, Pill, Row } from "../../../../widget";
import { DisplayHorizontalProject } from "../../../../widget/cards/HorizontalProjectCards/models";
import { CoreCarbonPrinciples } from "../../../../widget/general/CoreCarbonPrinciples";
import { AboutUsSection, ContentSection, SocialMediaSection } from "../../../components";
import { KeyFactsSection } from "../KeyFactsSection";
import { ProjectProcessSection } from "../ProjectProcessSection";
import { ProjectTypesSection } from "../ProjectTypesSection";
import { ReportingStandardsSection } from "../ReportingStandardsSection";

interface PublicCodeDetailsProps {
  codeDetailsData?: CodeDetailsData;
  organisationUuid: string | undefined;
  organisationProjects: DisplayHorizontalProject[] | undefined;
  isPreview: boolean;
  navigateBackToProject?: () => void;
  navigateBackToCodeComparison?: () => void;
}

export const PublicCodeDetails = ({
  codeDetailsData,
  organisationUuid,
  organisationProjects,
  isPreview,
  navigateBackToProject,
  navigateBackToCodeComparison,
}: PublicCodeDetailsProps): JSX.Element => {
  const [searchParams] = useSearchParams();

  const projectUuid = useMemo(() => {
    return searchParams.get("projectUuid");
  }, [searchParams]);

  return (
    <div className="PublicCodeDetails">
      {codeDetailsData?.hero.enabled && (
        <div
          className="HeroSectionOrganisationImage"
          style={{
            backgroundImage:
              codeDetailsData?.hero?.imageUrl !== undefined ? `url(${codeDetailsData?.hero?.imageUrl})` : undefined,
          }}
        />
      )}
      <div className="PublicCodeDetailsPageTemplate">
        {codeDetailsData?.hero.enabled ? (
          <>
            {codeDetailsData?.logo && (
              <div className="HeroSectionOrganisationLogo">
                <Logo displayedLogo={codeDetailsData?.logo} />
              </div>
            )}
            {!isPreview &&
              // TODO treat the case when navigating to a Established code from the code-comparison page
              (codeDetailsData.status === CodeStatus.Emerging ? (
                <div className="PreviewButtonsContainer">
                  <Button
                    text="Back"
                    variant="secondary"
                    icon={<ArrowFullLeftDarkIcon />}
                    iconPosition="left"
                    onClick={navigateBackToCodeComparison}
                  />
                </div>
              ) : (
                <div className="PreviewButtonsContainer">
                  <div className={projectUuid ? "" : "HideBackToProjectButton"}>
                    <Button
                      text="Back to project"
                      variant="secondary"
                      icon={<ArrowFullLeftDarkIcon />}
                      iconPosition="left"
                      onClick={navigateBackToProject}
                    />
                  </div>
                </div>
              ))}
            <Row spacingV="ml" justify="center">
              <Column span={12}>
                <h1>{codeDetailsData?.displayName}</h1>
              </Column>
            </Row>
            {codeDetailsData?.hero?.subtitle && (
              <Row spacingV="m" justify="center">
                <Column span={12}>
                  <div className="HeroSectionSubtitle">
                    <p className="body1">{codeDetailsData?.hero?.subtitle}</p>
                  </div>
                </Column>
              </Row>
            )}
            {codeDetailsData?.registryData?.name && codeDetailsData?.registryData?.url && (
              <Row spacingV="ml" justify="center">
                <Column span={12}>
                  <div className="HeroSectionRegistry">
                    <a href={codeDetailsData?.registryData?.url} target="_blank" rel="noopener noreferrer">
                      {codeDetailsData?.registryData?.name}
                    </a>
                  </div>
                </Column>
              </Row>
            )}
            {codeDetailsData?.websiteUrl && (
              <Row spacingV="ml" justify="center">
                <Column span={12}>
                  <Pill
                    variant="blue"
                    label={getHostFromURL(codeDetailsData?.websiteUrl)}
                    link={codeDetailsData?.websiteUrl}
                  />
                </Column>
              </Row>
            )}
            <SocialMediaSection isPreview={isPreview} socialMedia={codeDetailsData.socialMediaData} />
          </>
        ) : (
          <>
            {!isPreview && (
              <div
                className={` ${
                  codeDetailsData?.logo
                    ? "NoHeroSectionPreviewButtonsContainer"
                    : "NoHeroSectionNoCodeLogoPreviewButtonsContainer"
                }`}
              >
                {codeDetailsData?.status === CodeStatus.Emerging ? (
                  // TODO treat the case when navigating to a Established code from the code-comparison page
                  <Button
                    text="Back"
                    variant="secondary"
                    icon={<ArrowFullLeftDarkIcon />}
                    iconPosition="left"
                    onClick={navigateBackToCodeComparison}
                  />
                ) : (
                  <div className={projectUuid ? "" : "HideBackToProjectButton"}>
                    <Button
                      text="Back to project"
                      variant="secondary"
                      icon={<ArrowFullLeftDarkIcon />}
                      iconPosition="left"
                      onClick={navigateBackToProject}
                    />
                  </div>
                )}
              </div>
            )}
            {codeDetailsData?.logo && (
              <div className="NoHeroSectionOrganisationLogo">
                <Logo displayedLogo={codeDetailsData?.logo} />
              </div>
            )}
            <Row spacingV="ml" justify="center">
              <Column span={12}>
                <h1>{codeDetailsData?.displayName}</h1>
              </Column>
            </Row>
            {codeDetailsData?.hero?.subtitle && (
              <Row spacingV="ml" justify="center">
                <Column span={12}>
                  <div className="HeroSectionSubtitle">
                    <p className="body1">{codeDetailsData?.hero?.subtitle}</p>
                  </div>
                </Column>
              </Row>
            )}
            {codeDetailsData?.registryData?.name && codeDetailsData?.registryData?.url && (
              <Row spacingV="ml" justify="center">
                <Column span={12}>
                  <div className="HeroSectionRegistry">
                    <a href={codeDetailsData?.registryData?.url} target="_blank" rel="noopener noreferrer">
                      {codeDetailsData?.registryData?.name}
                    </a>
                  </div>
                </Column>
              </Row>
            )}
            {codeDetailsData?.websiteUrl && (
              <Row spacingV="ml" justify="center">
                <Column span={12}>
                  <Pill
                    variant="blue"
                    label={getHostFromURL(codeDetailsData?.websiteUrl)}
                    link={codeDetailsData?.websiteUrl}
                  />
                </Column>
              </Row>
            )}
            {codeDetailsData?.socialMediaData && (
              <SocialMediaSection isPreview={isPreview} socialMedia={codeDetailsData.socialMediaData} />
            )}
          </>
        )}
        {codeDetailsData?.codeCharacteristics && (
          <Row spacingV="ml">
            <Column span={3}>
              <ReportingStandardsSection reportingStandards={codeDetailsData?.ukEnvironmentalReporting} />
            </Column>
            <Column span={5}>
              <div className="CodeStatusKPIContainer">
                <CodeStatusKPI codeCharacteristics={codeDetailsData?.codeCharacteristics} />
              </div>
            </Column>
            <Column span={4}>
              <div className="ProjectTypesSectionContainer">
                <ProjectTypesSection codeUuid={organisationUuid} projectTypes={codeDetailsData?.projectTypes} />
              </div>
            </Column>
          </Row>
        )}
        {codeDetailsData?.ourMission?.enabled && codeDetailsData?.ourMission?.content && (
          <Row spacingV="ml">
            <Column span={12}>
              <ContentSection title="Our mission" content={codeDetailsData.ourMission.content} />
            </Column>
          </Row>
        )}
        {codeDetailsData?.keyFacts.enabled && (
          <Row spacingV="ml">
            <Column span={12}>
              <KeyFactsSection keyFactsText={codeDetailsData?.keyFacts} organisationUuid={organisationUuid} />
            </Column>
          </Row>
        )}
        {codeDetailsData?.codeCharacteristics &&
          (codeDetailsData?.codeCharacteristics.enabled ? (
            <Row spacingV="ml">
              <Column span={12}>
                <CoreCarbonPrinciples
                  characteristics={codeDetailsData.coreCarbonPrinciples.characteristics}
                  codeUrl={codeDetailsData.coreCarbonPrinciples.codeUrl}
                />
              </Column>
            </Row>
          ) : (
            <Row spacingV="ml">
              <Column span={12}>
                <div className="CoreCarbonPrinciplesEmergingSection">
                  <h2>Core carbon principles</h2>
                  <div className="CoreCarbonPrinciplesEmergingBanner">
                    <InformationIcon />
                    <span className="body2">
                      This code is in early stages of its development and does not yet have any published guidance. As
                      the code emerges, Kana will represent their approach and methodology on this page. Check back here
                      in the future for an update on how the code is progressing, as well has any projects that have
                      been listed on Kana.
                    </span>
                  </div>
                </div>
              </Column>
            </Row>
          ))}
        {codeDetailsData?.projectProcess?.enabled && (
          <Row spacingV="ml">
            <Column span={12}>
              <ProjectProcessSection projectProcess={codeDetailsData?.projectProcess} />
            </Column>
          </Row>
        )}
        {organisationProjects && !!organisationProjects.length && codeDetailsData?.displayName && (
          <Row spacingV="ml">
            <Column span={12}>
              <HorizontalProjectCards
                codeUuid={organisationUuid}
                codeName={codeDetailsData?.displayName}
                projects={organisationProjects}
              />
            </Column>
          </Row>
        )}
        {codeDetailsData?.aboutUs?.enabled && codeDetailsData?.aboutUs?.content && codeDetailsData?.aboutUs?.imageUrl && (
          <Row>
            <Column span={12}>
              <AboutUsSection content={codeDetailsData.aboutUs.content} imageUrl={codeDetailsData.aboutUs.imageUrl} />
            </Column>
          </Row>
        )}
      </div>
    </div>
  );
};
