/* eslint-disable unused-imports/no-unused-vars,no-console */
import { BaseResponse, Status } from "../Shared";
import { LogErrorRequest } from "./Types";

export async function logError(req: LogErrorRequest): Promise<BaseResponse> {
  await new Promise((r) => {
    setTimeout(r, 150);
  });

  console.error(req.error);

  return {
    errors: [],
    status: Status.Success,
  };
}
