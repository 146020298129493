import { Option } from "rc-select";
import { useEffect, useState } from "react";

import { SelectData } from "../../../models";

interface useAutocompleteProps {
  value?: string | null;
  data: SelectData;
  onChange: (value: string) => void;
}

interface useAutocompleteReturnData {
  currentValue: string | null | undefined;
  options: JSX.Element[];
  handleSearch: (newVal: string) => void;
  handleSelect: (newKey: string) => void;
}

export const useAutocomplete = ({ value, data, onChange }: useAutocompleteProps): useAutocompleteReturnData => {
  const [currentValue, setCurrentValue] = useState(value);
  const [options, setOptions] = useState<JSX.Element[]>(
    data.map((kvp) => (
      <Option key={kvp.key} value={kvp.key}>
        {kvp.value}
      </Option>
    ))
  );

  useEffect(() => {
    const va = data.find((v) => v.key === value)?.value;
    if (va) {
      setCurrentValue(va.toString());
    } else {
      setCurrentValue("");
    }
  }, [value]);

  useEffect(() => {
    setOptions(
      data.map((kvp) => (
        <Option key={kvp.key} value={kvp.key}>
          {kvp.value}
        </Option>
      ))
    );
  }, [data]);

  const handleSearch = (newVal: string): void => {
    setCurrentValue(newVal);
    setOptions(
      data
        .filter((kvp) => kvp.value?.toString().toLowerCase().includes(newVal.toLowerCase()) || kvp.key === newVal)
        .map((kvp) => (
          <Option key={kvp.key} value={kvp.key}>
            {kvp.value}
          </Option>
        ))
    );
  };

  const handleSelect = (newKey: string): void => {
    const el = data.filter((kvp) => (kvp.key ? kvp.key.toString().toLowerCase() : "") === newKey.toLowerCase())[0];
    if (el) {
      const newVal = el.value?.toString();
      onChange(el.key?.toString());
      handleSearch(newKey);
      setCurrentValue(newVal);
    }
  };

  return { currentValue, options, handleSearch, handleSelect };
};
