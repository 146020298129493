import "./AboutUsSection.css";

interface AboutUsSectionProps {
  content: string;
  imageUrl: string;
}

export const AboutUsSection = ({ content, imageUrl }: AboutUsSectionProps): JSX.Element => {
  return (
    <div className="AboutUsSection">
      <h2>About us</h2>
      <img src={imageUrl} alt="About us" />
      <p className="body1">{content}</p>
    </div>
  );
};
