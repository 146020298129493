import { Dispatch, FormEvent, SetStateAction, useState } from "react";

import { register } from "../../../../service/identity";
import { ServiceError, Status } from "../../../../service/Shared";

interface UseRegisterFormReturnData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  errors: ServiceError[] | undefined;
  passwordConfirmation: string;
  setFirstName: Dispatch<SetStateAction<string>>;
  setLastName: Dispatch<SetStateAction<string>>;
  setEmail: Dispatch<SetStateAction<string>>;
  setPassword: Dispatch<SetStateAction<string>>;
  setPasswordConfirmation: Dispatch<SetStateAction<string>>;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
}

export function useRegisterForm(onSuccess: (e: boolean) => void): UseRegisterFormReturnData {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [errors, setErrors] = useState<ServiceError[] | undefined>();

  async function handleSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault();
    setErrors([]);

    if (password !== passwordConfirmation) {
      setErrors([
        {
          code: "NOT_MATCHING_PASSWORDS",
          message: "The passwords do not match",
        },
      ]);
      return;
    }

    const res = await register({ firstName, lastName, email, password });
    if (res.status === Status.Success) {
      onSuccess(true);
    } else {
      setErrors(res.errors);
    }
  }

  return {
    firstName,
    lastName,
    email,
    password,
    passwordConfirmation,
    errors,
    setFirstName,
    setLastName,
    setEmail,
    setPassword,
    setPasswordConfirmation,
    handleSubmit,
  };
}
