import { PublicTemplate } from "../../../../../templates";
import { PublicCodeDetailsPageTemplate } from "../../../../../templates/PublicCodeDetailsPageTemplate";
import { useDetails } from "./useDetails";

const Details = (): JSX.Element => {
  const { dataIsLoading, codeDetailsData } = useDetails();

  return (
    <PublicTemplate activeElement="Project list" isContentUnwrapped>
      {!dataIsLoading && codeDetailsData && <PublicCodeDetailsPageTemplate codeDetailsData={codeDetailsData} />}
    </PublicTemplate>
  );
};

export default Details;
