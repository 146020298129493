import "./Basic.css";

import { Link } from "react-router-dom";

import { getPublicValidatorRoute } from "../../../../utils/routes";
import { Column, PieChart, ReadonlyText, Row } from "../../..";
import { ValidatorConstants } from "./constants";
import { BasicProjectDetails, useBasic } from "./useBasic";

interface BasicProps {
  projectDetails: BasicProjectDetails | undefined;
}

export const Basic = ({ projectDetails }: BasicProps): JSX.Element => {
  const { totalArea, totalPredictedCarbonSequestration, chartLabel, chartData, isPcc } = useBasic(projectDetails);
  return (
    <>
      <Row spacingV="ll">
        <Column span={12}>
          <h2>Details</h2>
        </Column>
      </Row>
      <Row spacingV="ll">
        <Column span={6}>
          <ReadonlyText label="Total area" value={totalArea || "Not available"} noDataAvailable={!totalArea} />
        </Column>
        <Column span={6}>
          <ReadonlyText
            label={`Total predicted carbon ${isPcc ? "reduction" : "sequestration"}`}
            value={totalPredictedCarbonSequestration || "Not available"}
            noDataAvailable={!totalPredictedCarbonSequestration}
          />
        </Column>
      </Row>
      <Row spacingV="ll">
        <Column span={12}>
          <Row spacingV="m">
            <Column span={12}>
              <h3>Validator</h3>
            </Column>
          </Row>
          <Row align="center" className="ProjectDetailsBasic_ValidatorContainer">
            {projectDetails?.validator?.files && projectDetails?.validator?.files.length > 0 && (
              <div className="ImageContainer">
                <img src={projectDetails?.validator.files[0].file.url} alt="ValidatorLogo" />
              </div>
            )}
            <Column span={5}>
              {projectDetails?.validator?.uuid &&
              ValidatorConstants.VALIDATOR_UUIDS.includes(projectDetails?.validator?.uuid) ? (
                <Link
                  to={{
                    pathname: getPublicValidatorRoute(projectDetails?.validator?.uuid),
                    search: `?projectUuid=${projectDetails.uuid}`,
                  }}
                >
                  {projectDetails?.validator?.displayName}
                </Link>
              ) : (
                <p className="body1">
                  {projectDetails?.validator?.displayName || "No validator selected for this project"}
                </p>
              )}
            </Column>
          </Row>
        </Column>
      </Row>
      <Row>
        <Column span={12}>
          <Row spacingV="m">
            <Column span={12}>
              <h3>{chartLabel}</h3>
            </Column>
          </Row>
          <PieChart size={160} data={chartData} centerTextHidden />
        </Column>
      </Row>
    </>
  );
};
