import "./Documents.css";

import { DocumentCard } from "./components/DocumentCard";
import { useDocuments } from "./useDocuments";

const Documents = (): JSX.Element => {
  const { isLoading, documents } = useDocuments();
  return (
    <div className="PublicProjectTabSection">
      <h2>Documents</h2>
      {!isLoading && (
        <div className="PublicDocumentsList">
          {documents && documents.length ? (
            documents?.map((el) => (
              <DocumentCard
                key={el.uuid}
                name={el.type}
                url={el.file.url}
                uploadedDate={el.cachedCreatedAt.toLocaleDateString("en-GB")}
              />
            ))
          ) : (
            <p className="body2 ">This project doesn&apos;t have any documents yet.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Documents;
