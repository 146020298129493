import { SelectData } from "../../../../../../models";
import {
  ActionButtons,
  Column,
  ErrorBox,
  InformationBox,
  RadioButtonGroup,
  Row,
  Select,
} from "../../../../../../widget";
import { useEditForm } from "./useEditForm";

interface EditFormProps {
  userUuid: string | undefined;
  defaultRoleValue: string;
  defaultActiveValue: boolean;
  roles: SelectData | undefined;
  rowVersion: number;
}

export const EditForm = ({
  userUuid,
  defaultRoleValue,
  defaultActiveValue,
  roles,
  rowVersion,
}: EditFormProps): JSX.Element => {
  const { radioValues, role, active, setRole, setActive, handleSubmit, handleCancel, errors } = useEditForm(
    userUuid,
    defaultRoleValue,
    defaultActiveValue,
    rowVersion
  );

  return (
    <>
      {errors && errors.length > 0 && (
        <Row spacingV="ml">
          <Column span={5}>
            <ErrorBox title="Please solve the below issues before saving" text={errors.map((error) => error.message)} />
          </Column>
        </Row>
      )}

      <form onSubmit={(e) => handleSubmit(e)}>
        {roles && (
          <Row spacingV="2xl">
            <Column span={4}>
              <Select label="Role" initialKey={role} data={roles} onChange={(value) => setRole(value)} />
              <InformationBox variant="info" title="What can each role do?">
                <ul>
                  <li>
                    Owner: Can update all projects and organisation details. Owners can invite admin & staff members &
                    edit all organisation users. There must always be one owner.
                  </li>
                  <br />
                  <li>
                    Admin: Can update all projects and organisation details. Admin can invite staff members & edit staff
                    users.
                  </li>
                  <br />
                  <li>
                    Staff: Can update all projects but cannot update organisation details. Cannot invite other staff
                    members
                  </li>
                </ul>
              </InformationBox>
            </Column>
          </Row>
        )}
        <Row spacingV="4xl">
          <Column span={4}>
            <RadioButtonGroup
              data={radioValues}
              currentKey={active}
              onChange={(e) => {
                setActive(e.target.value);
              }}
            />
          </Column>
        </Row>
        <Row>
          <Column span={2}>
            <ActionButtons onCancel={handleCancel} />
          </Column>
        </Row>
      </form>
    </>
  );
};
