import React, { useContext, useState } from "react";
import { HeaderRendererProps } from "react-data-grid";

import { ArrowLeftDarkIcon } from "../../../../assets/icons";
import { ResultData } from "../../../../service/Shared";
import { FilterContext } from "../context";
import { ColumnDefinition, FilterOperator } from "../models/DataGrid.types";

export type onFilterChangedEvent = (columnKey: string, operator: FilterOperator, value: string) => void;

export const HeaderRenderer = <R, SR>({
  columnDefinition,
  onFilterChanged,
  dataGridFilterable,
  dataGridSortable,
}: {
  columnDefinition: ColumnDefinition;
  dataGridFilterable: boolean;
  dataGridSortable: boolean;
  onFilterChanged: onFilterChangedEvent;
}): ((props2: HeaderRendererProps<ResultData, void>) => React.ReactElement) => {
  const isColumnFilterable =
    (dataGridFilterable === null ? true : dataGridFilterable) &&
    (columnDefinition.filterable === null || columnDefinition.filterable === undefined
      ? true
      : columnDefinition.filterable);
  const isColumnSortable =
    (dataGridSortable === null ? true : dataGridSortable) &&
    (columnDefinition.sortable === null || columnDefinition.sortable === undefined ? true : columnDefinition.sortable);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return ({ column, sortDirection, onSort }: HeaderRendererProps<R, SR>) => {
    const filters = useContext(FilterContext);
    if (filters === undefined) throw new Error("FilterContext not set; check parent DataGrid component.");
    const [filterValue, setFilterValue] = useState(
      filters.find((f) => f.key === columnDefinition.key)?.value?.toString() || ""
    );
    const handleInput = (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (["ArrowLeft", "ArrowRight"].includes(event.key)) {
        event.stopPropagation();
      }
      if (event.key === "Enter") {
        onFilterChanged(column.key, columnDefinition.dataType === "string" ? "contains" : "eq", filterValue);
      }
    };

    const currentColumnSortDirection = sortDirection?.toLowerCase();

    const handleKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>): void => {
      if (isColumnSortable && (event.key === " " || event.key === "Enter")) {
        // stop propagation to prevent scrolling
        event.preventDefault();
        onSort(event.ctrlKey || event.metaKey);
      }
    };

    const handleClick = (event: React.MouseEvent<HTMLSpanElement>): void => {
      if (isColumnSortable) onSort(event.ctrlKey || event.metaKey);
    };

    return (
      <>
        <div
          role="button"
          tabIndex={0}
          className={`DataGridHeaderColumnName ${columnDefinition.alignment || ""} ${
            isColumnSortable ? "DataGridHeaderColumnName_sortable" : ""
          }`}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
        >
          {isColumnSortable && sortDirection ? (
            <ArrowLeftDarkIcon
              className={`DataGridHeaderSortIcon DataGridHeaderSortIcon_${currentColumnSortDirection}`}
            />
          ) : (
            ""
          )}
          <span>{column.name}</span>
        </div>
        {isColumnFilterable && (
          <input
            tabIndex={0}
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
            onKeyDown={handleInput}
            className="DataGridHeaderFilter"
          />
        )}
      </>
    );
  };
};
