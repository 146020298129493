import { useCallback, useEffect, useState } from "react";

import { SelectData } from "../../../../../models";
import { lookupRoles } from "../../../../../service/query";
import { Status } from "../../../../../service/Shared";

interface UseSendInvitationReturnData {
  roles: SelectData | undefined;
}

export const useSendInvitation = (): UseSendInvitationReturnData => {
  const [roles, setRoles] = useState<SelectData | undefined>(undefined);

  const fetchRoles = useCallback(async () => {
    const res = await lookupRoles();
    if (res.status === Status.Success) {
      setRoles(
        res.data?.map((role) => {
          return {
            key: role.key,
            value: role.valueString,
          };
        })
      );
    }
  }, []);

  useEffect(() => {
    fetchRoles().then((r) => r);
  }, [fetchRoles]);

  return { roles };
};
