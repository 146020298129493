import React, { ForwardedRef, RefObject, useId, useImperativeHandle, useRef, useState } from "react";

import { Toast } from "../../../general";

interface useSingleFileUploadReturnData {
  id: string;
  selectedFile: File | undefined;
  inputRef: RefObject<HTMLInputElement>;
  onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface useSingleFileUploadProps {
  onChange: (e: File) => void;
  forwardedRef: ForwardedRef<unknown>;
  maxFileSize: number;
}

export const useSingleFileUpload = ({
  onChange,
  forwardedRef,
  maxFileSize,
}: useSingleFileUploadProps): useSingleFileUploadReturnData => {
  const id = useId();
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const inputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(forwardedRef, () => ({
    clearInput() {
      setSelectedFile(undefined);
      if (inputRef.current) {
        inputRef.current.value = "";
      }
    },
  }));

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { files } = event.target;
    if (files && !!files.length) {
      const fileSize = Number((files[0].size / 1024 / 1024).toFixed(4)); // MB
      if (fileSize < maxFileSize) {
        setSelectedFile(files[0]);
        onChange(files[0]);
      } else {
        Toast.error({
          message: `Please select a file which is less than ${maxFileSize}MB`,
        });
      }
    }
  };

  return {
    id,
    selectedFile,
    inputRef,
    onFileChange,
  };
};
