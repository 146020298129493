import "./PublicCodeComparisonTemplate.css";

import { useNavigate } from "react-router-dom";

import { CheckCircleLightIcon } from "../../assets";
import { formatNumber } from "../../utils";
import { getPublicCodeDetailsRoute, getPublicProjectTypesRoute } from "../../utils/routes";
import { Column, Divider, InformationBox, KanaTooltip, Pill, Row, TextLink } from "../../widget";
import { CodeComparisonConstants } from "../constants";

export const PublicCodeComparisonTemplate = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <div className="PublicCodeComparisonTemplate">
      <Row spacingV="m">
        <Column span={12}>
          <h1>Codes</h1>
        </Column>
      </Row>
      <Row spacingV="ll">
        <Column span={12}>
          <p className="body1">
            Codes provide the framework for measuring and certifying the volume of carbon emission reductions of
            schemes. Schemes typically have to meet certain benchmarks, standards, and protocols outlined in detailed
            policies by the codes.
          </p>
          <p className="body1">
            There are a number of codes currently running or being set up in the UK, some of which are established while
            the rest are emerging.
          </p>
        </Column>
      </Row>
      <Row spacingV="2xl">
        <Column span={12}>
          <div className="CodesTableWrapper">
            <table>
              <tbody>
                <tr>
                  <th>
                    <h4>Established</h4>
                  </th>
                  <td>
                    <button
                      className="BtnLink"
                      onClick={() =>
                        navigate(getPublicCodeDetailsRoute(CodeComparisonConstants.WOODLAND_CARBON_CODE_UUID))
                      }
                    >
                      Woodland Carbon Code
                    </button>
                  </td>
                  <td>
                    <button
                      className="BtnLink"
                      onClick={() => navigate(getPublicCodeDetailsRoute(CodeComparisonConstants.PEATLAND_CODE_UUID))}
                    >
                      Peatland Code
                    </button>
                  </td>
                  <td />
                  <td />
                  <td />
                </tr>
                <tr>
                  <th>
                    <h4>Emerging</h4>
                  </th>
                  <td>
                    <button
                      className="BtnLink"
                      onClick={() =>
                        navigate(getPublicCodeDetailsRoute(CodeComparisonConstants.UK_CARBON_CODE_OF_CONDUCT_CODE_UUID))
                      }
                    >
                      UK Carbon Code of Conduct
                    </button>
                  </td>
                  <td>
                    <button
                      className="BtnLink"
                      onClick={() => navigate(getPublicCodeDetailsRoute(CodeComparisonConstants.WILDER_CARBON_UUID))}
                    >
                      Wilder Carbon
                    </button>
                  </td>
                  <td>
                    <button
                      className="BtnLink"
                      onClick={() =>
                        navigate(getPublicCodeDetailsRoute(CodeComparisonConstants.HEDGEROW_CARBON_CODE_UUID))
                      }
                    >
                      Hedgerow Carbon Code
                    </button>
                  </td>
                  <td>
                    <button
                      className="BtnLink"
                      onClick={() =>
                        navigate(getPublicCodeDetailsRoute(CodeComparisonConstants.ENHANCED_WEATHERING_CODE_UUID))
                      }
                    >
                      Enhanced Weathering Code
                    </button>
                  </td>
                  <td />
                </tr>
                <tr>
                  <th>
                    <h4>Coming soon</h4>
                  </th>
                  <td>Biochar Code</td>
                  <td>Saltmarsh Code</td>
                  <td>Seagrass Code</td>
                  <td>Livestock Feed Code</td>
                  <td>Bio Energy Code</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Column>
      </Row>
      <Row spacingV="2xl">
        <Column span={12}>
          <Divider type="horizontal" />
        </Column>
      </Row>
      <Row spacingV="m">
        <Column span={12}>
          <h1>Codes comparison</h1>
        </Column>
      </Row>
      <Row spacingV="4xl">
        <Column span={12}>
          <p className="body1">
            The matrix belows outlines the codes that list carbon projects on Kana, as well as their comparable stances
            across the Core Carbon Principles (CCPs).
          </p>
          <div className="TooltipContainer">
            <KanaTooltip
              linkText="What are the CCPs?"
              fill={false}
              tooltipHeader="What are the core carbon principles?"
              tooltipText="The core carbon principles (CCPs) align with the Integrity Council for Voluntary Carbon Markets (ICVCM) 
            principles for identifying high-quality carbon units. The CCPs form the basis of the Integrity Council's Assessment 
            Framework, which elaborates criteria for evaluating whether carbon credits and carbon-crediting programs reach a 
            high-quality threshold. Once established, Kana intends to leverage ICVCM's assessment of each Code and publish their 
            findings. Until this assessment framework has been implemented, Kana has assimilated 18 high-level comparison points 
            to represent the Code's published positions against the 10 CCPs."
            />
          </div>
          <p className="body1">
            As codes develop and begin supervising projects, they are asked to demonstrate positions against all 10 of
            the Principles in order to represent projects on Kana. Without 10 published positions, across 18 data
            points, the code is designated as &apos;Emerging&apos;. Emerging codes can still list projects and sell
            units through Kana, but the level of support and protection provided by the code may be different to that
            offered by an &apos;Established&apos; code. As a result, how a buyer can use these units may be different
            between the codes.
          </p>
        </Column>
      </Row>
      <section>
        <Row spacingV="s">
          <Column span={12}>
            <h2>Core highlights</h2>
          </Column>
        </Row>
        <Row spacingV="ml">
          <Column span={12}>
            <p className="body1">
              These are the key facts about the code regarding its scale and the types of project they cover
            </p>
          </Column>
        </Row>
        <Row spacingV="4xl">
          <Column span={12}>
            <div className="CodesComparisonTableWrapper WithoutStrongHeaders">
              <table>
                <tbody>
                  <tr>
                    <th>{}</th>
                    <td>
                      <button
                        className="BtnLink"
                        onClick={() =>
                          navigate(getPublicCodeDetailsRoute(CodeComparisonConstants.WOODLAND_CARBON_CODE_UUID))
                        }
                      >
                        Woodland Carbon Code
                      </button>
                    </td>
                    <td>
                      <button
                        className="BtnLink"
                        onClick={() => navigate(getPublicCodeDetailsRoute(CodeComparisonConstants.PEATLAND_CODE_UUID))}
                      >
                        Peatland Code
                      </button>
                    </td>
                    <td>
                      <p className="body2 DisabledText">Emerging codes</p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <p className="body4">Predicted tonnes of CO2</p>
                    </th>
                    <td>
                      <p className="body3">{formatNumber(20607878)}</p>
                    </td>
                    <td>
                      <p className="body3">{formatNumber(3847861)}</p>
                    </td>
                    <td>
                      <p className="body3 DisabledText">Coming soon</p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <p className="body4"># Active projects</p>
                    </th>
                    <td>
                      <p className="body3">{formatNumber(1686)}</p>
                    </td>
                    <td>
                      <p className="body3">{formatNumber(135)}</p>
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <th>
                      <p className="body4">Area covered (ha)</p>
                    </th>
                    <td>
                      <p className="body3">{formatNumber(64564)}</p>
                    </td>
                    <td>
                      <p className="body3">{formatNumber(17943)}</p>
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <th>
                      <p className="body4">Category</p>
                    </th>
                    <td>
                      <Pill label="Green" variant="green" />
                    </td>
                    <td>
                      <Pill label="Brown" variant="coral" />
                    </td>
                    <td />
                  </tr>
                </tbody>
              </table>
            </div>
          </Column>
        </Row>
      </section>
      <section>
        <Row spacingV="s">
          <Column span={12}>
            <h2>Safe investment</h2>
          </Column>
        </Row>
        <Row spacingV="ml">
          <Column span={12}>
            <p className="body1">
              These principles and underlying characteristics help to help to safeguard your investment
            </p>
          </Column>
        </Row>
        <Row spacingV="4xl">
          <Column span={12}>
            <div className="CodesComparisonTableWrapper">
              <table>
                <tbody>
                  <tr>
                    <th>{}</th>
                    <td>
                      <button
                        className="BtnLink"
                        onClick={() =>
                          navigate(getPublicCodeDetailsRoute(CodeComparisonConstants.WOODLAND_CARBON_CODE_UUID))
                        }
                      >
                        Woodland Carbon Code
                      </button>
                    </td>
                    <td>
                      <button
                        className="BtnLink"
                        onClick={() => navigate(getPublicCodeDetailsRoute(CodeComparisonConstants.PEATLAND_CODE_UUID))}
                      >
                        Peatland Code
                      </button>
                    </td>
                    <td>
                      <p className="body2 DisabledText">Emerging codes</p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <h4>Programme governance</h4>
                    </th>
                    <td />
                    <td />
                    <td>
                      <p className="body3 DisabledText">Coming soon</p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <p className="body4">Usable units</p>
                    </th>
                    <td>
                      <span className="CheckIcon">
                        <CheckCircleLightIcon />
                      </span>
                    </td>
                    <td>
                      <span className="CheckIcon">
                        <CheckCircleLightIcon />
                      </span>
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <th>
                      <p className="body4">
                        Effective corporate governance with transparent, independent decision-making
                      </p>
                    </th>
                    <td>
                      <p className="body3">
                        Internal executive board with external advisory panels and/or other forums
                      </p>
                    </td>
                    <td>
                      <p className="body3">
                        Internal executive board with external advisory panels and/or other forums
                      </p>
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <th>
                      <p className="body4">Access to Independent grievance resolution mechanism</p>
                    </th>
                    <td>
                      <p className="body3">Independent mechanism to investigate and resolve disputes</p>
                    </td>
                    <td>
                      <p className="body3">Independent mechanism to investigate and resolve disputes</p>
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <th>
                      <h4>No double counting</h4>
                    </th>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <th>
                      <p className="body4">
                        Checks on other programs to verify mitigation activity is unique and each carbon unit has been
                        issued once and not already been used.
                      </p>
                    </th>
                    <td>
                      <p className="body3">Independent and internal verification</p>
                    </td>
                    <td>
                      <p className="body3">Independent and internal verification</p>
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <th>
                      <h4>Registry</h4>
                    </th>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <th>
                      <p className="body4">
                        Use of an external registry to identify, issue and track unique carbon units
                      </p>
                    </th>
                    <td>
                      <p className="body3">Externally managed - IHS Markit Registry</p>
                    </td>
                    <td>
                      <p className="body3">Externally managed - IHS Markit Registry</p>
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <th>
                      <h4>Mitigation activity information</h4>
                    </th>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <th>
                      <p className="body4">Public availability of program and mitigation activity documentation</p>
                    </th>
                    <td>
                      <p className="body3">
                        Full documentation, including registration, validation, verification and loss reporting,
                        publicly available.
                      </p>
                    </td>
                    <td>
                      <p className="body3">
                        Full documentation, including registration, validation, verification and loss reporting,
                        publicly available.
                      </p>
                    </td>
                    <td />
                  </tr>
                </tbody>
              </table>
            </div>
          </Column>
        </Row>
      </section>
      <section>
        <Row spacingV="s">
          <Column span={12}>
            <h2>Low risk of greenwashing</h2>
          </Column>
        </Row>
        <Row spacingV="ml">
          <Column span={12}>
            <p className="body1">
              These principles and underlying characteristics help to ensure that projects use sound science and methods
              to predict carbon reduction
            </p>
          </Column>
        </Row>
        <Row spacingV="4xl">
          <Column span={12}>
            <div className="CodesComparisonTableWrapper">
              <table>
                <tbody>
                  <tr>
                    <th>{}</th>
                    <td>
                      <button
                        className="BtnLink"
                        onClick={() =>
                          navigate(getPublicCodeDetailsRoute(CodeComparisonConstants.WOODLAND_CARBON_CODE_UUID))
                        }
                      >
                        Woodland Carbon Code
                      </button>
                    </td>
                    <td>
                      <button
                        className="BtnLink"
                        onClick={() => navigate(getPublicCodeDetailsRoute(CodeComparisonConstants.PEATLAND_CODE_UUID))}
                      >
                        Peatland Code
                      </button>
                    </td>
                    <td>
                      <p className="body2 DisabledText">Emerging codes</p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <h4>Additionality</h4>
                    </th>
                    <td />
                    <td />
                    <td>
                      <p className="body3 DisabledText">Coming soon</p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <p className="body4">Tests to evidence additionality</p>
                    </th>
                    <td>
                      <p className="body3">Legal and Investment tests</p>
                    </td>
                    <td>
                      <p className="body3">Legal, Investment and Barrier tests</p>
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <th>
                      <h4>Robust Quantification</h4>
                    </th>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <th>
                      <p className="body4">Determination of baseline</p>
                    </th>
                    <td>
                      <p className="body3">Estimated baseline</p>
                    </td>
                    <td>
                      <p className="body3">Estimated baseline</p>
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <th>
                      <p className="body4">Quantification of emissions and leakage</p>
                    </th>
                    <td>
                      <p className="body3">On-site surveys, with predictions accounting for leakage</p>
                    </td>
                    <td>
                      <p className="body3">On-site surveys, with predictions accounting for leakage</p>
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <th>
                      <h4>Permanance</h4>
                    </th>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <th>
                      <p className="body4">Minimum commitment</p>
                    </th>
                    <td>
                      <p className="body3">25+ commitment</p>
                    </td>
                    <td>
                      <p className="body3">25+ commitment</p>
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <th>
                      <p className="body4">Buffer requirement (over project duration)</p>
                    </th>
                    <td>
                      <p className="body3">20%</p>
                    </td>
                    <td>
                      <p className="body3">20%</p>
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <th>
                      <p className="body4">Reversal rules (against VCUs)</p>
                    </th>
                    <td>
                      <p className="body3">Reversal rules in place for avoidable losses against VCUs</p>
                    </td>
                    <td>
                      <p className="body3">Reversal rules in place for avoidable losses against PIUs</p>
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <th>
                      <h4>Robust Independent validation and verification</h4>
                    </th>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <th>
                      <p className="body4">Independence of validation and verification</p>
                    </th>
                    <td>
                      <p className="body3">Independently verified, 5-10 year assessment cycle</p>
                    </td>
                    <td>
                      <p className="body3">Independently verified, 10 year assessment cycle</p>
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <th>
                      <p className="body4">Accreditation of 3rd party verifiers</p>
                    </th>
                    <td>
                      <p className="body3">UKAS accredited suppliers</p>
                    </td>
                    <td>
                      <p className="body3">Not accredited</p>
                    </td>
                    <td />
                  </tr>
                </tbody>
              </table>
            </div>
          </Column>
        </Row>
      </section>
      <section>
        <Row spacingV="s">
          <Column span={12}>
            <h2>More than a tonne of carbon</h2>
          </Column>
        </Row>
        <Row spacingV="ml">
          <Column span={12}>
            <p className="body1">
              These principles and underlying characteristics outline the additional benefits that the project creates
            </p>
          </Column>
        </Row>
        <Row spacingV="2xl">
          <Column span={12}>
            <div className="CodesComparisonTableWrapper">
              <table>
                <tbody>
                  <tr>
                    <th>{}</th>
                    <td>
                      <button
                        className="BtnLink"
                        onClick={() =>
                          navigate(getPublicCodeDetailsRoute(CodeComparisonConstants.WOODLAND_CARBON_CODE_UUID))
                        }
                      >
                        Woodland Carbon Code
                      </button>
                    </td>
                    <td>
                      <button
                        className="BtnLink"
                        onClick={() => navigate(getPublicCodeDetailsRoute(CodeComparisonConstants.PEATLAND_CODE_UUID))}
                      >
                        Peatland Code
                      </button>
                    </td>
                    <td>
                      <p className="body2 DisabledText">Emerging codes</p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <h4>Sustainable development impacts and safeguards</h4>
                    </th>
                    <td />
                    <td />
                    <td>
                      <p className="body3 DisabledText">Coming soon</p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <p className="body4">Identification of wider, social, environmental impact</p>
                    </th>
                    <td>
                      <p className="body3">Yes</p>
                    </td>
                    <td>
                      <p className="body3">Yes</p>
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <th>
                      <p className="body4">Consideration for wider co-benefits</p>
                    </th>
                    <td>
                      <p className="body3">Yes</p>
                    </td>
                    <td>
                      <p className="body3">Yes</p>
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <th>
                      <h4>Transition towards net-zero emissions</h4>
                    </th>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <th>
                      <p className="body4">Buyer integrity</p>
                    </th>
                    <td>
                      <p className="body3">Yes</p>
                    </td>
                    <td>
                      <p className="body3">No</p>
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <th>
                      <p className="body4">Practices and procedures independently reviewed</p>
                    </th>
                    <td>
                      <p className="body3">External panel</p>
                    </td>
                    <td>
                      <p className="body3">External panel</p>
                    </td>
                    <td />
                  </tr>
                </tbody>
              </table>
            </div>
          </Column>
        </Row>
      </section>
      <Row spacingV="4xl">
        <Column span={12}>
          <InformationBox
            variant="info"
            title="Kana does not evaluate the quality of each Code's published
          positions represented on the platform, nor does it offer financial
          advice to buyers on which projects to invest in."
          />
        </Column>
      </Row>
      <Row spacingV="ml">
        <Column span={12}>
          <h2>How we categorise codes</h2>
        </Column>
      </Row>
      <Row spacingV="4xl">
        <Column span={3}>
          <div className="CodeCategory CodeCategoryBrown">
            <p className="body1">Brown</p>
            <div>
              <p className="body1">Codes which manage projects focused below the ground, e.g., peatland or soil</p>
            </div>
          </div>
        </Column>
        <Column span={3}>
          <div className="CodeCategory CodeCategoryGreen">
            <p className="body1">Green</p>
            <div>
              <p className="body1">Codes which manage projects focused above the ground, e.g., woodland or hedgerow</p>
            </div>
          </div>
        </Column>
        <Column span={3}>
          <div className="CodeCategory CodeCategoryBlue">
            <p className="body1">Blue</p>
            <div>
              <p className="body1">
                Codes which manage projects focused on the coast or bodies of water, e.g., saltmarsh or floodplain
              </p>
            </div>
          </div>
        </Column>
        <Column span={3}>
          <div className="CodeCategory CodeCategoryGrey">
            <p className="body1">Grey</p>
            <div>
              <p className="body1">
                Codes which manage projects focused on the manufacture of products designed to capture carbon; e.g.,
                biochar or enhanced weathering
              </p>
            </div>
          </div>
        </Column>
      </Row>
      <Row spacingV="2xl">
        <Column span={12}>
          <p className="body1">
            You can learn more about the different project types that fall under each of these categories{" "}
            <TextLink onAction={() => navigate(getPublicProjectTypesRoute())}>here</TextLink>
          </p>
        </Column>
      </Row>
    </div>
  );
};
