/* global google */

import { useLoadScript } from "@react-google-maps/api";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";

import { logError } from "../../../service/error";
import { MapConstants } from "./constants";

type CoordinateType = { lat: number; lng: number };

interface UseMapReturnData {
  coordinatesList: number[][];
  isLoaded: boolean;
  loadError: Error | undefined;
  center: CoordinateType;
  setMap: Dispatch<SetStateAction<google.maps.Map | null>>;
}

export function useMap(coordinates: string[], fullWidth: boolean | undefined): UseMapReturnData {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [coordinatesList, setCoordinatesList] = useState<number[][]>([]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: MapConstants.API_KEY,
    language: MapConstants.LANGUAGE,
    region: MapConstants.REGION,
  });

  const ComputeMarkerAreaCenter = (tempCoordinatesList: number[][]): CoordinateType => {
    const latValues = tempCoordinatesList.map((coordinate) => coordinate[0]);
    const lngValues = tempCoordinatesList.map((coordinate) => coordinate[1]);

    const minLat = Math.min(...latValues);
    const maxLat = Math.max(...latValues);
    const minLng = Math.min(...lngValues);
    const maxLng = Math.max(...lngValues);

    const centerLat = minLat + (maxLat - minLat) * 0.5;
    const centerLng = minLng + (maxLng - minLng) * 0.5;

    return { lat: centerLat, lng: centerLng };
  };

  const center = useMemo(() => {
    const tempCoordinatesList: number[][] = [];

    coordinates.forEach((coordinate) => tempCoordinatesList.push(coordinate.split(",").map(Number)));

    setCoordinatesList(tempCoordinatesList);

    return ComputeMarkerAreaCenter(tempCoordinatesList);
  }, [coordinates]);

  useEffect(() => {
    if (loadError) {
      (async () => {
        await logError({
          error: `Map could not be loaded with error: ${loadError}`,
        });
      })();
    }
  }, [loadError]);

  useEffect(() => {
    if (map) {
      const bounds = new google.maps.LatLngBounds();
      for (let i = 0; i < coordinatesList.length; i++) {
        bounds.extend({
          lat: coordinatesList[i][0],
          lng: coordinatesList[i][1],
        });
      }

      if (coordinatesList.length === 1) {
        if (fullWidth) {
          google.maps.event.addListenerOnce(map, "bounds_changed", () => {
            map.setZoom(MapConstants.DEFAULT_ZOOM_LEVEL_FULL_WIDTH);
          });
        } else {
          google.maps.event.addListenerOnce(map, "bounds_changed", () => {
            map.setZoom(MapConstants.DEFAULT_ZOOM_LEVEL);
          });
        }
      } else {
        google.maps.event.addListenerOnce(map, "bounds_changed", () => {
          map.setZoom((map.getZoom() as number) - 1);
        });
      }

      map.fitBounds(bounds);
    }
  }, [map]);

  return {
    coordinatesList,
    isLoaded,
    loadError,
    center,
    setMap,
  };
}
