export class ValidatorConstants {
  private static readonly SOIL_ASSOCIATION_UUID = "1ea72b11-65b3-49b4-a5af-c0ee33347957";

  private static readonly ORGANIC_FARMERS_AND_GROWERS_UUID = "e18e2119-f028-42ba-b474-8ddf06aee30d";

  public static readonly VALIDATOR_UUIDS = [
    ValidatorConstants.SOIL_ASSOCIATION_UUID,
    ValidatorConstants.ORGANIC_FARMERS_AND_GROWERS_UUID,
  ];
}
