import { Dispatch, FormEvent, SetStateAction, useState } from "react";

import { createForgottenPasswordRequest } from "../../../../service/identity";
import { ServiceError, Status } from "../../../../service/Shared";

interface UseForgottenPasswordFormReturnData {
  email: string;
  errors: ServiceError[] | undefined;
  setEmail: Dispatch<SetStateAction<string>>;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
}

export function useForgottenPasswordForm(onSuccess: (e: boolean) => void): UseForgottenPasswordFormReturnData {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState<ServiceError[] | undefined>([]);

  async function handleSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault();
    setErrors([]);
    const res = await createForgottenPasswordRequest({ email });

    if (res.status === Status.Success) {
      onSuccess(true);
    } else {
      setErrors(res.errors);
    }
  }

  return {
    email,
    errors,
    setEmail,
    handleSubmit,
  };
}
