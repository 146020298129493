import { useEffect, useId, useState } from "react";

interface UseToggleReturnData {
  toggleId: string;
  checked: boolean;
  handleToggleClick: () => void;
}

export function useToggle(defaultValue: boolean, onChangeCallback: (value: boolean) => void): UseToggleReturnData {
  const toggleId = useId();
  const [checked, setChecked] = useState<boolean>(defaultValue);

  useEffect(() => {
    setChecked(defaultValue);
  }, [defaultValue]);

  function handleToggleClick(): void {
    const newCheckedValue = !checked;
    setChecked(newCheckedValue);
    onChangeCallback(newCheckedValue);
  }

  return {
    toggleId,
    checked,
    handleToggleClick,
  };
}
