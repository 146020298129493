import "./LeftNavigation.css";

import { Link } from "react-router-dom";

import {
  // ChartsLightIcon,
  DashboardLightIcon,
  DocumentsLightIcon,
  KanaLightLogo,
  SettingsLightIcon,
} from "../../../assets";
import { NavigationActiveElement } from "../../../models";
import { NavigationTooltip } from "./components";

type UserType = "Developer";

interface LeftNavigationProps {
  userType?: UserType;
  activeElement?: NavigationActiveElement;
}

const navigationActions = {
  Developer: [
    {
      icon: <DashboardLightIcon />,
      route: "/d/dashboard",
      tooltipText: "Dashboard",
    },
    {
      icon: <DocumentsLightIcon />,
      route: "/d/projects",
      tooltipText: "Projects",
    },
    {
      icon: <SettingsLightIcon />,
      route: "/d/settings",
      tooltipText: "Settings",
    },
    // {
    //   icon: <ChartsLightIcon />,
    //   route: "/d/marketing/marketing-stats",
    //   tooltipText: "Stats",
    // },
  ],
};

export const LeftNavigation = ({ userType = "Developer", activeElement }: LeftNavigationProps): JSX.Element => {
  return (
    <nav className={`Navigation ${userType}Navigation`}>
      <KanaLightLogo className="NavigationLogo" />
      {navigationActions[userType].map((action) => (
        <Link
          key={action.tooltipText}
          to={action.route}
          className={`NavigationElement${action.tooltipText === activeElement ? " NavigationElement_active" : ""}`}
        >
          <NavigationTooltip text={action.tooltipText}>{action.icon}</NavigationTooltip>
        </Link>
      ))}
    </nav>
  );
};
