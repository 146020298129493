import React, { SetStateAction, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { logon } from "../../../../service/identity";
import { ServiceError, Status } from "../../../../service/Shared";
import { getErrorMessageFromCode } from "../../../../service/ValidationErrorFormatter";
import { useAuth } from "../../../../useAuth";
import { getDashboardRoute } from "../../../../utils/routes";

interface UseLogonFormReturnData {
  origin: string | null;
  email: string;
  password: string;
  errors: ServiceError[] | undefined;
  setEmail: React.Dispatch<SetStateAction<string>>;
  setPassword: React.Dispatch<SetStateAction<string>>;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
}

export function useLogonForm(): UseLogonFormReturnData {
  const navigate = useNavigate();
  const { setCurrentUser } = useAuth();
  const [searchParams] = useSearchParams();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState<ServiceError[] | undefined>([]);

  const errorCodeNoLinkedOrganisations = "VALIDATION_LOGON_NO_ACTIVE_LINKED_ORGANISATIONS";

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault();
    setErrors([]);

    const res = await logon({ username: email, password });
    if (res.status === Status.Success && res.data) {
      window.ACCESS_TOKEN = res.data?.accessToken;
      const currentUserResult = await setCurrentUser(res.data?.userUuid);
      if (!currentUserResult?.organisations || currentUserResult.organisations.length === 0)
        setErrors([
          {
            code: errorCodeNoLinkedOrganisations,
            message: getErrorMessageFromCode(errorCodeNoLinkedOrganisations),
            property: "username",
          },
        ]);
      else navigate(getDashboardRoute(), { replace: true });
    } else {
      setErrors(res.errors);
    }
  }

  return {
    origin: searchParams.get("origin"),
    email,
    password,
    errors,
    setEmail,
    setPassword,
    handleSubmit,
  };
}
