import "./Avatar.css";

import { Link } from "react-router-dom";

import { useAvatar } from "./useAvatar";

export interface AvatarProps {
  userUuid: string;
  name: string;
  imageSource?: string;
  route?: string;
  onClick?: () => void;
  withName?: boolean;
}

export const Avatar = ({ userUuid, name, imageSource, route, onClick, withName = false }: AvatarProps): JSX.Element => {
  const { nameInitial, avatarBackgroundColor, avatarTextColor } = useAvatar(userUuid, name);

  return (
    <div className="Avatar">
      {withName && <span className="body1">{name}</span>}
      <Link to={route ?? "#"} onClick={onClick} className={onClick ? "AvatarAnchor" : undefined}>
        {imageSource ? (
          <img className="AvatarImage" src={imageSource} alt="AvatarImage" />
        ) : (
          <span
            className="AvatarInitial"
            style={{
              backgroundColor: avatarBackgroundColor,
              color: avatarTextColor,
            }}
          >
            {nameInitial}
          </span>
        )}
      </Link>
    </div>
  );
};
