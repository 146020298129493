import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { FileType, PublicProjectOverviewPreviewParams } from "../../../models";
import { getProjectListingPreview } from "../../../service/project";
import { getProjectDetails, GetProjectDetailsResponse } from "../../../service/publicquery";
import { Status } from "../../../service/Shared";
import { usePublicContext } from "../usePublicContext";

interface useProjectPreviewReturnData {
  isLoading: boolean;
  projectDetails: GetProjectDetailsResponse | undefined;
  developerIcon: string | undefined;
  images: string[] | undefined;
}

export const useProjectPreview = (): useProjectPreviewReturnData => {
  const { uuid, previewUuid } = useParams<PublicProjectOverviewPreviewParams>();
  const { projectDetails, setProjectDetails } = usePublicContext();
  const [developerIcon, setDeveloperIcon] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = useCallback(async () => {
    if (uuid) {
      let previewResponse;

      if (previewUuid) {
        previewResponse = await getProjectListingPreview({
          previewUuid,
        });
      }

      const projectDetailsResponse = await getProjectDetails({
        projectUuid: uuid,
      });

      if (projectDetailsResponse.status === Status.Success && projectDetailsResponse.data) {
        let projData = projectDetailsResponse.data;
        if (previewResponse && previewResponse.status === Status.Success && previewResponse.data) {
          projData = {
            ...projectDetailsResponse?.data,
            listing: {
              ...projectDetailsResponse.data.listing,
              content: previewResponse.data.content,
            },
            listingFiles: previewResponse.data.listingFiles,
          };
        }
        setProjectDetails(projData);
        setDeveloperIcon(
          projectDetailsResponse.data?.developer.files.find((f) => f.type === FileType.SmallLogo)?.file.url
        );
      }
    }
  }, [previewUuid, uuid]);

  useEffect(() => {
    fetchData().then(() => setIsLoading(false));
  }, [fetchData, previewUuid, uuid]);

  const images = projectDetails?.listing?.content?.images?.flatMap((imgUuid: string) => {
    const listingFile = projectDetails?.listingFiles?.find((lf) => lf.uuid === imgUuid);
    if (listingFile) {
      return listingFile.file.url;
    }
    return [];
  });

  return {
    isLoading,
    images,
    developerIcon,
    projectDetails,
  };
};
