import "./InformationBox.css";

import { ReactNode } from "react";

import { AlertIcon, InformationIcon, SuccessIcon } from "../../../assets";
import { capitalize } from "../../../utils";

type InformationBoxVariant = "success" | "alert" | "info";

export interface InformationBoxProps {
  variant: InformationBoxVariant;
  title?: string;
  children?: ReactNode;
}

const getIcon = (variant: InformationBoxVariant): JSX.Element => {
  switch (variant) {
    case "success":
      return <SuccessIcon />;
    case "alert":
      return <AlertIcon />;
    case "info":
      return <InformationIcon />;
    default:
      return <InformationIcon />;
  }
};

export const InformationBox = ({ variant, title, children }: InformationBoxProps): JSX.Element => {
  return (
    <div className={`InformationBox InformationBox${capitalize(variant)}`}>
      <div className={`InformationBoxHeaderArea ${children ? "" : "InformationBoxNoChildren"}`}>
        <div>{getIcon(variant)}</div>
        <h3>{title}</h3>
      </div>
      {children && <div className="body2 InformationBoxTextArea">{children}</div>}
    </div>
  );
};
