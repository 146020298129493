import { useEffect, useState } from "react";

interface UseBasicReturnData {
  totalArea: string;
  totalPredictedCarbonSequestration: string;
  chartLabel: string;
  chartData: { [key: string]: number };
  isPcc: boolean;
}

const chartLabelsMapping: { [key: string]: string } = {
  mixedPredominantlyConifer50to80: "Mixed predominantly conifer (50-80%)",
  conifer80: "Conifer (>80%)",
  broadleaved80: "Broadleaved (>80%)",
  mixedPredominantlyBroadleaved50to80: "Mixed predominantly broadleaved",
  activelyErodingBlanketBogHagGully: "Actively Eroding Blanket Bog (hagg/gully)",
  activelyErodingBlanketBogFlatBare: "Actively Eroding Blanket Bog (flat/bare)",
  drainedBlanketBogArtificial: "Drained Blanket Bog (artificial)",
  drainedBlanketBogHaggGully: "Drained Blanket Bog (hagg/gully)",
  modifiedBlanketBog: "Modified Blanket Bog",
  nearNaturalBlanketBog: "Near Natural Blanket Bog",
  activelyErodingRaisedBogHaggGully: "Actively Eroding Raised Bog (hagg/gully)",
  activelyErodingRaisedBogFlatBare: "Actively Eroding Raised Bog (flat/bare)",
  drainedRaisedBogArtificial: "Drained Raised Bog (artificial)",
  drainedRaisedBogHaggGully: "Drained Raised Bog (hagg/gully)",
  modifiedRaisedBog: "Modified Raised Bog",
  nearNaturalRaisedBog: "Near Natural Raised Bog",
};

export type BasicProjectDetails = {
  uuid: string;
  areaNetHa: number | null;
  standard: {
    displayName: string;
    uuid: string;
    files: {
      file: {
        url: string;
      };
      type: string;
    }[];
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  standardSpecificData: any | null;
  validator: {
    displayName: string;
    uuid: string;
    files: {
      file: {
        url: string;
      };
      type: string;
    }[];
  };
};

export const useBasic = (projectDetails: BasicProjectDetails | undefined): UseBasicReturnData => {
  const isPcc = projectDetails?.standard.displayName === "Peatland Code";
  const hasPlanting = !isPcc;
  const hasPeatland = isPcc;
  const [totalArea, setTotalArea] = useState("");
  const [totalPredictedCarbonSequestration, setTotalPredictedCarbonSequestration] = useState("");
  const [chartLabel, setChartLabel] = useState("");
  const [chartData, setChartData] = useState<{ [key: string]: number }>({});

  useEffect(() => {
    if (!projectDetails) return;

    setTotalArea(projectDetails.areaNetHa !== null ? `${projectDetails.areaNetHa}ha` : "");
    let tempCarbon =
      projectDetails?.standardSpecificData?.totalPredictedCarbonSequestration !== null &&
      projectDetails?.standardSpecificData?.totalPredictedCarbonSequestration !== undefined
        ? projectDetails?.standardSpecificData?.totalPredictedCarbonSequestration
        : null;
    if (isPcc)
      tempCarbon =
        projectDetails?.standardSpecificData?.totalPredictedCarbonReduction !== null &&
        projectDetails?.standardSpecificData?.totalPredictedCarbonReduction !== undefined
          ? projectDetails?.standardSpecificData?.totalPredictedCarbonReduction
          : null;
    setTotalPredictedCarbonSequestration(tempCarbon !== null ? `${tempCarbon.toLocaleString()} tCO2e` : "");

    let resultChartLabel = "(hectares)";
    let chartSourceObject: { [key: string]: number } | undefined;

    if (hasPlanting) {
      resultChartLabel = `Planting ${resultChartLabel}`;
      chartSourceObject = projectDetails.standardSpecificData?.planting;
    } else if (hasPeatland) {
      resultChartLabel = `Peatland ${resultChartLabel}`;
      chartSourceObject = projectDetails.standardSpecificData?.peatland;
    }

    if (chartSourceObject) {
      const resultChartData = Object.keys(chartSourceObject).reduce((value, key) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, no-param-reassign
        value[chartLabelsMapping[key]] = chartSourceObject![key];

        return value;
      }, <{ [key: string]: number }>{});
      setChartData(resultChartData);
    }

    setChartLabel(resultChartLabel);
  }, [projectDetails]);

  return {
    totalArea,
    totalPredictedCarbonSequestration,
    chartLabel,
    chartData,
    isPcc,
  };
};
