import "./Button.css";

import { ReactNode } from "react";

type ButtonVariant = "primary" | "reversedPrimary" | "secondary" | "tertiary";
type ButtonSize = "small" | "medium";
type ButtonType = "button" | "submit";
type IconPosition = "left" | "right";

export interface ButtonProps {
  /**
   * The 'type' attribute on the HTML &lt;input&gt; element used to render the button.
   */
  type?: ButtonType;
  /**
   * The button variant
   */
  variant?: ButtonVariant;
  size?: ButtonSize;
  disabled?: boolean;
  /**
   * Should the button take the full width of the container
   */
  fullWidth?: boolean;
  icon?: ReactNode;
  /**
   * The icon position, by default it is set to right
   */
  iconPosition?: IconPosition;
  onClick?: () => void;
  text: string;
}

export const Button = ({
  type = "button",
  variant = "primary",
  size = "medium",
  disabled = false,
  fullWidth = false,
  icon,
  iconPosition = "right",
  onClick,
  text,
}: ButtonProps): JSX.Element => {
  return (
    <button
      className={`Button Button_${variant} Button_${size} Button_content_${size} 
      ${fullWidth ? "Button_fullWidth" : ""} ${icon ? `Button_${iconPosition}Icon` : ""}`}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {icon}
      <span>{text}</span>
    </button>
  );
};
