import "./ChartsSection.css";

import { ChartData, Column, PieChart, Row } from "../../../widget";

interface ChartsSectionProps {
  title: string;
  content?: string | null;
  projectsData: ChartData;
  unitsData: ChartData;
  locationsData: ChartData;
}

export const ChartsSection = ({
  title,
  content,
  projectsData,
  unitsData,
  locationsData,
}: ChartsSectionProps): JSX.Element => {
  return (
    <div className="ChartsSection">
      <Row spacingV="ll">
        <Column span={12}>
          <h2>{title}</h2>
        </Column>
      </Row>
      {content && (
        <Row spacingV="ll">
          <Column span={12}>
            <p className="body1">{content}</p>
          </Column>
        </Row>
      )}
      <Row justify="between">
        <Column span={3}>
          <div>
            <h3>Projects</h3>
            <PieChart size={120} data={projectsData} />
          </div>
        </Column>
        <Column span={3}>
          <div>
            <h3>Units</h3>
            <PieChart size={120} data={unitsData} noDataMessage="No units issued" />
          </div>
        </Column>
        <Column span={3}>
          <div>
            <h3>Locations</h3>
            <PieChart size={120} data={locationsData} />
          </div>
        </Column>
      </Row>
    </div>
  );
};
