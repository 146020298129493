import "./DeveloperTemplate.css";

import React from "react";

import { NavigationActiveElement } from "../../models";
import { LeftNavigation } from "../../widget";

export interface DeveloperTemplateProps {
  children: React.ReactNode;
  activeElement?: NavigationActiveElement;
}

export const DeveloperTemplate: React.FC<DeveloperTemplateProps> = ({ activeElement, children }): JSX.Element => {
  return (
    <div className="DeveloperScreen">
      <div className="DeveloperNavigation">
        <LeftNavigation activeElement={activeElement} />
      </div>
      <div className="DeveloperContainer">{children}</div>
    </div>
  );
};
