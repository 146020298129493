import { useNavigate, useParams } from "react-router-dom";

import { PublicProjectOverviewPreviewParams, TabsList } from "../../models";
import { GetProjectDetailsResponse } from "../../service/publicquery";
import { getProjectMarketingSettingsRoute } from "../../utils/routes";
import { PreviewBanner } from "../../widget";
import { PublicPreviewTemplate } from "../PublicTemplate";
import { PublicProjectTemplateComponent } from "./components";

interface PublicProjectTemplateProps {
  tabsList: TabsList;
  project: GetProjectDetailsResponse | undefined;
  developerIcon: string | undefined;
  images: string[] | undefined;
}

export const PublicProjectPreviewTemplate = ({
  tabsList,
  project,
  developerIcon,
  images,
}: PublicProjectTemplateProps): JSX.Element => {
  const navigate = useNavigate();
  const { uuid, previewUuid } = useParams<PublicProjectOverviewPreviewParams>();
  return (
    <>
      <PreviewBanner
        description="You are currently viewing a preview version of the project overview page. Any changes you have made within the marketing settings screen will not be published until you close the preview and save your changes"
        onClose={() => {
          if (uuid && previewUuid) {
            const previewQueryParam = new URLSearchParams({
              previewUuid,
            });
            navigate(getProjectMarketingSettingsRoute(uuid, previewQueryParam.toString()));
          }
        }}
      />
      <PublicPreviewTemplate>
        <PublicProjectTemplateComponent
          project={project}
          tabsList={tabsList}
          developerIcon={developerIcon}
          images={images}
          searchParams={undefined}
          otherDeveloperProjects={undefined}
          isPreview
        />
      </PublicPreviewTemplate>
    </>
  );
};
