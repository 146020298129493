import "./MarketingSettingsForm.css";

import { GetProjectDetailsResponse } from "../../../../../../service/query";
import {
  ActionButtons,
  Button,
  // CodeSnippetTextArea,
  Column,
  ErrorBox,
  // Divider,
  InformationBox,
  KanaTooltip,
  MultipleFileUpload,
  RadioButtonGroup,
  Row,
  TextArea,
  TextInput,
  UnsavedChangesModal,
} from "../../../../../../widget";
import { RadioChangeEvent } from "../../../../../../widget/forms/Input/RadioButtonGroup/components";
import { MarketingSettingsData } from "../models";
import { useMarketingSettingsForm } from "./useMarketingSettingsForm";

interface MarketingSettingsFormProps {
  defaultValues: MarketingSettingsData;
  projectDetails: GetProjectDetailsResponse;
}

export const MarketingSettingsForm = ({ defaultValues, projectDetails }: MarketingSettingsFormProps): JSX.Element => {
  const {
    radioButtonGroupData,
    hideProjectChecked,
    setHideProjectChecked,

    shortDescription,
    longDescription,
    videoUrl,
    images,
    // embedCode,
    hasUnsavedChanges,
    setShortDescription,
    setLongDescription,
    setVideoUrl,
    setImages,
    handleSubmit,
    handlePreview,
    onCancel,
    imageUploadRef,
    isPreviewMode,

    listUnitsChecked,
    setListUnitsChecked,
    unitPriceChecked,
    setUnitPriceChecked,
    disablePublicInterestChecked,
    setDisablePublicInterestChecked,
    redirectPublicInterestChecked,
    onRedirectPublicInterestChange,
    redirectPublicInterestEmail,
    setRedirectPublicInterestEmail,

    errors,
  } = useMarketingSettingsForm(defaultValues, projectDetails);
  return (
    <div className="MarketingSettingsFormContainer">
      {errors && errors.length > 0 && (
        <Row spacingV="ml">
          <Column span={5}>
            <ErrorBox title="Please solve the below issues before saving" text={errors.map((error) => error.message)} />
          </Column>
        </Row>
      )}
      <form onSubmit={(e) => handleSubmit(e)}>
        <Row>
          <Column span={5}>
            <Row spacingV="ll">
              <Column span={12}>
                <RadioButtonGroup
                  label="Hide the project from the public directory?"
                  tooltip={
                    <KanaTooltip
                      tooltipHeader="What does hiding your project do?"
                      tooltipText="Selecting 'yes' will hide this project in the public directory project list page and it will not be displayed in search results. However, the project will still be visible to the public if they have its unique URL, which allows you to share the project details with interested parties. The project will also still be visible in your external registry."
                    />
                  }
                  data={radioButtonGroupData}
                  currentKey={hideProjectChecked}
                  onChange={(e: RadioChangeEvent) => setHideProjectChecked(e.target.value)}
                />
              </Column>
            </Row>
            <Row spacingV="ll">
              <Column span={12}>
                <TextInput
                  label="Short Description"
                  name="shortDescription"
                  tooltip={
                    <KanaTooltip
                      tooltipHeader="What is the short description used for?"
                      tooltipText="The short description will be visible in the public project search page and should offer a concise overview of the project."
                    />
                  }
                  value={shortDescription || ""}
                  onChange={(e) => setShortDescription(e)}
                  displayCharacterCount
                  maxLength={100}
                />
              </Column>
            </Row>
            <Row spacingV="ll">
              <Column span={12}>
                <TextArea
                  label="Long Description"
                  name="longDescription"
                  tooltip={
                    <KanaTooltip
                      tooltipHeader="What is the long description used for?"
                      tooltipText="The long description will be visible in the project overview page."
                    />
                  }
                  value={longDescription || ""}
                  onChange={(e) => setLongDescription(e.currentTarget.value)}
                  displayCharacterCount
                  maxLength={4000}
                />
              </Column>
            </Row>
            <Row spacingV="ll">
              <Column span={12}>
                <MultipleFileUpload
                  accept="image/jpeg"
                  title="Images"
                  inputFiles={images}
                  ref={imageUploadRef}
                  subTitle="Upload up to 10 high resolution images of your project. Once uploaded, you can drag the images to re-order them. The first image in the order will be used as the main project photo."
                  onFilesChange={(files) => setImages([...files])}
                />
              </Column>
            </Row>
            <Row spacingV="ll">
              <Column span={12}>
                <TextInput
                  label="YouTube video"
                  name="videoUrl"
                  placeholder="http://www.youtube.com/watch?v="
                  value={videoUrl}
                  onChange={(value) => setVideoUrl(value)}
                />
              </Column>
            </Row>
            {(projectDetails?.cachedPiuQuantity > 0 || projectDetails?.cachedVcuQuantity > 0) && (
              <>
                <Row spacingV="ll">
                  <Column span={12}>
                    <RadioButtonGroup
                      label="List units as available for sale"
                      data={radioButtonGroupData}
                      currentKey={listUnitsChecked}
                      onChange={(e: RadioChangeEvent) => setListUnitsChecked(e.target.value)}
                    />
                  </Column>
                </Row>
                <Row spacingV="ll">
                  <Column span={12}>
                    <RadioButtonGroup
                      label="Show unit price in the public Directory"
                      tooltip={
                        <KanaTooltip
                          tooltipHeader="What does showing a unit price in the public directory do?"
                          tooltipText="This allows you to show and hide the price of your units in the public project search page and on the project page's issuances tab. To set unit prices, navigate to the 'Issuances & pricing' tab and enter the prices in the issuance table."
                        />
                      }
                      data={radioButtonGroupData}
                      currentKey={unitPriceChecked}
                      onChange={(e: RadioChangeEvent) => setUnitPriceChecked(e.target.value)}
                    />
                  </Column>
                </Row>
              </>
            )}
            <Row spacingV="ll">
              <Column span={12}>
                <RadioButtonGroup
                  label="Disable public interest emails"
                  data={radioButtonGroupData}
                  currentKey={disablePublicInterestChecked}
                  onChange={(e: RadioChangeEvent) => setDisablePublicInterestChecked(e.target.value)}
                />
              </Column>
            </Row>
            <Row spacingV="ll">
              <Column span={12}>
                <RadioButtonGroup
                  label="Re-direct public interest emails?"
                  data={radioButtonGroupData}
                  currentKey={redirectPublicInterestChecked}
                  onChange={onRedirectPublicInterestChange}
                />
              </Column>
            </Row>
            {redirectPublicInterestChecked && (
              <Row spacingV="2xl">
                <Column span={12}>
                  <TextInput
                    type="email"
                    label="Alternative contact email address"
                    name="alternative email"
                    value={redirectPublicInterestEmail}
                    onChange={(value) => setRedirectPublicInterestEmail(value)}
                  />
                </Column>
              </Row>
            )}
            {/* <Row spacingV="3xl"> this is the row spacing for when the embedding area will be used */}
            <Row>
              <Column span={12}>
                <div className="ActionButtons">
                  <Button text="Preview" onClick={handlePreview} />
                  <ActionButtons onCancel={onCancel} />
                </div>
              </Column>
            </Row>
          </Column>
          <Column span={4} offset={1}>
            <InformationBox variant="info" title="What are marketing settings?">
              <p className="body2">
                Marketing Settings will determine what is displayed in the public project directory. Here you can
                control settings and add project descriptions and imagery.
              </p>
            </InformationBox>
          </Column>
        </Row>
        {/* <Row spacingV="xl">
          <Column span={12}>
            <Divider type="horizontal" />
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={12}>
            <h2>Embed your project</h2>
          </Column>
        </Row>
        <Row>
          <Column span={5}>
            <CodeSnippetTextArea name="embedCode" value={embedCode} />
          </Column>
        </Row> */}
      </form>
      {/* Temporarily disabling UnsavedChangesModal ENG-664 - by adding false check */}
      <UnsavedChangesModal hasUnsavedChanges={false && !isPreviewMode && hasUnsavedChanges} />
    </div>
  );
};
