import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getProjectDocuments, GetProjectDocumentsResponse } from "../../../../service/publicquery";
import { Status } from "../../../../service/Shared";

interface useDocumentsReturnData {
  documents: GetProjectDocumentsResponse[] | undefined;
  isLoading: boolean;
}

export function useDocuments(): useDocumentsReturnData {
  const { uuid } = useParams();
  const [documents, setDocuments] = useState<GetProjectDocumentsResponse[]>();
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = useCallback(async () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const response = await getProjectDocuments({ projectUuid: uuid! });
    if (response.status === Status.Success) {
      setDocuments(response.data);
    }
  }, []);

  useEffect(() => {
    fetchData().then(() => setIsLoading(false));
  }, []);

  return {
    documents,
    isLoading,
  };
}
