import { Dispatch, ForwardedRef, forwardRef, SetStateAction } from "react";

import { ActionButtons, Column, Row, Toggle } from "../../../../../../widget";
import { FormGetInTouchData, MarketingAssetsFormChangedAction, MarketingAssetsSubmitHandler } from "../../models";
import { useGetInTouchForm } from "./useGetInTouchForm";

interface GetInTouchProps {
  getInTouchDefaultValues: FormGetInTouchData;
  getInTouchOldDefaultValues: FormGetInTouchData;
  listingRowVersion: number;
  setListingRowVersion: Dispatch<SetStateAction<number>>;
  dispatch: Dispatch<MarketingAssetsFormChangedAction>;
  getCurrentFormsData: (formWhichWasSaved: string) => string;
}

export const GetInTouchForm = forwardRef(
  (
    {
      getInTouchDefaultValues,
      getInTouchOldDefaultValues,
      listingRowVersion,
      setListingRowVersion,
      dispatch,
      getCurrentFormsData,
    }: GetInTouchProps,
    forwardedRef: ForwardedRef<MarketingAssetsSubmitHandler>
  ): JSX.Element => {
    const { enabled, setEnabled, handleSubmit, handleCancel } = useGetInTouchForm(
      getInTouchDefaultValues,
      getInTouchOldDefaultValues,
      listingRowVersion,
      setListingRowVersion,
      dispatch,
      forwardedRef,
      getCurrentFormsData
    );

    return (
      <form onSubmit={(e) => handleSubmit(e)}>
        <Row spacingV="ll">
          <Column span={4}>
            <h2>Get in touch</h2>
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={12}>
            <Toggle label="Show this section on organisation profile" defaultValue={enabled} onChange={setEnabled} />
          </Column>
        </Row>
        <Row spacingV="2xl">
          <Column span={4}>
            <ActionButtons onCancel={handleCancel} />
          </Column>
        </Row>
      </form>
    );
  }
);
