import "./ConfirmationDialog.css";

import { Button } from "../../forms";
import { Column, Row } from "../../layout";
import { Modal } from "../Modal";

interface ConfirmationDialogProps {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const ConfirmationDialog = ({ show, onClose, onConfirm }: ConfirmationDialogProps): JSX.Element => {
  return (
    <div className="ConfirmationDialog">
      <Modal show={show} title="Leave unsaved changes?" onClose={onClose}>
        <Row justify="center" spacingV="2xl">
          <Column span={10}>
            <span className="body1">
              If you leave this page before pressing &lsquo;Save&rsquo;, the information you have entered will be lost.
              Are you sure you want to leave the page?
            </span>
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <div className="ConfirmationDialogActionButtons">
              <Button
                text="Yes"
                onClick={() => {
                  onClose();
                  onConfirm();
                }}
              />
              <Button text="No, stay on page" variant="secondary" onClick={onClose} />
            </div>
          </Column>
        </Row>
      </Modal>
    </div>
  );
};
