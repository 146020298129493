import { DeveloperErrorTemplate } from "../../../templates/DeveloperErrorTemplate/DeveloperErrorTemplate";

export const DeveloperError500 = (): JSX.Element => {
  return (
    <DeveloperErrorTemplate
      errorText="Something went wrong. The server encountered an error and could not complete your request. Refresh the page or try again later."
      errorCode="500"
    />
  );
};
