import { GetProjectDetailsResponse } from "../../../../../../service/publicquery";
import { ChartData, Column, KPIGroup, Row, StackedBarChart, Tag } from "../../../../../../widget";
import { AboutTheCodeSection } from "../AboutTheCodeSection";

interface OverviewComponentProps {
  projectDetails: GetProjectDetailsResponse | undefined;
  chartData: ChartData | undefined;
  tags: string[] | null;
  isPreview?: boolean;
}
export const OverviewComponent = ({
  projectDetails,
  chartData,
  tags,
  isPreview = false,
}: OverviewComponentProps): JSX.Element => {
  return (
    <>
      <div className="PublicProjectTabSection">
        <Row spacingV="ml">
          <Column span={12}>
            <h2>About the project</h2>
          </Column>
        </Row>
        <Row spacingV={tags ? "ll" : undefined}>
          <Column span={12}>
            {projectDetails?.listing?.content?.longDescription ? (
              <p className="body1" style={{ whiteSpace: "pre-line" }}>
                {projectDetails?.listing.content.longDescription}
              </p>
            ) : (
              <p className="ReadonlyNoDataAvailable body1" style={{ whiteSpace: "pre-line" }}>
                {projectDetails?.developer?.displayName || "The developer"} has not yet provided a description for this
                project
              </p>
            )}
          </Column>
        </Row>
        {tags && (
          <Row>
            <Column span={12}>
              {projectDetails?.tags.map((el) => (
                <Tag key={el} label={el} />
              ))}
            </Column>
          </Row>
        )}
      </div>
      {projectDetails?.status && (
        <Row spacingV="ml">
          <Column span={12}>
            <KPIGroup
              projectStatus={projectDetails.status}
              totalSequestration={projectDetails.standardSpecificData?.totalPredictedCarbonSequestration}
              totalReduction={projectDetails.standardSpecificData?.totalPredictedCarbonReduction}
              vcus={projectDetails.cachedVcuQuantity}
              pius={projectDetails.cachedPiuQuantity}
              isPcc={projectDetails.standard.displayName === "Peatland Code"}
            />
          </Column>
        </Row>
      )}
      {projectDetails && chartData && Object.keys(chartData).length > 0 && (
        <div className="PublicProjectTabSection">
          <Row spacingV="xl">
            <Column span={12}>
              <h2>Carbon offset profile</h2>
            </Column>
          </Row>
          <Row>
            <Column span={12}>
              <StackedBarChart
                data={chartData}
                mainLabel="Verified carbon units (VCUs)"
                secondaryChart={{
                  label: "Pending issuance units (PIUs)",
                  data: Object.keys(chartData).map(
                    (label) => projectDetails.cachedVcuQuantity + projectDetails.cachedPiuQuantity - chartData[label]
                  ),
                }}
              />
            </Column>
          </Row>
        </div>
      )}
      {projectDetails && (
        <div className="PublicProjectTabSection">
          <AboutTheCodeSection
            organisationUuid={projectDetails.standard.uuid}
            projectUuid={projectDetails.uuid}
            isPreview={isPreview}
          />
        </div>
      )}
    </>
  );
};
