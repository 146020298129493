import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { PublicProjectTypeParams, RichContentSectionData } from "../../../models";
import { getProjectType } from "../../../service/publicquery";
import { Status } from "../../../service/Shared";

interface UseProjectTypeReturnData {
  dataIsLoading: boolean;
  projectTypeData: ProjectTypeData | undefined;
}

// TODO move these interfaces to another place as it is used in both /route and /templates, more details in the refactor hotlist
export interface ProjectTypeRatings {
  carbonImpact: string | null | undefined;
  nationalPotential: string | null | undefined;
  speedOfImpact: string | null | undefined;
}

export interface ProjectTypeData {
  category: string | null | undefined;
  hero: {
    title: string | null | undefined;
    imageUrl: string | null | undefined;
  };
  aboutTheType: {
    title: string | null | undefined;
    content: string | null | undefined;
  };
  typeRatings: ProjectTypeRatings;
  typesOfProjects: RichContentSectionData;
}

export function useProjectType(): UseProjectTypeReturnData {
  const { projectTypeIdentifier } = useParams<PublicProjectTypeParams>();
  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [projectTypeData, setProjectTypeData] = useState<ProjectTypeData | undefined>(undefined);

  const fetchData = useCallback(async (): Promise<void> => {
    if (projectTypeIdentifier) {
      const res = await getProjectType({
        projectTypeIdentifier,
      });

      if (res.status === Status.Success && res.data) {
        const mappedProjectTypeData: ProjectTypeData = {
          category: res.data.category,
          hero: {
            title: res.data.hero?.title,
            imageUrl: res.data.hero?.imageUrl,
          },
          aboutTheType: {
            title: res.data.aboutTheType?.title,
            content: res.data.aboutTheType?.content,
          },
          typeRatings: {
            carbonImpact: res.data.typeRatings?.carbonImpact,
            nationalPotential: res.data.typeRatings?.nationalPotential,
            speedOfImpact: res.data.typeRatings?.speedOfImpact,
          },
          typesOfProjects: {
            enabled: res.data.typesOfProject?.enabled,
            title: res.data.typesOfProject?.title,
            part1: res.data.typesOfProject?.part1
              ? {
                  content: res.data.typesOfProject?.part1?.content,
                  title: res.data.typesOfProject?.part1?.title,
                  imageUrl: res.data.typesOfProject?.part1?.imageUrl,
                }
              : null,
            part2: res.data.typesOfProject?.part2
              ? {
                  content: res.data.typesOfProject?.part2?.content,
                  title: res.data.typesOfProject?.part2?.title,
                  imageUrl: res.data.typesOfProject?.part2?.imageUrl,
                }
              : null,
            part3: res.data.typesOfProject?.part3
              ? {
                  content: res.data.typesOfProject?.part3?.content,
                  title: res.data.typesOfProject?.part3?.title,
                  imageUrl: res.data.typesOfProject?.part3?.imageUrl,
                }
              : null,
            part4: res.data.typesOfProject?.part4
              ? {
                  content: res.data.typesOfProject?.part4?.content,
                  title: res.data.typesOfProject?.part4?.title,
                  imageUrl: res.data.typesOfProject?.part4?.imageUrl,
                }
              : null,
            part5: res.data.typesOfProject?.part5
              ? {
                  content: res.data.typesOfProject?.part5?.content,
                  title: res.data.typesOfProject?.part5?.title,
                  imageUrl: res.data.typesOfProject?.part5?.imageUrl,
                }
              : null,
          },
        };
        setProjectTypeData(mappedProjectTypeData);
      }
    }
  }, []);

  useEffect(() => {
    fetchData().then(() => setDataIsLoading(false));
  }, [fetchData]);

  return {
    dataIsLoading,
    projectTypeData,
  };
}
