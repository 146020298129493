import { useCallback, useState } from "react";
import { SortColumn } from "react-data-grid";
import { useParams } from "react-router-dom";

import { searchProjectDocuments, SearchProjectDocumentsResponse } from "../../../../../service/query";
import { ResultData } from "../../../../../service/Shared";
import { flattenObject } from "../../../../../utils";
import {
  DataGridButtonCellFormatterData,
  DataGridColumnDefinition,
  dataGridMapFilterCriteria,
} from "../../../../../widget";
import { CursorChangeProps } from "../../../../../widget/data/DataGrid/models/DataGrid.types";

interface UseDocumentsReturnData {
  columns: DataGridColumnDefinition[];
  defaultSortingCriteria: SortColumn[];
  dataIsLoading: boolean;
  onChange: ({ filtering, paging, sorting }: CursorChangeProps) => Promise<{
    resultData: ResultData[];
    paging: {
      pageSize: number;
      totalCount: number;
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  }>;
}
interface ResultType {
  resultData: ResultData[];
  paging: {
    startCursor: string;
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    pageSize: number;
    totalCount: number;
  };
}

export const useDocuments = (): UseDocumentsReturnData => {
  const { projectUuid } = useParams<"projectUuid">();

  const [dataIsLoading, setDataIsLoading] = useState(true);

  const columns: DataGridColumnDefinition[] = [
    { key: "type", name: "Document", dataType: "string" },
    {
      key: "activityName",
      name: "Activity",
      dataType: "string",
      formatter: "align",
      alignment: "center",
    },
    { key: "visibility", name: "Visibility", dataType: "string" },
    {
      key: "cachedCreatedByUser.calculatedFullName",
      name: "User",
      dataType: "string",
      filterable: false,
      sortable: false,
    },
    {
      key: "cachedCreatedAt",
      name: "Date",
      dataType: "Date",
      formatter: "dateOnly",
      alignment: "center",
      filterable: false,
    },
    {
      key: "downloadLink",
      name: "Download",
      dataType: "string",
      formatter: "button",
      filterable: false,
      sortable: false,
      alignment: "center",
    },
  ];

  const defaultSortingCriteria: SortColumn[] = [{ columnKey: "type", direction: "ASC" }];

  const formatData = useCallback((responseData: SearchProjectDocumentsResponse | undefined): ResultData[] => {
    return (
      responseData?.results?.map((d) => {
        const result = flattenObject(d);

        result.downloadLink = <DataGridButtonCellFormatterData>{
          text: "Download",
          variant: "secondary",
          onClick: () => {
            window.open(d.file.url, "_blank")?.focus();
          },
        };

        return result;
      }) || []
    );
  }, []);

  const onChange = async ({ filtering, paging, sorting }: CursorChangeProps): Promise<ResultType> => {
    if (!projectUuid) throw new Error("Uuid must not be empty!");

    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize: 0,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };

    await searchProjectDocuments({
      projectUuid,
      paging: {
        limit: paging.pageSize,
        beforeCursor: paging.beforeCursor,
        afterCursor: paging.afterCursor,
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      sort: sorting.map((s: { key: any; direction: any }) => ({
        key: s.key as any,
        direction: s.direction,
      })),
      filter: { results: dataGridMapFilterCriteria(filtering) },
    })
      .then((response) => {
        data = {
          resultData: formatData(response.data),
          paging: {
            startCursor: response.data?.paging?.startCursor || "",
            endCursor: response.data?.paging?.endCursor || "",
            pageSize: paging.pageSize || 10,
            totalCount: response.data?.paging?.total || 0,
            hasNextPage: response.data?.paging?.hasNextPage || false,
            hasPreviousPage: response.data?.paging?.hasPreviousPage || false,
          },
        };
      })
      .finally(() => {
        setDataIsLoading(false);
      });

    return data;
  };

  return {
    columns,
    defaultSortingCriteria,
    dataIsLoading,
    onChange,
  };
};
