import { ActionButtons, Column, Divider, Row } from "../../../../../widget";
import { Basic, Dates, Location, Monitoring } from "../../../../../widget/data/ProjectDetails";
import { useDetails } from "./useDetails";

const Details = (): JSX.Element => {
  const {
    projectDetails,
    implementationDate,
    startDate,
    locationGridReference,
    addressCountryCode,
    firstAssessmentDate,
    secondAssessmentDate,
    frequency,
    readOnly,
    isPcc,
    setImplementationDate,
    setStartDate,
    setLocationGridReference,
    setFirstAssessmentDate,
    setSecondAssessmentDate,
    setFrequency,
    onSubmit,
    onCancel,
  } = useDetails();

  return (
    <form onSubmit={onSubmit}>
      <Row spacingV="2xl">
        <Column span={12}>
          <Basic projectDetails={projectDetails} />
        </Column>
      </Row>
      <Row spacingV="2xl">
        <Column span={12}>
          <Divider type="horizontal" />
        </Column>
      </Row>
      <Row spacingV="2xl">
        <Column span={12}>
          <Location
            gridReference={locationGridReference}
            addressCountryCode={addressCountryCode}
            onGridReferenceChange={setLocationGridReference}
            readOnly={readOnly}
          />
        </Column>
      </Row>
      <Row spacingV="2xl">
        <Column span={12}>
          <Divider type="horizontal" />
        </Column>
      </Row>
      <Row spacingV="2xl">
        <Column span={12}>
          <Dates
            implementationDate={implementationDate}
            startDate={startDate}
            duration={projectDetails?.standardSpecificData.duration}
            onImplementationDateChange={setImplementationDate}
            onStartDateChange={setStartDate}
            readOnly={readOnly}
            isPcc={isPcc}
          />
        </Column>
      </Row>
      <Row spacingV="2xl">
        <Column span={12}>
          <Divider type="horizontal" />
        </Column>
      </Row>
      <Row spacingV={!readOnly ? "2xl" : undefined}>
        <Column span={12}>
          <Monitoring
            firstAssessmentDate={firstAssessmentDate}
            secondAssessmentDate={secondAssessmentDate}
            frequency={frequency}
            onFirstAssessmentDateChange={setFirstAssessmentDate}
            onSecondAssessmentDateChange={setSecondAssessmentDate}
            onFrequencyChange={setFrequency}
            readOnly={readOnly}
          />
        </Column>
      </Row>
      {!readOnly && (
        <Row>
          <Column span={12}>
            <ActionButtons onCancel={onCancel} />
          </Column>
        </Row>
      )}
    </form>
  );
};

export default Details;

// Old code for Details.tsx
// import { ActionButtons, Column, Divider, Row } from "../../../../../widget";
// import {
//   Basic,
//   Dates,
//   Location,
//   Monitoring,
// } from "../../../../../widget/data/ProjectDetails";
// import { useDetails } from "./useDetails";

// const Details = (): JSX.Element => {
//   const {
//     projectDetails,
//     implementationDate,
//     startDate,
//     locationGridReference,
//     addressCountryCode,
//     firstAssessmentDate,
//     secondAssessmentDate,
//     frequency,
//     readOnly,
//     showLocationSection,
//     showDatesSection,
//     showMonitoringSection,
//     setImplementationDate,
//     setStartDate,
//     setLocationGridReference,
//     setFirstAssessmentDate,
//     setSecondAssessmentDate,
//     setFrequency,
//     onSubmit,
//     onCancel,
//   } = useDetails();

//   return (
//     <form onSubmit={onSubmit}>
//       <Row spacingV="2xl">
//         <Column span={12}>
//           <Basic projectDetails={projectDetails} />
//         </Column>
//       </Row>
//       {showLocationSection && (
//         <>
//           <Row spacingV="2xl">
//             <Column span={12}>
//               <Divider type="horizontal" />
//             </Column>
//           </Row>
//           <Row spacingV="2xl">
//             <Column span={12}>
//               <Location
//                 gridReference={locationGridReference}
//                 addressCountryCode={addressCountryCode}
//                 onGridReferenceChange={setLocationGridReference}
//                 readOnly={readOnly}
//               />
//             </Column>
//           </Row>
//         </>
//       )}
//       {showDatesSection && (
//         <>
//           <Row spacingV="2xl">
//             <Column span={12}>
//               <Divider type="horizontal" />
//             </Column>
//           </Row>
//           <Row spacingV="2xl">
//             <Column span={12}>
//               <Dates
//                 implementationDate={implementationDate}
//                 startDate={startDate}
//                 duration={projectDetails?.standardSpecificData.duration}
//                 onImplementationDateChange={setImplementationDate}
//                 onStartDateChange={setStartDate}
//                 readOnly={readOnly}
//               />
//             </Column>
//           </Row>
//         </>
//       )}
//       {showMonitoringSection && (
//         <>
//           <Row spacingV="2xl">
//             <Column span={12}>
//               <Divider type="horizontal" />
//             </Column>
//           </Row>
//           <Row spacingV={!readOnly ? "2xl" : undefined}>
//             <Column span={12}>
//               <Monitoring
//                 firstAssessmentDate={firstAssessmentDate}
//                 secondAssessmentDate={secondAssessmentDate}
//                 frequency={frequency}
//                 onFirstAssessmentDateChange={setFirstAssessmentDate}
//                 onSecondAssessmentDateChange={setSecondAssessmentDate}
//                 onFrequencyChange={setFrequency}
//                 readOnly={readOnly}
//               />
//             </Column>
//           </Row>
//         </>
//       )}
//       {!readOnly && (
//         <Row>
//           <Column span={12}>
//             <ActionButtons onCancel={onCancel} />
//           </Column>
//         </Row>
//       )}
//     </form>
//   );
// };

// export default Details;
