import "../styles/DataGrid.css";

import { useEffect, useState } from "react";
import { Column as RdgColumn, SortColumn as RdgSortColumn } from "react-data-grid";

import { ResultData } from "../../../../service/Shared";
import { FilterCriteria, FilterOperator, SortCriteria } from "../models/DataGrid.types";
import { mapColumnDefinitionToRdgColumn } from "../utils";
import { CursorDataGridProps } from "./CursorDataGrid";

export interface UseDataGridReturnData {
  filterCriteria: FilterCriteria[];
  resultData: readonly ResultData[];
  rdgColumns: RdgColumn<ResultData>[];
  hasFilters: boolean;
  sortColumns: readonly RdgSortColumn[];
  onSort: (newSortColumns: RdgSortColumn[]) => void;
  onPrevious: () => void;
  onNext: () => void;
  hasPrevious: boolean;
  hasNext: boolean;
  totalCount: number;
}

export function useCursorDataGrid({
  data,
  columns,
  filterable = false,
  sortable = true,
  onChange,
  pagination = { pageSize: 10 },
  defaultSortingCriteria = [],
}: CursorDataGridProps): UseDataGridReturnData {
  const [resultData, setResultData] = useState<readonly ResultData[]>(data || []);
  const [sortColumns, setSortColumns] = useState<readonly RdgSortColumn[]>(
    sortable && defaultSortingCriteria ? defaultSortingCriteria : []
  );
  const [filterCriteria, setFilterCriteria] = useState<FilterCriteria[]>([]);
  const [dgStartCursor, setDgStartCursor] = useState<string | undefined>(pagination.startCursor);
  const [dgEndCursor, setDgEndCursor] = useState<string | undefined>(pagination.endCursor);
  const [dgBeforeCursor, setDgBeforeCursor] = useState<string>();
  const [dgAfterCursor, setDgAfterCursor] = useState<string>();
  const [hasNext, setHasNext] = useState<boolean>(true);
  const [hasPrevious, setHasPrevious] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const onPrevious = (): void => {
    setDgBeforeCursor(dgStartCursor);
    setDgAfterCursor(undefined);
  };
  const onNext = (): void => {
    setDgBeforeCursor(undefined);
    setDgAfterCursor(dgEndCursor);
  };
  const onSort = (newSortColumns: RdgSortColumn[]): void => {
    setSortColumns(newSortColumns);
    // reset paging when changing sort order
    setDgBeforeCursor(undefined);
    setDgAfterCursor(undefined);
  };
  const pageSize = pagination.pageSize || 10;
  const onFilterChanged = (columnKey: string, operator: FilterOperator, value: string): void => {
    const hasFilterValue = value !== null && value !== undefined && value !== "";
    let starterFilters = filterCriteria.filter((f) => f.key !== columnKey);
    if (hasFilterValue)
      starterFilters = starterFilters.concat({
        key: columnKey,
        operator,
        value,
      });
    setFilterCriteria(starterFilters);
    // reset paging when changing filtering
    setDgBeforeCursor(undefined);
    setDgAfterCursor(undefined);
  };
  const rdgColumns: RdgColumn<ResultData>[] = mapColumnDefinitionToRdgColumn(
    columns,
    sortable,
    filterable,
    onFilterChanged
  );
  if (onChange) {
    useEffect(() => {
      const sortCriteria = sortColumns.map(
        (sc): SortCriteria => ({
          key: sc.columnKey,
          direction: sc.direction === "DESC" ? "desc" : "asc",
        })
      );
      onChange({
        // client-side filtering
        filtering: filterCriteria,
        // client-side sorting
        sorting: sortCriteria,
        // client-side paging
        paging: {
          pageSize,
          afterCursor: dgAfterCursor,
          beforeCursor: dgBeforeCursor,
        },
      }).then((update) => {
        setResultData(update.resultData);
        if (update.paging?.startCursor) setDgStartCursor(update.paging.startCursor);
        if (update.paging?.endCursor) setDgEndCursor(update.paging.endCursor);
        setHasPrevious(update.paging.hasPreviousPage);
        setHasNext(update.paging.hasNextPage);
        setTotalCount(update.paging.totalCount);
      });
    }, [sortColumns, filterCriteria, dgBeforeCursor, dgAfterCursor]);
  }

  const hasFilters = filterable === true && columns.some((cd) => cd.filterable || true);
  return {
    resultData,
    filterCriteria,
    onPrevious,
    onNext,
    hasPrevious,
    hasNext,
    hasFilters,
    rdgColumns,
    onSort,
    sortColumns,
    totalCount,
  };
}
