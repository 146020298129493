import "./DeveloperProjectCardWithTabsTemplate.css";

import { CODE_PILL_VARIANT_MAP } from "../../constants";
import { NavigationActiveElement, TabsList } from "../../models";
import {
  Column,
  DeveloperHeader,
  ExpandableCard,
  KanaTooltip,
  LeftNavigation,
  PieChart,
  Pill,
  Row,
  Tabs,
} from "../../widget";
import { StatusCard } from "./components";

interface DeveloperProjectCardWithTabsTemplateProps {
  activeElement: NavigationActiveElement;
  pageTitle: string | undefined;
  projectCode: string | undefined;
  projectStatus: string | undefined;
  chartData: { [key: string]: number };
  tabsList: TabsList;
  backButtonText?: string;
  handleBackClick?: () => void;
}

export const DeveloperProjectCardWithTabsTemplate = ({
  activeElement,
  pageTitle,
  projectCode,
  projectStatus,
  chartData,
  tabsList,
  backButtonText,
  handleBackClick,
}: DeveloperProjectCardWithTabsTemplateProps): JSX.Element => {
  return (
    <div className="DeveloperProjectCardWithTabsTemplate">
      <div className="DeveloperProjectCardWithTabsTemplateNavigation">
        <LeftNavigation activeElement={activeElement} />
      </div>
      <div className="DeveloperProjectCardWithTabsTemplateContainer">
        <Row spacingV="ll">
          <Column span={12}>
            <DeveloperHeader backButtonText={backButtonText} handleBackClick={handleBackClick} />
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={12}>
            <div className="HeaderContent">
              <h1>{pageTitle}</h1>
              {projectCode && <Pill label={projectCode} variantMap={CODE_PILL_VARIANT_MAP} />}
            </div>
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={3}>
            <StatusCard status={projectStatus || ""} />
          </Column>
          <Column span={9}>
            <ExpandableCard
              title="Units"
              contentAlignment="center"
              tooltip={
                <KanaTooltip
                  tooltipHeader="Buffer units"
                  tooltipText="Please note that buffer units created by this project are not shown in this chart"
                />
              }
            >
              <PieChart size={120} data={chartData} noDataMessage="No units issued" />
            </ExpandableCard>
          </Column>
        </Row>
        <Tabs className="TemplateTabs" tabsList={tabsList} />
      </div>
    </div>
  );
};
