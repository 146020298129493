import { useEffect } from "react";
import { useLocation, useNavigationType } from "react-router-dom";

export const useScrollToTop = (): void => {
  const { pathname } = useLocation();
  const navigationType = useNavigationType();

  useEffect(() => {
    if (navigationType !== "POP" && window.isTab !== true) {
      document.documentElement.scrollTo({ top: 0, behavior: "auto" });
    }
    window.isTab = false;
  }, [pathname]);
};
