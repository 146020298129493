import mem from "mem";

import { getLogonRoute } from "../utils/routes";
import { axios } from "./axios";
import { RefreshTokenResponse } from "./identity";
import { BaseResponseWithSingle } from "./Shared";

const refreshTokenFn = async (): Promise<BaseResponseWithSingle<RefreshTokenResponse>> => {
  const res = await axios
    .request<BaseResponseWithSingle<RefreshTokenResponse>>({
      method: "post",
      url: "authentication/refresh-token",
      withCredentials: true,
    })
    .then((result) => result.data)
    .catch((error) => {
      window.location.href = getLogonRoute();
      return Promise.reject(error);
    });
  if (res.data?.accessToken) window.ACCESS_TOKEN = res.data?.accessToken;
  return res;
};

// The refresh token response is memoized for 15 seconds in case you land on a page with multiple unauthorized queries.
export const memoizedRefreshAccessToken = mem(refreshTokenFn, {
  maxAge: 5000,
});
