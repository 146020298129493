import "../styles/DataGrid.css";

import ReactDataGrid, { SortColumn } from "react-data-grid";

import { ResultData } from "../../../../service/Shared";
import { Column, Row } from "../../../layout";
import { CursorPagination } from "../../../wayfinding";
import { DataGridConstants } from "../constants";
import { FilterContext } from "../context";
import { ColumnDefinition, OnCursorChangeEventHandler } from "../models/DataGrid.types";
import { useComputeDataGridHeight } from "../utils";
import { useCursorDataGrid } from "./useCursorDataGrid";

export interface CursorDataGridProps {
  data: ResultData[];
  columns: ColumnDefinition[];
  // client-side filtering & sorting
  clientSide: boolean;
  // grid-level sorting enabled?
  sortable?: boolean;
  // grid-level filtering enabled?
  filterable?: boolean;
  // paging enabled?
  pageable?: boolean;
  /*
   Used for server-side sorting/filtering/paging.
   Only raised if `clientSide = false`.
   */
  onChange?: OnCursorChangeEventHandler;
  /*
    Configuration for the pagination
   */
  pagination?: {
    // mandatory for server-side
    totalCount?: number;
    pageSize?: number;
    startCursor?: string;
    endCursor?: string;
  };
  noDataMessage?: string;
  dataIsLoading?: boolean;
  defaultSortingCriteria?: SortColumn[];
}

export const CursorDataGrid = ({
  data,
  columns,
  filterable = false,
  sortable = true,
  clientSide = false,
  pageable = true,
  defaultSortingCriteria,
  onChange,
  pagination = { pageSize: 10 },
  noDataMessage,
  dataIsLoading,
}: CursorDataGridProps): JSX.Element => {
  const {
    filterCriteria,
    resultData,
    rdgColumns,
    hasFilters,
    sortColumns,
    onSort,
    onPrevious,
    onNext,
    hasNext,
    hasPrevious,
    totalCount,
  } = useCursorDataGrid({
    data,
    columns,
    filterable,
    sortable,
    clientSide,
    pageable,
    defaultSortingCriteria,
    onChange,
    pagination,
  });

  const { computedHeight } = useComputeDataGridHeight(resultData.length, DataGridConstants.rowHeight);

  return (
    <div className="DataGridContainer">
      {resultData && (!!resultData.length || (!resultData.length && !!filterCriteria.length)) ? (
        <div className="DataGrid">
          <Row spacingV={pageable ? "ml" : undefined}>
            <Column span={12}>
              <FilterContext.Provider value={filterCriteria}>
                <ReactDataGrid
                  style={{
                    height: computedHeight,
                  }}
                  rows={resultData}
                  columns={rdgColumns}
                  defaultColumnOptions={{
                    sortable,
                  }}
                  className="rdg-light"
                  rowHeight={DataGridConstants.rowHeight}
                  headerRowHeight={hasFilters ? 2 * DataGridConstants.rowHeight : undefined}
                  sortColumns={sortColumns}
                  onSortColumnsChange={onSort}
                />
              </FilterContext.Provider>
            </Column>
          </Row>
          <Row spacingV={pageable ? "ml" : undefined}>
            <div className="DataGridResultCount">
              <span className="body2">{totalCount} results</span>
            </div>
          </Row>
          <Row>
            <Column span={12}>
              {pageable && (
                <CursorPagination onPrevious={onPrevious} onNext={onNext} hasNext={hasNext} hasPrevious={hasPrevious} />
              )}
            </Column>
          </Row>
        </div>
      ) : (
        <p className="body2 NoData">{dataIsLoading ? "Loading..." : noDataMessage}</p>
      )}
    </div>
  );
};
