import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { SelectData } from "../../../../../models";
import { logError } from "../../../../../service/error";
import { searchProjects, SearchProjectsRequest, SearchProjectsResponse } from "../../../../../service/publicquery";
import { SortDirection, Status } from "../../../../../service/Shared";
import { getCountryNameByCode, useDebounce } from "../../../../../utils";
import { getPublicProjectOverviewRoute } from "../../../../../utils/routes";
import { CheckboxFilterValueType, DataFilterValueType, Filter, Toast } from "../../../../../widget";

type DisplayProject = {
  uuid: string;
  imageSource: string | null;
  hasUnits?: boolean;
  hasPriceInDirectory?: boolean;
  name: string;
  addressCountryCode: string;
  developerSmallLogoFileUrl: string | null;
  developerDisplayName?: string;
  standardDisplayName?: string;
  listingContentShortDescription: string;
  tags?: string[];
  calculatedIssuancePriceMin: number | null;
  calculatedIssuancePriceMax: number | null;
  calculatedTotalQuantityMin: number | null;
  calculatedTotalQuantityMax: number | null;
  calculatedTotalQuantity: number | null;
  cachedPiuQuantity: number;
  cachedVcuQuantity: number;
};

interface ProjectsFilter extends DataFilterValueType {
  search?: string | null;
  unitsNumber?: number[] | null;
  unitsSale?: CheckboxFilterValueType | null;
  unitPrice?: number[] | null;
  unitType?: CheckboxFilterValueType | null;
  vintage?: number[] | null;
  code?: CheckboxFilterValueType | null;
  status?: string | null;
  developer?: string | null;
  countryCode?: string | null;
  tags?: string | null;
}

interface UseProjectsSearchReturnData {
  filters: Filter[];
  currentFilter: ProjectsFilter;
  currentSort: string;
  sorting: SelectData;
  projects: DisplayProject[];
  onFiltersChange: (value: DataFilterValueType) => void;
  onSortingChange: (value: string) => void;
  onProjectClick: (project: DisplayProject) => void;
  onPrevious: () => void;
  onNext: () => void;
  hasPrevious: boolean;
  hasNext: boolean;
  totalCount: number;
}

export const useProjectsSearch = (developerUuid?: string, codeUuid?: string): UseProjectsSearchReturnData => {
  const [filters, setFilters] = useState<Filter[]>([]);
  const [aspects, setAspects] = useState<SearchProjectsResponse["aspects"]>();
  const [projects, setProjects] = useState<DisplayProject[]>([]);
  const [pageSize] = useState<number>(8);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [sorting] = useState<SelectData>([
    {
      key: "results.dataScore|desc",
      value: "Default",
    },
    {
      key: "results.displayName|asc",
      value: "Name (asc)",
    },
    {
      key: "results.displayName|desc",
      value: "Name (desc)",
    },
    {
      key: "results.developer.displayName|asc",
      value: "Developer (asc)",
    },
    {
      key: "results.developer.displayName|desc",
      value: "Developer (desc)",
    },
    {
      key: "results.addressCountryCode|asc",
      value: "Country (asc)",
    },
    {
      key: "results.addressCountryCode|desc",
      value: "Country (desc)",
    },
    {
      key: "results.standard.displayName|asc",
      value: "Code (asc)",
    },
    {
      key: "results.standard.displayName|desc",
      value: "Code (desc)",
    },
    {
      key: "results.calculatedTotalQuantityMin|asc",
      value: "Number of units (asc)",
    },
    {
      key: "results.calculatedTotalQuantityMin|desc",
      value: "Number of units (desc)",
    },
  ]);
  const [currentSort, setCurrentSort] = useState<string>(sorting[0].key as string);
  const [currentFilter, setCurrentFilter] = useState<ProjectsFilter>({});

  const navigate = useNavigate();
  const debounceValue = useDebounce(currentFilter);
  const [searchParams, setSearchParams] = useSearchParams();
  const [startCursor, setStartCursor] = useState<string>();
  const [endCursor, setEndCursor] = useState<string>();
  const [beforeCursor, setBeforeCursor] = useState<string>();
  const [afterCursor, setAfterCursor] = useState<string>();
  const [hasNext, setHasNext] = useState<boolean>(true);
  const [hasPrevious, setHasPrevious] = useState<boolean>(false);
  const onPrevious = (): void => {
    setBeforeCursor(startCursor);
    setAfterCursor(undefined);
  };
  const onNext = (): void => {
    setBeforeCursor(undefined);
    setAfterCursor(endCursor);
  };

  useEffect(() => {
    currentFilter.search = searchParams.get("search");
    currentFilter.status = searchParams.get("status");
    currentFilter.developer = searchParams.get("developer");
    currentFilter.countryCode = searchParams.get("countryCode");
    currentFilter.tags = searchParams.get("tags");

    const unitsNumber = searchParams.get("unitsNumber");

    if (unitsNumber) {
      const [calculatedTotalQuantityMin, calculatedTotalQuantityMax] = unitsNumber.split(",");
      currentFilter.unitsNumber = [parseInt(calculatedTotalQuantityMin, 10), parseInt(calculatedTotalQuantityMax, 10)];
    }

    const unitsSale = searchParams.get("unitsSale");

    if (unitsSale) {
      currentFilter.unitsSale = unitsSale.split(",").reduce<CheckboxFilterValueType>((accumulator, current) => {
        const [key, value] = current.split(":");
        accumulator[key] = value === "true";

        return accumulator;
      }, {});
    }

    const unitPrice = searchParams.get("unitPrice");

    if (unitPrice) {
      const [calculatedIssuancePriceMin, calculatedIssuancePriceMax] = unitPrice.split(",");
      currentFilter.unitPrice = [parseInt(calculatedIssuancePriceMin, 10), parseInt(calculatedIssuancePriceMax, 10)];
    }

    const unitType = searchParams.get("unitType");

    if (unitType) {
      currentFilter.unitType = unitType.split(",").reduce<CheckboxFilterValueType>((accumulator, current) => {
        const [key, value] = current.split(":");
        accumulator[key] = value === "true";

        return accumulator;
      }, {});
    }

    const vintage = searchParams.get("vintage");

    if (vintage) {
      const [calculatedVintageEndDateMin, calculatedVintageEndDateMax] = vintage.split(",");
      currentFilter.vintage = [
        parseInt(calculatedVintageEndDateMin.split("-")[0], 10),
        parseInt(calculatedVintageEndDateMax.split("-")[0], 10),
      ];
    }

    const code = searchParams.get("code");

    if (code) {
      currentFilter.code = code.split(",").reduce<CheckboxFilterValueType>((accumulator, current) => {
        const [key, value] = current.split(":");
        accumulator[key] = value === "true";

        return accumulator;
      }, {});
    }

    setCurrentFilter({ ...currentFilter });

    const sort = searchParams.get("sort");

    if (sort) setCurrentSort(sort);

    const qsBeforeCursor = searchParams.get("beforeCursor");
    const qsAfterCursor = searchParams.get("afterCursor");

    if (qsBeforeCursor) setBeforeCursor(qsBeforeCursor);
    if (qsAfterCursor) setAfterCursor(qsAfterCursor);
  }, []);

  useEffect(() => {
    if (!aspects) return;

    const code = codeUuid || searchParams.get("code");
    const projectStatus = searchParams.get("status");
    const developer = developerUuid || searchParams.get("developer");
    const country = searchParams.get("countryCode");
    const tags = searchParams.get("tags");

    const newFilters: Filter[] = [
      {
        kind: "text",
        name: "search",
        placeholder: "Search by project name",
      },
      {
        kind: "slider",
        name: "unitsNumber",
        label: "Number of units",
        min: aspects.calculatedTotalQuantityMin,
        max: aspects.calculatedTotalQuantityMax,
        range: true,
      },
      {
        kind: "checkbox",
        name: "unitsSale",
        label: "Units available for sale",
        checkboxes: [
          { label: "Yes", value: "yes" },
          { label: "No", value: "no" },
        ],
      },
      {
        kind: "checkbox",
        name: "unitType",
        label: "Unit type",
        tooltipHeader: "What are the unit types?",
        tooltipText:
          "VCUs are Verified Issuance Units which are from vintages that have been verified. PIUs are Pending Issuance Units are units from future vintages that are yet to be verified.",
        checkboxes: [
          { label: "PIU", value: "piu" },
          { label: "VCU", value: "vcu" },
        ],
      },
      {
        kind: "slider",
        name: "vintage",
        label: "Vintage",
        min: aspects.calculatedVintageEndDateMin?.getFullYear(),
        max: aspects.calculatedVintageEndDateMax?.getFullYear(),
        range: true,
        formatValue: (value) => value?.toString(),
      },
    ];

    if (aspects.calculatedIssuancePriceMin && aspects.calculatedIssuancePriceMax) {
      newFilters.splice(3, 0, {
        kind: "slider",
        name: "unitPrice",
        label: "Unit price",
        min: aspects.calculatedIssuancePriceMin,
        max: aspects.calculatedIssuancePriceMax,
        range: true,
      });
    }

    const codes = aspects.standards.reduce((accumulator, current) => {
      if (!accumulator.some((i) => i.label === current.displayName)) {
        accumulator.push({
          label: current.displayName,
          value: current.uuid,
        });
      }

      return accumulator;
    }, <{ label: string; value: string }[]>[]);

    if (!!code || codes.length > 1) {
      newFilters.push({
        kind: "checkbox",
        name: "code",
        label: "Code",
        checkboxes: aspects.standards.reduce((accumulator, current) => {
          if (!accumulator.some((i) => i.label === current.displayName)) {
            accumulator.push({
              label: current.displayName,
              value: current.uuid,
            });
          }

          return accumulator;
        }, <{ label: string; value: string }[]>[]),
      });
    }

    const statuses = aspects.statuses.map((status) => ({
      key: status,
      value: status,
    }));

    if (!!projectStatus || statuses.length > 1) {
      newFilters.push({
        kind: "select",
        name: "status",
        label: "Status",
        data: statuses,
      });
    }

    if (!developerUuid) {
      const developers = aspects.developers.reduce((accumulator, current) => {
        if (!accumulator.some((i) => i.key === current.uuid)) {
          accumulator.push({
            key: current.uuid,
            value: current.displayName,
          });
        }

        return accumulator;
      }, [] as SelectData);

      if (!!developer || developers.length > 1) {
        newFilters.push({
          kind: "autocomplete",
          name: "developer",
          label: "Developer",
          data: developers,
        });
      }
    }

    const countries = aspects.addressCountryCodes.map((countryCode) => ({
      key: countryCode,
      value: getCountryNameByCode(countryCode) || countryCode,
    }));

    if (!!country || countries.length > 1) {
      newFilters.push({
        kind: "select",
        name: "countryCode",
        label: "Country",
        data: countries,
      });
    }

    const tagFilterValues = aspects.tags.map((tag) => ({
      key: tag,
      value: tag,
    }));

    if (!!tags || tagFilterValues.length > 1) {
      newFilters.push({
        kind: "select",
        name: "tags",
        label: "Tags",
        data: aspects.tags.map((tag) => ({
          key: tag,
          value: tag,
        })),
      });
    }

    setFilters(newFilters);
  }, [aspects]);

  const loadProjects = useCallback(async () => {
    const request: SearchProjectsRequest = {
      paging: {
        limit: pageSize,
      },
    };

    const search = searchParams.get("search");
    const unitsNumber = searchParams.get("unitsNumber");
    const unitsSale = searchParams.get("unitsSale");
    const unitPrice = searchParams.get("unitPrice");
    const unitType = searchParams.get("unitType");
    const vintage = searchParams.get("vintage");
    const code = searchParams.get("code");
    const status = searchParams.get("status");
    const developer = developerUuid || searchParams.get("developer");
    const countryCode = searchParams.get("countryCode");
    const tags = searchParams.get("tags");
    const sort = searchParams.get("sort");
    // 2022-09: can be removed after 2022-10 if still not used
    // const qsBeforeCursor = searchParams.get("beforeCursor");
    // const qsAfterCursor = searchParams.get("afterCursor");

    request.filter = {};
    request.filter.results = {};

    if (search) {
      request.filter.results.displayName = {
        operator: "contains",
        value: search,
      };
    }

    if (unitsNumber) {
      const [calculatedTotalQuantityMin, calculatedTotalQuantityMax] = unitsNumber.split(",");
      request.filter.results.calculatedTotalQuantityMin = {
        operator: "gte",
        value: parseInt(calculatedTotalQuantityMin, 10),
      };
      request.filter.results.calculatedTotalQuantityMax = {
        operator: "lte",
        value: parseInt(calculatedTotalQuantityMax, 10),
      };
    }

    if (unitsSale && unitsSale.includes("false")) {
      request.filter.results.listing = {
        availableForSale: {
          operator: "eq",
          value: unitsSale.includes("yes:true"),
        },
      };
    }

    if (unitPrice) {
      const [calculatedIssuancePriceMin, calculatedIssuancePriceMax] = unitPrice.split(",");
      request.filter.results.calculatedIssuancePriceMax = {
        operator: "gte",
        value: parseInt(calculatedIssuancePriceMin, 10),
      };
      request.filter.results.calculatedIssuancePriceMin = {
        operator: "lte",
        value: parseInt(calculatedIssuancePriceMax, 10),
      };
    }

    if (unitType && unitType.includes("false")) {
      const unitTypes = unitType.split(",").reduce<string[]>((accumulator, current) => {
        const [key, value] = current.split(":");

        if (!accumulator.includes(key) && value === "true") {
          accumulator.push(key);
        }

        return accumulator;
      }, []);

      if (unitTypes.includes("piu")) {
        request.filter.results.cachedPiuQuantity = {
          operator: "gt",
          value: 0,
        };
      }

      if (unitTypes.includes("vcu")) {
        request.filter.results.cachedVcuQuantity = {
          operator: "gt",
          value: 0,
        };
      }
    }

    if (vintage) {
      const [calculatedVintageEndDateMin, calculatedVintageEndDateMax] = vintage.split(",");

      const [minYear, minMonth, minDay] = calculatedVintageEndDateMax.split("-");
      request.filter.results.calculatedVintageEndDateMin = {
        operator: "lte",
        value: new Date(parseInt(minYear, 10), parseInt(minMonth, 10), parseInt(minDay, 10)),
      };

      const [maxYear, maxMonth, maxDay] = calculatedVintageEndDateMin.split("-");
      request.filter.results.calculatedVintageEndDateMax = {
        operator: "gte",
        value: new Date(parseInt(maxYear, 10), parseInt(maxMonth, 10), parseInt(maxDay, 10)),
      };
    }

    if (code && code.includes("false")) {
      request.filter.results.standard = {
        uuid: {
          operator: "in",
          value: code
            .split(",")
            .reduce<string[]>((accumulator, current) => {
              const [key, value] = current.split(":");

              if (!accumulator.includes(key) && value === "true") {
                accumulator.push(key);
              }

              return accumulator;
            }, [])
            .join(","),
        },
      };
    }

    if (status) {
      request.filter.results.status = {
        operator: "eq",
        value: status,
      };
    }

    if (developer) {
      request.filter.results.developer = {
        uuid: {
          operator: "eq",
          value: developer,
        },
      };
    }

    if (countryCode) {
      request.filter.results.addressCountryCode = {
        operator: "eq",
        value: countryCode,
      };
    }

    if (tags) {
      request.filter.results.tags = { operator: "eq", value: tags };
    }

    const sortOrder = sort || currentSort;
    if (sortOrder) {
      const [key, direction] = sortOrder.split("|");

      request.sort = [
        {
          key: key as never,
          direction: direction as SortDirection,
        },
      ];
    }

    if (beforeCursor) {
      request.paging.beforeCursor = beforeCursor;
    } else if (afterCursor) {
      request.paging.afterCursor = afterCursor;
    }

    const response = await searchProjects(request);

    if (response.status === Status.Success && response.data) {
      if (response.data.aspects) {
        setAspects(response.data.aspects);
      }

      setTotalCount(response.data.paging.total || 0);
      setStartCursor(response.data.paging.startCursor);
      setEndCursor(response.data.paging.endCursor);
      setHasPrevious(response.data.paging.hasPreviousPage);
      setHasNext(response.data.paging.hasNextPage);
      setProjects(
        response.data.results?.map((d) => ({
          uuid: d.uuid,
          name: d.displayName,
          status: d.status,
          imageSource:
            d?.listing?.content?.images && d?.listing?.content?.images[0]
              ? d.listingFiles?.find((el) => el.uuid === d?.listing?.content?.images[0])?.file.url
              : "",
          hasUnits: !!d.listing?.availableForSale,
          hasPriceInDirectory: !!d.listing?.showPriceInDirectory,
          addressCountryCode: d.addressCountryCode || "",
          developerSmallLogoFileUrl: d.developer?.files ? d.developer.files[0]?.file.url : "",
          developerDisplayName: d.developer?.displayName || undefined,
          standardDisplayName: d.standard?.displayName || undefined,
          listingContentShortDescription: d.listing?.content.shortDescription,
          calculatedIssuancePriceMin: d.calculatedIssuancePriceMin,
          calculatedIssuancePriceMax: d.calculatedIssuancePriceMax,
          calculatedTotalQuantityMin: d.calculatedTotalQuantityMin,
          calculatedTotalQuantityMax: d.calculatedTotalQuantityMax,
          calculatedTotalQuantity: (d.cachedPiuQuantity || 0) + (d.cachedVcuQuantity || 0),
          cachedPiuQuantity: d.cachedPiuQuantity,
          cachedVcuQuantity: d.cachedVcuQuantity,
          tags: d.tags,
        })) || []
      );
    }
  }, [aspects, searchParams, beforeCursor, afterCursor]);

  useEffect(() => {
    loadProjects().catch(async (error) => {
      Toast.error({ message: "An error happend" });
      await logError({ error });
    });
  }, [searchParams, beforeCursor, afterCursor]);

  useEffect(() => {
    if (!aspects) return;

    if (currentFilter) {
      const {
        search,
        unitsNumber,
        unitsSale,
        unitPrice,
        unitType,
        vintage,
        code,
        status,
        developer,
        countryCode,
        tags,
      } = currentFilter;

      if (search) {
        searchParams.set("search", search);
      } else searchParams.delete("search");

      if (unitsNumber) {
        searchParams.set("unitsNumber", `${unitsNumber[0]},${unitsNumber[1]}`);
      } else {
        searchParams.delete("unitsNumber");
      }

      if (unitsSale) {
        const keys = Object.keys(unitsSale);

        if (keys.some((key) => unitsSale[key])) {
          searchParams.set("unitsSale", keys.map((key) => `${key}:${unitsSale[key]}`).join(","));
        } else {
          searchParams.delete("unitsSale");
        }
      } else {
        searchParams.delete("unitsSale");
      }

      if (unitPrice) {
        searchParams.set("unitPrice", `${unitPrice[0]},${unitPrice[1]}`);
      } else {
        searchParams.delete("unitPrice");
      }

      if (unitType) {
        const keys = Object.keys(unitType);

        if (keys.some((key) => unitType[key])) {
          searchParams.set("unitType", keys.map((key) => `${key}:${unitType[key]}`).join(","));
        } else {
          searchParams.delete("unitType");
        }
      } else {
        searchParams.delete("unitType");
      }

      if (vintage) {
        searchParams.set(
          "vintage",
          `${vintage[0].toString()}-${aspects.calculatedVintageEndDateMin.getMonth()}-${aspects.calculatedVintageEndDateMin.getDay()},` +
            `${vintage[1].toString()}-${aspects.calculatedVintageEndDateMax.getMonth()}-${aspects.calculatedVintageEndDateMax.getDay()}`
        );
      } else {
        searchParams.delete("vintage");
      }

      if (code) {
        const keys = Object.keys(code);

        if (keys.some((key) => code[key])) {
          searchParams.set("code", keys.map((key) => `${key}:${code[key]}`).join(","));
        } else {
          searchParams.delete("code");
        }
      } else {
        searchParams.delete("code");
      }

      if (status) {
        searchParams.set("status", status);
      } else searchParams.delete("status");

      if (developer) {
        searchParams.set("developer", developer);
      } else searchParams.delete("developer");

      if (countryCode) {
        searchParams.set("countryCode", countryCode);
      } else searchParams.delete("countryCode");

      if (tags) {
        searchParams.set("tags", tags);
      } else searchParams.delete("tags");
    }

    if (currentSort && currentSort !== sorting[0].key) {
      searchParams.set("sort", currentSort);
    } else searchParams.delete("sort");

    if (beforeCursor) searchParams.set("beforeCursor", beforeCursor);
    else searchParams.delete("beforeCursor");

    if (afterCursor) searchParams.set("afterCursor", afterCursor);
    else searchParams.delete("afterCursor");

    setSearchParams(searchParams);
  }, [debounceValue, currentSort, beforeCursor, afterCursor]);

  const onFiltersChange = (value: ProjectsFilter): void => {
    setCurrentFilter(value);
    // reset paging when changing filtering
    setBeforeCursor(undefined);
    setAfterCursor(undefined);
  };

  const onSortingChange = (value: string): void => {
    setCurrentSort(value);
    // reset paging when changing filtering
    setBeforeCursor(undefined);
    setAfterCursor(undefined);
  };

  const onProjectClick = (el: DisplayProject): void => {
    navigate({
      pathname: getPublicProjectOverviewRoute(el.uuid),
      search: searchParams.toString(),
    });
  };

  return {
    filters,
    currentFilter,
    totalCount,
    currentSort,
    sorting,
    projects,
    onFiltersChange,
    onSortingChange,
    onProjectClick,
    onPrevious,
    onNext,
    hasPrevious,
    hasNext,
  };
};
