import "./TextInput.css";

import { ReactNode } from "react";

import { FormLabel } from "../../FormLabel";
import { useTextInput } from "./useTextInput";

export type InputType = "text" | "email" | "number" | "url";

export interface TextInputProps {
  type?: InputType;
  label?: string;
  name: string;
  value?: string | null;
  placeholder?: string;
  helperText?: string;
  optional?: boolean;
  autoCompleteOff?: boolean;
  disabled?: boolean;
  icon?: ReactNode;
  onChange?: (value: string) => void;
  tooltip?: ReactNode;
  decimalPlaces?: number;
  maxLength?: number;
  displayCharacterCount?: boolean;
}

export const TextInput = ({
  type = "text",
  label,
  name,
  value,
  placeholder,
  helperText,
  optional,
  disabled,
  icon,
  autoCompleteOff,
  onChange,
  tooltip,
  decimalPlaces,
  maxLength,
  displayCharacterCount,
}: TextInputProps): JSX.Element => {
  const { currentValue, onInputChange, onInputBlur } = useTextInput(name, value, type, decimalPlaces, onChange);

  return (
    <div className={`form_field TextInput ${disabled ? `TextInput_disabled` : ""}`}>
      <FormLabel label={label} optional={optional} tooltip={tooltip}>
        <input
          type={type}
          name={name}
          id={name}
          value={currentValue}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onInputChange}
          onBlur={onInputBlur}
          autoComplete={autoCompleteOff ? "off" : "on"}
          step="any"
          maxLength={maxLength}
        />
        {displayCharacterCount && (
          <div className="body2 TextAreaCharacterCount">
            {value?.length || 0}/{maxLength}
          </div>
        )}
        {icon && <div className="TextInputIcon">{icon}</div>}
        {helperText && <span className="body2 Helper">{helperText}</span>}
      </FormLabel>
    </div>
  );
};
