import "./Overview.css";

import { Column, Map, Row, VerticalLifecycle } from "../../../../../widget";
import { useOverview } from "./useOverview";

const Overview = (): JSX.Element => {
  const { currentStatus, mapCoordinates } = useOverview();

  return (
    <>
      <Row spacingV="ll">
        <Column span={12}>
          <h2>Overview</h2>
        </Column>
      </Row>
      <Row>
        <Column span={7}>
          <Row spacingV="ml">
            <Column span={12}>
              <h3>Lifecycle</h3>
            </Column>
          </Row>
          <VerticalLifecycle currentStatus={currentStatus} />
        </Column>
        <Column span={5}>
          <Row spacingV="ml">
            <Column span={12}>
              <h3>Project location</h3>
            </Column>
          </Row>
          {mapCoordinates && mapCoordinates.length ? (
            <Map height={420} coordinates={mapCoordinates} />
          ) : (
            <p className="NoData">This project has not specified a location</p>
          )}
        </Column>
      </Row>
    </>
  );
};

export default Overview;
