// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isoDateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d{2,3})?Z$/;
const isoDateOnlyRegex = /^\d{4}-\d{2}-\d{2}$/;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function maybeJsonDateStringParser(value: any): Date | any {
  if (value && typeof value === "string")
    if (value.match(isoDateTimeRegex)) {
      return new Date(value);
    } else if (value.match(isoDateOnlyRegex)) {
      const spl = value.split("-");
      return new Date(parseInt(spl[0], 10), parseInt(spl[1], 10) - 1, parseInt(spl[2], 10));
    }
  return value;
}
