import { DeveloperPreviewData } from "../../../route/public/developer/details/preview/usePreview";
import { PublicDeveloperDetails } from "../components/PublicDeveloperDetails";
import { usePublicDeveloperDetailsPreviewTemplate } from "./usePublicDeveloperDetailsPreviewTemplate";

interface PublicDeveloperDetailsPreviewTemplateProps {
  previewData: DeveloperPreviewData;
}

export const PublicDeveloperDetailsPreviewTemplate = ({
  previewData,
}: PublicDeveloperDetailsPreviewTemplateProps): JSX.Element => {
  const { dataIsLoading, currentOrganisationUuid, organisationDetails, organisationSocialMedia, projectsCoordinates } =
    usePublicDeveloperDetailsPreviewTemplate();

  return (
    <div>
      {!dataIsLoading && organisationSocialMedia && previewData && organisationDetails && (
        <PublicDeveloperDetails
          projectsCoordinates={projectsCoordinates}
          organisationUuid={currentOrganisationUuid}
          isPreview
          developerDetailsData={{
            ...previewData,
            ...organisationDetails,
            calculatedContactable: false,
            socialMediaData: organisationSocialMedia,
          }}
        />
      )}
    </div>
  );
};
