import { Column, Divider, Row } from "../../../../widget";
import { Basic, Dates, Location, Monitoring } from "../../../../widget/data/ProjectDetails";
import { useDetails } from "./useDetails";

const Details = (): JSX.Element => {
  const {
    projectDetails,
    implementationDate,
    startDate,
    locationGridReference,
    addressCountryCode,
    firstAssessmentDate,
    secondAssessmentDate,
    frequency,
    isPcc,
  } = useDetails();

  return (
    <div className="PublicProjectTabSection">
      {projectDetails && (
        <>
          <Row spacingV="2xl">
            <Column span={12}>
              <Basic projectDetails={projectDetails} />
            </Column>
          </Row>
          <Row spacingV="2xl">
            <Column span={12}>
              <Divider type="horizontal" />
            </Column>
          </Row>
          <Row spacingV="2xl">
            <Column span={12}>
              <Location
                gridReference={locationGridReference}
                addressCountryCode={addressCountryCode}
                readOnly
                isUnderDevelopment={projectDetails?.status === "Under Development"}
              />
            </Column>
          </Row>
          <Row spacingV="2xl">
            <Column span={12}>
              <Divider type="horizontal" />
            </Column>
          </Row>
          <Row spacingV="2xl">
            <Column span={12}>
              <Dates
                implementationDate={implementationDate}
                startDate={startDate}
                duration={projectDetails?.standardSpecificData?.duration}
                readOnly
                isPcc={isPcc}
                isUnderDevelopment={projectDetails?.status === "Under Development"}
              />
            </Column>
          </Row>
          <Row spacingV="2xl">
            <Column span={12}>
              <Divider type="horizontal" />
            </Column>
          </Row>
          <Row spacingV="2xl">
            <Column span={12}>
              <Monitoring
                firstAssessmentDate={firstAssessmentDate}
                secondAssessmentDate={secondAssessmentDate}
                frequency={frequency}
                readOnly
                isUnderDevelopment={projectDetails?.status === "Under Development"}
              />
            </Column>
          </Row>
        </>
      )}
    </div>
  );
};

export default Details;
