import { ChartsSection } from "../../../components";
import { useAtAGlanceSection } from "./useAtAGlanceSection";

interface AtAGlanceSectionProps {
  organisationUuid: string | undefined;
}

export const AtAGlanceSection = ({ organisationUuid }: AtAGlanceSectionProps): JSX.Element => {
  const { projectCodesChartData, unitsChartData, locationsChartData, isLoading } =
    useAtAGlanceSection(organisationUuid);

  return (
    <div>
      {!isLoading && (
        <ChartsSection
          title="At a glance"
          projectsData={projectCodesChartData}
          unitsData={unitsChartData}
          locationsData={locationsChartData}
        />
      )}
    </div>
  );
};
