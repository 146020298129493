import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { GetProjectDetailsResponse } from "../../../../service/query";
import { getProjectsRoute } from "../../../../utils/routes";
import { ProjectContext } from "./ProjectContext";

interface UseProjectReturnData {
  projectDetails: GetProjectDetailsResponse | undefined;
  handleBackClick: () => void;
}

export function useProject(): UseProjectReturnData {
  const navigate = useNavigate();
  const { projectDetails } = useContext(ProjectContext);

  const handleBackClick = (): void => {
    navigate(getProjectsRoute());
  };

  return {
    projectDetails,
    handleBackClick,
  };
}
