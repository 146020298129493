import "./PieChart.css";

import { Doughnut } from "react-chartjs-2";

import { maybeSetGlobalChartJsConfiguration } from "../../../utils";
import { ChartProps } from "../ChartProps";
import { ChartLegend } from ".";
import { usePieChart } from "./usePieChart";

maybeSetGlobalChartJsConfiguration();

export interface PieChartProps extends ChartProps {
  size: number;
  centerTextHidden?: boolean;
  noDataMessage?: string;
}

export const PieChart = ({
  data,
  size,
  centerTextHidden = false,
  noDataMessage = "No data",
}: PieChartProps): JSX.Element => {
  const { chartData, legendData, chartColours, plugins, options, hasData } = usePieChart(
    data,
    centerTextHidden,
    noDataMessage
  );

  return (
    <div>
      {plugins && (
        <div className="PieChart">
          <div className="Chart" style={{ maxWidth: size, maxHeight: size }}>
            <Doughnut data={chartData} options={options} plugins={plugins} />
          </div>
          <ChartLegend data={legendData} colours={chartColours} hasData={hasData} />
        </div>
      )}
      {(data === null || data === undefined || Object.values(data).length === 0) && (
        <div className="PieChart">
          <div className="Chart" style={{ maxWidth: size, maxHeight: size }}>
            <Doughnut data={chartData} options={options} />
          </div>
          <ChartLegend data={legendData} colours={chartColours} hasData={hasData} />
        </div>
      )}
    </div>
  );
};
