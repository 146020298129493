import "./DocumentCard.css";

import { FileBlueIcon } from "../../../../../../assets";

interface DocumentCardProps {
  name: string;
  uploadedDate: string;
  url: string;
}

export const DocumentCard = ({ name, uploadedDate, url }: DocumentCardProps): JSX.Element => {
  return (
    <button className="DocumentCard" onClick={() => window.open(url, "_blank")?.focus()}>
      <div className="DocumentCardTitle">
        <FileBlueIcon />
        <h3>{name}</h3>
      </div>
      <p className="body2 DocumentCardUploadDateText">{`Uploaded on ${uploadedDate}`}</p>
    </button>
  );
};
