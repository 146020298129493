import { Dispatch, ForwardedRef, SetStateAction } from "react";

import { FileUploadHandle } from "../../../../../../models";
import { Column, KanaTooltip, Row, SingleFileUpload, TextArea, TextInput } from "../../../../../../widget";
import { MaxLengthConstants } from "../constants";

interface SectionProps {
  disabled: boolean;
  header: string;
  parentForm?: "ourApproach" | "ourServices";
  formName?: "our approach" | "our services";
  sectionNumber: number;
  title: string | null | undefined;
  content: string | null | undefined;
  imageUrl?: string | null | undefined;
  imageRef: ForwardedRef<FileUploadHandle>;
  setTitle: Dispatch<SetStateAction<string | null | undefined>>;
  setContent: Dispatch<SetStateAction<string | null | undefined>>;
  handleSetImage: (f: File, part: number) => Promise<void>;
}

export const Section = ({
  disabled,
  header,
  parentForm = "ourApproach",
  formName,
  sectionNumber,
  title,
  content,
  imageUrl,
  imageRef,
  setTitle,
  setContent,
  handleSetImage,
}: SectionProps): JSX.Element => {
  return (
    <>
      <Row spacingV="ll">
        <Column span={4}>
          <h4>{header}</h4>
        </Column>
      </Row>
      <Row spacingV="sl">
        <Column span={6}>
          <SingleFileUpload
            ref={imageRef}
            label="Image"
            informationLabel="The image should be 450px x 450px"
            disabled={disabled}
            previewUrl={imageUrl}
            onChange={(f) => handleSetImage(f, sectionNumber)}
          />
        </Column>
      </Row>
      <Row spacingV="sl">
        <Column span={10}>
          <TextInput
            name={`${parentForm}-${header}-title`}
            label="Title"
            disabled={disabled}
            tooltip={
              <KanaTooltip
                tooltipHeader={`What is the ${formName?.split(" ", 2)[1]} section title used for?`}
                tooltipText={`This is a section heading that will be displayed under the '${formName}' section on your developer profile. If left blank, this section will not be displayed.`}
              />
            }
            value={title}
            onChange={(value) => setTitle(value)}
            maxLength={MaxLengthConstants.SECTION_TITLE_MAX_LENGTH}
            displayCharacterCount
          />
        </Column>
      </Row>
      <Row spacingV="2xl">
        <Column span={10}>
          <TextArea
            name={`${parentForm}-${header}-content`}
            label="Content"
            disabled={disabled}
            tooltip={
              <KanaTooltip
                tooltipHeader={`What is the ${formName?.split(" ", 2)[1]} section content used for?`}
                tooltipText="This is the content which will be displayed under the above heading. If left blank, this section will not be displayed."
              />
            }
            value={content || ""}
            onChange={(e) => setContent(e.currentTarget.value)}
            displayCharacterCount
            maxLength={MaxLengthConstants.SECTION_CONTENT_MAX_LENGTH}
          />
        </Column>
      </Row>
    </>
  );
};
