import { useContext, useEffect, useState } from "react";

import { ProjectContext } from "../ProjectContext";

interface UseOverviewReturnData {
  currentStatus: string | undefined;
  mapCoordinates: string[] | undefined;
}

export const useOverview = (): UseOverviewReturnData => {
  const { projectDetails } = useContext(ProjectContext);

  const [currentStatus, setCurrentStatus] = useState<string | undefined>();
  const [mapCoordinates, setMapCoordinates] = useState<string[] | undefined>();

  useEffect(() => {
    if (!projectDetails) return;

    setCurrentStatus(projectDetails.status);

    if (projectDetails.locationCoordinates) {
      setMapCoordinates([projectDetails.locationCoordinates]);
    }
  }, [projectDetails]);

  return {
    currentStatus,
    mapCoordinates,
  };
};
