import "./BarChart.css";

import { ChartData, ChartOptions } from "chart.js";
import { Bar } from "react-chartjs-2";

import { ColorConstants } from "../../../constants";
import { maybeSetGlobalChartJsConfiguration } from "../../../utils";
import { ChartProps } from "../ChartProps";

export type BarChartProps = ChartProps;

maybeSetGlobalChartJsConfiguration();

const options: ChartOptions<"bar"> = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      displayColors: false,
      callbacks: {
        title: () => "",
      },
    },
  },
  scales: {
    x: {
      ticks: {
        padding: 6,
      },
      grid: {
        display: false,
        borderColor: ColorConstants.GREY_20,
        drawTicks: false,
      },
    },
    y: {
      grid: {
        color: ColorConstants.LIGHT_GREY,
        drawBorder: false,
        tickColor: ColorConstants.WHITE,
      },
    },
  },
};

export const BarChart = ({ data, emptyPlaceholder = "No data to display" }: BarChartProps): JSX.Element => {
  if (!data || Object.keys(data).length === 0)
    return (
      <div className="Bar Bar_empty">
        <p className="body3">{emptyPlaceholder}</p>
      </div>
    );
  const xLabels = Object.keys(data);
  const dataPoints = xLabels.map((label) => data[label]);
  const chartData: ChartData<"bar", number[], string> = {
    labels: xLabels,
    datasets: [
      {
        data: dataPoints,
        barPercentage: 0.46,
        hoverBackgroundColor: ColorConstants.BLUE_80,
      },
    ],
  };
  return (
    <div className="Bar">
      <Bar data={chartData} options={options} />
    </div>
  );
};
