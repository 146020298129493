import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { SelectData } from "../../../../../models";
import { logError } from "../../../../../service/error";
import {
  getOrganisationUserDetails,
  GetOrganisationUserDetailsResponse,
  lookupRoles,
} from "../../../../../service/query";
import { Status } from "../../../../../service/Shared";
import { Toast } from "../../../../../widget";

interface UseEditReturnData {
  pageTitle: string | undefined;
  dataIsLoading: boolean;
  userDetails: GetOrganisationUserDetailsResponse | undefined;
  userUuid: string | undefined;
  roles: SelectData | undefined;
}

type EditPageRouteParams = "userUuid";

export function useEdit(): UseEditReturnData {
  const { userUuid } = useParams<EditPageRouteParams>();

  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [userDetails, setUserDetails] = useState<GetOrganisationUserDetailsResponse | undefined>(undefined);
  const [roles, setRoles] = useState<SelectData | undefined>(undefined);

  const fetchData = useCallback(async () => {
    if (userUuid) {
      const organisationRes = await getOrganisationUserDetails({ userUuid });
      if (organisationRes.status === Status.Success) {
        setUserDetails(organisationRes.data);
      }
    }
    const rolesRes = await lookupRoles();
    if (rolesRes.status === Status.Success) {
      setRoles(
        rolesRes.data?.map((role) => {
          return {
            key: role.key,
            value: role.valueString,
          };
        })
      );
    }
  }, []);

  useEffect(() => {
    fetchData()
      .then(() => setDataIsLoading(false))
      .catch(async (error) => {
        Toast.error({ message: "No user could be found for this Uuid" });
        await logError({ error });
      });
  }, [fetchData]);

  return {
    pageTitle:
      userDetails?.user.firstName && userDetails?.user.lastName
        ? `${userDetails?.user.firstName} ${userDetails?.user.lastName}`
        : undefined,
    dataIsLoading,
    userDetails,
    userUuid,
    roles,
  };
}
