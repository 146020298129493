import { FocusEventHandler, useEffect, useState } from "react";

interface UseTextInputReturnData {
  currentValue: string;
  onInputChange: FocusEventHandler<HTMLInputElement> | undefined;
  onInputBlur: FocusEventHandler<HTMLInputElement> | undefined;
}

export function useTextInput(
  name: string,
  value?: string | null,
  type?: string,
  decimalPlaces?: number,
  onChange?: (value: string) => void
): UseTextInputReturnData {
  const [currentValue, setCurrentValue] = useState<string>(value || "");

  const formatNumberValue = (targetValue: string): string => {
    const formattedValue = parseFloat(targetValue);

    return decimalPlaces ? formattedValue.toFixed(decimalPlaces) : formattedValue.toString();
  };

  useEffect(() => {
    if (value) {
      if (type === "number") {
        setCurrentValue(formatNumberValue(value));
      } else {
        setCurrentValue(value);
      }
    } else {
      setCurrentValue("");
    }
  }, [value]);

  const onInputChange: FocusEventHandler<HTMLInputElement> = (e) => {
    setCurrentValue(e.currentTarget.value);

    if (onChange) onChange(e.currentTarget.value);
  };

  const onInputBlur: FocusEventHandler<HTMLInputElement> = () => {
    if (type === "number" && currentValue) {
      setCurrentValue(formatNumberValue(currentValue));
    }
  };

  return {
    currentValue,
    onInputChange,
    onInputBlur,
  };
}
