import { Dispatch, SetStateAction, useState } from "react";

interface UseRegisterReturnData {
  registrationSuccessful: boolean;
  setRegistrationSuccessful: Dispatch<SetStateAction<boolean>>;
}

export function useRegister(): UseRegisterReturnData {
  const [registrationSuccessful, setRegistrationSuccessful] = useState(false);

  return {
    registrationSuccessful,
    setRegistrationSuccessful,
  };
}
