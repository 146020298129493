import "./Toggle.css";

import RcSwitch from "rc-switch";

import { FormLabel } from "../../FormLabel";
import { useToggle } from "./useToggle";

interface ToggleProps {
  defaultValue: boolean;
  label?: string;
  labelAlignment?: "left" | "right";
  onChange: (value: boolean) => void;
}

export const Toggle = ({ label, labelAlignment = "right", onChange, defaultValue }: ToggleProps): JSX.Element => {
  const { toggleId, checked, handleToggleClick } = useToggle(defaultValue, onChange);

  return (
    <div className="Toggle">
      <FormLabel label={label} labelAlignment={labelAlignment}>
        <RcSwitch id={toggleId} checked={checked} onClick={handleToggleClick} prefixCls="ToggleComponent" />
      </FormLabel>
    </div>
  );
};
