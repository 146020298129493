export class ProjectTypesConstants {
  public static readonly WOODLAND_CREATION_PROJECT_TYPE = "woodland-creation";

  public static readonly HEDGEROW_PROJECT_TYPE = "hedgerow-creation";

  public static readonly REWILDING_PROJECT_TYPE = "rewilding";

  public static readonly SOIL_PROJECT_TYPE = "soil";

  public static readonly PEATLAND_RESTORATION_PROJECT_TYPE = "peatland-restoration";

  public static readonly BIOENERGY_PROJECT_TYPE = "bioenergy";

  public static readonly BIOCHAR_PROJECT_TYPE = "biochar";

  public static readonly ENHANCED_WEATHERING_PROJECT_TYPE = "enhanced-weathering";

  public static readonly LIVESTOCK_FEEDS_PROJECT_TYPE = "livestock-feeds";

  public static readonly SEAGRASS_RESTORATION_PROJECT_TYPE = "seagrass-restoration";

  public static readonly SALTMARSH_PROJECT_TYPE = "saltmarsh";

  public static readonly CONSTRUCTED_WETLAND_PROJECT_TYPE = "constructed-wetland";
}
