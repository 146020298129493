import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  AboutUsData,
  AtAGlanceData,
  FileType,
  HeroData,
  PublicVerifierDetailsParams,
  RichContentSectionData,
  SectionPart,
  SocialMediaData,
} from "../../../../../models";
import { getOrganisationDetails, getStandardDetails } from "../../../../../service/publicquery";
import { Status } from "../../../../../service/Shared";
import { getPublicCodeDetailsRoute } from "../../../../../utils/routes";
import { OurExpertiseData, StandardsData } from "../../models";

interface UseDetailsReturnData {
  dataIsLoading: boolean;
  verifierDetailsData: VerifierDetailsData | undefined;
}

export interface VerifierDetailsData {
  displayName: string | null;
  logo: string | null;
  websiteUrl: string | null;
  contactPhone?: string | null;
  calculatedContactable: boolean;
  socialMediaData: SocialMediaData;
  hero: HeroData;
  aboutUs: AboutUsData;
  ourExpertise: OurExpertiseData;
  standards: StandardsData;
  atAGlance: AtAGlanceData;
  ourServices: RichContentSectionData;
}

export const useDetails = (): UseDetailsReturnData => {
  const { organisationUuid } = useParams<PublicVerifierDetailsParams>();
  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [verifierDetailsData, setVerifierDetailsData] = useState<VerifierDetailsData | undefined>(undefined);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getStandardValues = async (standards: any): Promise<StandardsData> => {
    if (!standards || !standards.enabled) {
      return {
        enabled: false,
        content: null,
        sectionDataList: [],
      };
    }
    const standardsArray = standards?.standards || [];
    const dataList: SectionPart[] = [];

    if (standardsArray.length) {
      for (let index = 0; index < standardsArray.length; index++) {
        // eslint-disable-next-line no-await-in-loop
        const res = await getStandardDetails({
          organisationUuid: standardsArray[index].uuid,
        });
        if (res.status === Status.Success && res.data) {
          dataList.push({
            title: res.data.displayName || null,
            content: standardsArray[index].content,
            imageUrl: res.data.files.find((f) => f.type === FileType.LargeLogo)?.file.url || null,
            imageLink: getPublicCodeDetailsRoute(standardsArray[index].uuid),
          });
        }
      }
    }

    return {
      enabled: standards ? standards.enabled : false,
      content: standards.content,
      sectionDataList: dataList,
    };
  };

  const fetchData = useCallback(async (): Promise<void> => {
    if (organisationUuid) {
      const res = await getOrganisationDetails({
        organisationUuid,
      });
      if (res.status === Status.Success && res.data) {
        const logo = res.data.files.find((f) => f.type === FileType.LargeLogo)?.file.url || null;

        const heroValues: HeroData = {
          enabled: res.data.listing?.content?.hero ? res.data.listing?.content?.hero?.enabled : false,
          title: res.data.listing?.content?.hero?.title || null,
          subtitle: res.data.listing?.content?.hero?.subtitle || null,
          imageUrl:
            res.data.listingFiles.find((file) => file.uuid === res.data?.listing?.content?.hero?.imageFileUuid)?.file
              .url || null,
        };

        const socialMediaValues: SocialMediaData = {
          twitterUsername: res.data.socialMediaTwitterUsername,
          linkedinURL: res.data.socialMediaLinkedInUrl,
          instagramUsername: res.data.socialMediaInstagramUsername,
          youtubeChannel: res.data.socialMediaYoutubeChannel,
          facebookURL: res.data.socialMediaFacebookUrl,
        };

        const aboutUsValues: AboutUsData = {
          enabled: res.data.listing?.content?.aboutUs ? res.data.listing?.content?.aboutUs?.enabled : false,
          content: res.data?.listing?.content?.aboutUs?.content,
          imageUrl:
            res.data.listingFiles.find((file) => file.uuid === res.data?.listing?.content?.aboutUs?.imageFileUuid)?.file
              .url || null,
        };

        const ourExpertiseValues: OurExpertiseData = {
          enabled: res.data.listing?.content?.ourMission ? res.data.listing?.content?.ourMission?.enabled : false,
          content: res.data?.listing?.content?.ourMission?.content,
        };
        const standardsValues: StandardsData = await getStandardValues(res.data.listing?.content?.standards);

        const atAGlanceValues: AtAGlanceData = {
          enabled: res.data.listing?.content?.atAGlance ? res.data.listing?.content?.atAGlance?.enabled : false,
        };

        const ourServicesValues: RichContentSectionData = {
          enabled: res.data.listing?.content?.ourServices ? res.data.listing?.content?.ourServices?.enabled : false,
          part1: res.data.listing?.content?.ourServices?.part1
            ? {
                title: res.data.listing?.content?.ourServices?.part1?.title,
                content: res.data.listing?.content?.ourServices?.part1?.content,
                imageUrl:
                  res.data.listingFiles.find(
                    (file) => file.uuid === res.data?.listing?.content?.ourServices?.part1?.imageFileUuid
                  )?.file.url || null,
              }
            : null,
          part2: res.data.listing?.content?.ourServices?.part2
            ? {
                title: res.data.listing?.content?.ourServices?.part2?.title,
                content: res.data.listing?.content?.ourServices?.part2?.content,
                imageUrl:
                  res.data.listingFiles.find(
                    (file) => file.uuid === res.data?.listing?.content?.ourServices?.part2?.imageFileUuid
                  )?.file.url || null,
              }
            : null,
          part3: res.data.listing?.content?.ourServices?.part3
            ? {
                title: res.data.listing?.content?.ourServices?.part3?.title,
                content: res.data.listing?.content?.ourServices?.part3?.content,
                imageUrl:
                  res.data.listingFiles.find(
                    (file) => file.uuid === res.data?.listing?.content?.ourServices?.part3?.imageFileUuid
                  )?.file.url || null,
              }
            : null,
          part4: res.data.listing?.content?.ourServices?.part4
            ? {
                title: res.data.listing?.content?.ourServices?.part4?.title,
                content: res.data.listing?.content?.ourServices?.part4?.content,
                imageUrl:
                  res.data.listingFiles.find(
                    (file) => file.uuid === res.data?.listing?.content?.ourServices?.part4?.imageFileUuid
                  )?.file.url || null,
              }
            : null,
        };

        setVerifierDetailsData({
          displayName: res.data.displayName,
          logo,
          websiteUrl: res.data.websiteUrl,
          contactPhone: res.data.contactPhone,
          calculatedContactable: res.data.calculatedContactable,
          socialMediaData: socialMediaValues,
          hero: heroValues,
          aboutUs: aboutUsValues,
          ourExpertise: ourExpertiseValues,
          standards: standardsValues,
          atAGlance: atAGlanceValues,
          ourServices: ourServicesValues,
        });
      }
    }
  }, [organisationUuid]);

  useEffect(() => {
    fetchData().then(() => setDataIsLoading(false));
  }, [fetchData]);

  return {
    dataIsLoading,
    verifierDetailsData,
  };
};
