import { Dispatch, FormEvent, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";

import { inviteUserToJoinOrganisation } from "../../../../../../service/identity";
import { ServiceError, Status } from "../../../../../../service/Shared";
import { useAuth } from "../../../../../../useAuth";
import { getUserSettingsRoute } from "../../../../../../utils/routes";
import { Toast } from "../../../../../../widget";

interface UseSendInvitationFormReturnData {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  errors: ServiceError[] | undefined;
  setFirstName: Dispatch<SetStateAction<string>>;
  setLastName: Dispatch<SetStateAction<string>>;
  setEmail: Dispatch<SetStateAction<string>>;
  setRole: Dispatch<SetStateAction<string>>;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  handleCancel: () => void;
}

export function useSendInvitationForm(): UseSendInvitationFormReturnData {
  const navigate = useNavigate();
  const { currentOrganisationUuid } = useAuth();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [errors, setErrors] = useState<ServiceError[] | undefined>();

  async function handleSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault();
    setErrors([]);

    if (currentOrganisationUuid) {
      const res = await inviteUserToJoinOrganisation({
        organisationUuid: currentOrganisationUuid,
        userFirstName: firstName,
        userLastName: lastName,
        userEmail: email,
        role,
      });

      if (res.status === Status.Success) {
        Toast.success({ message: "User invitation sent successfully" });
        navigate(getUserSettingsRoute());
      } else {
        setErrors(res.errors);
      }
    }
  }

  const handleCancel = (): void => {
    navigate(getUserSettingsRoute());
  };

  return {
    firstName,
    lastName,
    email,
    role,
    errors,
    setFirstName,
    setLastName,
    setEmail,
    setRole,
    handleSubmit,
    handleCancel,
  };
}
