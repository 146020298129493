import "./VerticalLifecycle.css";

import { CompletedCircleGreenIcon, InProgressCircleGreenIcon, NotStartedCircleGreyIcon } from "../../../../assets";

interface VerticalLifecycleProps {
  currentStatus?: string;
}

export const VerticalLifecycle = ({ currentStatus }: VerticalLifecycleProps): JSX.Element => {
  const steps = ["Under Development", "Validated", "Verified"];
  const currentStepIndex = currentStatus ? steps.indexOf(currentStatus) : 0;

  return (
    <>
      {steps.map((step, index) => (
        <div key={step} className="LifecycleStep">
          <div className="LifecycleStepColumn">
            {(index < currentStepIndex && <CompletedCircleGreenIcon />) ||
              (index === currentStepIndex && <InProgressCircleGreenIcon />) ||
              (index > currentStepIndex && <NotStartedCircleGreyIcon />)}
            {index !== steps.length - 1 && (
              <div
                className={`LifecycleStepDivider ${
                  index < currentStepIndex ? "LifecycleStepDivider_completed" : "LifecycleStepDivider_not-started"
                }`}
              />
            )}
          </div>
          <div className="LifecycleStepLabel">
            <span className="body2">{step}</span>
          </div>
        </div>
      ))}
    </>
  );
};
