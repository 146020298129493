import "./ChartLegend.css";

import { thousandsFormatter } from "../../../utils";
import { ChartProps } from "../ChartProps";

interface ChartLegendProps extends ChartProps {
  colours: string[];
  hasData: boolean;
}

export const ChartLegend = ({ data, colours, hasData }: ChartLegendProps): JSX.Element => {
  return (
    <div className="ChartLegend">
      <table>
        <tbody>
          {Object.keys(data).map((key, idx) => {
            return (
              <tr key={key}>
                <td>
                  <div className="ChartLegendBox" style={{ backgroundColor: colours[idx] }} />
                </td>
                <td className={hasData ? "ChartLegendValue" : "ChartLegendNoValue"}>
                  {hasData ? thousandsFormatter.format(data[key]) : null}
                </td>
                <td>{key}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
