import "./RichContentSection.css";

import { RichContentSectionData } from "../../../models";
import { isEven } from "../../../utils";

interface RichContentSectionProps {
  title: string;
  sectionData: RichContentSectionData;
}

export const RichContentSection = ({ title, sectionData }: RichContentSectionProps): JSX.Element => {
  return (
    <div className="RichContentSection">
      <h2>{title}</h2>
      {Object.entries(sectionData)
        // filter enabled property, all null and undefined parts and also the parts that are missing content and title properties.
        .filter(([part, partData]) => {
          return part !== "enabled" && partData && partData.content && partData.title;
        })
        .map(([partName, partData], index) => (
          <div
            key={`${partName}_${partData.title}`}
            className={isEven(index) ? "RichContentSectionPart" : "RichContentSectionPartReversed"}
          >
            <div>
              <h3>{partData.title}</h3>
              <p className="body1">{partData.content}</p>
            </div>
            {partData?.imageUrl && (
              <div>
                <img className="RichContentSectionPartImage" src={partData.imageUrl} alt={partData.title} />
              </div>
            )}
          </div>
        ))}
    </div>
  );
};
