import { Column, Row } from "../../../layout";
import { ProjectStatusKPI } from "../ProjectStatusKPI";
import { TotalSequestrationKPI } from "../TotalSequestrationKPI";
import { VcuPiuKPI } from "../VcuPiuKPI";

interface KPIGroupProps {
  projectStatus: string;
  totalSequestration: number;
  totalReduction: number;
  vcus: number;
  pius: number;
  isPcc: boolean;
}

export const KPIGroup = ({
  projectStatus,
  totalSequestration,
  totalReduction,
  vcus,
  pius,
  isPcc,
}: KPIGroupProps): JSX.Element => (
  <div className="KPIGroup">
    {pius === 0 && vcus === 0 ? (
      <Row>
        <Column span={6}>
          <ProjectStatusKPI status={projectStatus} />
        </Column>
        <Column span={6}>
          <TotalSequestrationKPI
            totalSequestration={totalSequestration}
            totalReduction={totalReduction}
            isPcc={isPcc}
          />
        </Column>
      </Row>
    ) : (
      <Row>
        <Column span={4}>
          <ProjectStatusKPI status={projectStatus} graphBarFlexSize={3} />
        </Column>
        <Column span={4}>
          <TotalSequestrationKPI
            totalSequestration={totalSequestration}
            totalReduction={totalReduction}
            isPcc={isPcc}
          />
        </Column>
        <Column span={4}>
          <VcuPiuKPI vcus={vcus} pius={pius} />
        </Column>
      </Row>
    )}
  </div>
);
