// TODO temporarily disable eslint on this file until v1.0 Unwired is complete/services are used
/* eslint unused-imports/no-unused-vars: "off" */
/* eslint @typescript-eslint/no-explicit-any: "off" */
/* eslint @typescript-eslint/no-non-null-assertion: "off" */
// PublicQueryService
import { GetStandardDetailsResponse } from "../../models";
import { projectTypes } from "../projectType";
import { BaseResponseWithMultiple, BaseResponseWithSingle, Status } from "../Shared";
import { identityInvitations, organisationOrganisations, projectProjects } from "../TempData";
import {
  DownloadProjectDocumentRequest,
  DownloadProjectDocumentResponse,
  GetInvitationDetailsRequest,
  GetInvitationDetailsResponse,
  GetOrganisationDetailsRequest,
  GetOrganisationDetailsResponse,
  GetProjectAggregateRequest,
  GetProjectAggregateResponse,
  GetProjectDetailsRequest,
  GetProjectDetailsResponse,
  GetProjectDocumentsRequest,
  GetProjectDocumentsResponse,
  GetProjectIssuancesRequest,
  GetProjectIssuancesResponse,
  SearchProjectsRequest,
  SearchProjectsResponse,
} from "./Types";

export async function getProjectDocuments(
  req: GetProjectDocumentsRequest
): Promise<BaseResponseWithMultiple<GetProjectDocumentsResponse>> {
  const data: any = projectProjects.find((p) => p.uuid === req.projectUuid)?.documents || [];

  const dataWithFiles: GetProjectDocumentsResponse[] = data.map((d: any) => {
    return {
      ...d,
      file: { url: d.cached.url },
    };
  });

  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return {
    errors: [],
    status: Status.Success,
    data: dataWithFiles,
  };
}

export async function getProjectIssuances(
  req: GetProjectIssuancesRequest
): Promise<BaseResponseWithMultiple<GetProjectIssuancesResponse>> {
  const data: GetProjectIssuancesResponse[] = projectProjects.find((p) => p.uuid === req.projectUuid)?.issuances || [];
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return {
    errors: [],
    status: Status.Success,
    data,
  };
}

export async function downloadProjectDocument(
  req: DownloadProjectDocumentRequest
): Promise<BaseResponseWithSingle<DownloadProjectDocumentResponse>> {
  const data: DownloadProjectDocumentResponse = {
    file: new File([], `${req.projectDocumentUuid}.txt`),
  };
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return {
    errors: [],
    status: Status.Success,
    data,
  };
}

export async function searchProjects(
  req: SearchProjectsRequest
): Promise<BaseResponseWithSingle<SearchProjectsResponse>> {
  const matchingProjects = projectProjects
    .filter((p) => {
      let isMatch = true;
      if (
        req.filter?.results?.addressCountryCode &&
        req.filter?.results?.addressCountryCode.value !== p.addressCountryCode
      )
        isMatch = false;
      if (req.filter?.results?.status && req.filter?.results?.status.value !== p.status) isMatch = false;
      if (req.filter?.results?.developer?.uuid && req.filter?.results?.developer?.uuid.value !== p.developer.uuid)
        isMatch = false;
      if (req.filter?.results?.standard?.uuid && req.filter?.results?.standard?.uuid.value !== p.standard.uuid)
        isMatch = false;
      if (
        req.filter?.results?.listing?.availableForSale &&
        req.filter.results.listing.availableForSale.value !== p.listing.cachedAvailableForSale
      )
        isMatch = false;
      if (req.filter?.results?.cachedPiuQuantity && req.filter.results.cachedPiuQuantity.value >= p.cachedPiuQuantity)
        isMatch = false;
      if (req.filter?.results?.cachedVcuQuantity && req.filter.results.cachedVcuQuantity.value >= p.cachedVcuQuantity)
        isMatch = false;
      return isMatch;
    })
    .map((project) => ({
      addressCountryCode: project.addressCountryCode,
      status: project.status,
      cachedPiuQuantity: project.cachedPiuQuantity,
      cachedVcuQuantity: project.cachedVcuQuantity,
      calculatedTotalQuantityMin: (project.cachedPiuQuantity || 0) + (project.cachedVcuQuantity || 0),
      calculatedTotalQuantityMax: (project.cachedPiuQuantity || 0) + (project.cachedVcuQuantity || 0),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      calculatedIssuancePriceMin: project.issuances.reduce(
        (acc: number | null, val: { price: number }) => (acc === null || val.price < acc ? val.price : acc),
        null
      ),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      calculatedIssuancePriceMax: project.issuances.reduce(
        (acc: number | null, val: { price: number }) => (acc === null || val.price > acc ? val.price : acc),
        null
      ),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      calculatedVintageEndDateMin: project.issuances.reduce(
        (acc: Date | null, val: { vintageEndDate: Date }) =>
          acc === null || val.vintageEndDate?.getTime() > acc.getTime() ? val.vintageEndDate : acc,
        null
      ),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      calculatedVintageEndDateMax: project.issuances.reduce(
        (acc: Date | null, val: { vintageEndDate: Date }) =>
          acc === null || val.vintageEndDate?.getTime() < acc.getTime() ? val.vintageEndDate : acc,
        null
      ),
      developer: project.developer,
      listing: {
        content: JSON.parse(project.listing.cachedContent),
        availableForSale: !!project.listing.cachedAvailableForSale,
        showPriceInDirectory: !!project.listing.cachedShowPriceInDirectory,
      },
      displayName: project.displayName,
      uuid: project.uuid,
      standard: project.standard,
      tags: project.tags,
      locationCoordinates: project.locationCoordinates,
      listingFiles: project.listingFiles,
      dataScore: project.dataScore,
    }));
  const results = matchingProjects;

  const data: SearchProjectsResponse = {
    results,
    paging: {
      startCursor: "TEMP",
      endCursor: "TEMP",
      hasNextPage: true,
      hasPreviousPage: true,
      total: matchingProjects.length,
    },
    aspects: {
      addressCountryCodes: matchingProjects
        .map((p) => p.addressCountryCode)
        .filter((value, index, array) => array.indexOf(value) === index),
      statuses: matchingProjects.map((p) => p.status).filter((value, index, array) => array.indexOf(value) === index),
      developers: matchingProjects
        .map((p) => ({
          uuid: p.developer.uuid,
          displayName: p.developer.displayName || p.developer.name,
        }))
        .filter((value, index, array) => array.indexOf(value) === index),
      standards: matchingProjects
        .map((p) => ({
          uuid: p.standard.uuid,
          displayName: p.standard.displayName || p.standard.name,
        }))
        .filter((value, index, array) => array.indexOf(value) === index),
      calculatedIssuancePriceMin: 1,
      calculatedIssuancePriceMax: 100,
      calculatedVintageEndDateMin: new Date(2016, 0, 1),
      calculatedVintageEndDateMax: new Date(2100, 0, 1),
      calculatedTotalQuantityMin: 1,
      calculatedTotalQuantityMax: 10000,
      tags: matchingProjects
        .map((p) => p.tags)
        .reduce((ts1, ts2) => ts1.concat(ts2))
        .filter((value, index, array) => array.indexOf(value) === index),
    },
  };
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return {
    errors: [],
    status: Status.Success,
    data,
  };
}

export async function getProjectDetails(
  req: GetProjectDetailsRequest
): Promise<BaseResponseWithSingle<GetProjectDetailsResponse>> {
  const project = projectProjects.find((x) => x.uuid === req.projectUuid)!;
  const data: GetProjectDetailsResponse = {
    addressCity: project.addressCity,
    addressCountryCode: project.addressCountryCode,
    addressLine1: project.addressLine1,
    addressLine2: project.addressLine2,
    addressLine3: project.addressLine3,
    addressLine4: project.addressLine4,
    uuid: project.uuid,
    displayName: project.displayName,
    validator: project.validator,
    tags: project.tags,
    listing: {
      showPriceInDirectory: !!project.listing.cachedShowPriceInDirectory,
      content: JSON.parse(project.listing.cachedContent),
      availableForSale: !!project.listing.cachedAvailableForSale,
    },
    addressPostcode: project.addressPostcode,
    areaNetHa: project.areaNetHa,
    standard: project.standard,
    developer: {
      displayName: project.developer.displayName,
      uuid: project.developer.uuid,
      files: project.developer.files,
      listing: {
        content: project.developer.listing != null ? JSON.parse(project.developer.listing.cachedContent) : null,
      },
    },
    cachedPiuQuantity: project.cachedPiuQuantity,
    cachedVcuQuantity: project.cachedVcuQuantity,
    standardSpecificData: JSON.parse(project.standardSpecificData),
    listingFiles: project.listingFiles,
    status: project.status,
    locationCoordinates: project.locationCoordinates,
    locationGridReference: project.locationGridreference,
    calculatedContactable: !project.listing.cachedContactOptOut && !!project.developer.contactEmail,
  };
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return {
    errors: [],
    status: Status.Success,
    data,
  };
}

export async function getOrganisationDetails(
  req: GetOrganisationDetailsRequest
): Promise<BaseResponseWithSingle<GetOrganisationDetailsResponse>> {
  const organisation = organisationOrganisations.find((x) => x.uuid === req.organisationUuid)!;
  const data: GetOrganisationDetailsResponse = {
    contactEmail: organisation.contactEmail,
    contactPhone: organisation.contactPhone,
    socialMediaLinkedInUrl: organisation.socialMediaLinkedinUrl,
    socialMediaFacebookUrl: organisation.socialMediaFacebookUrl,
    socialMediaYoutubeChannel: organisation.socialMediaYoutubeChannel,
    socialMediaInstagramUsername: organisation.socialMediaInstagramUsername,
    socialMediaTwitterUsername: organisation.socialMediaTwitterUsername,
    websiteUrl: organisation.websiteUrl,
    displayName: organisation.displayName,
    files: organisation.files,
    listingFiles: organisation.listingFiles,
    listing: {
      content: organisation.listing == null ? null : JSON.parse(organisation.listing.cachedContent),
    },
    calculatedContactable: true,
  };
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return {
    errors: [],
    status: Status.Success,
    data,
  };
}

export async function getStandardDetails(
  req: GetOrganisationDetailsRequest
): Promise<BaseResponseWithSingle<GetStandardDetailsResponse>> {
  const organisation = organisationOrganisations.find((x) => x.uuid === req.organisationUuid)!;
  const data: GetStandardDetailsResponse = {
    displayName: organisation.displayName,
    files: organisation.files,
  };
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return {
    errors: [],
    status: Status.Success,
    data,
  };
}

export async function getInvitationDetails(
  req: GetInvitationDetailsRequest
): Promise<BaseResponseWithSingle<GetInvitationDetailsResponse>> {
  const data: GetInvitationDetailsResponse = identityInvitations.find((i) => i.token === req.token)!;
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return {
    errors: [],
    status: Status.Success,
    data,
  };
}

const groupBy = <T>(arr: T[], keys: (keyof T)[]): { [key: string]: T[] } => {
  // from https://gist.github.com/robmathers/1830ce09695f759bf2c4df15c29dd22d
  // same as in QueryService.ts
  return arr.reduce((storage, item) => {
    const objKey = keys.map((key) => `${item[key]}`).join(":");
    if (storage[objKey]) {
      storage[objKey].push(item);
    } else {
      // eslint-disable-next-line no-param-reassign
      storage[objKey] = [item];
    }
    return storage;
  }, {} as { [key: string]: T[] });
};

export async function getProjectAggregate(
  req: GetProjectAggregateRequest
): Promise<BaseResponseWithMultiple<GetProjectAggregateResponse>> {
  let data: GetProjectAggregateResponse[] = [];
  // we only support filtering by developer organisation currently
  if (req.filterBy !== "developer" && req.filterBy !== "developerOrganisationUuid")
    throw new Error(
      `Unsupported filterBy value - must be one of 'developer', 'developerOrganisationUuid', but received '${req.filterBy}'`
    );
  const developerProjects = projectProjects.filter((p) => p.developer.uuid === req.filterValue);
  if (req.aggregate === "status") {
    const groupByStatus = groupBy(developerProjects, ["status"]);
    data = Object.keys(groupByStatus).map((key) => ({
      key,
      value: groupByStatus[key].length,
    }));
  } else if (req.aggregate === "units") {
    let projects = developerProjects;
    if (req.filterValue) {
      projects = [projectProjects.find((p) => p.uuid === req.filterValue)!];
    }
    data = [
      {
        key: "VCUs",
        value: projects.reduce<number>(
          (previousValue, currentValue) => previousValue + currentValue.cachedVcuQuantity,
          0
        ),
      },
      {
        key: "PIUs",
        value: projects.reduce<number>(
          (previousValue, currentValue) => previousValue + currentValue.cachedPiuQuantity,
          0
        ),
      },
    ];
  } else {
    throw new Error(`Aggregation '${req.aggregate}' not supported - only 'status' and 'units' are supported`);
  }
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return {
    errors: [],
    status: Status.Success,
    data,
  };
}

interface GetProjectTypeRequest {
  projectTypeIdentifier: string;
}

export async function getProjectType(req: GetProjectTypeRequest): Promise<BaseResponseWithSingle<any>> {
  await new Promise((r) => {
    setTimeout(r, 150);
  });

  const data = projectTypes.find((projectType) => projectType.reference === req.projectTypeIdentifier);

  return { status: Status.Success, data };
}
