import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";

import { getInvitationDetails } from "../../../service/publicquery";
import { Status } from "../../../service/Shared";

interface UseAcceptInvitationReturnData {
  urlToken: string | undefined;
  developerName: string;
  accountCreated: boolean;
  email: string;
  navigation: NavigateFunction;
  setAccountCreated: Dispatch<SetStateAction<boolean>>;
}

type AcceptInvitationPageRouteParams = "token";

export function useAcceptInvitation(): UseAcceptInvitationReturnData {
  const { token } = useParams<AcceptInvitationPageRouteParams>();
  const navigation = useNavigate();

  const [accountCreated, setAccountCreated] = useState(false);
  const [developerName, setDeveloperName] = useState("");
  const [email, setEmail] = useState("");

  const fetchData = useCallback(async () => {
    if (token) {
      const res = await getInvitationDetails({
        token,
      });

      if (res.status === Status.Success && res.data) {
        setDeveloperName(res.data.targetOrganisation.displayName);
        setEmail(res.data.targetEmail);
      }
    }
  }, [token]);

  useEffect(() => {
    fetchData();
  }, [fetchData, token]);

  return {
    urlToken: token,
    developerName,
    accountCreated,
    email,
    navigation,
    setAccountCreated,
  };
}
