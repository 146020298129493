import "./DeveloperHeader.css";

import { ArrowFullLeftDarkIcon } from "../../../assets";
import { Avatar, Button } from "../..";
import { ProfileMenu } from "../ProfileMenu";
import { ProfileMenuItem } from "../ProfileMenuItem";
import { useDeveloperHeader } from "./useDeveloperHeader";

interface DeveloperHeaderProps {
  backButtonText?: string;
  handleBackClick?: () => void;
}

export const DeveloperHeader = ({ backButtonText, handleBackClick }: DeveloperHeaderProps): JSX.Element => {
  const { showProfileMenu, setShowProfileMenu, currentUserData, optionList, onOptionSelect } = useDeveloperHeader();

  return currentUserData ? (
    <header className="DeveloperHeader">
      {backButtonText ? (
        <Button
          variant="secondary"
          text={backButtonText}
          icon={<ArrowFullLeftDarkIcon />}
          iconPosition="left"
          onClick={handleBackClick}
        />
      ) : (
        <div />
      )}
      <div>
        <Avatar
          userUuid={currentUserData?.userUuid}
          imageSource={currentUserData?.avatar?.url}
          name={`${currentUserData?.firstName} ${currentUserData?.lastName}`}
          withName
          onClick={() => setShowProfileMenu(true)}
        />
        <ProfileMenu show={showProfileMenu} onClose={() => setShowProfileMenu(false)}>
          {optionList.map((el) => (
            <ProfileMenuItem key={el.id} profileMenuItem={el} onClick={() => onOptionSelect(el)} />
          ))}
        </ProfileMenu>
      </div>
    </header>
  ) : (
    <div />
  );
};
