import { ReactElement } from "react";
import { To } from "react-router-dom";

import { ResultData } from "../../../../service/Shared";
import { ButtonProps, SelectProps, TextInputProps } from "../../../forms";
import { CellFormatterNames } from "../components/CellFormatters";

export type SupportedDataTypeNames = "string" | "number" | "boolean" | "Date";

export type SupportedDataTypes = string | number | boolean | Date;

export enum ColumnCurrency {
  Gbp = "GBP",
  Usd = "USD",
}

export interface ColumnDefinition {
  key: string;
  name?: string;
  dataType: SupportedDataTypeNames;
  visible?: boolean;
  formatter?: CellFormatterNames;
  currency?: ColumnCurrency;
  sortable?: boolean;
  filterable?: boolean;
  minWidth?: number;
  alignment?: "left" | "center" | "right";
}

export interface ColumnDefinitionWithCustomCellFormatter<T> extends ColumnDefinition {
  customCellFormatter?: CellFormatter<T>;
}

export type SortDirection = "asc" | "desc";
export type SortCriteria = {
  key: string;
  direction: SortDirection;
};

export type FilterOperator =
  | "eq"
  | "neq"
  | "gt"
  | "lt"
  | "gte"
  | "lte"
  | "empty"
  | "notEmpty"
  | "startsWith"
  | "contains"
  | "ncontains"
  | "in"
  | "nin";

export type FilterCriteria = {
  key: string;
  operator: FilterOperator;
  value: SupportedDataTypes;
};

export interface ChangeProps {
  sorting: SortCriteria[];
  filtering: FilterCriteria[];
  paging: {
    currentPage: number;
    pageSize: number;
  };
}

export type OnChangeEventHandler = (changeProps: ChangeProps) => Promise<{
  resultData: ResultData[];
  paging: { pageSize: number; totalCount: number; currentPage: number };
}>;

export interface CursorChangeProps {
  sorting: SortCriteria[];
  filtering: FilterCriteria[];
  paging: {
    beforeCursor?: string;
    afterCursor?: string;
    pageSize: number;
  };
}

export type OnCursorChangeEventHandler = (changeProps: CursorChangeProps) => Promise<{
  resultData: ResultData[];
  paging: {
    pageSize: number;
    totalCount: number;
    startCursor: string;
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
}>;

export type CellFormatter<T> = (columnDefinition: ColumnDefinition) => (value: T) => ReactElement;

export type CellFormatterData = Record<string, never>;

export type LinkCellFormatterData = CellFormatterData & {
  to: To;
  text: string;
};

export type ButtonCellFormatterData = CellFormatterData & ButtonProps;

export type InputCellFormatterData = CellFormatterData & TextInputProps;

export type SelectCellFormatterData = CellFormatterData & SelectProps;
