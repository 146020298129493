import "./DataFilter.css";

import { ReactNode } from "react";

import { SearchDarkIcon } from "../../../assets";
import { Autocomplete, Button, Checkbox, FormLabel, KanaTooltip, Select, Slider, TextInput } from "../../forms";
import { Column, Row } from "../../layout";
import { CheckboxFilterValueType, DataFilterValueType, Filter, useDataFilter } from "./useDataFilter";

export interface DataFilterProps<T extends DataFilterValueType> {
  filters: Filter[];
  value?: T;
  onChange?: (value: T) => void;
  children: ReactNode;
}

export const DataFilter = <T extends DataFilterValueType>({
  filters,
  value,
  onChange,
  children,
}: DataFilterProps<T>): JSX.Element => {
  const {
    defaultValue,
    currentValue,
    hasAnyFilterApplied,
    onTextFilterChange,
    onSliderFilterChange,
    onCheckboxGroupFilterChange,
    onSelectFilterChange,
    onAutocompleteFilterChange,
    onClear,
    onFilterClear,
  } = useDataFilter(filters, value, onChange as (value: DataFilterValueType) => void);

  const renderFilter = (filter: Filter): ReactNode => {
    const filterValue = currentValue[filter.name] || defaultValue[filter.name];

    switch (filter.kind) {
      case "text":
        return (
          <TextInput
            label={filter.label}
            name="search"
            value={filterValue as string | null | undefined}
            placeholder={filter.placeholder}
            icon={<SearchDarkIcon />}
            onChange={(newValue) => onTextFilterChange(filter, newValue)}
          />
        );
      case "slider":
        return (
          <div className="DataFilterContainer slider">
            <Slider
              label={filter.label}
              range={filter.range}
              value={filterValue as number[]}
              min={filter.min}
              max={filter.max}
              onChange={(newValue) => onSliderFilterChange(filter, newValue)}
              formatValue={filter.formatValue}
            />
            {filterValue !== defaultValue[filter.name] && (
              <Button variant="secondary" text="Clear" onClick={() => onFilterClear(filter)} />
            )}
          </div>
        );
      case "checkbox":
        return (
          filterValue && (
            <div className="DataFilterContainer">
              <div className="DataFilterCheckboxHeader">
                {filter.tooltipHeader && filter.tooltipText ? (
                  <FormLabel
                    label={filter.label}
                    tooltip={<KanaTooltip tooltipHeader={filter.tooltipHeader} tooltipText={filter.tooltipText} />}
                  />
                ) : (
                  <FormLabel label={filter.label} />
                )}
                {filterValue !== defaultValue[filter.name] && (
                  <Button variant="secondary" text="Clear" onClick={() => onFilterClear(filter)} />
                )}
              </div>
              {filter.checkboxes.map((c, checkboxIndex) => (
                <Checkbox
                  // eslint-disable-next-line react/no-array-index-key
                  key={checkboxIndex}
                  label={c.label}
                  checked={(filterValue as CheckboxFilterValueType)[c.value]}
                  onChange={(event) => onCheckboxGroupFilterChange(filter, event, checkboxIndex)}
                />
              ))}
            </div>
          )
        );
      case "select":
        return (
          <div className="DataFilterContainer select">
            <Select
              label={filter.label}
              placeholder={filter.placeholder}
              value={filterValue as string | null | undefined}
              data={[{ key: "", value: "Please select" }, ...filter.data]}
              onChange={(newValue) => onSelectFilterChange(filter, newValue)}
            />
            {filterValue !== defaultValue[filter.name] && (
              <Button variant="secondary" text="Clear" onClick={() => onFilterClear(filter)} />
            )}
          </div>
        );
      case "autocomplete":
        return (
          <div className="DataFilterContainer autocomplete">
            <Autocomplete
              label={filter.label}
              placeholder={filter.placeholder}
              value={filterValue as string | null | undefined}
              data={[...filter.data]}
              onChange={(newValue) => {
                onAutocompleteFilterChange(filter, newValue);
              }}
            />
            {filterValue !== defaultValue[filter.name] && (
              <Button variant="secondary" text="Clear" onClick={() => onFilterClear(filter)} />
            )}
          </div>
        );
      default:
        throw new Error("Could not determine the filter type");
    }
  };

  return (
    <div className="DataFilter">
      <div className="DataFilterFilters">
        <Row spacingV="ml" justify="between" align="center" className="DataFilterFiltersHeader">
          <Column span={6}>
            <h3>Filter</h3>
          </Column>
          <Column span={6} className="DataFilterFiltersHeaderClear">
            {hasAnyFilterApplied && <Button variant="secondary" text="Clear all" onClick={onClear} />}
          </Column>
        </Row>
        {filters.map((f) => {
          return (
            <Row key={f.name} spacingV="l">
              <Column span={12}>{renderFilter(f)}</Column>
            </Row>
          );
        })}
      </div>
      <div className="DataFilterContent">{children}</div>
    </div>
  );
};
