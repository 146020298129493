import "./IconForm.css";

import { Dispatch } from "react";

import {
  ActionButtons,
  Column,
  DeveloperIcon,
  ErrorBox,
  KanaTooltip,
  Row,
  SingleFileUpload,
} from "../../../../../../widget";
import { OrganisationFormChangedAction } from "../../models";
import { useIconForm } from "./useIconForm";

interface LogoFormProps {
  organisationDefaultIcon: string | null;
  dispatch: Dispatch<OrganisationFormChangedAction>;
}

export const IconForm = ({ organisationDefaultIcon, dispatch }: LogoFormProps): JSX.Element => {
  const { displayedIcon, iconUploadRef, errors, setIcon, handleCancel, handleSubmit } = useIconForm(
    organisationDefaultIcon,
    dispatch
  );

  return (
    <>
      {errors && errors.length > 0 && (
        <Row spacingV="ml">
          <Column span={5}>
            <ErrorBox title="Please solve the below issues before saving" text={errors.map((error) => error.message)} />
          </Column>
        </Row>
      )}
      <form onSubmit={(e) => handleSubmit(e)} className="OrganisationIconForm">
        <Row spacingV="2xl">
          <Column span={5}>
            <SingleFileUpload
              label="New icon"
              informationLabel="The image should be a square, maximum 192px x 192px"
              ref={iconUploadRef}
              maxFileSize={0.5}
              tooltip={
                <KanaTooltip
                  tooltipHeader="Where will the icon show?"
                  tooltipText="This is the icon which will be displayed next to your developer name on the public project list and project details page"
                />
              }
              onChange={(f) => {
                setIcon(f);
              }}
            />
          </Column>
          <Column span={2} offset={1}>
            <div className="IconContainer">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label id="icon">Current icon</label>
              {displayedIcon && <DeveloperIcon developerIcon={displayedIcon} />}
            </div>
          </Column>
        </Row>
        <Row spacingV="2xl">
          <Column span={5}>
            <ActionButtons onCancel={handleCancel} />
          </Column>
        </Row>
      </form>
    </>
  );
};
