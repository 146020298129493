import { Button, Column, ErrorBox, InformationBox, Password, Row } from "../../../../widget";
import { useForgottenPasswordCompleteForm } from "./useForgottenPasswordCompleteForm";

interface ForgottenPasswordCompleteFormProps {
  urlToken: string;
}

export const ForgottenPasswordCompleteForm = ({ urlToken }: ForgottenPasswordCompleteFormProps): JSX.Element => {
  const { newPassword, newPasswordConfirmation, errors, setNewPassword, setNewPasswordConfirmation, handleSubmit } =
    useForgottenPasswordCompleteForm(urlToken);

  return (
    <>
      {errors && errors.length > 0 && (
        <Row spacingV="ml">
          <Column span={12}>
            <ErrorBox title="Please amend your password" text={errors.map((error) => error.message)} />
          </Column>
        </Row>
      )}
      <Row spacingV="ll">
        <Column span={12}>
          <InformationBox variant="info" title="Password requirements">
            <ul>
              <li>Be at least 8 characters long</li>
              <li>
                Not be a commonly used password such as <q>Password1</q>
              </li>
            </ul>
          </InformationBox>
        </Column>
      </Row>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Row spacingV="ll">
          <Column span={12}>
            <Password
              label="New password"
              name="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.currentTarget.value)}
            />
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={12}>
            <Password
              label="Confirm new password"
              name="newPasswordConfirmation"
              value={newPasswordConfirmation}
              onChange={(e) => {
                setNewPasswordConfirmation(e.currentTarget.value);
              }}
            />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <Button fullWidth type="submit" text="Reset password" />
          </Column>
        </Row>
      </form>
    </>
  );
};
