import { Dispatch, useCallback, useEffect, useReducer, useState } from "react";

import { AddressType, CountriesData, FileType } from "../../../../models";
import { getOrganisationDetails, lookupCountries } from "../../../../service/query";
import { Status } from "../../../../service/Shared";
import { useAuth } from "../../../../useAuth";
import {
  OrganisationAddressData,
  OrganisationDetailsData,
  OrganisationFormChangedAction,
  OrganisationFormChangedEnum,
} from "./models";

interface UseOrganisationReturnData {
  organisationDefaultDetails: OrganisationDetailsData | undefined;
  organisationDefaultAddress: OrganisationAddressData | undefined;
  organisationDefaultIcon: string | null;
  organisationDefaultLogo: string | null;
  countries: CountriesData | undefined;
  dataIsLoading: boolean;
  hasUnsavedChanges: OrganisationHasUnsavedChanges;
  dispatch: Dispatch<OrganisationFormChangedAction>;
}

interface OrganisationHasUnsavedChanges {
  address: boolean;
  details: boolean;
  icon: boolean;
  logo: boolean;
}

const reducer = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  prevState: any,
  action: OrganisationFormChangedAction
): OrganisationHasUnsavedChanges => {
  switch (action.type) {
    case OrganisationFormChangedEnum.SET_ADDRESS_FORM_CHANGED: {
      return {
        ...prevState,
        address: action.value,
      };
    }
    case OrganisationFormChangedEnum.SET_DETAILS_FORM_CHANGED: {
      return {
        ...prevState,
        details: action.value,
      };
    }
    case OrganisationFormChangedEnum.SET_ICON_FORM_CHANGED: {
      return {
        ...prevState,
        icon: action.value,
      };
    }
    case OrganisationFormChangedEnum.SET_LOGO_FORM_CHANGED: {
      return {
        ...prevState,
        logo: action.value,
      };
    }
    default: {
      return {
        // unrecognized dispatch action
        ...prevState,
      };
    }
  }
};

export const useOrganisation = (): UseOrganisationReturnData => {
  const { currentOrganisationUuid } = useAuth();

  const [hasUnsavedChanges, dispatch] = useReducer(reducer, {
    address: false,
    details: false,
    icon: false,
    logo: false,
  });
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(true);

  const [organisationDefaultDetails, setOrganisationDefaultDetails] = useState<OrganisationDetailsData>();
  const [organisationDefaultAddress, setOrganisationDefaultAddress] = useState<OrganisationAddressData>();
  const [organisationDefaultIcon, setOrganisationDefaultIcon] = useState<string | null>(null);
  const [organisationDefaultLogo, setOrganisationDefaultLogo] = useState<string | null>(null);
  const [countries, setCountries] = useState<CountriesData | undefined>(undefined);

  const fetchData = useCallback(async () => {
    if (currentOrganisationUuid) {
      const organisationRes = await getOrganisationDetails({
        organisationUuid: currentOrganisationUuid,
      });

      const countriesRes = await lookupCountries();

      if (countriesRes.status === Status.Success && countriesRes.data) {
        setCountries(
          countriesRes.data.map((country) => ({
            key: country.key,
            value: country.valueString,
          }))
        );
      }

      if (organisationRes.status === Status.Success && organisationRes.data) {
        setOrganisationDefaultDetails({
          displayName: organisationRes.data?.displayName,
          phoneNumber: organisationRes.data?.contactPhone,
          contactEmail: organisationRes.data?.contactEmail,
          website: organisationRes.data.websiteUrl,
          twitterUsername: organisationRes.data.socialMediaTwitterUsername,
          linkedinURL: organisationRes.data.socialMediaLinkedInUrl,
          instagramUsername: organisationRes.data.socialMediaInstagramUsername,
          youtubeChannel: organisationRes.data.socialMediaYoutubeChannel,
          facebookURL: organisationRes.data.socialMediaFacebookUrl,
          rowVersion: organisationRes.data.rowVersion,
        });

        const resultAddress = organisationRes.data.addresses.find((a) => a.type === AddressType.Correspondance) || {
          countryCode: "",
          line1: "",
          line2: "",
          postcode: "",
          city: "",
          rowVersion: 1,
        };

        setOrganisationDefaultAddress({
          countryCode: resultAddress.countryCode,
          line1: resultAddress.line1,
          line2: resultAddress.line2,
          postcode: resultAddress.postcode,
          city: resultAddress.city,
          rowVersion: resultAddress.rowVersion,
        });

        setOrganisationDefaultIcon(
          organisationRes.data?.files.find((f) => f.type === FileType.SmallLogo)?.file.url || null
        );

        setOrganisationDefaultLogo(
          organisationRes.data?.files.find((f) => f.type === FileType.LargeLogo)?.file.url || null
        );
      }
    }
  }, []);

  useEffect(() => {
    fetchData().then(() => setDataIsLoading(false));
  }, [fetchData]);

  return {
    organisationDefaultDetails,
    organisationDefaultAddress,
    organisationDefaultIcon,
    organisationDefaultLogo,
    countries,
    dataIsLoading,
    hasUnsavedChanges,
    dispatch,
  };
};
