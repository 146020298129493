// TODO temporarily disable eslint on this file until v1.0 Unwired is complete/services are used
/* eslint @typescript-eslint/no-explicit-any: "off" */
// StatsService

export type SaveEventRequest = {
  metric: string;
  objectType: string;
  objectUuid: string;
  timestamp: Date | null;
};

export const enum Metric {
  Enquiry = "Enquiry",
  View = "View",
}

export const enum ObjectType {
  Project = "Project",
  Organisation = "Organisation",
}
