import { ResultData } from "../../../../service/Shared";
import { ColumnDefinition, FilterCriteria } from "../models/DataGrid.types";

// DD/MM/YYYY - with checks for valid dates e.g. not 29/02/2000
const dateRegex =
  "(((0[1-9]|[12][0-9]|3[01])([/])(0[13578]|10|12)([/])(\\d{4}))|(([0][1-9]|[12][0-9]|30)([/])(0[469]|11)([/])(\\d{4}))|((0[1-9]|1[0-9]|2[0-8])([/])(02)([/])(\\d{4}))|((29)(\\/)(02)([/])([02468][048]00))|((29)([/])(02)([/])([13579][26]00))|((29)([/])(02)([/])([0-9][0-9][0][48]))|((29)([/])(02)([/])([0-9][0-9][2468][048]))|((29)([/])(02)([/])([0-9][0-9][13579][26])))";

export interface clientSideFilterArgs<T> {
  value: T;
  filterValue: T | string;
}

export interface clientSideFilter<T> {
  [key: string]: (filterArgs: clientSideFilterArgs<T>) => boolean;
}

export const stringClientSideFilters: clientSideFilter<string> = {
  contains: (filterArgs: clientSideFilterArgs<string>) => {
    return !filterArgs.filterValue
      ? true
      : (filterArgs.value || "").toLowerCase().indexOf(filterArgs.filterValue.toLowerCase()) !== -1;
  },
  ncontains: (filterArgs: clientSideFilterArgs<string>) =>
    !filterArgs.filterValue
      ? true
      : (filterArgs.value || "").toLowerCase().indexOf(filterArgs.filterValue.toLowerCase()) === -1,
  eq: (filterArgs: clientSideFilterArgs<string>) => {
    return !filterArgs.filterValue
      ? true
      : (filterArgs.value || "").toLowerCase() === filterArgs.filterValue.toLowerCase();
  },
  neq: (filterArgs: clientSideFilterArgs<string>) => {
    return !filterArgs.filterValue
      ? true
      : (filterArgs.value || "").toLowerCase() !== filterArgs.filterValue.toLowerCase();
  },
  empty: (filterArgs: clientSideFilterArgs<string>) => {
    return filterArgs.value === "" || filterArgs.value === null;
  },
  notEmpty: (filterArgs: clientSideFilterArgs<string>) => {
    return filterArgs.value !== "" && filterArgs.value !== null;
  },
  startsWith: (filterArgs: clientSideFilterArgs<string>) =>
    !filterArgs.filterValue
      ? true
      : (filterArgs.value || "").toLowerCase().startsWith(filterArgs.filterValue.toLowerCase()),
};

function toBoolean(s: string | boolean): boolean | null {
  if (typeof s === "string") {
    if (s.toLowerCase() === "true") return true;
    if (s.toLowerCase() === "false") return false;
    return null;
  }
  return s;
}

function toNumber(s: string | number): number | null {
  if (typeof s === "string") {
    if (s.indexOf(".") >= 0) return parseFloat(s);
    return parseInt(s, 10);
  }
  return s;
}

function toDate(s: string | Date): Date | null {
  if (typeof s === "string") {
    const m = s.match(dateRegex);
    if (m && m.length > 0) {
      return new Date(parseInt(m[2], 10), parseInt(m[1], 10), parseInt(m[0], 10));
    }
    return null;
  }
  return s;
}

export const booleanClientSideFilters: clientSideFilter<boolean> = {
  eq: (filterArgs: clientSideFilterArgs<boolean>) => {
    const filterValue = toBoolean(filterArgs.filterValue);
    return filterValue != null ? filterValue === filterArgs.value : true;
  },
  neq: (filterArgs: clientSideFilterArgs<boolean>) => {
    const filterValue = toBoolean(filterArgs.filterValue);
    return filterValue != null ? filterValue !== filterArgs.value : true;
  },
};

export const enumClientSideFilters = [
  {
    name: "in",
    fn: (filterArgs: clientSideFilterArgs<string>) => {
      return !filterArgs.filterValue || !filterArgs.filterValue.length
        ? true
        : filterArgs.filterValue.indexOf(filterArgs.value) !== -1;
    },
  },
  {
    name: "nin",
    fn: (filterArgs: clientSideFilterArgs<string>) => {
      return !filterArgs.filterValue || !filterArgs.filterValue.length
        ? true
        : filterArgs.filterValue.indexOf(filterArgs.value) === -1;
    },
  },
  {
    name: "eq",
    fn: (filterArgs: clientSideFilterArgs<string>) => {
      return filterArgs.filterValue !== null ? filterArgs.filterValue === filterArgs.value : true;
    },
  },
  {
    name: "neq",
    fn: (filterArgs: clientSideFilterArgs<string>) => {
      return filterArgs.filterValue !== null ? filterArgs.filterValue !== filterArgs.value : true;
    },
  },
];

export const numberClientSideFilters: clientSideFilter<number> = {
  gt: (filterArgs: clientSideFilterArgs<number>) => {
    const filterValue = toNumber(filterArgs.filterValue);
    return filterValue != null ? filterArgs.value > filterValue : true;
  },
  gte: (filterArgs: clientSideFilterArgs<number>) => {
    const filterValue = toNumber(filterArgs.filterValue);
    return filterValue != null ? filterArgs.value >= filterValue : true;
  },
  lt: (filterArgs: clientSideFilterArgs<number>) => {
    const filterValue = toNumber(filterArgs.filterValue);
    return filterValue != null ? filterArgs.value < filterValue : true;
  },
  lte: (filterArgs: clientSideFilterArgs<number>) => {
    const filterValue = toNumber(filterArgs.filterValue);
    return filterValue != null ? filterArgs.value <= filterValue : true;
  },
  eq: (filterArgs: clientSideFilterArgs<number>) => {
    const filterValue = toNumber(filterArgs.filterValue);
    return filterValue != null ? filterArgs.value === filterValue : true;
  },
  neq: (filterArgs: clientSideFilterArgs<number>) => {
    const filterValue = toNumber(filterArgs.filterValue);
    return filterValue != null ? filterArgs.value !== filterValue : true;
  },
};

export const dateClientSideFilters: clientSideFilter<Date> = {
  gt: (filterArgs: clientSideFilterArgs<Date>) => {
    const filterValue = toDate(filterArgs.filterValue);
    return filterValue ? filterArgs.value.getTime() > filterValue.getTime() : true;
  },
  gte: (filterArgs: clientSideFilterArgs<Date>) => {
    const filterValue = toDate(filterArgs.filterValue);
    return filterValue != null ? filterArgs.value.getTime() >= filterValue.getTime() : true;
  },
  lt: (filterArgs: clientSideFilterArgs<Date>) => {
    const filterValue = toDate(filterArgs.filterValue);
    return filterValue != null ? filterArgs.value.getTime() < filterValue.getTime() : true;
  },
  lte: (filterArgs: clientSideFilterArgs<Date>) => {
    const filterValue = toDate(filterArgs.filterValue);
    return filterValue != null ? filterArgs.value.getTime() <= filterValue.getTime() : true;
  },
  eq: (filterArgs: clientSideFilterArgs<Date>) => {
    const filterValue = toDate(filterArgs.filterValue);
    return filterValue != null ? filterArgs.value.getTime() === filterValue.getTime() : true;
  },
  neq: (filterArgs: clientSideFilterArgs<Date>) => {
    const filterValue = toDate(filterArgs.filterValue);
    return filterValue != null ? filterArgs.value.getTime() !== filterValue.getTime() : true;
  },
};

export function getClientSideFilter<T>(dataType: string): clientSideFilter<T> {
  switch (dataType) {
    case "string":
      return stringClientSideFilters as unknown as clientSideFilter<T>;
    case "boolean":
      return booleanClientSideFilters as unknown as clientSideFilter<T>;
    case "number":
      return numberClientSideFilters as unknown as clientSideFilter<T>;
    case "Date":
      return dateClientSideFilters as unknown as clientSideFilter<T>;
    default:
      throw new Error(`Couldn't get client side sort function; unexpected dataType ${dataType}`);
  }
}

export const rdgClientSideFilter = (
  data: ResultData[],
  columnDefinitions: ColumnDefinition[],
  filters: readonly FilterCriteria[]
): ResultData[] => {
  if (filters.length === 0) return data;
  // column definition for filters
  const filterColumnDefinitions = new Array<ColumnDefinition>(filters.length);
  for (let i = 0; i < filters.length; i++) {
    const filterColumnDefinition = columnDefinitions.find((cd) => cd.key === filters[i].key);
    if (!filterColumnDefinition)
      throw new Error(`Couldn't find ColumnDefinition for column key ${filters[i].key} in rdgClientSideFilter`);
    filterColumnDefinitions[i] = filterColumnDefinition;
  }
  return data.filter((value) => {
    for (let i = 0; i < filters.length; i++) {
      const clientSideFilter = getClientSideFilter(filterColumnDefinitions[i].dataType);
      const filterFunction = clientSideFilter[filters[i].operator];
      if (
        !filterFunction({
          value: value[filters[i].key],
          filterValue: filters[i].value,
        })
      )
        return false;
    }
    return true;
  });
};
