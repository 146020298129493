import "./MarketingAssets.css";

import { Button, Column, Divider, Row, UnsavedChangesModal } from "../../../../widget";
import {
  AboutUsForm,
  AtAGlanceForm,
  GetInTouchForm,
  HeroForm,
  OurApproachForm,
  OurServicesForm,
  OurValuesForm,
} from "./forms";
import { useMarketingAssets } from "./useMarketingAssets";

const MarketingAssets = (): JSX.Element => {
  const {
    heroFormRef,
    ourValuesFormRef,
    getInTouchFormRef,
    ourApproachFormRef,
    ourServicesFormRef,
    atAGlanceFormRef,
    aboutUsFormRef,
    dataIsLoading,
    hasUnsavedChanges,
    listingRowVersion,
    setListingRowVersion,
    dispatch,
    heroDefaultValues,
    ourValuesDefaultValues,
    getInTouchDefaultValues,
    ourApproachDefaultValues,
    atAGlanceDefaultValues,
    ourServicesDefaultValues,
    aboutUsDefaultValues,
    heroOldDefaultValues,
    ourValuesOldDefaultValues,
    getInTouchOldDefaultValues,
    ourApproachOldDefaultValues,
    atAGlanceOldDefaultValues,
    ourServicesOldDefaultValues,
    aboutUsOldDefaultValues,
    handlePreview,
    previewMode,
    getCurrentFormsData,
  } = useMarketingAssets();

  return (
    <div>
      {!dataIsLoading && (
        <>
          <Row spacingV="sl" justify="between">
            <Column span={12}>
              <div className="MarketingAssetsContainer">
                <h2>Marketing assets</h2>
                <Button text="Preview" onClick={handlePreview} />
              </div>
            </Column>
          </Row>
          {heroDefaultValues && heroOldDefaultValues && (
            <HeroForm
              ref={heroFormRef}
              heroDefaultValues={heroDefaultValues}
              heroOldDefaultValues={heroOldDefaultValues}
              listingRowVersion={listingRowVersion}
              setListingRowVersion={setListingRowVersion}
              dispatch={dispatch}
              getCurrentFormsData={getCurrentFormsData}
            />
          )}

          {ourValuesDefaultValues && ourValuesOldDefaultValues && (
            <>
              <Row spacingV="2xl">
                <Column span={12}>
                  <Divider type="horizontal" />
                </Column>
              </Row>
              <OurValuesForm
                ref={ourValuesFormRef}
                ourValuesDefaultValues={ourValuesDefaultValues}
                ourValuesOldDefaultValues={ourValuesOldDefaultValues}
                listingRowVersion={listingRowVersion}
                setListingRowVersion={setListingRowVersion}
                dispatch={dispatch}
                getCurrentFormsData={getCurrentFormsData}
              />
            </>
          )}

          {getInTouchDefaultValues && getInTouchOldDefaultValues && (
            <>
              <Row spacingV="2xl">
                <Column span={12}>
                  <Divider type="horizontal" />
                </Column>
              </Row>
              <GetInTouchForm
                ref={getInTouchFormRef}
                getInTouchDefaultValues={getInTouchDefaultValues}
                getInTouchOldDefaultValues={getInTouchOldDefaultValues}
                listingRowVersion={listingRowVersion}
                setListingRowVersion={setListingRowVersion}
                dispatch={dispatch}
                getCurrentFormsData={getCurrentFormsData}
              />
            </>
          )}

          {ourApproachDefaultValues && ourApproachOldDefaultValues && (
            <>
              <Row spacingV="2xl">
                <Column span={12}>
                  <Divider type="horizontal" />
                </Column>
              </Row>
              <OurApproachForm
                ref={ourApproachFormRef}
                ourApproachDefaultValues={ourApproachDefaultValues}
                ourApproachOldDefaultValues={ourApproachOldDefaultValues}
                listingRowVersion={listingRowVersion}
                setListingRowVersion={setListingRowVersion}
                dispatch={dispatch}
                getCurrentFormsData={getCurrentFormsData}
              />
            </>
          )}

          {atAGlanceDefaultValues && atAGlanceOldDefaultValues && (
            <>
              <Row spacingV="2xl">
                <Column span={12}>
                  <Divider type="horizontal" />
                </Column>
              </Row>
              <AtAGlanceForm
                ref={atAGlanceFormRef}
                atAGlanceDefaultValues={atAGlanceDefaultValues}
                atAGlanceOldDefaultValues={atAGlanceOldDefaultValues}
                listingRowVersion={listingRowVersion}
                setListingRowVersion={setListingRowVersion}
                dispatch={dispatch}
                getCurrentFormsData={getCurrentFormsData}
              />
            </>
          )}

          {ourServicesDefaultValues && ourServicesOldDefaultValues && (
            <>
              <Row spacingV="2xl">
                <Column span={12}>
                  <Divider type="horizontal" />
                </Column>
              </Row>
              <OurServicesForm
                ref={ourServicesFormRef}
                ourServicesDefaultValues={ourServicesDefaultValues}
                ourServicesOldDefaultValues={ourServicesOldDefaultValues}
                listingRowVersion={listingRowVersion}
                setListingRowVersion={setListingRowVersion}
                dispatch={dispatch}
                getCurrentFormsData={getCurrentFormsData}
              />
            </>
          )}

          {aboutUsDefaultValues && aboutUsOldDefaultValues && (
            <>
              <Row spacingV="2xl">
                <Column span={12}>
                  <Divider type="horizontal" />
                </Column>
              </Row>
              <AboutUsForm
                ref={aboutUsFormRef}
                aboutUsDefaultValues={aboutUsDefaultValues}
                aboutUsOldDefaultValues={aboutUsOldDefaultValues}
                listingRowVersion={listingRowVersion}
                setListingRowVersion={setListingRowVersion}
                dispatch={dispatch}
                getCurrentFormsData={getCurrentFormsData}
              />
            </>
          )}
          {/* Temporarily disabling UnsavedChangesModal ENG-664 - by adding false check */}
          <UnsavedChangesModal
            hasUnsavedChanges={
              false && !previewMode && Object.values(hasUnsavedChanges).some((value) => value === true)
            }
          />
        </>
      )}
    </div>
  );
};

export default MarketingAssets;
