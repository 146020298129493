import { PublicCodeComparisonTemplate, PublicTemplate } from "../../../templates";

const CodeComparison = (): JSX.Element => {
  return (
    <PublicTemplate activeElement="Codes" isContentUnwrapped>
      <PublicCodeComparisonTemplate />
    </PublicTemplate>
  );
};

export default CodeComparison;
