/*
 * Converters between the data formats required by the DataGrid, and other
 * application data formats
 */

import { ComponentType } from "react";
import { Column as RdgColumn, FormatterProps as RdgFormatterProps } from "react-data-grid";

import { ResultData } from "../../../../service/Shared";
import { cellFormatters } from "../components/CellFormatters";
import { HeaderRenderer, onFilterChangedEvent } from "../components/HeaderRenderer";
import {
  ColumnDefinition,
  ColumnDefinitionWithCustomCellFormatter,
  FilterCriteria,
  SupportedDataTypes,
} from "../models/DataGrid.types";

// maps `FilterCriteria` in flattened DataGrid format to a nested value
// e.g. `{ "a.b.c": { operator: "eq", value: "John" } }`
// becomes `{ a: { b: { c: { operator: "eq", value: "John" } } } }`
// NCU: this function is dynamic by nature -> `any` is required
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mapFilterCriteria(filterCriteria: FilterCriteria[]): any {
  // NCU: this function is dynamic by nature -> `any` is required
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const all: any = {};
  for (let i = 0; i < filterCriteria.length; i++) {
    // NCU: this function is dynamic by nature -> `any` is required
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let current: any = all;
    filterCriteria[i].key.split(".").forEach((prop) => {
      current[prop] = current[prop] || {};
      current = current[prop];
    });
    current.operator = filterCriteria[i].operator;
    current.value = filterCriteria[i].value;
  }
  return all;
}

export const mapColumnDefinitionToRdgColumn = (
  columns: ColumnDefinition[],
  dataGridSortable: boolean,
  dataGridFilterable: boolean,
  onFilterChanged: onFilterChangedEvent
): RdgColumn<ResultData>[] => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return columns.map((columnDefinition) => {
    let formatter: ComponentType<RdgFormatterProps<ResultData>> | undefined;
    if (columnDefinition.formatter !== undefined) {
      let firstLevelFormatterFn = cellFormatters[columnDefinition.formatter];
      if (firstLevelFormatterFn === undefined && columnDefinition.formatter === "custom") {
        firstLevelFormatterFn = (columnDefinition as ColumnDefinitionWithCustomCellFormatter<SupportedDataTypes>)
          .customCellFormatter;
      }
      if (firstLevelFormatterFn === undefined)
        throw new Error(
          `Column definition formatter is ${columnDefinition.formatter}; expecting a "customCellFormatter" to be supplied, but was undefined`
        );
      const secondLevelFormatterFn = firstLevelFormatterFn(columnDefinition);
      const { key } = columnDefinition;
      formatter = (props) => secondLevelFormatterFn(props.row[key] as never);
    }
    return {
      key: columnDefinition.key,
      name: columnDefinition.name ?? columnDefinition.key[0].toUpperCase() + columnDefinition.key.substring(1),
      minWidth: columnDefinition.minWidth,
      formatter,
      sortable: (columnDefinition.sortable === undefined && dataGridSortable) || columnDefinition.sortable,
      headerRenderer: HeaderRenderer<ResultData, void>({
        onFilterChanged,
        columnDefinition,
        dataGridFilterable,
        dataGridSortable,
      }),
    };
  });
};
