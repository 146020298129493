import { logError } from "../service/error";
import { recursivelyRemoveEmptyStrings } from "./rest";

export const capitalize = (str: string): string => {
  return str.length ? str[0].toUpperCase() + str.slice(1).toLowerCase() : str;
};

export const range = (start: number, end: number): number[] => {
  const length = end - start + 1;
  /*
  	Create an array of certain length and set the elements within it from
    start value to end value.
  */
  return Array.from({ length }, (_, idx) => idx + start);
};

export const wordCount = (str: string): number => {
  const count = str.match(/\S+/g);
  return count ? count.length : 0;
};

export const formatNumber = (val: number | undefined): string | undefined => {
  if (val === undefined || val === null) {
    return undefined;
  }

  return val.toLocaleString("en-GB");
};

export const thousandsFormatter = new Intl.NumberFormat("en", {
  notation: "compact",
});

export const stringToNumber = (str: string): number => {
  let number = 0;
  for (let i = 0; i < str.length; i++) {
    number += str.charCodeAt(i);
  }
  return number;
};

export const isFileImage = (file: File): boolean => {
  return file && file.type.split("/")[0] === "image";
};

export const isMimeTypeImage = (mimeType: string): boolean => {
  return mimeType.split("/")[0] === "image";
};

export const arraySwap = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  arr: any[],
  toBeSwappedIdx: number,
  toSwapWithIdx: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any[] => {
  const tmp = arr[toBeSwappedIdx];
  // eslint-disable-next-line no-param-reassign
  arr[toBeSwappedIdx] = arr[toSwapWithIdx];
  // eslint-disable-next-line no-param-reassign
  arr[toSwapWithIdx] = tmp;
  return arr;
};

export const arrayMove = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  arr: any[],
  from: number,
  to: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any[] => {
  arr.splice(to, 0, arr.splice(from, 1)[0]);
  return arr;
};

export const getCountryNameByCode = (addressCountryCode: string | null | undefined): string | null | undefined => {
  if (addressCountryCode) {
    switch (addressCountryCode) {
      case "GB-ENG":
        return "England";
      case "GB-NIR":
        return "Northern Ireland";
      case "GB-SCT":
        return "Scotland";
      case "GB-WLS":
        return "Wales";
      default:
        logError({
          error: `No country name has been found for this country code: ${addressCountryCode}`,
        });
        return null;
    }
  }
  return addressCountryCode;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const areObjectsDeepEqual = (arg1: any, arg2: any): boolean => {
  const noEmptyStrArg1 = recursivelyRemoveEmptyStrings(arg1);
  const noEmptyStrArg2 = recursivelyRemoveEmptyStrings(arg2);

  if (Object.prototype.toString.call(noEmptyStrArg1) === Object.prototype.toString.call(noEmptyStrArg2)) {
    if (
      Object.prototype.toString.call(noEmptyStrArg1) === "[object Object]" ||
      Object.prototype.toString.call(noEmptyStrArg1) === "[object Array]"
    ) {
      if (Object.keys(noEmptyStrArg1).length !== Object.keys(noEmptyStrArg2).length) {
        return false;
      }
      return Object.keys(noEmptyStrArg1).every((key) => areObjectsDeepEqual(noEmptyStrArg1[key], noEmptyStrArg2[key]));
    }
    return noEmptyStrArg1 === noEmptyStrArg2;
  }
  return false;
};

export const getImageHeight = (url: string): Promise<number> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    try {
      img.onload = () => resolve(img.height);
      img.onerror = (error) => reject(error);
      img.src = url;
    } catch (e) {
      logError({
        error: `The image with url ${url} could not be loaded`,
      });
    }
  });
};

export const getHostFromURL = (url: string): string => {
  let host = "";

  try {
    host = new URL(url).host;
  } catch (e) {
    logError({
      error: `Url ${url} is not valid`,
    });
  }

  return host;
};

export const getFormattedDate = (date?: Date): string | null => {
  if (!date) return null;
  const formattedDate = `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${date.getFullYear()}`;
  return formattedDate;
};

export const isEven = (value: number): boolean => value % 2 === 0;
