import { useCallback, useEffect, useState } from "react";

import { FileType, SocialMediaData } from "../../../models";
import { searchProjects } from "../../../service/publicquery";
import { getOrganisationDetails } from "../../../service/query";
import { Status } from "../../../service/Shared";
import { useAuth } from "../../../useAuth";
import { DetailsMapConstants } from "../constants";

interface UsePublicDeveloperDetailsPreviewTemplateReturnData {
  dataIsLoading: boolean;
  currentOrganisationUuid: string | undefined;
  organisationDetails: OrganisationDetails | undefined;
  organisationSocialMedia: SocialMediaData | undefined;
  projectsCoordinates: string[] | undefined;
}

export interface OrganisationDetails {
  displayName: string | null;
  logo: string | null;
  websiteUrl: string | null;
}

export function usePublicDeveloperDetailsPreviewTemplate(): UsePublicDeveloperDetailsPreviewTemplateReturnData {
  const { currentOrganisationUuid } = useAuth();

  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [projectsCoordinates, setProjectsCoordinates] = useState<string[] | undefined>();
  const [organisationDetails, setOrganisationDetails] = useState<OrganisationDetails | undefined>();
  const [organisationSocialMedia, setOrganisationSocialMedia] = useState<SocialMediaData | undefined>();

  const fetchData = useCallback(async (): Promise<void> => {
    if (currentOrganisationUuid) {
      const res = await getOrganisationDetails({
        organisationUuid: currentOrganisationUuid,
      });

      if (res.status === Status.Success && res.data) {
        const logo = res.data.files.find((f) => f.type === FileType.LargeLogo)?.file.url || null;

        setOrganisationDetails({
          displayName: res.data.displayName,
          logo,
          websiteUrl: res.data.websiteUrl,
        });

        setOrganisationSocialMedia({
          twitterUsername: res.data.socialMediaTwitterUsername,
          linkedinURL: res.data.socialMediaLinkedInUrl,

          instagramUsername: res.data.socialMediaInstagramUsername,
          youtubeChannel: res.data.socialMediaYoutubeChannel,
          facebookURL: res.data.socialMediaFacebookUrl,
        });
      }

      const projectsRes = await searchProjects({
        paging: {
          limit: DetailsMapConstants.MAXIMUM_MARKERS,
        },
        filter: {
          results: {
            developer: {
              uuid: { operator: "eq", value: currentOrganisationUuid },
            },
          },
        },
      });

      if (projectsRes.status === Status.Success && projectsRes.data) {
        setProjectsCoordinates(projectsRes.data.results.flatMap((project) => project.locationCoordinates || []));
      }
    }
  }, []);

  useEffect(() => {
    fetchData().then(() => setDataIsLoading(false));
  }, [fetchData]);

  return {
    dataIsLoading,
    currentOrganisationUuid,
    organisationDetails,
    organisationSocialMedia,
    projectsCoordinates,
  };
}
