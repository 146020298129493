import "./ProfileMenu.css";

import React, { useRef } from "react";

import { useClickOutside } from "../../../utils";

interface ProfileMenuProps {
  show: boolean;
  onClose: () => void;
  children: React.ReactNode;
  position?: { top?: string; right?: string; bottom?: string; left?: string };
}

export const ProfileMenu = ({ show, onClose, position, children }: ProfileMenuProps): JSX.Element | null => {
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => {
    onClose();
  });

  return show ? (
    <div ref={wrapperRef} className="ProfileMenuWrapper">
      <div className="ProfileMenuContainer" style={{ ...position }}>
        {children}
      </div>
    </div>
  ) : null;
};
