import "./TotalSequestrationKPI.css";

import { useMemo } from "react";

import { KPITooltip } from "../components";

interface TotalSequestrationProps {
  totalSequestration: number;
  totalReduction: number;
  isPcc: boolean;
}

const getProjectSize = (totalSequestration: number): string => {
  if (totalSequestration > 10000) return "Large";
  if (totalSequestration > 2500) return "Medium";
  if (totalSequestration >= 0) return "Small";
  return "";
};

export const TotalSequestrationKPI = ({
  totalSequestration,
  totalReduction,
  isPcc,
}: TotalSequestrationProps): JSX.Element => {
  const steps = useMemo(
    () => [
      {
        tooltipText: "Large",
        offsetWidth: 55,
        offsetHeight: -120,
      },
      {
        tooltipText: "Medium",
        offsetWidth: 45,
        offsetHeight: -95,
      },
      {
        tooltipText: "Small",
        offsetWidth: 25,
        offsetHeight: -70,
      },
    ],
    []
  );
  return (
    <div className="TotalSequestrationContainer">
      <div className={`TotalSequestrationGraph ${getProjectSize(isPcc ? totalReduction : totalSequestration)}`}>
        {steps.map((step) => {
          return (
            <KPITooltip
              key={step.tooltipText}
              text={step.tooltipText}
              offsetWidth={step.offsetWidth}
              offsetHeight={step.offsetHeight}
            >
              <div className={`GraphSection ${step.tooltipText}`} />
            </KPITooltip>
          );
        })}
      </div>
      <div className="TotalSequestrationHeaderArea">
        {/* eslint-disable-next-line no-nested-ternary */}
        {isPcc ? (
          totalReduction !== undefined && totalReduction !== null ? (
            <h2>{(totalReduction / 1000).toFixed(1)}k tCO2e</h2>
          ) : (
            <h2>N/A</h2>
          )
        ) : totalSequestration !== undefined && totalSequestration !== null ? (
          <h2>{(totalSequestration / 1000).toFixed(1)}k tCO2e</h2>
        ) : (
          <h2>N/A</h2>
        )}
        <p className="body2">Total {isPcc ? "Reduction" : "Sequestration"}</p>
        <p className="body3">{getProjectSize(isPcc ? totalReduction : totalSequestration)} size project</p>
      </div>
    </div>
  );
};
