import raf from "rc-util/lib/raf";
import { MutableRefObject, useEffect, useRef } from "react";

type TooltipHandle = {
  forcePopupAlign: () => void;
};

interface UseHandleTooltipRenderReturnData {
  tooltipRef: MutableRefObject<TooltipHandle | undefined>;
}

export function useHandleTooltipRender(value: number): UseHandleTooltipRenderReturnData {
  const tooltipRef = useRef<TooltipHandle>();
  const rafRef = useRef<number | null>(null);

  useEffect(() => {
    rafRef.current = raf(() => {
      tooltipRef.current?.forcePopupAlign();
    });
  }, [value]);

  return {
    tooltipRef,
  };
}
