import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";

import { searchProjects } from "../../../service/publicquery";
import { Status } from "../../../service/Shared";

interface useHorizontalProjectCardsReturnData {
  hasLeftArrow: boolean;
  hasRightArrow: boolean;
  onRightArrowClick: () => void;
  onLeftArrowClick: () => void;
  cardsRef: MutableRefObject<null | HTMLDivElement>;
  onScroll: () => void;
  codeUuids: string[] | undefined;
}
const PROJECT_CARD_WIDTH = 301;
export const useHorizontalProjectCards = (codeUuid?: string): useHorizontalProjectCardsReturnData => {
  const cardsRef = useRef<HTMLDivElement>(null);
  const [hasLeftArrow, setHasLeftArrow] = useState(false);
  const [hasRightArrow, setHasRightArrow] = useState(false);
  const [codeUuids, setCodeUuids] = useState<string[]>();

  const fetchProjects = useCallback(async (): Promise<void> => {
    if (setCodeUuids) {
      const res = await searchProjects({
        paging: { limit: 10 },
      });
      if (res.status === Status.Success && res.data) {
        const codeUuidsValues = res.data.results?.map((d) => d.standard.uuid);
        const uniqueCodeUuidsValues = codeUuidsValues.filter((uuid, index) => codeUuidsValues.indexOf(uuid) === index);
        setCodeUuids(uniqueCodeUuidsValues?.filter((uuid) => uuid !== codeUuid));
      }
    }
  }, []);

  const calcArrowsValue = (): void => {
    if (cardsRef.current) {
      setHasLeftArrow(!!cardsRef.current.scrollLeft);
      setHasRightArrow(cardsRef.current.scrollLeft < cardsRef.current.scrollWidth - cardsRef.current.clientWidth);
    }
  };

  const onScroll = (): void => {
    calcArrowsValue();
  };

  const onLeftArrowClick = (): void => {
    if (cardsRef.current) {
      cardsRef.current.scrollLeft -= PROJECT_CARD_WIDTH;
    }
  };

  const onRightArrowClick = (): void => {
    if (cardsRef.current) {
      cardsRef.current.scrollLeft += PROJECT_CARD_WIDTH;
    }
  };

  useEffect(() => {
    window.addEventListener("resize", calcArrowsValue);
    return () => {
      window.removeEventListener("resize", calcArrowsValue);
    };
  }, []);

  useEffect(() => {
    calcArrowsValue();
    fetchProjects();
  }, []);

  return {
    hasLeftArrow,
    hasRightArrow,
    onRightArrowClick,
    onLeftArrowClick,
    cardsRef,
    onScroll,
    codeUuids,
  };
};
