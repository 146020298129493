import { RcFile } from "rc-upload/lib/interface";
import { Dispatch, ForwardedRef, SetStateAction, useEffect, useId, useImperativeHandle, useState } from "react";

import { PreviewFile } from "../../../../models";
import { arrayMove, arraySwap } from "../../../../utils";
import { Toast } from "../../../general";

interface useMultipleFileUploadReturnData {
  id: string;
  previewFiles: PreviewFile[];
  onDragOverDropZone: boolean;
  setOnDragOverDropZone: Dispatch<SetStateAction<boolean>>;
  onAction: (file: RcFile) => string;
  onRemoveFile: (file: PreviewFile) => void;
  onFileDrop: (droppedOnFileId: string, draggedFileId: string) => void;
}

export type DragAndDropOrderBehavior = "swap" | "move";

interface useMultipleFileUploadProps {
  onFilesChange: (previewFiles: PreviewFile[]) => void;
  inputFiles?: PreviewFile[];
  orderBehavior: DragAndDropOrderBehavior;
  maxFileCount: number;
  maxFileSize: number;
  forwardedRef: ForwardedRef<unknown>;
}

export const useMultipleFileUpload = ({
  onFilesChange,
  inputFiles,
  orderBehavior,
  maxFileCount,
  maxFileSize,
  forwardedRef,
}: useMultipleFileUploadProps): useMultipleFileUploadReturnData => {
  const id = useId();
  const [originalFiles, setOriginalFiles] = useState<PreviewFile[]>(inputFiles || []);
  const [previewFiles, setPreviewFiles] = useState<PreviewFile[]>(inputFiles || []);
  const [onDragOverDropZone, setOnDragOverDropZone] = useState(false);

  useImperativeHandle(forwardedRef, () => ({
    clearInput() {
      setPreviewFiles(originalFiles || []);
    },
    save(images: PreviewFile[]) {
      setOriginalFiles(images);
      setPreviewFiles(images);
    },
    setNewPreviewFiles(images: PreviewFile[]) {
      setPreviewFiles(images);
    },
  }));

  const onAction = (file: RcFile): string => {
    const fileSize = Number((file.size / 1024 / 1024).toFixed(4)); // MB
    if (fileSize > maxFileSize) {
      Toast.error({
        message: `Please select a file which is less than ${maxFileSize}MB`,
      });
      return "";
    }

    setPreviewFiles((files) => {
      if (files.length < maxFileCount) {
        return [
          ...files,
          {
            file,
            mimeType: file.type,
            uuid: file.uid,
            url: URL.createObjectURL(file),
            filename: file.name,
          },
        ];
      }
      Toast.error({
        message: `You cannot upload more than ${maxFileCount} files`,
      });
      return files;
    });

    return "";
  };

  const onRemoveFile = (file: PreviewFile): void => {
    setPreviewFiles((files) => [...files.filter((f) => f.uuid !== file.uuid)]);
    URL.revokeObjectURL(file.url);
  };

  const onFileDrop = (droppedOnFileId: string, draggedFileId: string): void => {
    setPreviewFiles((files) => {
      const droppedOnFileIdx = files.findIndex((el) => el.uuid === droppedOnFileId);
      const draggedFileIdx = files.findIndex((el) => el.uuid === draggedFileId);

      if (orderBehavior === "swap") {
        return arraySwap([...files], droppedOnFileIdx, draggedFileIdx);
      }
      return arrayMove([...files], draggedFileIdx, droppedOnFileIdx);
    });
  };

  useEffect(() => {
    onFilesChange(previewFiles);
  }, [previewFiles]);

  // Cleanup the created object Urls
  useEffect(() => {
    return () => {
      previewFiles.map((el) => URL.revokeObjectURL(el.url));
    };
  }, []);

  return {
    id,
    previewFiles,
    onDragOverDropZone,
    setOnDragOverDropZone,
    onAction,
    onRemoveFile,
    onFileDrop,
  };
};
