export type MarketingAssetsFormChangedAction =
  | {
      type: MarketingAssetsFormChangedEnum.SET_HERO_FORM_CHANGED;
      value: boolean;
    }
  | {
      type: MarketingAssetsFormChangedEnum.SET_OUR_VALUES_FORM_CHANGED;
      value: boolean;
    }
  | {
      type: MarketingAssetsFormChangedEnum.SET_GET_IN_TOUCH_FORM_CHANGED;
      value: boolean;
    }
  | {
      type: MarketingAssetsFormChangedEnum.SET_OUR_APPROACH_FORM_CHANGED;
      value: boolean;
    }
  | {
      type: MarketingAssetsFormChangedEnum.SET_AT_A_GLANCE_FORM_CHANGED;
      value: boolean;
    }
  | {
      type: MarketingAssetsFormChangedEnum.SET_OUR_SERVICES_FORM_CHANGED;
      value: boolean;
    }
  | {
      type: MarketingAssetsFormChangedEnum.SET_ABOUT_US_FORM_CHANGED;
      value: boolean;
    };

export enum MarketingAssetsFormChangedEnum {
  SET_HERO_FORM_CHANGED,
  SET_OUR_VALUES_FORM_CHANGED,
  SET_GET_IN_TOUCH_FORM_CHANGED,
  SET_OUR_APPROACH_FORM_CHANGED,
  SET_AT_A_GLANCE_FORM_CHANGED,
  SET_OUR_SERVICES_FORM_CHANGED,
  SET_ABOUT_US_FORM_CHANGED,
}
