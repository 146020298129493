import "../ProjectDetails.css";

import { Column, ReadonlyText, Row, TextInput } from "../../..";

interface DatesProps {
  implementationDate: string | null | undefined;
  startDate: string | null | undefined;
  duration: string | null | undefined;
  onImplementationDateChange?: (value: string) => void;
  onStartDateChange?: (value: string) => void;
  readOnly?: boolean;
  isPcc: boolean;
  isUnderDevelopment?: boolean;
}

export const Dates = ({
  implementationDate,
  startDate,
  duration,
  onImplementationDateChange,
  onStartDateChange,
  readOnly = true,
  isPcc,
  isUnderDevelopment = false,
}: DatesProps): JSX.Element => {
  return (
    <>
      <Row spacingV="ll">
        <Column span={12}>
          <h2>Dates</h2>
          {readOnly && isUnderDevelopment && !(implementationDate && startDate) && (
            <p className="SectionText body1">
              This project is under development so some data might not be available yet
            </p>
          )}
        </Column>
      </Row>
      <Row spacingV="ll">
        {!isPcc && (
          <Column span={5}>
            {readOnly ? (
              <ReadonlyText
                label="Implementation date"
                value={implementationDate || "No date available"}
                noDataAvailable={!implementationDate}
              />
            ) : (
              <TextInput
                label="Implementation date"
                name="implementationDate"
                value={implementationDate}
                onChange={onImplementationDateChange}
              />
            )}
          </Column>
        )}
        <Column span={5} offset={isPcc ? 0 : 1}>
          {readOnly ? (
            <ReadonlyText label="Start date" value={startDate || "No date available"} noDataAvailable={!startDate} />
          ) : (
            <TextInput label="Start date" name="startDate" value={startDate} onChange={onStartDateChange} />
          )}
        </Column>
        {isPcc && (
          <Column span={5} offset={1}>
            <ReadonlyText label="Project duration" value={`${duration} years`} />
          </Column>
        )}
      </Row>
      {!isPcc && (
        <Row>
          <Column span={12}>
            <ReadonlyText
              label="Project duration"
              value={duration ? `${duration} years` : "Not available"}
              noDataAvailable={!duration}
            />
          </Column>
        </Row>
      )}
    </>
  );
};
