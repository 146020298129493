import { DeveloperErrorTemplate } from "../../../templates/DeveloperErrorTemplate/DeveloperErrorTemplate";

export const DeveloperError403 = (): JSX.Element => {
  return (
    <DeveloperErrorTemplate
      errorText="The page or resource you are trying to view has restricted access."
      errorCode="403"
    />
  );
};
