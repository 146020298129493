// TODO Commented column values were the original spacing values,
// they were changed so that Location, Dates and Monitoring sections are always aligned
import "../ProjectDetails.css";

import { Column, ReadonlyText, Row, TextInput } from "../../..";

interface MonitoringProps {
  firstAssessmentDate: string | null | undefined;
  secondAssessmentDate: string | null | undefined;
  frequency: string | null | undefined;
  onFirstAssessmentDateChange?: (value: string) => void;
  onSecondAssessmentDateChange?: (value: string) => void;
  onFrequencyChange?: (value: string) => void;
  readOnly?: boolean;
  isUnderDevelopment?: boolean;
}

export const Monitoring = ({
  firstAssessmentDate,
  secondAssessmentDate,
  frequency,
  onFirstAssessmentDateChange,
  onSecondAssessmentDateChange,
  onFrequencyChange,
  readOnly = true,
  isUnderDevelopment = false,
}: MonitoringProps): JSX.Element => {
  return (
    <>
      <Row spacingV="ll">
        <Column span={12}>
          <h2>Monitoring</h2>
          {readOnly &&
            !(firstAssessmentDate || secondAssessmentDate || frequency) &&
            (isUnderDevelopment ? (
              <p className="SectionText body1">
                This project is under development so no monitoring data is available yet
              </p>
            ) : (
              <p className="SectionText body1">No monitoring data is available for this project</p>
            ))}
        </Column>
      </Row>
      {readOnly ? (
        (firstAssessmentDate || secondAssessmentDate || frequency) && (
          <Row spacingV="ll">
            {/* <Column span={5}> */}
            <Column span={6}>
              <ReadonlyText
                label="First assessment date"
                value={firstAssessmentDate || "No date available"}
                noDataAvailable={!firstAssessmentDate}
              />
            </Column>
            {/* <Column span={5} offset={firstAssessmentDate ? 1 : 0}> */}
            <Column span={6}>
              <ReadonlyText
                label="Second assessment date"
                value={secondAssessmentDate || "No date available"}
                noDataAvailable={!secondAssessmentDate}
              />
            </Column>
          </Row>
        )
      ) : (
        <Row spacingV="ll">
          {/* <Column span={5}> */}
          <Column span={6}>
            <TextInput
              label="First assessment date"
              name="firstAssessmentDate"
              value={firstAssessmentDate}
              onChange={onFirstAssessmentDateChange}
            />
          </Column>
          {/* <Column span={5} offset={1}> */}
          <Column span={6}>
            <TextInput
              label="Second assessment date"
              name="secondAssessmentDate"
              value={secondAssessmentDate}
              onChange={onSecondAssessmentDateChange}
            />
          </Column>
        </Row>
      )}
      <Row>
        {/* <Column span={5}> */}
        <Column span={12}>
          {readOnly ? (
            (firstAssessmentDate || secondAssessmentDate || frequency) && (
              <ReadonlyText
                label="Subsequent frequency"
                value={(frequency ? `${frequency} years` : frequency) || "No frequency available"}
                noDataAvailable={!frequency}
              />
            )
          ) : (
            <TextInput label="Subsequent frequency" name="frequency" value={frequency} onChange={onFrequencyChange} />
          )}
        </Column>
      </Row>
    </>
  );
};
