import "../DeveloperSingleCardTemplate/DeveloperSingleCardTemplate.css";
import "./DeveloperSingleCardWithTabsTemplate.css";

import { NavigationActiveElement, TabsList } from "../../models";
import { Column, DeveloperHeader, LeftNavigation, Row, Tabs } from "../../widget";

interface DeveloperSingleCardWithTabsTemplateProps {
  activeElement: NavigationActiveElement;
  pageTitle: string | undefined;
  tabsList: TabsList;
}

export const DeveloperSingleCardWithTabsTemplate = ({
  activeElement,
  pageTitle,
  tabsList,
}: DeveloperSingleCardWithTabsTemplateProps): JSX.Element => {
  return (
    <div className="DeveloperSingleCardTemplate">
      <div className="DeveloperSingleCardTemplateNavigation">
        <LeftNavigation activeElement={activeElement} />
      </div>
      <div className="DeveloperSingleCardTemplateContainer">
        <DeveloperHeader />
        <Row spacingV="ll">
          <Column span={12}>
            <h1>{pageTitle}</h1>
          </Column>
        </Row>
        <Tabs className="TemplateTabs" tabsList={tabsList} />
      </div>
    </div>
  );
};
