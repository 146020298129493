import { Column, Row } from "../../../../../widget";
import { IssuancesForm } from "./form";

const Issuances = (): JSX.Element => {
  return (
    <>
      <Row spacingV="ll">
        <Column span={12}>
          <h2>Issuances</h2>
        </Column>
      </Row>
      <IssuancesForm />
    </>
  );
};

export default Issuances;
