import { Dispatch, FormEvent, ForwardedRef, SetStateAction, useCallback, useEffect, useRef, useState } from "react";

import { FileType, FileUploadHandle } from "../../../../../../models";
import { uploadOrganisationFile } from "../../../../../../service/organisation";
import { ServiceError, Status } from "../../../../../../service/Shared";
import { useAuth } from "../../../../../../useAuth";
import { Toast } from "../../../../../../widget";
import { OrganisationFormChangedAction, OrganisationFormChangedEnum } from "../../models";

interface UseIconFormReturnData {
  iconUploadRef: ForwardedRef<FileUploadHandle>;
  displayedIcon: string | null;
  errors: ServiceError[] | undefined;
  setIcon: Dispatch<SetStateAction<File | null>>;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  handleCancel: () => void;
}

export const useIconForm = (
  organisationDefaultIcon: string | null,
  dispatch: Dispatch<OrganisationFormChangedAction>
): UseIconFormReturnData => {
  const { currentOrganisationUuid } = useAuth();

  const [submittedValues, setSubmittedValues] = useState<File | null>(null);

  const iconUploadRef = useRef<FileUploadHandle>(null);
  const [icon, setIcon] = useState<File | null>(null);
  const [displayedIcon, setDisplayedIcon] = useState<string | null>(organisationDefaultIcon);

  const [errors, setErrors] = useState<ServiceError[] | undefined>();

  const resetForm = (): void => {
    if (iconUploadRef.current) {
      iconUploadRef.current.clearInput();
      setIcon(null);
      setSubmittedValues(null);
      setErrors([]);
    }
  };

  const getCurrentFormData = useCallback((): File | null => {
    return icon;
  }, [icon]);

  useEffect(() => {
    dispatch({
      type: OrganisationFormChangedEnum.SET_ICON_FORM_CHANGED,
      value: submittedValues !== getCurrentFormData(),
    });
  }, [icon, submittedValues]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setErrors([]);

    if (currentOrganisationUuid && icon) {
      const res = await uploadOrganisationFile({
        organisationUuid: currentOrganisationUuid,
        type: FileType.SmallLogo,
        file: icon,
      });

      if (res.status === Status.Success && res.data && iconUploadRef.current) {
        Toast.success({ message: "Organisation icon changed successfully" });
        setSubmittedValues(icon);
        iconUploadRef.current.clearInput();
        setDisplayedIcon(res.data?.file.url);
      }

      if (res.status === Status.Error) {
        setErrors(res.errors);
      }
    }
  };

  const handleCancel = (): void => {
    resetForm();
  };

  return {
    iconUploadRef,
    displayedIcon,
    errors,
    setIcon,
    handleSubmit,
    handleCancel,
  };
};
