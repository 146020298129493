import { Button, Column, ErrorBox, Row, TextInput } from "../../../../widget";
import { useForgottenPasswordForm } from "./useForgottenPasswordForm";

interface ResetPasswordFormProps {
  onSuccess: (e: boolean) => void;
}

export const ForgottenPasswordForm = ({ onSuccess }: ResetPasswordFormProps): JSX.Element => {
  const { email, errors, setEmail, handleSubmit } = useForgottenPasswordForm(onSuccess);

  return (
    <>
      {errors && errors.length > 0 && (
        <Row spacingV="ll">
          <Column span={12}>
            <ErrorBox text={errors.map((error) => error.message)} />
          </Column>
        </Row>
      )}
      <form onSubmit={(e) => handleSubmit(e)}>
        <Row spacingV="ll">
          <Column span={12}>
            <TextInput label="Email" type="email" name="email" value={email} onChange={(value) => setEmail(value)} />
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={12}>
            <Button fullWidth type="submit" text="Send Email" />
          </Column>
        </Row>
      </form>
    </>
  );
};
