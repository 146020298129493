import "./PublicProjectTypeTemplate.css";

import { ArrowFullLeftDarkIcon } from "../../assets";
import { PROJECT_TYPES_PILL_VARIANT_MAP } from "../../constants";
import { ProjectTypeData } from "../../route/public/project-type/useProjectType";
import { Button, Column, Pill, Row } from "../../widget";
import { ContentSection, RichContentSection } from "../components";
import { TypeRatingsSection } from "./components";
import { usePublicProjectTypeTemplate } from "./usePublicProjectTypeTemplate";

interface PublicProjectTypeTemplateProps {
  projectTypeData: ProjectTypeData;
}

export const PublicProjectTypeTemplate = ({ projectTypeData }: PublicProjectTypeTemplateProps): JSX.Element => {
  const { codeUuid, navigateBackToCode } = usePublicProjectTypeTemplate();

  return (
    <div className="PublicProjectTypeTemplate">
      {projectTypeData.hero.imageUrl && (
        <div
          className="HeroSectionImage"
          style={{
            backgroundImage: `url(${projectTypeData.hero.imageUrl})`,
          }}
        />
      )}
      <div className={codeUuid ? "" : "HideBackToProjectButton"}>
        <Button
          text="Back to code"
          variant="secondary"
          icon={<ArrowFullLeftDarkIcon />}
          iconPosition="left"
          onClick={navigateBackToCode}
        />
      </div>
      <div className="PublicProjectTypeTemplateContents">
        {projectTypeData.hero.title && (
          <Row spacingV="ml" justify="center">
            <Column span={12}>
              <h1>{projectTypeData.hero.title}</h1>
            </Column>
          </Row>
        )}
        {projectTypeData.category && (
          <Row spacingV="l" justify="center">
            <Column span={12}>
              <Pill label={`${projectTypeData.category} project`} variantMap={PROJECT_TYPES_PILL_VARIANT_MAP} />
            </Column>
          </Row>
        )}
        {projectTypeData.typeRatings && (
          <Row spacingV="l" justify="center">
            <Column span={12}>
              <TypeRatingsSection typeRatings={projectTypeData.typeRatings} />
            </Column>
          </Row>
        )}
        {projectTypeData.aboutTheType?.title && projectTypeData.aboutTheType?.content && (
          <Row spacingV="ml">
            <Column span={12}>
              <ContentSection
                title={projectTypeData.aboutTheType?.title}
                content={projectTypeData.aboutTheType?.content}
              />
            </Column>
          </Row>
        )}
        <Row>
          <Column span={12}>
            {projectTypeData.typesOfProjects.enabled && (
              <RichContentSection
                title={projectTypeData.typesOfProjects.title || ""}
                sectionData={projectTypeData.typesOfProjects}
              />
            )}
          </Column>
        </Row>
      </div>
    </div>
  );
};
