import { ActionButtons, Column, ErrorBox, InformationBox, Password, Row } from "../../../../../widget";
import { useChangePasswordForm } from "./useChangePasswordForm";

export const ChangePasswordForm = (): JSX.Element => {
  const {
    newPassword,
    newPasswordConfirmation,
    currentPassword,
    errors,
    setCurrentPassword,
    setNewPassword,
    setNewPasswordConfirmation,
    handleSubmit,
    onCancel,
  } = useChangePasswordForm();

  return (
    <>
      {errors && errors.length > 0 && (
        <Row spacingV="ml">
          <Column span={8}>
            <ErrorBox title="The existing password doesn't match" text={errors.map((error) => error.message)} />
          </Column>
        </Row>
      )}
      <form onSubmit={(e) => handleSubmit(e)}>
        <Row spacingV="ll">
          <Column span={4}>
            <Password
              label="Current password"
              name="currentPassword"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.currentTarget.value)}
            />
          </Column>
        </Row>
        <Row spacingV="m">
          <Column span={4}>
            <p className="body2">Please enter a new password for your account.</p>
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={4}>
            <InformationBox variant="info" title="Password requirements">
              <ul>
                <li>Be at least 8 characters long</li>
                <li>
                  Not be a commonly used password such as <q>Password1</q>
                </li>
              </ul>
            </InformationBox>
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={4}>
            <Password
              label="New password"
              name="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.currentTarget.value)}
            />
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={4}>
            <Password
              label="Confirm new password"
              name="newPasswordConfirmation"
              value={newPasswordConfirmation}
              onChange={(e) => {
                setNewPasswordConfirmation(e.currentTarget.value);
              }}
            />
          </Column>
        </Row>
        <Row>
          <Column span={2}>
            <ActionButtons onCancel={onCancel} />
          </Column>
        </Row>
      </form>
    </>
  );
};
