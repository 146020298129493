import { Dispatch, FormEvent, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Origin } from "../../../../models";
import { forgottenPasswordComplete } from "../../../../service/identity";
import { ServiceError, Status } from "../../../../service/Shared";
import { getLogonRoute } from "../../../../utils/routes";

interface UseForgottenPasswordCompleteFormReturnData {
  newPassword: string;
  newPasswordConfirmation: string;
  errors: ServiceError[] | undefined;
  setNewPassword: Dispatch<SetStateAction<string>>;
  setNewPasswordConfirmation: Dispatch<SetStateAction<string>>;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
}

export function useForgottenPasswordCompleteForm(urlToken: string): UseForgottenPasswordCompleteFormReturnData {
  const navigate = useNavigate();

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const [errors, setErrors] = useState<ServiceError[] | undefined>();

  async function handleSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault();
    setErrors([]);

    if (newPassword !== newPasswordConfirmation) {
      setErrors([
        {
          code: "NOT_MATCHING_PASSWORDS",
          message: "The passwords do not match",
        },
      ]);
      return;
    }

    const res = await forgottenPasswordComplete({
      token: urlToken,
      password: newPassword,
    });

    if (res.status === Status.Success) {
      navigate(getLogonRoute({ origin: Origin.PasswordReset }));
    } else {
      setErrors(res.errors);
    }
  }

  return {
    newPassword,
    newPasswordConfirmation,
    errors,
    setNewPassword,
    setNewPasswordConfirmation,
    handleSubmit,
  };
}
