import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { PublicDeveloperDetailsParams } from "../../../models";
import { searchProjects } from "../../../service/publicquery";
import { Status } from "../../../service/Shared";
import { getPublicProjectOverviewRoute } from "../../../utils/routes";
import { DetailsMapConstants } from "../constants";

interface UsePublicDeveloperDetailsPageTemplateReturnData {
  dataIsLoading: boolean;
  projectsCoordinates: string[] | undefined;
  organisationUuid: string | undefined;
  navigateBackToProject: () => void;
  showContactUsModal: boolean;
  setShowContactUsModal: Dispatch<SetStateAction<boolean>>;
}

export function usePublicDeveloperDetailsPageTemplate(): UsePublicDeveloperDetailsPageTemplateReturnData {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { organisationUuid } = useParams<PublicDeveloperDetailsParams>();

  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [projectsCoordinates, setProjectsCoordinates] = useState<string[] | undefined>();

  const fetchData = useCallback(async (): Promise<void> => {
    if (organisationUuid) {
      const projectsRes = await searchProjects({
        paging: {
          limit: DetailsMapConstants.MAXIMUM_MARKERS,
        },
        filter: {
          results: {
            developer: {
              uuid: { operator: "eq", value: organisationUuid },
            },
          },
        },
      });

      if (projectsRes.status === Status.Success && projectsRes.data) {
        setProjectsCoordinates(projectsRes.data.results.flatMap((project) => project.locationCoordinates || []));
      }
    }
  }, []);

  useEffect(() => {
    fetchData().then(() => setDataIsLoading(false));
  }, [fetchData]);

  const navigateBackToProject = (): void => {
    const projectUuid = searchParams.get("projectUuid");
    if (projectUuid) {
      navigate(getPublicProjectOverviewRoute(projectUuid));
    }
  };

  const [showContactUsModal, setShowContactUsModal] = useState(false);

  return {
    dataIsLoading,
    projectsCoordinates,
    organisationUuid,
    navigateBackToProject,
    showContactUsModal,
    setShowContactUsModal,
  };
}
