// TODO temporarily disable eslint on this file until v1.0 Unwired is complete/services are used
/* eslint unused-imports/no-unused-vars: "off" */
/* eslint @typescript-eslint/no-explicit-any: "off" */
/* eslint @typescript-eslint/no-non-null-assertion: "off" */
// OrganisationService
import { BaseResponse, BaseResponseWithSingle, Status } from "../Shared";
import {
  DeleteOrganisationUserRequest,
  GetOrganisationListingPreviewRequest,
  GetOrganisationListingPreviewResponse,
  SaveOrganisationListingPreviewRequest,
  SaveOrganisationListingPreviewResponse,
  UpdateOrganisationAddressRequest,
  UpdateOrganisationAddressResponse,
  UpdateOrganisationDetailsRequest,
  UpdateOrganisationDetailsResponse,
  UpdateOrganisationEmbeddingPreferencesRequest,
  UpdateOrganisationEmbeddingPreferencesResponse,
  UpdateOrganisationListingRequest,
  UpdateOrganisationListingResponse,
  UpdateOrganisationUserRequest,
  UpdateOrganisationUserResponse,
  UploadListingFileRequest,
  UploadListingFileResponse,
  UploadOrganisationFileRequest,
  UploadOrganisationFileResponse,
} from "./Types";

// TEMP save preview data

// TODO Remove the mocked data below
// If the preview function of the marketing-assets page is being tested, simply click on the preview button
// In that case, the data inserted in the marketing assets form will be used, no images are displayed(the empty containers are) because their uuids are being saved instead of the sources
// In order to test the preview page with the mocked data bellow, simply refresh the page
const savedPreviews: any = {
  "a2be6c12-dbb5-11ec-8c9f-fb6f5850b16d": `{
    "aboutUs": {
      "enabled": true,
      "imageFileUuid": "https://images.unsplash.com/photo-1648050759500-d0e7385b862e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1333&q=80",
      "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non sollicitudin sem. Morbi at enim et eros hendrerit eleifend non id nulla. Pellentesque consectetur, dolor eu pharetra tincidunt, massa nibh condimentum quam, quis hendrerit dui sapien non leo. Cras a rhoncus quam.\\n\\nProin vitae vestibulum ligula. Vivamus efficitur eleifend nunc a ornare. Fusce id ultricies eros. Aliquam erat volutpat. Nunc et scelerisque erat. Fusce quis porttitor turpis. Praesent non diam magna. Sed id ligula tempor, porttitor orci nec, blandit ligula. Mauris eget aliquet urna, ac faucibus nunc. Phasellus quis interdum mi. Phasellus aliquet arcu vel eros ultrices vulputate. Nulla sed eros ut metus efficitur sagittis. Maecenas eget vehicula augue. Interdum et malesuada fames ac ante ipsum primis in faucibus.\\n\\nEtiam ullamcorper nisi sed lorem mollis feugiat. Duis tincidunt erat ac erat aliquet dictum. Donec porttitor neque eu purus consequat, ut congue quam fringilla. Vivamus dolor libero, ultrices ut justo ut, finibus luctus arcu. Morbi dignissim non ex ornare scelerisque. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Proin dignissim faucibus ex, vel tincidunt tellus semper in. Sed at ligula quis metus ultrices fringilla."
    },
    "atAGlance": {
      "enabled": true
    },
    "getInTouch": {
      "enabled": true
    },
    "hero": {
      "enabled": true,
      "imageFileUuid": "https://images.unsplash.com/photo-1552083375-1447ce886485?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      "subtitle": "Darcy Developments leads the way in UK woodland creation for carbon capture. Through the planting of over 13 million new trees since 2006, our partners' projects are removing nearly 3 million tonnes of CO2 from the atmosphere, as well as providing a host of other benefits to society, including flood mitigation, enhanced biodiversity and increased public access.",
      "title": "Woodland Creation and Peatland Restoration for Carbon Capture and Ecosystem Services"
    },
    "ourApproach": {
      "enabled": true,
      "part1": {
        "imageFileUuid": "https://images.unsplash.com/photo-1470071459604-3b5ec3a7fe05?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1274&q=80",
        "title": "Sustainable Development",
        "content": "Darcy Developments brings together responsible landholders and business leaders to reverse climate change through the long-term restoration of peatlands across Scotland.\\n\\nThere are many ways we can tackle climate change in Scotland but experience and scientific expertise demonstrates that restoring our ancient peatlands is one of the quickest and most cost-effective of all the Nature Based Solutions."
      },
      "part2": {
        "imageFileUuid": "https://images.unsplash.com/photo-1470252649378-9c29740c9fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
        "title": "Sustainable Development",
        "content": "Darcy Developments brings together responsible landholders and business leaders to reverse climate change through the long-term restoration of peatlands across Scotland.\\n\\nThere are many ways we can tackle climate change in Scotland but experience and scientific expertise demonstrates that restoring our ancient peatlands is one of the quickest and most cost-effective of all the Nature Based Solutions."
      },
      "part3": {
        "imageFileUuid": "https://images.unsplash.com/photo-1500382017468-9049fed747ef?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1332&q=80",
        "title": "Sustainable Development",
        "content": "Darcy Developments brings together responsible landholders and business leaders to reverse climate change through the long-term restoration of peatlands across Scotland.\\n\\nThere are many ways we can tackle climate change in Scotland but experience and scientific expertise demonstrates that restoring our ancient peatlands is one of the quickest and most cost-effective of all the Nature Based Solutions."
      },
      "part4": {
        "imageFileUuid": "https://images.unsplash.com/photo-1418065460487-3e41a6c84dc5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
        "title": "Sustainable Development",
        "content": "Darcy Developments brings together responsible landholders and business leaders to reverse climate change through the long-term restoration of peatlands across Scotland.\\n\\nThere are many ways we can tackle climate change in Scotland but experience and scientific expertise demonstrates that restoring our ancient peatlands is one of the quickest and most cost-effective of all the Nature Based Solutions."
      }
    },
    "ourServices": {
      "enabled": true,
      "part1": {
        "imageFileUuid": "https://images.unsplash.com/photo-1639838311730-8fab8160a13a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
        "title": "Sustainable Development",
        "content": "Darcy Developments brings together responsible landholders and business leaders to reverse climate change through the long-term restoration of peatlands across Scotland.\\n\\nThere are many ways we can tackle climate change in Scotland but experience and scientific expertise demonstrates that restoring our ancient peatlands is one of the quickest and most cost-effective of all the Nature Based Solutions."
      },
      "part2": {
        "imageFileUuid": "https://images.unsplash.com/photo-1655319492860-9efedabbea98?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1332&q=80",
        "title": "Sustainable Development",
        "content": "Darcy Developments brings together responsible landholders and business leaders to reverse climate change through the long-term restoration of peatlands across Scotland.\\n\\nThere are many ways we can tackle climate change in Scotland but experience and scientific expertise demonstrates that restoring our ancient peatlands is one of the quickest and most cost-effective of all the Nature Based Solutions."
      },
      "part3": {
        "imageFileUuid": "https://images.unsplash.com/photo-1657311388556-eeff862b3471?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
        "title": "Sustainable Development",
        "content": "Darcy Developments brings together responsible landholders and business leaders to reverse climate change through the long-term restoration of peatlands across Scotland.\\n\\nThere are many ways we can tackle climate change in Scotland but experience and scientific expertise demonstrates that restoring our ancient peatlands is one of the quickest and most cost-effective of all the Nature Based Solutions."
      }
    },
    "ourValues": {
      "enabled": true,
      "content": "Darcy Developments brings together responsible landholders and business leaders to reverse climate change through the long-term restoration of peatlands across Scotland.\\n\\nThere are many ways we can tackle climate change in Scotland but experience and scientific expertise demonstrates that restoring our ancient peatlands is one of the quickest and most cost-effective of all the Nature Based Solutions."
    }
  }`,
};

export async function updateOrganisationDetails(
  req: UpdateOrganisationDetailsRequest
): Promise<BaseResponseWithSingle<UpdateOrganisationDetailsResponse>> {
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return { status: Status.Success, data: { rowVersion: req.rowVersion + 1 } };
}

export async function updateOrganisationEmbeddingPreferences(
  req: UpdateOrganisationEmbeddingPreferencesRequest
): Promise<BaseResponseWithSingle<UpdateOrganisationEmbeddingPreferencesResponse>> {
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return { status: Status.Success, data: { preferences: req.preferences } };
}

export async function uploadListingFile(
  req: UploadListingFileRequest
): Promise<BaseResponseWithSingle<UploadListingFileResponse>> {
  const fileUrl = "https://picsum.photos/450";
  const data: UploadListingFileResponse = {
    file: { uuid: "3ecfc14c-5860-4283-a233-aed9adadf877", url: fileUrl },
    organisationListingFileUuid: "29eoc14c-5860-4283-a233-aed9adad394o",
  };
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return {
    status: Status.Success,
    data,
  };
}

export async function updateOrganisationListing(
  req: UpdateOrganisationListingRequest
): Promise<BaseResponseWithSingle<UpdateOrganisationListingResponse>> {
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return { status: Status.Success, data: { rowVersion: req.rowVersion + 1 } };
}

export async function updateOrganisationAddress(
  req: UpdateOrganisationAddressRequest
): Promise<BaseResponseWithSingle<UpdateOrganisationAddressResponse>> {
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return { status: Status.Success, data: { rowVersion: req.rowVersion + 1 } };
}

export async function uploadOrganisationFile(
  req: UploadOrganisationFileRequest
): Promise<BaseResponseWithSingle<UploadOrganisationFileResponse>> {
  let fileUrl = "https://picsum.photos/450";
  if (req.type === "SmallLogo") fileUrl = "https://picsum.photos/192";
  const data: UploadOrganisationFileResponse = {
    file: { uuid: "3ecfc14c-5860-4283-a233-aed9adadf877", url: fileUrl },
  };
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return {
    status: Status.Success,
    data,
  };
}

export async function updateOrganisationUser(
  req: UpdateOrganisationUserRequest
): Promise<BaseResponseWithSingle<UpdateOrganisationUserResponse>> {
  await new Promise((r) => {
    setTimeout(r, 150);
  });

  return { status: Status.Success, data: { rowVersion: req.rowVersion + 1 } };
}

export async function deleteOrganisationUser(req: DeleteOrganisationUserRequest): Promise<BaseResponse> {
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return { status: Status.Success };
}

export async function saveOrganisationListingPreview(
  req: SaveOrganisationListingPreviewRequest
): Promise<BaseResponseWithSingle<SaveOrganisationListingPreviewResponse>> {
  const previewUuid = "a2be6c12-dbb5-11ec-8c9f-fb6f5850b16d";
  savedPreviews[previewUuid] = req.content;
  const data: SaveOrganisationListingPreviewResponse = { previewUuid };
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return {
    status: Status.Success,
    data,
  };
}

export async function getOrganisationListingPreview(
  req: GetOrganisationListingPreviewRequest
): Promise<BaseResponseWithSingle<GetOrganisationListingPreviewResponse>> {
  const existingPreview = savedPreviews[req.previewUuid];

  const data: GetOrganisationListingPreviewResponse = {
    content: JSON.parse(existingPreview),
    listingFiles: [],
  };

  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return {
    errors: [],
    status: Status.Success,
    data,
  };
}
