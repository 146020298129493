import "./Select.css";

import RcSelect, { Option } from "rc-select";

import { DropdownLightIcon } from "../../../assets";
import { SelectData } from "../../../models";
import { FormLabel } from "../FormLabel";

export interface SelectProps {
  label?: string;
  initialKey?: string | null;
  value?: string | null;
  placeholder?: string;
  className?: string;
  dropdownMatchSelectWidth?: boolean;
  data: SelectData;
  onChange: (value: string) => void;
}

export const Select = ({
  label,
  initialKey,
  value,
  placeholder = "Please select",
  className,
  dropdownMatchSelectWidth,
  data,
  onChange,
}: SelectProps): JSX.Element => {
  const options = data?.map((kvp) => (
    <Option key={kvp.key} value={kvp.key}>
      {kvp.value}
    </Option>
  ));
  return (
    <FormLabel label={label}>
      <div className={`${className || ""} form_field`}>
        <RcSelect
          defaultValue={initialKey}
          value={value}
          inputIcon={<DropdownLightIcon />}
          menuItemSelectedIcon={undefined}
          placeholder={placeholder || undefined}
          onChange={onChange}
          prefixCls="Select"
          dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        >
          {options}
        </RcSelect>
      </div>
    </FormLabel>
  );
};
