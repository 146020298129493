import { useNavigate } from "react-router-dom";

import { DesktopSlashDarkIcon } from "../../assets";
import { getDashboardRoute } from "../../utils/routes";
import { Button, Column, DeveloperHeader, Row } from "../../widget";
import { DeveloperTemplate } from "../DeveloperTemplate";

interface DeveloperErrorTemplateProps {
  errorText: string;
  errorCode: string;
}

export const DeveloperErrorTemplate = ({ errorText, errorCode }: DeveloperErrorTemplateProps): JSX.Element => {
  const navigate = useNavigate();
  return (
    <DeveloperTemplate>
      <DeveloperHeader />
      <Row spacingV="2xl" justify="center" align="center">
        <Column span={12} />
      </Row>
      <Row spacingV="ml" justify="center" align="center">
        <Column span={12}>
          <DesktopSlashDarkIcon />
        </Column>
      </Row>
      <Row spacingV="ll" justify="center" align="center">
        <Column span={12}>
          <p className="body0">OOPS!</p>
        </Column>
      </Row>
      <Row spacingV="ll" justify="center" align="center">
        <Column span={12}>
          <p className="body1">{errorText}</p>
        </Column>
      </Row>
      <Row spacingV="4xl" justify="center" align="center">
        <Column span={12}>
          <p className="body3">Error code: {errorCode}</p>
        </Column>
      </Row>
      <Row spacingV="ml" justify="center" align="center">
        <Column span={12}>
          <Button text="Go to dashboard" onClick={() => navigate(getDashboardRoute())} />
        </Column>
      </Row>
    </DeveloperTemplate>
  );
};
