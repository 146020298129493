import { Dispatch, FormEvent, SetStateAction, useCallback, useEffect, useState } from "react";

import { AddressType } from "../../../../../../models";
import { updateOrganisationAddress } from "../../../../../../service/organisation";
import { ServiceError, Status } from "../../../../../../service/Shared";
import { useAuth } from "../../../../../../useAuth";
import { areObjectsDeepEqual } from "../../../../../../utils";
import { Toast } from "../../../../../../widget";
import { OrganisationAddressData, OrganisationFormChangedAction, OrganisationFormChangedEnum } from "../../models";

interface UseAddressFormReturnData {
  addressLine1: string | null;
  postcode: string | null;
  addressLine2: string | null;
  countryCode: string;
  city: string;
  errors: ServiceError[] | undefined;
  setAddressLine1: Dispatch<SetStateAction<string>>;
  setPostcode: Dispatch<SetStateAction<string | null>>;
  setAddressLine2: Dispatch<SetStateAction<string | null>>;
  setCountryCode: Dispatch<SetStateAction<string>>;
  setCity: Dispatch<SetStateAction<string>>;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  handleCancel: () => void;
}

export const useAddressForm = (
  organisationDefaultAddress: OrganisationAddressData,
  dispatch: Dispatch<OrganisationFormChangedAction>
): UseAddressFormReturnData => {
  const { currentOrganisationUuid } = useAuth();

  const [submittedValues, setSubmittedValues] = useState<OrganisationAddressData>(organisationDefaultAddress);
  const [addressLine1, setAddressLine1] = useState(organisationDefaultAddress.line1);
  const [postcode, setPostcode] = useState(organisationDefaultAddress.postcode);
  const [addressLine2, setAddressLine2] = useState(organisationDefaultAddress.line2);
  const [countryCode, setCountryCode] = useState<string>(organisationDefaultAddress.countryCode);
  const [city, setCity] = useState<string>(organisationDefaultAddress.city);

  const [errors, setErrors] = useState<ServiceError[] | undefined>();

  const resetForm = (): void => {
    setAddressLine1(submittedValues.line1);
    setPostcode(submittedValues.postcode);
    setAddressLine2(submittedValues.line2);
    setCountryCode(submittedValues.countryCode);
    setCity(submittedValues.city);
    setErrors([]);
  };

  const getCurrentFormData = useCallback((): OrganisationAddressData => {
    return {
      countryCode,
      line1: addressLine1,
      line2: addressLine2 || null,
      postcode,
      city,
      rowVersion: organisationDefaultAddress.rowVersion,
    };
  }, [countryCode, addressLine1, addressLine2, postcode, city]);

  useEffect(() => {
    dispatch({
      type: OrganisationFormChangedEnum.SET_ADDRESS_FORM_CHANGED,
      value: !areObjectsDeepEqual(submittedValues, getCurrentFormData()),
    });
  }, [countryCode, addressLine1, addressLine2, postcode, city, submittedValues]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setErrors([]);
    if (currentOrganisationUuid) {
      const res = await updateOrganisationAddress({
        organisationUuid: currentOrganisationUuid,
        city,
        type: AddressType.Correspondance,
        countryCode,
        postcode,
        line1: addressLine1,
        line2: addressLine2,
        line3: null,
        line4: null,
        rowVersion: submittedValues.rowVersion,
      });

      if (res.status === Status.Success && res.data) {
        Toast.success({ message: "Organisation address changed successfully" });
        setSubmittedValues({
          city,
          countryCode,
          postcode,
          line1: addressLine1,
          line2: addressLine2,
          rowVersion: res.data.rowVersion,
        });
      }

      if (res.status === Status.Error) {
        setErrors(res.errors);
      }
    }
  };

  const handleCancel = (): void => {
    resetForm();
  };

  return {
    addressLine1,
    postcode,
    addressLine2,
    countryCode,
    city,
    errors,
    setAddressLine1,
    setPostcode,
    setAddressLine2,
    setCountryCode,
    setCity,
    handleSubmit,
    handleCancel,
  };
};
