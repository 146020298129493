import { Dispatch, ForwardedRef, forwardRef, SetStateAction } from "react";

import { ActionButtons, Column, ErrorBox, Row, Toggle } from "../../../../../../widget";
import { FormOurServicesData, MarketingAssetsFormChangedAction, MarketingAssetsSubmitHandler } from "../../models";
import { Section } from "../components";
import { useOurServicesForm } from "./useOurServicesForm";

interface OurServicesFormProps {
  ourServicesDefaultValues: FormOurServicesData;
  ourServicesOldDefaultValues: FormOurServicesData;
  listingRowVersion: number;
  setListingRowVersion: Dispatch<SetStateAction<number>>;
  dispatch: Dispatch<MarketingAssetsFormChangedAction>;
  getCurrentFormsData: (formWhichWasSaved: string) => string;
}

export const OurServicesForm = forwardRef(
  (
    {
      ourServicesDefaultValues,
      ourServicesOldDefaultValues,
      listingRowVersion,
      setListingRowVersion,
      dispatch,
      getCurrentFormsData,
    }: OurServicesFormProps,
    forwardedRef: ForwardedRef<MarketingAssetsSubmitHandler>
  ): JSX.Element => {
    const {
      enabled,
      part1ImageUploadRef,
      part2ImageUploadRef,
      part3ImageUploadRef,

      part1Title,
      part1Content,
      part1ImageUrl,
      part2Title,
      part2Content,
      part2ImageUrl,
      part3Title,
      part3Content,
      part3ImageUrl,

      errors,

      setEnabled,
      setPart1Title,
      setPart1Content,
      setPart2Title,
      setPart2Content,
      setPart3Title,
      setPart3Content,

      handleSetImage,
      handleCancel,
      handleSubmit,
    } = useOurServicesForm(
      ourServicesDefaultValues,
      ourServicesOldDefaultValues,
      listingRowVersion,
      setListingRowVersion,
      dispatch,
      forwardedRef,
      getCurrentFormsData
    );

    return (
      <>
        <Row spacingV="ll">
          <Column span={4}>
            <h2>Our services</h2>
          </Column>
        </Row>
        {errors && errors.length > 0 && (
          <Row spacingV="ml">
            <Column span={4}>
              <ErrorBox
                title="Please solve the below issues before saving"
                text={errors.map((error) => error.message)}
              />
            </Column>
          </Row>
        )}
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <Row spacingV="ll">
            <Column span={12}>
              <Toggle label="Show this section on organisation profile" defaultValue={enabled} onChange={setEnabled} />
            </Column>
          </Row>
          <Section
            imageRef={part1ImageUploadRef}
            disabled={!enabled}
            header="Section 1"
            parentForm="ourServices"
            formName="our services"
            sectionNumber={1}
            title={part1Title}
            content={part1Content}
            imageUrl={part1ImageUrl}
            setTitle={setPart1Title}
            setContent={setPart1Content}
            handleSetImage={handleSetImage}
          />
          <Section
            imageRef={part2ImageUploadRef}
            disabled={!enabled}
            header="Section 2"
            parentForm="ourServices"
            formName="our services"
            sectionNumber={2}
            title={part2Title}
            content={part2Content}
            imageUrl={part2ImageUrl}
            setTitle={setPart2Title}
            setContent={setPart2Content}
            handleSetImage={handleSetImage}
          />
          <Section
            imageRef={part3ImageUploadRef}
            disabled={!enabled}
            header="Section 3"
            parentForm="ourServices"
            formName="our services"
            sectionNumber={3}
            title={part3Title}
            content={part3Content}
            imageUrl={part3ImageUrl}
            setTitle={setPart3Title}
            setContent={setPart3Content}
            handleSetImage={handleSetImage}
          />
          <Row spacingV="2xl">
            <Column span={2}>
              <ActionButtons onCancel={handleCancel} />
            </Column>
          </Row>
        </form>
      </>
    );
  }
);
