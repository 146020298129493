import { Dispatch, SetStateAction, useState } from "react";

import { logError } from "../../../../../service/error";

export type subStep = {
  met: boolean;
  stepName: string;
};

export enum StepStates {
  NotStarted,
  InProgress,
  Complete,
}

interface useStepReturnData {
  showSubSteps: boolean;
  setShowSubSteps: Dispatch<SetStateAction<boolean>>;
  stepState: StepStates;
}

export const useStep = (subSteps: subStep[]): useStepReturnData => {
  const [showSubSteps, setShowSubSteps] = useState(false);

  let metCount = 0;
  let stepState: StepStates;

  for (let i = 0; i < subSteps.length; i++) {
    if (subSteps[i].met) metCount += 1;
  }

  switch (metCount) {
    case 0:
      stepState = StepStates.NotStarted;
      break;
    case subSteps.length === metCount && subSteps.length:
      stepState = StepStates.Complete;
      break;
    case metCount > 0 && metCount:
      stepState = StepStates.InProgress;
      break;
    default:
      stepState = StepStates.NotStarted;
      logError({ error: `${metCount} is not a valid number of met steps` });
      break;
  }

  return {
    showSubSteps,
    setShowSubSteps,
    stepState,
  };
};
