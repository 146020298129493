import { useParams } from "react-router-dom";

interface UseForgottenPasswordCompleteReturnData {
  urlToken: string | undefined;
}

type ForgottenPasswordCompletePageRouteParams = "token";

export function useForgottenPasswordComplete(): UseForgottenPasswordCompleteReturnData {
  const { token } = useParams<ForgottenPasswordCompletePageRouteParams>();

  return {
    urlToken: token,
  };
}
