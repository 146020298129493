import { stringToNumber } from "../../../utils";

interface UseAvatarReturnData {
  nameInitial: string;
  avatarBackgroundColor: string;
  avatarTextColor: string;
}

export function useAvatar(userUuid: string, name: string): UseAvatarReturnData {
  const nameInitial = name[0];
  const numberFromUuid = stringToNumber(userUuid);

  const allAvatarColours = [
    {
      background: "var(--color-green-20)",
      text: "var(--color-green)",
    },
    {
      background: "var(--color-coral-20)",
      text: "var(--color-coral)",
    },
    {
      background: "var(--color-blue-20)",
      text: "var(--color-blue)",
    },
    {
      background: "var(--color-yellow-20)",
      text: "var(--color-yellow)",
    },
    {
      background: "var(--color-red-20)",
      text: "var(--color-red)",
    },
  ];

  const avatarColour = allAvatarColours[numberFromUuid % 5];

  return {
    nameInitial,
    avatarBackgroundColor: avatarColour.background,
    avatarTextColor: avatarColour.text,
  };
}
