import "./Dashboard.css";

import { DeveloperTemplate } from "../../../templates";
import {
  // getMarketingRoute,  // TODO ENG-557 hidden until post-launch
  getProjectsRoute,
} from "../../../utils/routes";
import { Column, DeveloperHeader, KanaTooltip, Row } from "../../../widget";
import {
  // DashboardBarCard,  // TODO ENG-557 hidden until post-launch
  DashboardPieCard,
  DashboardTableCard,
} from "./components";
import { useDashboard } from "./useDashboard";

const Dashboard = (): JSX.Element => {
  const {
    statusChartData,
    unitsChartData,
    // metricsChartData, // TODO ENG-557 hidden until post-launch
    tableColumnsTopProjects,
    isLoading,
    isLoadingProjects,
    onTopProjectsDataLoad,
  } = useDashboard();
  // const marketingRoute = getMarketingRoute();  // TODO ENG-557 hidden until post-launch
  const projectsRoute = getProjectsRoute();

  return (
    <DeveloperTemplate activeElement="Dashboard">
      <div>
        <DeveloperHeader />
        <main>
          <div className="Dashboard">
            <Row spacingV="ll">
              <Column span={12}>
                <h1>Dashboard</h1>
              </Column>
            </Row>
            {!isLoading && (
              <Row spacingV="ml">
                <Column span={6}>
                  <DashboardPieCard title="Status" data={statusChartData} />
                </Column>
                <Column span={6}>
                  <DashboardPieCard
                    title="Units"
                    data={unitsChartData}
                    noDataMessage="No units issued"
                    tooltip={
                      <KanaTooltip
                        tooltipHeader="Buffer units"
                        tooltipText="Please note that buffer units created by this project are not shown in this chart"
                      />
                    }
                  />
                </Column>
              </Row>
            )}
            {/* TODO ENG-557 hidden until post-launch */}
            {/* <Row spacingV="ml">
              <Column span={12}>
                <DashboardBarCard
                  title="Marketing page views"
                  expandRoute={marketingRoute}
                  data={metricsChartData}
                />
              </Column>
            </Row> */}
            <Row spacingV="ml">
              <Column span={12}>
                <DashboardTableCard
                  title="Top projects"
                  expandRoute={projectsRoute}
                  onChange={onTopProjectsDataLoad}
                  columns={tableColumnsTopProjects}
                  isLoading={isLoadingProjects}
                />
              </Column>
            </Row>
          </div>
        </main>
      </div>
    </DeveloperTemplate>
  );
};

export default Dashboard;
