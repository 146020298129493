/* global google */

import { GoogleMap, MarkerF } from "@react-google-maps/api";

import MapMarkerSVG from "../../../assets/icons/map-marker-blue-shadow.svg";
import { MapConstants } from "./constants";
import { useMap } from "./useMap";

interface MapProps {
  coordinates: string[];
  fullWidth?: boolean;
  mode?: "terrain" | "satellite" | "hybrid" | "roadmap";
  height: number;
}

export const Map = ({ coordinates, fullWidth = false, mode = "roadmap", height }: MapProps): JSX.Element => {
  const { coordinatesList, isLoaded, loadError, center, setMap } = useMap(coordinates, fullWidth);

  if (loadError) {
    return <div>Map could not be loaded</div>;
  }
  return (
    <div>
      {!isLoaded ? (
        <div>Loading...</div>
      ) : (
        <GoogleMap
          onLoad={(loadedMap) => {
            setMap(loadedMap);
          }}
          mapContainerStyle={{
            height,
          }}
          center={center}
          options={{
            minZoom: MapConstants.MIN_ZOOM_LEVEL,
            maxZoom: MapConstants.MAX_ZOOM_LEVEL,
            streetViewControl: false,
            fullscreenControl: false,
            zoomControlOptions: {
              position: google.maps.ControlPosition.RIGHT_TOP,
            },
            styles: [
              {
                featureType: "poi",
                elementType: "labels.icon",
                stylers: [
                  {
                    visibility: "off",
                  },
                ],
              },
              {
                featureType: "all",
                elementType: "labels",
                stylers: [
                  {
                    visibility: "off",
                  },
                ],
              },
              {
                featureType: "administrative.neighborhood",
                elementType: "all",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "administrative.land_parcel",
                elementType: "all",
                stylers: [{ visibility: "off" }],
              },
            ],
            mapTypeId: mode,
          }}
        >
          {coordinatesList.map((coordinate) => (
            <MarkerF
              key={coordinate[0] + coordinate[1]}
              position={{
                lat: coordinate[0],
                lng: coordinate[1],
              }}
              icon={{
                url: MapMarkerSVG,
              }}
            />
          ))}
        </GoogleMap>
      )}
    </div>
  );
};
