import { ChartData, ChartOptions, Plugin, TooltipItem } from "chart.js";
import { useEffect, useMemo, useState } from "react";

import { ColorConstants } from "../../../constants";
import { getChartColours, getChartHighlightColours, thousandsFormatter } from "../../../utils";

interface UsePieChartReturnData {
  chartData: ChartData<"doughnut", number[], string>;
  legendData: { [key: string]: number };
  chartColours: string[];
  plugins: Plugin<"doughnut">[] | undefined;
  options: ChartOptions<"doughnut">;
  hasData: boolean;
}

export function usePieChart(
  data: {
    [key: string]: number;
  },
  centerTextHidden: boolean,
  noDataMessage: string
): UsePieChartReturnData {
  const hasData: boolean = useMemo(() => data && Object.values(data).find((x) => !!x) !== undefined, [data]);

  const [chartData, setChartData] = useState<ChartData<"doughnut", number[], string>>({ datasets: [] });
  const [legendData, setLegendData] = useState<{ [key: string]: number }>(data || { [noDataMessage]: 0 });
  const [chartColours, setChartColours] = useState<string[]>([]);
  const [chartNoDataColour] = useState<string>(ColorConstants.GREY_20);

  const [plugins, setPlugins] = useState<Plugin<"doughnut">[] | undefined>(undefined);

  useEffect(() => {
    if (data && Object.values(data).length) {
      setPlugins([
        {
          id: "text",
          beforeDraw: (chart) => {
            if (centerTextHidden || !hasData) return;
            const { width, height, ctx } = chart;
            ctx.restore();
            const centerText = thousandsFormatter.format(
              Math.abs(
                chart.data.datasets
                  .reduce<number[]>((a, b) => a.concat(b.data as number[]), [])
                  .reduce((a, b) => a + b, 0)
              )
            );
            const fontSize = (width / 90).toFixed(2);
            ctx.font = `${fontSize}em "Space Grotesk", sans-serif`;
            ctx.textBaseline = "middle";
            const textX = Math.round((width - ctx.measureText(centerText?.toString() || "").width) / 2);
            const textY = height / 2;

            ctx.fillText(centerText?.toString() || "", textX, textY);
            ctx.save();
          },
        },
      ]);
    }
  }, [data]);

  const options: ChartOptions<"doughnut"> = useMemo(
    () => ({
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltip: {
          enabled: hasData,
          displayColors: false,
          callbacks: {
            label: (tooltipItem: TooltipItem<"doughnut">) => tooltipItem.formattedValue,
          },
        },
      },
    }),
    [data]
  );

  useEffect(() => {
    if (!data) return;

    const xLabels = Object.keys(data);
    const dataPoints = xLabels.map((label) => data[label]);
    const colours = hasData ? getChartColours(dataPoints.length) : [chartNoDataColour];
    const highlightColours = hasData ? getChartHighlightColours(dataPoints.length) : [chartNoDataColour];
    setChartColours(colours);
    setChartData({
      labels: xLabels,
      datasets: [
        {
          data: hasData ? dataPoints : [1],
          backgroundColor: hasData ? colours : chartNoDataColour,
          borderWidth: 0,
          hoverBackgroundColor: highlightColours,
        },
      ],
    });
    setLegendData(hasData ? data : { [noDataMessage]: 0 });
  }, [data]);

  return {
    chartData,
    legendData,
    chartColours,
    plugins,
    options,
    hasData,
  };
}
