export class CodeComparisonConstants {
  public static readonly WOODLAND_CARBON_CODE_UUID = "1ea72b11-65b3-49b4-a5af-c0ee33347956";

  public static readonly PEATLAND_CODE_UUID = "1ea72b11-65b3-49b4-a5af-c0ee33347958";

  public static readonly UK_CARBON_CODE_OF_CONDUCT_CODE_UUID = "79dddaff-0135-4b54-ba7c-9c8e399b5af2";

  public static readonly ENHANCED_WEATHERING_CODE_UUID = "6d86229e-db1d-4501-97c9-0ff492900c20";

  public static readonly WILDER_CARBON_UUID = "1ea72b11-65b3-49b4-a5af-c0ee33347961";

  public static readonly HEDGEROW_CARBON_CODE_UUID = "dd4f26ee-8dc1-4c85-9b88-2b8726058225";
}
