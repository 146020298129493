import { Dispatch } from "react";

import { ActionButtons, Column, ErrorBox, KanaTooltip, Row, TextInput } from "../../../../../../widget";
import { OrganisationDetailsData, OrganisationFormChangedAction } from "../../models";
import { useDetailsForm } from "./useDetailsForm";

interface DetailsFormProps {
  organisationDefaultDetails: OrganisationDetailsData;
  dispatch: Dispatch<OrganisationFormChangedAction>;
}

export const DetailsForm = ({ organisationDefaultDetails, dispatch }: DetailsFormProps): JSX.Element => {
  const {
    displayName,
    contactEmail,
    phoneNumber,
    website,
    linkedinURL,
    twitterUsername,
    instagramUsername,
    youtubeChannel,
    facebookURL,
    errors,
    setDisplayName,
    setPhoneNumber,
    setContactEmail,
    setWebsite,
    setLinkedinURL,
    setTwitterUsername,
    setInstagramUsername,
    setYoutubeChannel,
    setFacebookURL,
    handleSubmit,
    handleCancel,
  } = useDetailsForm(organisationDefaultDetails, dispatch);

  return (
    <>
      {errors && errors.length > 0 && (
        <Row spacingV="ll">
          <Column span={5}>
            <ErrorBox title="Please solve the below issues before saving" text={errors.map((error) => error.message)} />
          </Column>
        </Row>
      )}
      <form onSubmit={(e) => handleSubmit(e)}>
        <Row spacingV="ll">
          <Column span={5}>
            <TextInput
              label="Display name"
              name="displayName"
              tooltip={
                <KanaTooltip
                  tooltipHeader="What is a display name?"
                  tooltipText="This controls the name of your business that will be displayed to the public in the directory, on the project search screen, the project overviews and on your developer profile."
                />
              }
              value={displayName}
              onChange={(value) => setDisplayName(value)}
            />
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={5}>
            <TextInput
              label="Phone number"
              name="phoneNumber"
              value={phoneNumber}
              onChange={(value) => setPhoneNumber(value)}
            />
          </Column>
          <Column span={5} offset={1}>
            <TextInput
              label="Contact email address"
              name="contactEmailAddress"
              type="email"
              tooltip={
                <KanaTooltip
                  tooltipHeader="What will we use your contact email address for?"
                  tooltipText="This is where we will send any incoming contact requests, and how we will inform you when people register interest in your projects."
                />
              }
              value={contactEmail}
              onChange={(value) => setContactEmail(value)}
            />
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={5}>
            <TextInput
              optional
              label="Website"
              name="website"
              value={website}
              onChange={(value) => setWebsite(value)}
            />
          </Column>
          <Column span={5} offset={1}>
            <TextInput
              optional
              label="LinkedIn URL"
              name="linkedinURL"
              value={linkedinURL}
              onChange={(value) => setLinkedinURL(value)}
              tooltip={
                <KanaTooltip
                  tooltipHeader="What format should you use?"
                  tooltipText="Please provide your Linkedin URL in the following format:
                  https://www.linkedin.com/company/ kana-earth/"
                />
              }
            />
          </Column>
        </Row>
        <Row spacingV="ll">
          <Column span={5}>
            <TextInput
              optional
              label="Twitter username"
              name="twitterUsername"
              value={twitterUsername}
              onChange={(value) => setTwitterUsername(value)}
              tooltip={
                <KanaTooltip
                  tooltipHeader="What format should you use?"
                  tooltipText="Please provide your Twitter username with no @ symbol or URL, for example:
                  getkana"
                />
              }
            />
          </Column>
          <Column span={5} offset={1}>
            <TextInput
              optional
              label="Instagram username"
              name="instagramUsername"
              value={instagramUsername}
              onChange={(value) => setInstagramUsername(value)}
              tooltip={
                <KanaTooltip
                  tooltipHeader="What format should you use?"
                  tooltipText="Please provide your Instagram username with no @ symbol or URL, for example:
                  getkanaearth"
                />
              }
            />
          </Column>
        </Row>
        <Row spacingV="2xl">
          <Column span={5}>
            <TextInput
              optional
              label="Youtube Channel"
              name="youtubeChannel"
              value={youtubeChannel}
              onChange={(value) => setYoutubeChannel(value)}
              tooltip={
                <KanaTooltip
                  tooltipHeader="What format should you use?"
                  tooltipText="Please provide your YouTube Channel in the following format:
                  https://www.youtube.com/channel/ getkanaearth"
                />
              }
            />
          </Column>
          <Column span={5} offset={1}>
            <TextInput
              optional
              label="Facebook URL"
              name="facebookURL"
              value={facebookURL}
              onChange={(value) => setFacebookURL(value)}
              tooltip={
                <KanaTooltip
                  tooltipHeader="What format should you use?"
                  tooltipText="Please provide your Facebook URL in the following format:
                  https://www.facebook.com/ getkanaearth/"
                />
              }
            />
          </Column>
        </Row>
        <Row spacingV="2xl">
          <Column span={5}>
            <ActionButtons onCancel={handleCancel} />
          </Column>
        </Row>
      </form>
    </>
  );
};
