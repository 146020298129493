import { Dispatch, FormEvent, SetStateAction, useEffect, useState } from "react";

import { usePublicContext } from "../../../../../route/public";
import { register } from "../../../../../service/identity";
import { registerProjectInterest } from "../../../../../service/public";
import { ServiceError, Status } from "../../../../../service/Shared";
import { Metric, ObjectType, saveEvent } from "../../../../../service/stats";

interface UseRegisterInterestModalReturnData {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
  password: string;
  errors: ServiceError[] | undefined;
  displaySecondStepSuccessBox: boolean;
  firstStepComplete: boolean;
  secondStepComplete: boolean;
  setFirstName: Dispatch<SetStateAction<string>>;
  setLastName: Dispatch<SetStateAction<string>>;
  setEmail: Dispatch<SetStateAction<string>>;
  setMessage: Dispatch<SetStateAction<string>>;
  setPassword: Dispatch<SetStateAction<string>>;
  handleFirstStepSubmit: (e: FormEvent<HTMLFormElement>) => void;
  handleSecondStepSubmit: (e: FormEvent<HTMLFormElement>) => void;
}

export function useRegisterInterestModalForm(): UseRegisterInterestModalReturnData {
  const { projectDetails } = usePublicContext();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState<ServiceError[] | undefined>([]);

  const [displaySecondStepSuccessBox, setDisplaySecondStepSuccessBox] = useState(true);
  const [firstStepComplete, setFirstStepComplete] = useState(false);
  const [secondStepComplete, setSecondStepComplete] = useState(false);

  async function handleFirstStepSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault();
    setErrors([]);

    if (!firstName || !lastName || !email) {
      const errorList = [];

      if (!firstName) {
        errorList.push({
          code: "NO_FIRSTNAME",
          message: "First name must be completed",
        });
      }

      if (!lastName) {
        errorList.push({
          code: "NO_FIRSTNAME",
          message: "Last name must be completed",
        });
      }

      if (!email) {
        errorList.push({
          code: "NO_EMAIL",
          message: "Email address must be completed",
        });
      }

      setErrors(errorList);
      return;
    }

    if (projectDetails) {
      const res = await registerProjectInterest({
        projectUuid: projectDetails?.uuid,
        firstName,
        lastName,
        email,
        message,
      });

      if (res.status === Status.Success) {
        setFirstStepComplete(true);
        saveEvent({
          metric: Metric.Enquiry,
          objectType: ObjectType.Project,
          objectUuid: projectDetails?.uuid,
          timestamp: null,
        });
      }
      if (res.status === Status.Error) {
        setErrors(res.errors);
      }
    }
  }

  async function handleSecondStepSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault();
    setErrors([]);

    const res = await register({
      firstName,
      lastName,
      email,
      password,
    });

    if (res.status === Status.Success) {
      setSecondStepComplete(true);
    }

    if (res.status === Status.Error) {
      setErrors(res.errors);
      setDisplaySecondStepSuccessBox(false);
    }
  }

  useEffect(() => {
    document.getElementById("registerInterestSecondStep")?.scrollIntoView({ behavior: "smooth" });

    document.getElementById("registerInterestThirdStep")?.scrollIntoView({ behavior: "smooth" });
  }, [firstStepComplete, secondStepComplete]);

  return {
    firstName,
    lastName,
    email,
    message,
    password,
    errors,
    displaySecondStepSuccessBox,
    firstStepComplete,
    secondStepComplete,
    setFirstName,
    setLastName,
    setEmail,
    setMessage,
    setPassword,
    handleFirstStepSubmit,
    handleSecondStepSubmit,
  };
}
