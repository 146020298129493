import { ApolloClient, FetchResult, from, HttpLink, InMemoryCache, Observable } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import {
  apolloAuthHeaderLink,
  apolloDefaultOptions,
  apolloParseJsonDatesLink,
  errorHandler400,
  errorHandler401,
  errorHandler403,
  errorHandler404,
  errorHandler500,
  otherErrorHandler,
} from "../utils";

const httpLink = new HttpLink({
  uri: `${window.API_URL}/private/graphql`,
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }): Observable<FetchResult> | undefined => {
    if (graphQLErrors) {
      // TODO: show error message as not result of user input?
      // console.log(`[GraphQl error]: ${networkError}`);
    }
    if (networkError) {
      // console.log(`[Network error]: ${networkError}`);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { statusCode } = networkError;
      if (statusCode === 400) return errorHandler400(networkError);
      if (statusCode === 401) return errorHandler401(networkError, operation, forward);
      if (statusCode === 403) return errorHandler403(networkError);
      if (statusCode === 404) return errorHandler404(networkError);
      if (statusCode === 500) return errorHandler500(networkError);
      return otherErrorHandler(networkError);
    }
    return undefined;
  }
);

export const apolloPrivate = new ApolloClient({
  link: from([apolloParseJsonDatesLink, errorLink, apolloAuthHeaderLink, httpLink]),
  cache: new InMemoryCache(),
  credentials: "include",
  defaultOptions: apolloDefaultOptions,
});
