// TODO temporarily disable eslint on this file until v1.0 Unwired is complete/services are used
/* eslint unused-imports/no-unused-vars: "off" */
/* eslint @typescript-eslint/no-explicit-any: "off" */
/* eslint @typescript-eslint/no-non-null-assertion: "off" */
// IdentityService
import { BaseResponse, BaseResponseWithSingle, Status } from "../Shared";
import { identityAppUsers, organisationOrganisations, organisationOrganisationUsers } from "../TempData";
import {
  AcceptInvitationRequest,
  ChangePasswordRequest,
  ChangePasswordResponse,
  CreateForgottenPasswordRequestRequest,
  ForgottenPasswordCompleteRequest,
  InviteUserToJoinOrganisationRequest,
  LogonRequest,
  LogonResponse,
  LogoutResponse,
  RegisterRequest,
  UpdatePersonalDetailsRequest,
  UpdatePersonalDetailsResponse,
} from "./Types";

export async function createForgottenPasswordRequest(
  req: CreateForgottenPasswordRequestRequest
): Promise<BaseResponse> {
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  if (!req.email.includes("@")) {
    return {
      status: Status.Error,
      errors: [
        {
          code: "INVALID_EMAIL",
          message: "The inserted email is not valid",
        },
      ],
    };
  }

  return { status: Status.Success };
}

export async function forgottenPasswordComplete(req: ForgottenPasswordCompleteRequest): Promise<BaseResponse> {
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  if (req.password.length < 8)
    return {
      status: Status.Error,
      errors: [
        {
          code: "INVALID_PASSWORD",
          message: "The password must have at least 8 characters",
        },
      ],
    };
  return { status: Status.Success };
}

export async function acceptInvitation(req: AcceptInvitationRequest): Promise<BaseResponse> {
  await new Promise((r) => {
    setTimeout(r, 150);
  });

  if (req.password.length < 8) {
    return {
      status: Status.Error,
      errors: [{ code: "AUTH_ERROR", message: "Must be longer than 8 characters" }],
    };
  }

  return { status: Status.Success };
}

async function getSha256(text: string): Promise<string> {
  const msgUint8 = new TextEncoder().encode(text);
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join(""); // convert bytes to hex string
  return hashHex;
}

export async function logon(req: LogonRequest): Promise<BaseResponseWithSingle<LogonResponse>> {
  const passwordHash = await getSha256(req.password);
  // console.log(passwordHash);
  const users = identityAppUsers.filter((u) => u.email === req.username && u.password === passwordHash);
  if (users.length === 1) {
    const userOrganisations = organisationOrganisationUsers
      .filter((ou) => ou.userUuid === users[0].uuid)
      .map((ou) => ({
        organisationUuid: organisationOrganisations.find((o) => o.id === ou.organisationId)!.uuid,
        role: ou.role,
      }));
    window.kanaCurrentUserUuid = users[0].uuid;
    return {
      status: Status.Success,
      data: {
        userUuid: users[0].uuid,
        accessToken: "SAMPLE_ACCESS_TOKEN",
      },
    };
  }
  return {
    status: Status.Error,
    errors: [
      {
        code: "VALIDATION_INVALID_CREDENTIALS",
        message: "Please enter a valid username and password",
      },
    ],
  };
}

export async function register(req: RegisterRequest): Promise<BaseResponse> {
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  if (req.password.length < 8)
    return {
      status: Status.Error,
      errors: [
        {
          code: "INVALID_PASSWORD",
          message: "The password must have at least 8 characters",
        },
      ],
    };
  return { status: Status.Success };
}

export async function changePassword(
  req: ChangePasswordRequest
): Promise<BaseResponseWithSingle<ChangePasswordResponse>> {
  await new Promise((r) => {
    setTimeout(r, 150);
  });

  if (req.newPassword.length < 8) {
    return {
      status: Status.Error,
      errors: [{ code: "AUTH_ERROR", message: "Must be longer than 8 characters" }],
    };
  }

  return { status: Status.Success, data: { rowVersion: req.rowVersion + 1 } };
}

export async function updatePersonalDetails(
  req: UpdatePersonalDetailsRequest
): Promise<BaseResponseWithSingle<UpdatePersonalDetailsResponse>> {
  await new Promise((r) => {
    setTimeout(r, 150);
  });
  return { status: Status.Success, data: { rowVersion: req.rowVersion + 1 } };
}

export async function inviteUserToJoinOrganisation(req: InviteUserToJoinOrganisationRequest): Promise<BaseResponse> {
  await new Promise((r) => {
    setTimeout(r, 150);
  });

  if (req.userFirstName.length < 4) {
    return {
      status: Status.Error,
      errors: [
        {
          code: "FIRST_NAME_ERROR",
          message: "First name must be longer than 3 characters",
        },
      ],
    };
  }

  return { status: Status.Success };
}

export async function logout(): Promise<BaseResponseWithSingle<LogoutResponse>> {
  await new Promise((r) => {
    setTimeout(r, 150);
  });

  return { status: Status.Success, data: { result: true } };
}
