import { useEffect } from "react";

interface UseErrorBoxReturnData {
  elementId: string;
  messages: string[];
}

export function useErrorBox(text: string | string[]): UseErrorBoxReturnData {
  const elementId = `ErrorBox${new Date().getTime()}`;
  const messages = Array.isArray(text) ? text : [text];

  useEffect(() => {
    document.getElementById(elementId)?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return {
    elementId,
    messages,
  };
}
