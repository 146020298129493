// Note - commented code relates to avatar section of the form which has been temporarily removed, will be re-added later
import { Dispatch, FormEvent, SetStateAction, useEffect, useState } from "react";

import { updatePersonalDetails } from "../../../../../service/identity";
import { ServiceError, Status } from "../../../../../service/Shared";
import { useAuth } from "../../../../../useAuth";
import { Toast } from "../../../../../widget";

interface UseChangePersonalDetailsFormReturnData {
  // TEMP: following values are only used for the avatar section
  // avatarUploadRef: ForwardedRef<FileUploadHandle>;
  // currentUserUuid: string;
  firstName: string;
  lastName: string;
  email: string;
  errors: ServiceError[] | undefined;
  setFirstName: Dispatch<SetStateAction<string>>;
  setLastName: Dispatch<SetStateAction<string>>;
  setEmail: Dispatch<SetStateAction<string>>;
  // TEMP: following value are only used for the avatar section
  // setProfilePicture: Dispatch<SetStateAction<File | null>>;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  onCancel: () => void;
}

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  rowVersion: number;
}

export const useChangePersonalDetailsForm = (defaultValues: FormData): UseChangePersonalDetailsFormReturnData => {
  const { user, updateCurrentUserPersonalDetails } = useAuth();
  // TEMP: following values are only used for the avatar section
  // const { user } = useAuth();
  // const avatarUploadRef = useRef<FileUploadHandle>(null);
  const [submittedValues, setSubmittedValues] = useState<FormData>(defaultValues);

  const [firstName, setFirstName] = useState(defaultValues.firstName);
  const [lastName, setLastName] = useState(defaultValues.lastName);
  const [email, setEmail] = useState(defaultValues.email);
  const [profilePicture] = useState<File | null>(null);
  const [rowVersion, setRowVersion] = useState(defaultValues.rowVersion);
  const [errors, setErrors] = useState<ServiceError[] | undefined>();

  const resetForm = (): void => {
    setFirstName(submittedValues.firstName);
    setLastName(submittedValues.lastName);
    setEmail(submittedValues.email);
    // TEMP: will need for resetting avatar section
    // if (avatarUploadRef.current) {
    //   avatarUploadRef.current.clearInput();
    // }
    // setProfilePicture(null);
    setErrors([]);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setErrors([]);
    const res = await updatePersonalDetails({
      email,
      firstName,
      lastName,
      avatarFile: profilePicture,
      rowVersion,
    });

    if (res.status === Status.Success && res.data) {
      Toast.success({ message: "Personal details changed successfully" });
      setSubmittedValues({
        firstName,
        lastName,
        email,
        rowVersion: res.data?.rowVersion,
      });
      setRowVersion(res.data?.rowVersion);

      if (user) {
        updateCurrentUserPersonalDetails({
          ...user,
          firstName,
          lastName,
          email,
        });
      }
    }

    if (res.status === Status.Error) {
      setErrors(res.errors);
    }
  };

  useEffect(() => {
    resetForm();
  }, [submittedValues]);

  const onCancel = (): void => {
    resetForm();
  };

  // TEMP: Commented out values are used in avatar section
  return {
    // avatarUploadRef,
    // // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    // currentUserUuid: user!.userUuid,
    firstName,
    lastName,
    email,
    errors,
    setFirstName,
    setLastName,
    setEmail,
    // setProfilePicture,
    handleSubmit,
    onCancel,
  };
};
