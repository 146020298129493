import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  GetProjectDetailsResponse,
  getProjectIssuances,
  GetProjectIssuancesResponse,
} from "../../../../../service/publicquery";
import { Status } from "../../../../../service/Shared";
import { usePublicContext } from "../../../usePublicContext";

interface UsePreviewReturnData {
  isLoading: boolean;
  projectDetails: GetProjectDetailsResponse | undefined;
  tags: string[] | null;
  chartData: { [key: string]: number } | undefined;
}

export const usePreview = (): UsePreviewReturnData => {
  const { uuid } = useParams();
  const { projectDetails } = usePublicContext();
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState<{ [key: string]: number } | undefined>(undefined);
  const tags = !!projectDetails?.tags && !!projectDetails?.tags.length ? projectDetails?.tags : null;

  const fetchData = useCallback(async (projectUuid: string) => {
    const response = await getProjectIssuances({ projectUuid });
    if (response.status === Status.Success && response.data) {
      const projectIssuancesChartData = response.data.reduce((tmp: { [key: string]: number }, value) => {
        const endYear = value.vintageEndDate.getFullYear();
        const period = endYear.toString();

        if (response.data !== null && response.data !== undefined)
          // eslint-disable-next-line no-param-reassign
          tmp[period] = response.data
            .filter((d) => d.vintageEndDate.getFullYear() <= endYear)
            .reduce((acc: number, k: GetProjectIssuancesResponse) => acc + k.quantity, 0);
        return tmp;
      }, {});

      setChartData(projectIssuancesChartData);
    }
  }, []);

  useEffect(() => {
    if (uuid) {
      fetchData(uuid).then(() => setIsLoading(false));
    }
  }, [fetchData]);

  return {
    isLoading,
    chartData,
    tags,
    projectDetails,
  };
};
