/* eslint-disable no-nested-ternary */
import { BarChart, Button, Column, DataGrid, Row } from "../../../../../../widget";
import { useIssuancesForm } from "./useIssuancesForm";

export const IssuancesForm = (): JSX.Element => {
  const { chartData, dataGridColumns, dataGridData, isLoading, onSubmit } = useIssuancesForm();
  return (
    <div>
      {!!Object.keys(chartData).length && (
        <Row spacingV="ll">
          <Column span={12}>
            <BarChart data={chartData} />
          </Column>
        </Row>
      )}
      <form onSubmit={onSubmit}>
        <Row spacingV="ll">
          <Column span={12}>
            <DataGrid
              clientSide
              columns={dataGridColumns}
              data={dataGridData}
              pagination={{ pageSize: dataGridData.length }}
              filterable={false}
              sortable={false}
              pageable={false}
              dataIsLoading={isLoading}
              noDataMessage="The project is not yet validated, so issuance data is not available
            yet."
            />
          </Column>
        </Row>
        {!isLoading && !!dataGridData.length && (
          <Row justify="end">
            <Column span={12}>
              <Button text="Save" type="submit" />
            </Column>
          </Row>
        )}
      </form>
    </div>
  );
};

// Old code for IssuancesForm.tsx
// import { Dispatch } from "react";

// import {
//   BarChart,
//   Button,
//   Column,
//   DataGrid,
//   Row,
// } from "../../../../../../../widget";
// import { IssuancesAndPricingFormChangedAction } from "../../models";
// import { useIssuancesForm } from "./useIssuancesForm";

// interface IssuancesFormProps {
//   dispatch: Dispatch<IssuancesAndPricingFormChangedAction>;
// }

// export const IssuancesForm = ({
//   dispatch,
// }: IssuancesFormProps): JSX.Element => {
//   const { onSubmit, chartData, dataGridColumns, dataGridData } =
//     useIssuancesForm(dispatch);

//   return (
//     <>
//       <Row spacingV="ll">
//         <Column span={12}>
//           <h2>Issuances</h2>
//         </Column>
//       </Row>
//       <Row spacingV="ll">
//         <Column span={12}>
//           <BarChart data={chartData} />
//         </Column>
//       </Row>
//       <form onSubmit={onSubmit}>
//         <Row spacingV="ll">
//           <Column span={12}>
//             <DataGrid
//               clientSide
//               columns={dataGridColumns}
//               data={dataGridData}
//               pagination={{ pageSize: dataGridData.length }}
//               filterable={false}
//               sortable={false}
//               pageable={false}
//             />
//           </Column>
//         </Row>
//         <Row justify="end">
//           <Column span={12}>
//             <Button text="Save" type="submit" />
//           </Column>
//         </Row>
//       </form>
//     </>
//   );
// };
