import "./StackedBarChart.css";

import { ChartData } from "chart.js";
import { Bar } from "react-chartjs-2";

import { ColorConstants } from "../../../constants";
import { maybeSetGlobalChartJsConfiguration } from "../../../utils";
import { ChartProps } from "../ChartProps";

maybeSetGlobalChartJsConfiguration();

export interface StackedBarChartProps extends ChartProps {
  mainLabel: string;
  secondaryChart: {
    label: string;
    data: number[];
  };
  emptyPlaceholder?: string;
}

export const options = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: true,
      reverse: true,
      position: "bottom" as const,
      labels: {
        padding: 40,
        boxWidth: 16,
        boxHeight: 16,
      },
    },
    tooltip: {
      displayColors: false,
      callbacks: {
        title: () => "",
      },
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      ticks: {
        display: false,
      },
      grid: {
        display: false,
        drawBorder: false,
      },
    },
  },
};

export const StackedBarChart = ({
  data,
  mainLabel,
  secondaryChart,
  emptyPlaceholder = "No data to display",
}: StackedBarChartProps): JSX.Element => {
  if (!data || Object.keys(data).length === 0)
    return (
      <div className="StackedBar StackedBarEmpty">
        <p className="body3">{emptyPlaceholder}</p>
      </div>
    );

  const xLabels = Object.keys(data);
  const dataPoints = xLabels.map((label) => data[label]);
  const chartData: ChartData<"bar", number[], string> = {
    labels: xLabels,
    datasets: [
      {
        label: mainLabel,
        data: dataPoints,
        backgroundColor: ColorConstants.BLUE,
        hoverBackgroundColor: ColorConstants.BLUE_80,
      },
      {
        label: secondaryChart.label,
        data: secondaryChart.data,
        backgroundColor: ColorConstants.BLUE_40,
      },
    ],
  };
  return (
    <div className="StackedBar">
      <Bar options={options} data={chartData} />
    </div>
  );
};
