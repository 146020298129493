import "./SingleFileUpload.css";

import { forwardRef, ReactNode } from "react";

import { Logo } from "../../../data";
import { useSingleFileUpload } from "./useSingleFileUpload";

interface SingleFileUploadProps {
  label?: string;
  btnLabel?: string;
  maxFileSize?: number;
  informationLabel?: string;
  disabled?: boolean;
  previewUrl?: string | null;
  previewLabel?: string;
  tooltip?: ReactNode;
  onChange: (e: File) => void;
}

export const SingleFileUpload = forwardRef(
  (
    {
      label = "Single file upload",
      btnLabel = "Choose a file",
      maxFileSize = 1,
      informationLabel,
      disabled = false,
      previewUrl = undefined,
      previewLabel = "Image preview",
      tooltip,
      onChange,
    }: SingleFileUploadProps,
    forwardedRef
  ): JSX.Element => {
    const { id, selectedFile, inputRef, onFileChange } = useSingleFileUpload({
      onChange,
      forwardedRef,
      maxFileSize,
    });
    return (
      <div className="SingleFileUpload">
        <div
          className={`form_field SingleFileUploadInputWrapper ${
            disabled ? "SingleFileUploadInputWrapper_disabled" : ""
          }`}
        >
          <div className="LabelValue">
            <div>
              <label htmlFor={id}>{label}</label>
            </div>
            {tooltip}
          </div>
          <input type="file" id={id} disabled={disabled} ref={inputRef} onChange={onFileChange} />
          <label className="SingleFileUploadInput" htmlFor={id}>
            <span className="SingleFileUploadInputFileBox body2">{selectedFile && selectedFile.name}</span>
            <span className="SingleFileUploadInputBtn body2">{btnLabel}</span>
          </label>
          <span className="body2">{informationLabel ?? `Max file size ${maxFileSize}MB`}</span>
        </div>
        {previewUrl && (
          <div className="SingleFileUploadPreview">
            <Logo displayedLogo={previewUrl} displayedLabel={previewLabel} />
          </div>
        )}
      </div>
    );
  }
);
