/* eslint no-console:0 */
import "./MultipleFileUpload.css";

import Upload from "rc-upload";
import { forwardRef } from "react";

import { FileBlueIcon, FilePlusBlueIcon, TrashDark } from "../../../../assets";
import { PreviewFile } from "../../../../models";
import { isMimeTypeImage } from "../../../../utils";
import { DragAndDropOrderBehavior, useMultipleFileUpload } from "./useMultipleFileUpload";

interface MultipleFileUploadProps {
  onFilesChange: (previewFiles: PreviewFile[]) => void;
  inputFiles?: PreviewFile[];
  title?: string;
  subTitle?: string;
  maxFileSize?: number;
  maxFileCount?: number;
  informationLabel?: string;
  accept?: string;
  orderBehavior?: DragAndDropOrderBehavior;
}
export const MultipleFileUpload = forwardRef(
  (
    {
      title = "Multiple file upload",
      subTitle,
      maxFileSize = 1,
      maxFileCount = 10,
      informationLabel,
      accept = "image/*",
      onFilesChange,
      inputFiles,
      orderBehavior = "move",
    }: MultipleFileUploadProps,
    forwardedRef
  ): JSX.Element => {
    const { id, previewFiles, onDragOverDropZone, setOnDragOverDropZone, onAction, onRemoveFile, onFileDrop } =
      useMultipleFileUpload({
        onFilesChange,
        inputFiles,
        orderBehavior,
        maxFileCount,
        maxFileSize,
        forwardedRef,
      });

    return (
      <div className="MultipleFileUploadWrapper">
        <label htmlFor={id}>{title}</label>
        {subTitle && <p className="body1">{subTitle}</p>}
        <Upload
          action={onAction}
          multiple
          className={`MultipleFileUpload ${onDragOverDropZone ? "MultipleFileUploadContentDragOver" : ""}`}
          accept={accept}
          id={id}
          customRequest={() => null} // Removing default component request by providing an empty one
        >
          <div
            className="MultipleFileUploadContent"
            onDragOver={() => setOnDragOverDropZone(true)}
            onDragLeave={() => setOnDragOverDropZone(false)}
          >
            <FilePlusBlueIcon />
            <p className="body1">Click or drag a file to this area to upload</p>
          </div>
        </Upload>
        <div className="body2 MultipleFileUploadInformation">
          {informationLabel ?? `Max file size ${maxFileSize}MB`}
        </div>
        {previewFiles.map((el) => (
          <div
            className={`MultipleFileUploadItem ${!el.file ? "MultipleFileUploadItemDefault" : ""}`}
            key={el.uuid}
            draggable
            onDrop={(ev) => {
              const draggedFileId = ev.dataTransfer.getData("fileId");
              onFileDrop(el.uuid, draggedFileId);
            }}
            onDragOver={(ev) => ev.preventDefault()}
            onDragStart={(ev) => ev.dataTransfer.setData("fileId", el.uuid)}
          >
            <div>
              <a href={el.url} target="_blank" rel="noreferrer">
                {isMimeTypeImage(el.mimeType) ? (
                  <img alt={el.filename} src={el.url} width={24} height={24} />
                ) : (
                  <div>
                    <FileBlueIcon />
                  </div>
                )}
              </a>
              <p>{el.filename}</p>
            </div>
            <button onClick={() => onRemoveFile(el)}>
              <TrashDark />
            </button>
          </div>
        ))}
      </div>
    );
  }
);
