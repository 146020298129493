import "./Modal.css";

import React, { ReactNode } from "react";

import { CloseDarkIcon } from "../../../assets";
import { useTrapFocusWithinDiv } from "../../../utils";
import { Backdrop } from "./components";

export interface ModalProps {
  show: boolean;
  title: string;
  onClose: () => void;
  onBackdrop?: () => void;
  children: ReactNode;
}

export const Modal: React.FC<ModalProps> = ({ show, title, children, onClose, onBackdrop }): JSX.Element | null => {
  const modalRefCallBack = useTrapFocusWithinDiv();

  return show ? (
    <>
      <div ref={modalRefCallBack} className="Modal">
        <button className="ModalCloseIcon" onClick={onClose}>
          <CloseDarkIcon width={16} height={18} />
        </button>
        <h1>{title}</h1>
        <div className="ModalContent">{children}</div>
      </div>
      <Backdrop show={show} onClick={onBackdrop} />
    </>
  ) : null;
};
