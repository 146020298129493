import { CodeDetailsData } from "../../../route/public/code/details/page/useDetails";
import { PublicCodeDetails } from "../components";
import { usePublicCodeDetailsPageTemplate } from "./usePublicCodeDetailsPageTemplate";

interface PublicCodeDetailsPageTemplateProps {
  codeDetailsData?: CodeDetailsData;
}

export const PublicCodeDetailsPageTemplate = ({ codeDetailsData }: PublicCodeDetailsPageTemplateProps): JSX.Element => {
  const { organisationUuid, organisationProjects, navigateBackToProject, navigateBackToCodeComparison } =
    usePublicCodeDetailsPageTemplate();
  return (
    <div>
      <PublicCodeDetails
        codeDetailsData={codeDetailsData}
        organisationUuid={organisationUuid}
        organisationProjects={organisationProjects}
        isPreview={false}
        navigateBackToProject={navigateBackToProject}
        navigateBackToCodeComparison={navigateBackToCodeComparison}
      />
    </div>
  );
};
