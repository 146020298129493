import { KeyFactsData } from "../../../../route/public/code/models";
import { ChartsSection } from "../../../components";
import { UseKeyFactsSection } from "./useKeyFactsSection";

interface KeyFactsSectionProps {
  keyFactsText: Omit<KeyFactsData, "enabled">;
  organisationUuid: string | undefined;
}

export const KeyFactsSection = ({ keyFactsText, organisationUuid }: KeyFactsSectionProps): JSX.Element => {
  const { totalActiveProjectsChartData, unitsCreatedChartData, totalPlannedCarbonSequestrationChartData, isLoading } =
    UseKeyFactsSection(organisationUuid);
  return (
    <div>
      {!isLoading && (
        <ChartsSection
          title="Key facts"
          content={keyFactsText?.content}
          projectsData={totalActiveProjectsChartData}
          unitsData={unitsCreatedChartData}
          locationsData={totalPlannedCarbonSequestrationChartData}
        />
      )}
    </div>
  );
};
