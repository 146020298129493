import "./HandleTooltipRender.css";

import RcTooltip from "rc-tooltip";
import { ReactElement } from "react";

import { formatNumber } from "../../../../../utils";
import { useHandleTooltipRender } from "./useHandleTooltipRender";

export const HandleTooltipRender = (
  node: ReactElement,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleProps: any,
  formatValue?: (val: number | undefined) => string | undefined
): ReactElement => {
  const { value } = handleProps;
  const { tooltipRef } = useHandleTooltipRender(value);

  return (
    <RcTooltip
      visible
      ref={tooltipRef}
      placement="bottom"
      overlayClassName="TooltipSlider"
      overlay={formatValue ? formatValue(value) : formatNumber(value)}
    >
      {node}
    </RcTooltip>
  );
};
