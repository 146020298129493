import "./PublicProjectTypesTemplate.css";

import { useNavigate } from "react-router-dom";

import { getPublicProjectTypeRoute } from "../../utils/routes";
import { Column, Pill, Row } from "../../widget";
import { ProjectTypesConstants } from "../constants";

export const PublicProjectTypesTemplate = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <div className="ProjectTypes">
      <Row spacingV="m">
        <Column span={12}>
          <h1>Project types</h1>
        </Column>
      </Row>
      <Row spacingV="4xl">
        <Column span={12}>
          <p className="body1">
            Nature-based carbon projects are those that protect, maintain and restore the natural ecosystems of the
            earth, on both land and at sea. Across UK carbon projects there are multiple project types & subtypes.
          </p>
          <p className="body1">
            The type of project will determine the speed at which the project can reduce or sequester carbon and other
            factors such as its potential within the UK or additional benefits that the project may bring to the area.
          </p>
        </Column>
      </Row>
      <Row spacingV="m">
        <Column span={12}>
          <div className="ProjectTypesPill">
            <h2>Above the ground</h2>
            <Pill label="Green projects" variant="green" />
          </div>
        </Column>
      </Row>
      <Row spacingV="ml">
        <Column span={12}>
          <p className="body1">
            These are the nature-based solutions most people think of first, often centred on preventing deforestation
            or promoting reforestation. And while we talk about them as taking place above the ground, they also have
            below-ground benefits too, supporting and and improving soil health
          </p>
        </Column>
      </Row>
      <Row spacingV="3xl">
        <Column span={12}>
          <div className="CodesTableWrapper">
            <table>
              <tbody>
                <tr>
                  <th>
                    <button
                      className="BtnLink"
                      onClick={() =>
                        navigate(getPublicProjectTypeRoute(ProjectTypesConstants.WOODLAND_CREATION_PROJECT_TYPE))
                      }
                    >
                      Forestry and Woodland Creation
                    </button>
                  </th>
                  <td>
                    <p className="body2">
                      Improving tree coverage (both its extent and its diversity) can help us capture and store CO2,
                      support biodiversity and prevent soil erosion, among other benefits.
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <button
                      className="BtnLink"
                      onClick={() => navigate(getPublicProjectTypeRoute(ProjectTypesConstants.HEDGEROW_PROJECT_TYPE))}
                    >
                      Hedgerow and Agroforestry
                    </button>
                  </th>
                  <td>
                    <p className="body2">
                      Planting hedgerows provides more capacity to capture CO2, while providing habitats and corridors
                      for wildlife.
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <button
                      className="BtnLink"
                      onClick={() => navigate(getPublicProjectTypeRoute(ProjectTypesConstants.REWILDING_PROJECT_TYPE))}
                    >
                      Rewilding
                    </button>
                  </th>
                  <td>
                    <p className="body2">
                      Rewilding involves restoring land to its natural, uncultivated state. This provides further
                      potential for carbon capture and storage, and make our natural environmental more liveable for
                      all.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Column>
      </Row>
      <Row spacingV="m">
        <Column span={12}>
          <div className="ProjectTypesPill">
            <h2>Below the ground</h2>
            <Pill label="Brown projects" variant="coral" />
          </div>
        </Column>
      </Row>
      <Row spacingV="ml">
        <Column span={12}>
          <p className="body1">These projects are focused below the ground</p>
        </Column>
      </Row>
      <Row spacingV="4xl">
        <Column span={12}>
          <div className="CodesTableWrapper">
            <table>
              <tbody>
                <tr>
                  <th>
                    <button
                      className="BtnLink"
                      onClick={() =>
                        navigate(getPublicProjectTypeRoute(ProjectTypesConstants.PEATLAND_RESTORATION_PROJECT_TYPE))
                      }
                    >
                      Peatland
                    </button>
                  </th>
                  <td>
                    <p className="body2">
                      Peatland is one of the world&apos;s most naturally-effective carbon sinks; high-quality peatland
                      can also improve water quality.
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <button
                      className="BtnLink"
                      onClick={() => navigate(getPublicProjectTypeRoute(ProjectTypesConstants.SOIL_PROJECT_TYPE))}
                    >
                      Soil Carbon
                    </button>
                  </th>
                  <td>
                    <p className="body2">Healthy soil provides the world&apos;s largest store of terrestrial carbon.</p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <button
                      className="BtnLink"
                      onClick={() => navigate(getPublicProjectTypeRoute(ProjectTypesConstants.BIOENERGY_PROJECT_TYPE))}
                    >
                      Bio Energy
                    </button>
                  </th>
                  <td>
                    <p className="body2">
                      Recently-living organic materials known as biomass, can be used to produce transportation fuels,
                      heat, electricity, and products.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Column>
      </Row>
      <Row spacingV="m">
        <Column span={12}>
          <div className="ProjectTypesPill">
            <h2>At sea and in wetlands</h2>
            <Pill label="Blue projects" variant="cyan" />
          </div>
        </Column>
      </Row>
      <Row spacingV="ml">
        <Column span={12}>
          <p className="body1">These projects are focused on the coast or bodies of water</p>
        </Column>
      </Row>
      <Row spacingV="4xl">
        <Column span={12}>
          <div className="CodesTableWrapper">
            <table>
              <tbody>
                <tr>
                  <th>
                    <button
                      className="BtnLink"
                      onClick={() =>
                        navigate(getPublicProjectTypeRoute(ProjectTypesConstants.SEAGRASS_RESTORATION_PROJECT_TYPE))
                      }
                    >
                      Seagrass Restoration
                    </button>
                  </th>
                  <td>
                    <p className="body2">
                      Living in shallow, sheltered areas along the coast, seagrass captures carbon up to 35 times faster
                      than tropical rainforests and absorbs 10% of the ocean&apos;s carbon each year.
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <button
                      className="BtnLink"
                      onClick={() =>
                        navigate(getPublicProjectTypeRoute(ProjectTypesConstants.CONSTRUCTED_WETLAND_PROJECT_TYPE))
                      }
                    >
                      Constructed Wetland
                    </button>
                  </th>
                  <td>
                    <p className="body2">
                      Protecting wetlands can provide carbon sinks while securing and regulating water supplies, and
                      protecting communities and infrastructure from floods, soil erosion and landslides.
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <button
                      className="BtnLink"
                      onClick={() => navigate(getPublicProjectTypeRoute(ProjectTypesConstants.SALTMARSH_PROJECT_TYPE))}
                    >
                      Saltmarsh Restoration
                    </button>
                  </th>
                  <td>
                    <p className="body2">
                      The vegetation that grows in saltmarshes captures and stores carbon, but this natural asset also
                      supports wildlife and provides us with sea defences.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Column>
      </Row>
      <Row spacingV="m">
        <Column span={12}>
          <div className="ProjectTypesPill">
            <h2>In manufacturing</h2>
            <Pill label="Grey projects" variant="grey" />
          </div>
        </Column>
      </Row>
      <Row spacingV="ml">
        <Column span={12}>
          <p className="body1">These projects are focused on the manufacture of products designed to capture carbon</p>
        </Column>
      </Row>
      <Row>
        <Column span={12}>
          <div className="CodesTableWrapper">
            <table>
              <tbody>
                <tr>
                  <th>
                    <button
                      className="BtnLink"
                      onClick={() =>
                        navigate(getPublicProjectTypeRoute(ProjectTypesConstants.ENHANCED_WEATHERING_PROJECT_TYPE))
                      }
                    >
                      Enhanced Weathering
                    </button>
                  </th>
                  <td>
                    <p className="body2">
                      This is an artificial process that mimics the way that bicarbonate is formed through rain that
                      falls to the ground having absorbed CO2 from the atmosphere; as this bicarbonate washes into the
                      ocean, the carbon is locked into the sea floor.
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <button
                      className="BtnLink"
                      onClick={() => navigate(getPublicProjectTypeRoute(ProjectTypesConstants.BIOCHAR_PROJECT_TYPE))}
                    >
                      Biochar
                    </button>
                  </th>
                  <td>
                    <p className="body2">
                      The waste material left over crop harvest can be baked to convert it to a form of charcoal called
                      biochar; this process locks much of the plant&apos;s carbon into a solid form that doesn&apos;t
                      easily decompose. When added back to croplands, some types types of biochar can also improve soil
                      health.
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <button
                      className="BtnLink"
                      onClick={() =>
                        navigate(getPublicProjectTypeRoute(ProjectTypesConstants.LIVESTOCK_FEEDS_PROJECT_TYPE))
                      }
                    >
                      Livestock Feed
                    </button>
                  </th>
                  <td>
                    <p className="body2">
                      Some 1.8 million dairy cows in the UK omit 2.3 tonnes of methane gas each year, but feed additives
                      can reduce those emissions by a third.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Column>
      </Row>
    </div>
  );
};
