import "./ProjectTypesSection.css";

import { Link } from "react-router-dom";

import { ProjectTypesData } from "../../../../route/public/code/models";
import { getPublicProjectTypeRoute } from "../../../../utils/routes";

interface ProjectTypesSectionProps {
  codeUuid: string | undefined;
  projectTypes: ProjectTypesData;
}

export const ProjectTypesSection = ({ codeUuid, projectTypes }: ProjectTypesSectionProps): JSX.Element => {
  return (
    <div className="ProjectTypesSection">
      <h2>Project types supported</h2>
      <div className="ProjectTypesLinks">
        {projectTypes.projectTypes?.map((projectType) => {
          return (
            <div key={projectType.code}>
              {projectType.code && (
                <p className="body1">
                  <Link
                    to={{
                      pathname: getPublicProjectTypeRoute(projectType.code),
                      search: `codeUuid=${codeUuid}`,
                    }}
                  >
                    {projectType.name}
                  </Link>
                </p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
