import { Modal } from "../../../../../widget";
import { ContactUsModalForm } from "./form";

interface ContactUsModalProps {
  developerName: string;
  developerPhoneNumber: string | null | undefined;
  show: boolean;
  closeModal: () => void;
}

export const ContactUsModal = ({
  developerName,
  developerPhoneNumber,
  show,
  closeModal,
}: ContactUsModalProps): JSX.Element => {
  return (
    <Modal show={show} title="Contact Us" onClose={closeModal}>
      <ContactUsModalForm
        developerName={developerName}
        developerPhoneNumber={developerPhoneNumber}
        closeModal={closeModal}
      />
    </Modal>
  );
};
