import { Dispatch, ForwardedRef, SetStateAction, useCallback, useEffect, useReducer, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { Origin, PublicDeveloperDetailsPreviewParams } from "../../../../models";
import { logError } from "../../../../service/error";
import { getOrganisationListingPreview, saveOrganisationListingPreview } from "../../../../service/organisation";
import { getOrganisationDetails } from "../../../../service/query";
import { ServiceError, Status } from "../../../../service/Shared";
import { useAuth } from "../../../../useAuth";
import { recursivelyRemoveEmptyStrings } from "../../../../utils/rest";
import { getPublicDeveloperDetailsPreviewRoute } from "../../../../utils/routes";
import {
  FormAboutUsData,
  FormAtAGlanceData,
  FormGetInTouchData,
  FormHeroData,
  FormNames,
  FormOurApproachData,
  FormOurServicesData,
  FormOurValuesData,
  MarketingAssetsFormChangedAction,
  MarketingAssetsFormChangedEnum,
  MarketingAssetsSubmitHandler,
} from "./models";

interface UseMarketingAssetsReturnData {
  heroFormRef: ForwardedRef<MarketingAssetsSubmitHandler>;
  ourValuesFormRef: ForwardedRef<MarketingAssetsSubmitHandler>;
  getInTouchFormRef: ForwardedRef<MarketingAssetsSubmitHandler>;
  ourApproachFormRef: ForwardedRef<MarketingAssetsSubmitHandler>;
  atAGlanceFormRef: ForwardedRef<MarketingAssetsSubmitHandler>;
  ourServicesFormRef: ForwardedRef<MarketingAssetsSubmitHandler>;
  aboutUsFormRef: ForwardedRef<MarketingAssetsSubmitHandler>;
  dataIsLoading: boolean;
  hasUnsavedChanges: MarketingAssetsHasUnsavedChanges;
  listingRowVersion: number;
  setListingRowVersion: Dispatch<SetStateAction<number>>;
  dispatch: Dispatch<MarketingAssetsFormChangedAction>;
  heroDefaultValues: FormHeroData | undefined;
  ourValuesDefaultValues: FormOurValuesData | undefined;
  getInTouchDefaultValues: FormGetInTouchData | undefined;
  ourApproachDefaultValues: FormOurApproachData | undefined;
  atAGlanceDefaultValues: FormAtAGlanceData | undefined;
  ourServicesDefaultValues: FormOurServicesData | undefined;
  aboutUsDefaultValues: FormAboutUsData | undefined;
  heroOldDefaultValues: FormHeroData | undefined;
  ourValuesOldDefaultValues: FormOurValuesData | undefined;
  getInTouchOldDefaultValues: FormGetInTouchData | undefined;
  ourApproachOldDefaultValues: FormOurApproachData | undefined;
  atAGlanceOldDefaultValues: FormAtAGlanceData | undefined;
  ourServicesOldDefaultValues: FormOurServicesData | undefined;
  aboutUsOldDefaultValues: FormAboutUsData | undefined;
  handlePreview: () => void;
  previewMode: boolean;
  getCurrentFormsData: (formWhichWasSaved: string) => string;
}

interface MarketingAssetsHasUnsavedChanges {
  hero: boolean;
  ourValues: boolean;
  getInTouch: boolean;
  ourApproach: boolean;
  atAGlance: boolean;
  ourServices: boolean;
  aboutUs: boolean;
}

const reducer = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  prevState: any,
  action: MarketingAssetsFormChangedAction
): MarketingAssetsHasUnsavedChanges => {
  switch (action.type) {
    case MarketingAssetsFormChangedEnum.SET_HERO_FORM_CHANGED: {
      return {
        ...prevState,
        hero: action.value,
      };
    }
    case MarketingAssetsFormChangedEnum.SET_OUR_VALUES_FORM_CHANGED: {
      return {
        ...prevState,
        ourValues: action.value,
      };
    }
    case MarketingAssetsFormChangedEnum.SET_GET_IN_TOUCH_FORM_CHANGED: {
      return {
        ...prevState,
        getInTouch: action.value,
      };
    }
    case MarketingAssetsFormChangedEnum.SET_OUR_APPROACH_FORM_CHANGED: {
      return {
        ...prevState,
        ourApproach: action.value,
      };
    }
    case MarketingAssetsFormChangedEnum.SET_AT_A_GLANCE_FORM_CHANGED: {
      return {
        ...prevState,
        atAGlance: action.value,
      };
    }
    case MarketingAssetsFormChangedEnum.SET_OUR_SERVICES_FORM_CHANGED: {
      return {
        ...prevState,
        ourServices: action.value,
      };
    }
    case MarketingAssetsFormChangedEnum.SET_ABOUT_US_FORM_CHANGED: {
      return {
        ...prevState,
        aboutUs: action.value,
      };
    }

    default: {
      return {
        // unrecognized dispatch action
        ...prevState,
      };
    }
  }
};

export function useMarketingAssets(): UseMarketingAssetsReturnData {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { previewUuid } = useParams<PublicDeveloperDetailsPreviewParams>();
  const { currentOrganisationUuid } = useAuth();

  const [hasUnsavedChanges, dispatch] = useReducer(reducer, {
    hero: false,
    ourValues: false,
    getInTouch: false,
    ourApproach: false,
    atAGlance: false,
    ourServices: false,
    aboutUs: false,
  });

  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [previewMode, setPreviewMode] = useState<boolean>(false);
  const [listingRowVersion, setListingRowVersion] = useState<number>(1);

  const [heroDefaultValues, setHeroDefaultValues] = useState<FormHeroData | undefined>(undefined);
  const [ourValuesDefaultValues, setOurValuesDefaultValues] = useState<FormOurValuesData | undefined>(undefined);
  const [getInTouchDefaultValues, setGetInTouchDefaultValues] = useState<FormGetInTouchData | undefined>(undefined);
  const [ourApproachDefaultValues, setOurApproachDefaultValues] = useState<FormOurApproachData | undefined>(undefined);
  const [atAGlanceDefaultValues, setAtAGlanceDefaultValues] = useState<FormAtAGlanceData | undefined>(undefined);
  const [ourServicesDefaultValues, setOurServicesDefaultValues] = useState<FormOurServicesData | undefined>(undefined);
  const [aboutUsDefaultValues, setAboutUsDefaultValues] = useState<FormAboutUsData | undefined>(undefined);

  const [heroOldDefaultValues, setHeroOldDefaultValues] = useState<FormHeroData | undefined>(undefined);
  const [ourValuesOldDefaultValues, setOurValuesOldDefaultValues] = useState<FormOurValuesData | undefined>(undefined);
  const [getInTouchOldDefaultValues, setGetInTouchOldDefaultValues] = useState<FormGetInTouchData | undefined>(
    undefined
  );
  const [ourApproachOldDefaultValues, setOurApproachOldDefaultValues] = useState<FormOurApproachData | undefined>(
    undefined
  );
  const [atAGlanceOldDefaultValues, setAtAGlanceOldDefaultValues] = useState<FormAtAGlanceData | undefined>(undefined);
  const [ourServicesOldDefaultValues, setOurServicesOldDefaultValues] = useState<FormOurServicesData | undefined>(
    undefined
  );
  const [aboutUsOldDefaultValues, setAboutUsOldDefaultValues] = useState<FormAboutUsData | undefined>(undefined);

  // This hook needs to call child methods in order to get the latest values from each child form
  // This approach should not be replicated in other pages, an exception was made here because of the large number of forms and fields
  // More information in the project standards https://www.notion.so/kana-earth/TypeScript-React-9c0cde0bbf07429ca4d84d5ca9f388b5#0582203770284f33b013224f58b46675
  const heroFormRef = useRef<MarketingAssetsSubmitHandler>(null);
  const ourValuesFormRef = useRef<MarketingAssetsSubmitHandler>(null);
  const getInTouchFormRef = useRef<MarketingAssetsSubmitHandler>(null);
  const ourApproachFormRef = useRef<MarketingAssetsSubmitHandler>(null);
  const atAGlanceFormRef = useRef<MarketingAssetsSubmitHandler>(null);
  const ourServicesFormRef = useRef<MarketingAssetsSubmitHandler>(null);
  const aboutUsFormRef = useRef<MarketingAssetsSubmitHandler>(null);

  const fetchListingPreviewData = useCallback(async () => {
    if (currentOrganisationUuid) {
      const organisationRes = await getOrganisationDetails({
        organisationUuid: currentOrganisationUuid,
      });

      if (organisationRes.status === Status.Success && organisationRes.data) {
        if (organisationRes.data.listing?.rowVersion) {
          setListingRowVersion(organisationRes.data.listing.rowVersion);
        }

        const heroValuesOld: FormHeroData = {
          enabled: organisationRes.data?.listing?.content?.hero?.enabled
            ? organisationRes.data?.listing?.content?.hero?.enabled
            : false,
          title: organisationRes.data?.listing?.content?.hero?.title,
          subtitle: organisationRes.data?.listing?.content?.hero?.subtitle,
          imageFileUuid: organisationRes.data?.listing?.content?.hero?.imageFileUuid,
          imageFileUrl: organisationRes.data?.listingFiles.find(
            (x) => x.uuid === organisationRes.data?.listing?.content?.hero?.imageFileUuid
          )?.file?.url,
        };
        const ourValuesOld: FormOurValuesData = {
          enabled: organisationRes.data?.listing?.content?.ourValues?.enabled
            ? organisationRes.data?.listing?.content?.ourValues?.enabled
            : false,
          content: organisationRes.data?.listing?.content?.ourValues?.content,
        };
        const getInTouchValuesOld: FormGetInTouchData = {
          enabled:
            organisationRes.data?.listing?.content?.getInTouch?.enabled !== undefined
              ? organisationRes.data?.listing?.content?.getInTouch?.enabled
              : true,
        };
        const ourApproachValuesOld: FormOurApproachData = {
          enabled: organisationRes.data?.listing?.content?.ourApproach?.enabled
            ? organisationRes.data?.listing?.content?.ourApproach?.enabled
            : false,
          part1: organisationRes.data?.listing?.content?.ourApproach?.part1
            ? {
                content: organisationRes.data?.listing?.content?.ourApproach?.part1.content,
                title: organisationRes.data?.listing?.content?.ourApproach?.part1.title,
                imageFileUuid: organisationRes.data?.listing?.content?.ourApproach?.part1.imageFileUuid,
                imageFileUrl: organisationRes.data?.listingFiles.find(
                  (x) => x.uuid === organisationRes.data?.listing?.content?.ourApproach?.part1.imageFileUuid
                )?.file?.url,
              }
            : undefined,
          part2: organisationRes.data?.listing?.content?.ourApproach?.part2
            ? {
                content: organisationRes.data?.listing?.content?.ourApproach?.part2.content,
                title: organisationRes.data?.listing?.content?.ourApproach?.part2.title,
                imageFileUuid: organisationRes.data?.listing?.content?.ourApproach?.part2.imageFileUuid,
                imageFileUrl: organisationRes.data?.listingFiles.find(
                  (x) => x.uuid === organisationRes.data?.listing?.content?.ourApproach?.part2.imageFileUuid
                )?.file?.url,
              }
            : undefined,
          part3: organisationRes.data?.listing?.content?.ourApproach?.part3
            ? {
                content: organisationRes.data?.listing?.content?.ourApproach?.part3.content,
                title: organisationRes.data?.listing?.content?.ourApproach?.part3.title,
                imageFileUuid: organisationRes.data?.listing?.content?.ourApproach?.part3.imageFileUuid,
                imageFileUrl: organisationRes.data?.listingFiles.find(
                  (x) => x.uuid === organisationRes.data?.listing?.content?.ourApproach?.part3.imageFileUuid
                )?.file?.url,
              }
            : undefined,
          part4: organisationRes.data?.listing?.content?.ourApproach?.part4
            ? {
                content: organisationRes.data?.listing?.content?.ourApproach?.part4.content,
                title: organisationRes.data?.listing?.content?.ourApproach?.part4.title,
                imageFileUuid: organisationRes.data?.listing?.content?.ourApproach?.part4.imageFileUuid,
                imageFileUrl: organisationRes.data?.listingFiles.find(
                  (x) => x.uuid === organisationRes.data?.listing?.content?.ourApproach?.part4.imageFileUuid
                )?.file?.url,
              }
            : undefined,
        };
        const atAGlanceValuesOld: FormAtAGlanceData = {
          enabled:
            organisationRes.data?.listing?.content?.atAGlance?.enabled !== undefined
              ? organisationRes.data?.listing?.content?.atAGlance?.enabled
              : true,
        };
        const ourServicesValuesOld: FormOurServicesData = {
          enabled: organisationRes.data?.listing?.content?.ourServices?.enabled
            ? organisationRes.data?.listing?.content?.ourServices?.enabled
            : false,
          part1: organisationRes.data?.listing?.content?.ourServices?.part1
            ? {
                content: organisationRes.data?.listing?.content?.ourServices?.part1.content,
                title: organisationRes.data?.listing?.content?.ourServices?.part1.title,
                imageFileUuid: organisationRes.data?.listing?.content?.ourServices?.part1.imageFileUuid,
                imageFileUrl: organisationRes.data?.listingFiles.find(
                  (x) => x.uuid === organisationRes.data?.listing?.content?.ourServices?.part1.imageFileUuid
                )?.file?.url,
              }
            : undefined,
          part2: organisationRes.data?.listing?.content?.ourServices?.part2
            ? {
                content: organisationRes.data?.listing?.content?.ourServices?.part2.content,
                title: organisationRes.data?.listing?.content?.ourServices?.part2.title,
                imageFileUuid: organisationRes.data?.listing?.content?.ourServices?.part2.imageFileUuid,
                imageFileUrl: organisationRes.data?.listingFiles.find(
                  (x) => x.uuid === organisationRes.data?.listing?.content?.ourServices?.part2.imageFileUuid
                )?.file?.url,
              }
            : undefined,
          part3: organisationRes.data?.listing?.content?.ourServices?.part3
            ? {
                content: organisationRes.data?.listing?.content?.ourServices?.part3.content,
                title: organisationRes.data?.listing?.content?.ourServices?.part3.title,
                imageFileUuid: organisationRes.data?.listing?.content?.ourServices?.part3.imageFileUuid,
                imageFileUrl: organisationRes.data?.listingFiles.find(
                  (x) => x.uuid === organisationRes.data?.listing?.content?.ourServices?.part3.imageFileUuid
                )?.file?.url,
              }
            : undefined,
        };
        const aboutUsValuesOld: FormAboutUsData = {
          enabled: organisationRes.data?.listing?.content?.aboutUs?.enabled
            ? organisationRes.data?.listing?.content?.aboutUs?.enabled
            : false,
          content: organisationRes.data?.listing?.content?.aboutUs?.content,
          imageFileUuid: organisationRes.data?.listing?.content?.aboutUs?.imageFileUuid,
          imageFileUrl: organisationRes.data?.listingFiles.find(
            (x) => x.uuid === organisationRes.data?.listing?.content?.aboutUs?.imageFileUuid
          )?.file?.url,
        };

        setHeroOldDefaultValues(heroValuesOld);
        setOurValuesOldDefaultValues(ourValuesOld);
        setGetInTouchOldDefaultValues(getInTouchValuesOld);
        setOurApproachOldDefaultValues(ourApproachValuesOld);
        setAtAGlanceOldDefaultValues(atAGlanceValuesOld);
        setOurServicesOldDefaultValues(ourServicesValuesOld);
        setAboutUsOldDefaultValues(aboutUsValuesOld);
      }

      if (previewUuid) {
        const res = await getOrganisationListingPreview({
          previewUuid,
        });
        if (res.status === Status.Success && res.data) {
          const heroValues: FormHeroData = {
            enabled: false,
            title: res.data?.content?.hero?.title,
            subtitle: res.data?.content?.hero?.subtitle,
            imageFileUuid: res.data?.content?.hero?.imageFileUuid,
            imageFileUrl: res.data?.listingFiles.find((x) => x.uuid === res.data?.content?.hero?.imageFileUuid)?.file
              ?.url,
          };

          if (res.data?.content?.hero?.enabled !== undefined) {
            heroValues.enabled = res.data?.content?.hero?.enabled;
          }

          setHeroDefaultValues(heroValues);

          const ourValues: FormOurValuesData = {
            enabled: false,
            content: res.data?.content?.ourValues?.content,
          };

          if (res.data?.content?.ourValues?.enabled !== undefined) {
            ourValues.enabled = res.data?.content?.ourValues?.enabled;
          }

          setOurValuesDefaultValues(ourValues);

          const getInTouchValues: FormGetInTouchData = {
            enabled: true,
          };

          if (res.data?.content?.getInTouch?.enabled !== undefined) {
            getInTouchValues.enabled = res.data?.content?.getInTouch?.enabled;
          }

          setGetInTouchDefaultValues(getInTouchValues);

          const ourApproachValues: FormOurApproachData = {
            enabled: false,
            part1: res.data?.content?.ourApproach?.part1
              ? {
                  content: res.data?.content.ourApproach.part1.content,
                  title: res.data?.content.ourApproach.part1.title,
                  imageFileUuid: res.data?.content.ourApproach.part1.imageFileUuid,
                  imageFileUrl: res.data?.listingFiles.find(
                    (x) => x.uuid === res.data?.content.ourApproach.part1.imageFileUuid
                  )?.file?.url,
                }
              : undefined,
            part2: res.data?.content?.ourApproach?.part2
              ? {
                  content: res.data?.content.ourApproach.part2.content,
                  title: res.data?.content.ourApproach.part2.title,
                  imageFileUuid: res.data?.content.ourApproach.part2.imageFileUuid,
                  imageFileUrl: res.data?.listingFiles.find(
                    (x) => x.uuid === res.data?.content.ourApproach.part2.imageFileUuid
                  )?.file?.url,
                }
              : undefined,
            part3: res.data?.content?.ourApproach?.part3
              ? {
                  content: res.data?.content.ourApproach.part3.content,
                  title: res.data?.content.ourApproach.part3.title,
                  imageFileUuid: res.data?.content.ourApproach.part3.imageFileUuid,
                  imageFileUrl: res.data?.listingFiles.find(
                    (x) => x.uuid === res.data?.content.ourApproach.part3.imageFileUuid
                  )?.file?.url,
                }
              : undefined,
            part4: res.data?.content?.ourApproach?.part4
              ? {
                  content: res.data?.content.ourApproach.part4.content,
                  title: res.data?.content.ourApproach.part4.title,
                  imageFileUuid: res.data?.content.ourApproach.part4.imageFileUuid,
                  imageFileUrl: res.data?.listingFiles.find(
                    (x) => x.uuid === res.data?.content.ourApproach.part4.imageFileUuid
                  )?.file?.url,
                }
              : undefined,
          };

          if (res.data?.content?.ourApproach?.enabled !== undefined) {
            ourApproachValues.enabled = res.data?.content?.ourApproach?.enabled;
          }

          setOurApproachDefaultValues(ourApproachValues);

          const atAGlanceValues: FormAtAGlanceData = {
            enabled: true,
          };

          if (res.data?.content?.atAGlance?.enabled !== undefined) {
            atAGlanceValues.enabled = res.data?.content?.atAGlance?.enabled;
          }

          setAtAGlanceDefaultValues(atAGlanceValues);

          const ourServicesValues: FormOurServicesData = {
            enabled: false,
            part1: res.data?.content?.ourServices?.part1
              ? {
                  content: res.data?.content?.ourServices?.part1?.content,
                  title: res.data?.content?.ourServices?.part1?.title,
                  imageFileUuid: res.data?.content?.ourServices?.part1?.imageFileUuid,
                  imageFileUrl: res.data?.listingFiles.find(
                    (x) => x.uuid === res.data?.content?.ourServices?.part1.imageFileUuid
                  )?.file?.url,
                }
              : undefined,
            part2: res.data?.content?.ourServices?.part2
              ? {
                  content: res.data?.content?.ourServices?.part2.content,
                  title: res.data?.content?.ourServices?.part2.title,
                  imageFileUuid: res.data?.content?.ourServices?.part2.imageFileUuid,
                  imageFileUrl: res.data?.listingFiles.find(
                    (x) => x.uuid === res.data?.content?.ourServices?.part2.imageFileUuid
                  )?.file?.url,
                }
              : undefined,
            part3: res.data?.content?.ourServices?.part3
              ? {
                  content: res.data?.content?.ourServices?.part3.content,
                  title: res.data?.content?.ourServices?.part3.title,
                  imageFileUuid: res.data?.content?.ourServices?.part3.imageFileUuid,
                  imageFileUrl: res.data?.listingFiles.find(
                    (x) => x.uuid === res.data?.content?.ourServices?.part3.imageFileUuid
                  )?.file?.url,
                }
              : undefined,
          };

          if (res.data?.content?.ourServices?.enabled !== undefined) {
            ourServicesValues.enabled = res.data?.content?.ourServices?.enabled;
          }

          setOurServicesDefaultValues(ourServicesValues);

          const aboutUsValues: FormAboutUsData = {
            enabled: false,
            content: res.data?.content?.aboutUs?.content,
            imageFileUuid: res.data?.content?.aboutUs?.imageFileUuid,
            imageFileUrl: res.data?.listingFiles.find((x) => x.uuid === res.data?.content?.aboutUs?.imageFileUuid)?.file
              ?.url,
          };

          if (res.data?.content?.aboutUs?.enabled !== undefined) {
            aboutUsValues.enabled = res.data?.content?.aboutUs?.enabled;
          }

          setAboutUsDefaultValues(aboutUsValues);
        }
      }
    }
  }, []);

  const fetchOrganisationData = useCallback(async () => {
    if (currentOrganisationUuid) {
      const res = await getOrganisationDetails({
        organisationUuid: currentOrganisationUuid,
      });

      if (res.status === Status.Success && res.data) {
        if (res.data.listing?.rowVersion) {
          setListingRowVersion(res.data.listing.rowVersion);
        }

        const heroValues: FormHeroData = {
          enabled: res.data.listing?.content?.hero?.enabled ? res.data.listing?.content?.hero?.enabled : false,
          title: res.data.listing?.content?.hero?.title,
          subtitle: res.data.listing?.content?.hero?.subtitle,
          imageFileUuid: res.data.listing?.content?.hero?.imageFileUuid,
          imageFileUrl: res.data?.listingFiles.find((x) => x.uuid === res.data?.listing?.content?.hero?.imageFileUuid)
            ?.file?.url,
        };

        if (res.data?.listing?.content?.hero?.enabled !== undefined) {
          heroValues.enabled = res.data?.listing?.content.hero.enabled;
        }

        setHeroDefaultValues(heroValues);
        setHeroOldDefaultValues(heroValues);

        const ourValues: FormOurValuesData = {
          enabled: res.data?.listing?.content?.ourValues?.enabled
            ? res.data?.listing?.content?.ourValues?.enabled
            : false,
          content: res.data?.listing?.content?.ourValues?.content,
        };

        if (res.data?.listing?.content?.ourValues?.enabled !== undefined) {
          ourValues.enabled = res.data?.listing?.content.ourValues.enabled;
        }

        setOurValuesDefaultValues(ourValues);
        setOurValuesOldDefaultValues(ourValues);

        const getInTouchValues: FormGetInTouchData = {
          enabled: true,
        };

        if (res.data?.listing?.content?.getInTouch?.enabled !== undefined) {
          getInTouchValues.enabled = res.data?.listing?.content.getInTouch.enabled;
        }

        setGetInTouchDefaultValues(getInTouchValues);
        setGetInTouchOldDefaultValues(getInTouchValues);

        const ourApproachValues: FormOurApproachData = {
          enabled: res.data?.listing?.content?.ourApproach?.enabled
            ? res.data?.listing?.content?.ourApproach?.enabled
            : false,
          part1: res.data?.listing?.content?.ourApproach?.part1
            ? {
                content: res.data?.listing?.content.ourApproach.part1.content,
                title: res.data?.listing?.content.ourApproach.part1.title,
                imageFileUuid: res.data?.listing?.content.ourApproach.part1.imageFileUuid,
                imageFileUrl: res.data?.listingFiles.find(
                  (x) => x.uuid === res.data?.listing?.content?.ourApproach.part1.imageFileUuid
                )?.file?.url,
              }
            : undefined,
          part2: res.data?.listing?.content?.ourApproach?.part2
            ? {
                content: res.data?.listing?.content.ourApproach.part2.content,
                title: res.data?.listing?.content.ourApproach.part2.title,
                imageFileUuid: res.data?.listing?.content.ourApproach.part2.imageFileUuid,
                imageFileUrl: res.data?.listingFiles.find(
                  (x) => x.uuid === res.data?.listing?.content?.ourApproach.part2.imageFileUuid
                )?.file?.url,
              }
            : undefined,
          part3: res.data?.listing?.content?.ourApproach?.part3
            ? {
                content: res.data?.listing?.content.ourApproach.part3.content,
                title: res.data?.listing?.content.ourApproach.part3.title,
                imageFileUuid: res.data?.listing?.content.ourApproach.part3.imageFileUuid,
                imageFileUrl: res.data?.listingFiles.find(
                  (x) => x.uuid === res.data?.listing?.content?.ourApproach.part3.imageFileUuid
                )?.file?.url,
              }
            : undefined,
          part4: res.data?.listing?.content?.ourApproach?.part4
            ? {
                content: res.data?.listing?.content.ourApproach.part4.content,
                title: res.data?.listing?.content.ourApproach.part4.title,
                imageFileUuid: res.data?.listing?.content.ourApproach.part4.imageFileUuid,
                imageFileUrl: res.data?.listingFiles.find(
                  (x) => x.uuid === res.data?.listing?.content?.ourApproach.part4.imageFileUuid
                )?.file?.url,
              }
            : undefined,
        };

        if (res.data?.listing?.content?.ourApproach?.enabled !== undefined) {
          ourApproachValues.enabled = res.data?.listing?.content.ourApproach.enabled;
        }

        setOurApproachDefaultValues(ourApproachValues);
        setOurApproachOldDefaultValues(ourApproachValues);

        const atAGlanceValues: FormAtAGlanceData = {
          enabled: true,
        };

        if (res.data?.listing?.content?.atAGlance?.enabled !== undefined) {
          atAGlanceValues.enabled = res.data?.listing?.content?.atAGlance?.enabled;
        }

        setAtAGlanceDefaultValues(atAGlanceValues);
        setAtAGlanceOldDefaultValues(atAGlanceValues);

        const ourServicesValues: FormOurServicesData = {
          enabled: res.data?.listing?.content?.ourServices?.enabled
            ? res.data?.listing?.content?.ourServices?.enabled
            : false,
          part1: res.data?.listing?.content?.ourServices?.part1
            ? {
                content: res.data?.listing?.content.ourServices.part1.content,
                title: res.data?.listing?.content.ourServices.part1.title,
                imageFileUuid: res.data?.listing?.content.ourServices.part1.imageFileUuid,
                imageFileUrl: res.data?.listingFiles.find(
                  (x) => x.uuid === res.data?.listing?.content?.ourServices.part1.imageFileUuid
                )?.file?.url,
              }
            : undefined,
          part2: res.data?.listing?.content?.ourServices?.part2
            ? {
                content: res.data?.listing?.content.ourServices.part2.content,
                title: res.data?.listing?.content.ourServices.part2.title,
                imageFileUuid: res.data?.listing?.content.ourServices.part2.imageFileUuid,
                imageFileUrl: res.data?.listingFiles.find(
                  (x) => x.uuid === res.data?.listing?.content?.ourServices.part2.imageFileUuid
                )?.file?.url,
              }
            : undefined,
          part3: res.data?.listing?.content?.ourServices?.part3
            ? {
                content: res.data?.listing?.content.ourServices.part3.content,
                title: res.data?.listing?.content.ourServices.part3.title,
                imageFileUuid: res.data?.listing?.content.ourServices.part3.imageFileUuid,
                imageFileUrl: res.data?.listingFiles.find(
                  (x) => x.uuid === res.data?.listing?.content?.ourServices.part3.imageFileUuid
                )?.file?.url,
              }
            : undefined,
        };

        if (res.data?.listing?.content?.ourServices?.enabled !== undefined) {
          ourServicesValues.enabled = res.data?.listing?.content.ourServices.enabled;
        }

        setOurServicesDefaultValues(ourServicesValues);
        setOurServicesOldDefaultValues(ourServicesValues);

        const aboutUsValues: FormAboutUsData = {
          enabled: res.data?.listing?.content?.aboutUs?.enabled ? res.data?.listing?.content?.aboutUs?.enabled : false,
          content: res.data?.listing?.content?.aboutUs?.content,
          imageFileUuid: res.data?.listing?.content?.aboutUs?.imageFileUuid,
          imageFileUrl: res.data?.listingFiles.find(
            (x) => x.uuid === res.data?.listing?.content?.aboutUs?.imageFileUuid
          )?.file?.url,
        };

        if (res.data?.listing?.content?.aboutUs?.enabled !== undefined) {
          aboutUsValues.enabled = res.data?.listing?.content.aboutUs.enabled;
        }

        setAboutUsDefaultValues(aboutUsValues);
        setAboutUsOldDefaultValues(aboutUsValues);
      }
    }
  }, []);

  useEffect(() => {
    if (searchParams.get("origin") === Origin.DeveloperDetailsPreview) {
      fetchListingPreviewData().then(() => setDataIsLoading(false));
    } else {
      fetchOrganisationData().then(() => setDataIsLoading(false));
    }
  }, [fetchListingPreviewData, fetchOrganisationData]);

  const getCurrentFormsData = (formWhichWasSaved: string): string => {
    let hero;
    let ourValues;
    let getInTouch;
    let ourApproach;
    let atAGlance;
    let aboutUs;
    let ourServices;

    if (heroFormRef.current?.getHeroSubmittedData) {
      hero = heroFormRef.current.getHeroSubmittedData();
    }

    if (ourValuesFormRef.current?.getOurValuesSubmittedData) {
      ourValues = ourValuesFormRef.current.getOurValuesSubmittedData();
    }

    if (getInTouchFormRef.current?.getInTouchSubmittedData) {
      getInTouch = getInTouchFormRef.current.getInTouchSubmittedData();
    }

    if (ourApproachFormRef.current?.getOurApproachSubmittedData) {
      ourApproach = ourApproachFormRef.current.getOurApproachSubmittedData();
    }

    if (atAGlanceFormRef.current?.getAtAGlanceSubmittedData) {
      atAGlance = atAGlanceFormRef.current.getAtAGlanceSubmittedData();
    }

    if (ourServicesFormRef.current?.getOurServicesSubmittedData) {
      ourServices = ourServicesFormRef.current.getOurServicesSubmittedData();
    }

    if (aboutUsFormRef.current?.getAboutUsSubmittedData) {
      aboutUs = aboutUsFormRef.current.getAboutUsSubmittedData();
    }

    switch (formWhichWasSaved) {
      case FormNames.Hero: {
        if (heroFormRef.current?.getHeroData) {
          hero = heroFormRef.current.getHeroData();
        }
        break;
      }
      case FormNames.OurValues: {
        if (ourValuesFormRef.current?.getOurValuesData) {
          ourValues = ourValuesFormRef.current.getOurValuesData();
        }
        break;
      }
      case FormNames.GetInTouch: {
        if (getInTouchFormRef.current?.getInTouchData) {
          getInTouch = getInTouchFormRef.current.getInTouchData();
        }
        break;
      }
      case FormNames.OurApproach: {
        if (ourApproachFormRef.current?.getOurApproachData) {
          ourApproach = ourApproachFormRef.current.getOurApproachData();
        }
        break;
      }
      case FormNames.AtAGlance: {
        if (atAGlanceFormRef.current?.getAtAGlanceData) {
          atAGlance = atAGlanceFormRef.current.getAtAGlanceData();
        }
        break;
      }
      case FormNames.OurServices: {
        if (ourServicesFormRef.current?.getOurServicesData) {
          ourServices = ourServicesFormRef.current.getOurServicesData();
        }
        break;
      }
      case FormNames.AboutUs: {
        if (aboutUsFormRef.current?.getAboutUsData) {
          aboutUs = aboutUsFormRef.current.getAboutUsData();
        }
        break;
      }
      default: {
        logError({ error: `${formWhichWasSaved} is not a valid form` });
      }
    }

    return JSON.stringify(
      recursivelyRemoveEmptyStrings({
        hero,
        ourValues,
        getInTouch,
        ourApproach,
        atAGlance,
        ourServices,
        aboutUs,
      })
    );
  };

  const handlePreview = async (): Promise<void> => {
    if (currentOrganisationUuid) {
      setPreviewMode(true);

      let hero;
      let ourValues;
      let getInTouch;
      let ourApproach;
      let atAGlance;
      let aboutUs;
      let ourServices;

      if (heroFormRef.current?.getHeroData) {
        hero = heroFormRef.current.getHeroData();
      }

      if (ourValuesFormRef.current?.getOurValuesData) {
        ourValues = ourValuesFormRef.current.getOurValuesData();
      }

      if (getInTouchFormRef.current?.getInTouchData) {
        getInTouch = getInTouchFormRef.current.getInTouchData();
      }

      if (ourApproachFormRef.current?.getOurApproachData) {
        ourApproach = ourApproachFormRef.current.getOurApproachData();
      }

      if (atAGlanceFormRef.current?.getAtAGlanceData) {
        atAGlance = atAGlanceFormRef.current.getAtAGlanceData();
      }

      if (ourServicesFormRef.current?.getOurServicesData) {
        ourServices = ourServicesFormRef.current.getOurServicesData();
      }

      if (aboutUsFormRef.current?.getAboutUsData) {
        aboutUs = aboutUsFormRef.current.getAboutUsData();
      }

      const res = await saveOrganisationListingPreview({
        organisationUuid: currentOrganisationUuid,
        content: JSON.stringify(
          recursivelyRemoveEmptyStrings({
            hero,
            ourValues,
            getInTouch,
            ourApproach,
            atAGlance,
            ourServices,
            aboutUs,
          })
        ),
      });

      if (res.status === Status.Success && res.data?.previewUuid) {
        navigate(getPublicDeveloperDetailsPreviewRoute(res.data.previewUuid));
      }
      if (res.status === Status.Error && res.errors) {
        const previewErrors: {
          hero: ServiceError[];
          ourValues: ServiceError[];
          ourApproach: ServiceError[];
          ourServices: ServiceError[];
          aboutUs: ServiceError[];
        } = {
          hero: [],
          ourValues: [],
          ourApproach: [],
          ourServices: [],
          aboutUs: [],
        };

        res.errors.forEach((error) => {
          switch (true) {
            case error.path?.includes(FormNames.Hero): {
              previewErrors.hero.push(error);
              break;
            }
            case error.path?.includes(FormNames.OurValues): {
              previewErrors.ourValues.push(error);
              break;
            }
            case error.path?.includes(FormNames.OurApproach): {
              previewErrors.ourApproach.push(error);
              break;
            }
            case error.path?.includes(FormNames.OurServices): {
              previewErrors.ourServices.push(error);
              break;
            }
            case error.path?.includes(FormNames.AboutUs): {
              previewErrors.aboutUs.push(error);
              break;
            }
            default: {
              logError({ error: `No form could be found for ${error.path}` });
            }
          }
        });

        if (heroFormRef.current?.setHeroErrors) {
          heroFormRef.current.setHeroErrors(previewErrors.hero);
        }
        if (ourValuesFormRef.current?.setOurValuesErrors) {
          ourValuesFormRef.current.setOurValuesErrors(previewErrors.ourValues);
        }
        if (ourApproachFormRef.current?.setOurApproachErrors) {
          ourApproachFormRef.current.setOurApproachErrors(previewErrors.ourApproach);
        }
        if (ourServicesFormRef.current?.setOurServicesErrors) {
          ourServicesFormRef.current?.setOurServicesErrors(previewErrors.ourServices);
        }
        if (aboutUsFormRef.current?.setAboutUsErrors) {
          aboutUsFormRef.current?.setAboutUsErrors(previewErrors.aboutUs);
        }
      }
    }
  };

  return {
    heroFormRef,
    ourValuesFormRef,
    getInTouchFormRef,
    ourApproachFormRef,
    ourServicesFormRef,
    atAGlanceFormRef,
    aboutUsFormRef,
    dataIsLoading,
    hasUnsavedChanges,
    listingRowVersion,
    setListingRowVersion,
    dispatch,
    heroDefaultValues,
    ourValuesDefaultValues,
    getInTouchDefaultValues,
    ourApproachDefaultValues,
    atAGlanceDefaultValues,
    ourServicesDefaultValues,
    aboutUsDefaultValues,
    heroOldDefaultValues,
    ourValuesOldDefaultValues,
    getInTouchOldDefaultValues,
    ourApproachOldDefaultValues,
    atAGlanceOldDefaultValues,
    ourServicesOldDefaultValues,
    aboutUsOldDefaultValues,
    handlePreview,
    previewMode,
    getCurrentFormsData,
  };
}
