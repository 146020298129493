import { DeveloperSingleCardTemplate } from "../../../../templates";
import { ChangePasswordForm } from "./form";

export const ChangePassword = (): JSX.Element => {
  return (
    <DeveloperSingleCardTemplate pageTitle="Change Password">
      <ChangePasswordForm />
    </DeveloperSingleCardTemplate>
  );
};
