import { useEffect, useState } from "react";

import { SelectData } from "../../../models";
import { CheckboxChangeEvent } from "../../forms";

export interface TextFilter {
  kind: "text";
  name: string;
  label?: string;
  placeholder?: string;
}

export interface SliderFilter {
  kind: "slider";
  name: string;
  label?: string;
  min: number;
  max: number;
  range?: boolean;
  formatValue?: (val: number | undefined) => string | undefined;
}

export interface CheckboxGroupFilter {
  kind: "checkbox";
  name: string;
  label?: string;
  tooltipHeader?: string;
  tooltipText?: string;
  checkboxes: { label: string; value: string }[];
}

export interface SelectFilter {
  kind: "select";
  name: string;
  label?: string;
  placeholder?: string;
  data: SelectData;
}

export interface AutocompleteFilter {
  kind: "autocomplete";
  name: string;
  label?: string;
  placeholder?: string;
  data: SelectData;
}

export type Filter = TextFilter | SliderFilter | CheckboxGroupFilter | SelectFilter | AutocompleteFilter;

interface useDataFilterReturnData {
  defaultValue: DataFilterValueType;
  currentValue: DataFilterValueType;
  hasAnyFilterApplied: boolean;
  onTextFilterChange: (filter: TextFilter, filterValue: string) => void;
  onSliderFilterChange: (filter: SliderFilter, filterValue: number | number[]) => void;
  onCheckboxGroupFilterChange: (filter: CheckboxGroupFilter, event: CheckboxChangeEvent, checkboxIndex: number) => void;
  onSelectFilterChange: (filter: SelectFilter, filterValue: string) => void;
  onAutocompleteFilterChange: (filter: AutocompleteFilter, filterValue: string) => void;
  onClear: () => void;
  onFilterClear: (filter: Filter) => void;
}

export type CheckboxFilterValueType = { [key: string]: boolean };

export type FilterValueType = string | number[] | CheckboxFilterValueType | null | undefined;

export type DataFilterValueType = {
  [key: string]: FilterValueType;
};

export function useDataFilter(
  filters: Filter[],
  value?: DataFilterValueType,
  onChange?: (value: DataFilterValueType) => void
): useDataFilterReturnData {
  const [defaultValue, setDefaultValue] = useState<DataFilterValueType>({});
  const [currentValue, setCurrentValue] = useState<DataFilterValueType>({});
  const [hasAnyFilterApplied, setHasAnyFilterApplied] = useState<boolean>(false);

  const isDefault = (name: string, filterValue: FilterValueType): boolean => {
    if (!filterValue) return true;

    if (typeof filterValue === "string") {
      return filterValue === defaultValue[name];
    }

    const defaultFilterValue = defaultValue[name];

    if (!defaultFilterValue) return true;

    if (Array.isArray(filterValue))
      return filterValue.every((innerValue, innerIndex) => innerValue === (<number[]>defaultFilterValue)[innerIndex]);

    return Object.keys(filterValue).every(
      (key) => filterValue[key] === (<CheckboxFilterValueType>defaultFilterValue)[key]
    );
  };

  const onValueChange = (newValue: DataFilterValueType): void => {
    setCurrentValue(newValue);
    if (onChange) onChange(newValue);
  };

  const onFilterValueChange = (filter: Filter, filterValue: FilterValueType): void => {
    onValueChange({
      ...currentValue,
      [filter.name]: !isDefault(filter.name, filterValue) ? filterValue : undefined,
    });
  };

  const onTextFilterChange = (filter: TextFilter, filterValue: string): void => {
    onFilterValueChange(filter, filterValue);
  };

  const onSliderFilterChange = (filter: SliderFilter, filterValue: number | number[]): void => {
    onFilterValueChange(filter, filterValue as number[]);
  };

  const onCheckboxGroupFilterChange = (
    filter: CheckboxGroupFilter,
    event: CheckboxChangeEvent,
    checkboxIndex: number
  ): void => {
    const filterValue = (currentValue[filter.name] as CheckboxFilterValueType) || {
      ...(<CheckboxFilterValueType>defaultValue[filter.name]),
    };
    const checkboxValue = filter.checkboxes[checkboxIndex].value;
    filterValue[checkboxValue] = event.target.checked;
    onFilterValueChange(filter, filterValue);
  };

  const onSelectFilterChange = (filter: SelectFilter, filterValue: string): void => {
    onFilterValueChange(filter, filterValue);
  };

  const onAutocompleteFilterChange = (filter: AutocompleteFilter, filterValue: string): void => {
    onFilterValueChange(filter, filterValue);
  };

  const onClear = (): void => {
    onValueChange({});
  };

  const onFilterClear = (filter: Filter): void => {
    onFilterValueChange(filter, undefined);
  };

  useEffect(() => {
    if (value) setCurrentValue(value);
  }, [value]);

  useEffect(() => {
    setDefaultValue(
      filters.reduce<DataFilterValueType>((result, f) => {
        /* eslint-disable no-param-reassign */
        switch (f.kind) {
          case "text":
          case "select":
            result[f.name] = "";
            break;
          case "autocomplete":
            result[f.name] = "";
            break;
          case "slider":
            result[f.name] = [f.min, f.max];
            break;
          case "checkbox":
            result[f.name] = f.checkboxes.reduce<CheckboxFilterValueType>((accumulator, current) => {
              accumulator[current.value] = false;

              return accumulator;
            }, {});
            break;
          default:
            throw new Error("Could not determine the filter type");
        }
        /* eslint-enable */

        return result;
      }, {})
    );
  }, [filters]);

  useEffect(() => {
    const anyFilterApplied = Object.keys(currentValue).some((key) => !isDefault(key, currentValue[key]));
    setHasAnyFilterApplied(anyFilterApplied);
  }, [currentValue, defaultValue]);

  return {
    defaultValue,
    currentValue,
    hasAnyFilterApplied,
    onTextFilterChange,
    onSliderFilterChange,
    onCheckboxGroupFilterChange,
    onSelectFilterChange,
    onAutocompleteFilterChange,
    onClear,
    onFilterClear,
  };
}
