import { logError } from "./error";

const validationErrorDictionary: { [key: string]: string } = {
  ERROR_ACCESS_DENIED: "You do not have access to this",
  ERROR_APPLICATION: "The application has encountered an unexpected error. Please try again later.",
  ERROR_AUTHENTICATION_REQUIRED: "Please login to your account to continue",
  ERROR_CONTENT_SCHEMA_VALIDATION: "The content you've submitted is invalid",
  ERROR_COUNTRY_NAME_NOT_FOUND: "This country could not be found.",
  ERROR_DOCUMENT_NOT_VISIBLE: "This document is not visable to the public",
  ERROR_NOT_VALID_JSON: "ERROR_NOT_VALID_JSON",
  ERROR_NO_RECORD_FOUND: "The data you requested has not been found",
  ERROR_NO_ROWS_UPDATED: "The data you are trying to update is out of date. Please refresh the page and try again",
  ERROR_PROJECT_DETAILS_DATA_NOT_EDITABLE: "This data is not editable",
  ERROR_PROJECT_FIELDS_NOT_EDITABLE: "These fields are not editable",
  ERROR_PROJECT_ISSUANCE_DATA_NOT_EDITABLE: "This project issuance data is not editable",
  ERROR_PROJECT_STANDARD_SPECIFIC_DATA_FIELDS_NOT_EDITABLE: "This data is not editable",
  VALIDATION_ADDRESS_CITY_REQUIRED: "City is a required field",
  VALIDATION_ADDRESS_COUNTRY_ISO: "Country is not invalid",
  "VALIDATION_ADDRESS_COUNTRY_ISO3166-1": "Country is not invalid",
  VALIDATION_ADDRESS_LINE: "Address line 1 is not valid",
  VALIDATION_ADDRESS_LINE1_REQUIRED: "Address line 1 is a required field",
  VALIDATION_ADDRESS_POSTAL_CODE_REQUIRED: "Postcode is a required field",
  VALIDATION_ADDRESS_POSTAL_CODE_UK_VALID: "Postcode is not valid",
  VALIDATION_AGGREGATE_INVALID: "VALIDATION_AGGREGATE_INVALID",
  VALIDATION_AGGREGATION_COUNT_AGGREGATE_INVALID: "VALIDATION_AGGREGATION_COUNT_AGGREGATE_INVALID",
  VALIDATION_AGGREGATION_INVALID: "VALIDATION_AGGREGATION_INVALID",
  VALIDATION_AGGREGATION_SUM_AGGREGATE_INVALID: "VALIDATION_AGGREGATION_SUM_AGGREGATE_INVALID",
  VALIDATION_ATTRIBUTE_MAXIMUM: "{property} must be less than {max}",
  VALIDATION_ATTRIBUTE_MAXIMUM_LENGTH: "{property} must be shorter than {maxLength} characters",
  VALIDATION_ATTRIBUTE_MINIMUM: "{property} must be greater than {min}",
  VALIDATION_ATTRIBUTE_MINIMUM_LENGTH: "{property} must be longer than {minLength} characters",
  VALIDATION_ATTRIBUTE_NOT_DEFAULT: "{property} is not valid",
  VALIDATION_ATTRIBUTE_RANGE: "{property} must be between {min} and {max}",
  VALIDATION_ATTRIBUTE_REGEX: "{property} is the wrong format",
  VALIDATION_ATTRIBUTE_REQUIRED: "{property} is a required field",
  VALIDATION_ATTRIBUTE_STRING_LENGTH: "{property} must be between {minLength} and {maxLength} characters",
  VALIDATION_AVATAR_FILE_CONTENTS_MAX_SIZE: "File must be less than {maximumSize}",
  VALIDATION_CONFLICTING_CONTACT_FLAGS: "Email redirecting must be disabled in order to disable public interest emails",
  VALIDATION_CURRENT_PASSWORD_INVALID: "Password is incorrect",
  VALIDATION_DATA_ACCESS_DENIED: "You do not have permission to perform that action",
  VALIDATION_DATE_ONLY_VALID: "Date is not valid",
  VALIDATION_DATE_TIME_VALID: "Date and time are not valid",
  VALIDATION_FILE_MAXNUMBER: "Maximum number of files exceeded",
  VALIDATION_FILE_MUST_BE_IMAGE: "Only .jpeg or .webp files are supported",
  VALIDATION_FILTERBY_INVALID: "VALIDATION_FILTERBY_INVALID",
  VALIDATION_FILTEROPERATOR_INVALID: "VALIDATION_FILTEROPERATOR_INVALID",
  VALIDATION_FILTERVALUE_ORGANISATION_UUID_INVALID: "VALIDATION_FILTERVALUE_ORGANISATION_UUID_INVALID",
  VALIDATION_FORGOTTEN_PASSWORD_TOKEN_ACCOUNT_LOCKED: "You can't reset your password because your account is locked",
  VALIDATION_FORGOTTEN_PASSWORD_TOKEN_ALREADY_ACCEPTED:
    "This password reset link has already been used. Please request a new one",
  VALIDATION_FORGOTTEN_PASSWORD_TOKEN_EXPIRED: "This password reset link has expired. Please request a new one",
  VALIDATION_FORGOTTEN_PASSWORD_TOKEN_INVALID: "This password reset link is not valid. Please request a new one",
  VALIDATION_INVALID_CREDENTIALS: "Email and password combination is incorrect",
  VALIDATION_INVITATION_TOKEN_ALREADY_ACCEPTED: "This invite has already been accepted. Please request a new one",
  VALIDATION_INVITATION_TOKEN_EXPIRED: "This invite has expired. Please request a new one",
  VALIDATION_INVITATION_TOKEN_INVALID: "This invite is invalid. Please request a new one",
  VALIDATION_INVITATION_TOKEN_NOT_FOUND: "This invite is invalid. Please request a new one",
  VALIDATION_LISTING_FILE_CONTENTS_MAX_SIZE: "File must be less than 1mb",
  VALIDATION_LOGON_NO_ACTIVE_LINKED_ORGANISATIONS: "Your account must be linked to an organisation for access",
  VALIDATION_MEDIA_FILE_KEY_NOT_VALID: "VALIDATION_MEDIA_FILE_KEY_NOT_VALID",
  VALIDATION_MEDIA_FILE_MAXIMUM_SIZE: "File must be less than 10mb",
  VALIDATION_MIME_TYPE_NOT_SUPPORTED: "Only .jpeg, .png, .gif, .webp or .pdf files are supported",
  VALIDATION_ORGANISATION_ACCESS_DENIED: "You don't have permission to update those details",
  VALIDATION_ORGANISATION_ADDRESS_TYPE_VALID: "Address is not valid",
  VALIDATION_ORGANISATION_EMBEDDING_KEY_VALID: "VALIDATION_ORGANISATION_EMBEDDING_KEY_VALID",
  VALIDATION_ORGANISATION_EMBEDDING_VALUE_VALID_HEX: "Colour code is not valid",
  VALIDATION_ORGANISATION_FILE_CONTENTS_MAX_SIZE: "File must be less than 550kb",
  VALIDATION_ORGANISATION_FILE_CONTENTS_REQUIRED: "Icon for your organisation is required",
  VALIDATION_ORGANISATION_FILE_TYPE_VALID: "VALIDATION_ORGANISATION_FILE_TYPE_VALID",
  VALIDATION_ORGANISATION_USER_MUST_HAVE_OWNER:
    "This action can't be performed as the organisation must have an active owner",
  VALIDATION_ORGANISATION_UUID_REQUIRED: "VALIDATION_ORGANISATION_UUID_REQUIRED",
  VALIDATION_PASSWORD_ACCOUNT_LOCKED:
    "You entered your password wrong too many times so your account has been temporarily locked",
  VALIDATION_PASSWORD_COMMONLY_USED:
    "This password is commonly used so is vulnerable to hackers. Please use a different password",
  VALIDATION_PASSWORD_CONFIRMATION_MISMATCH: "Passwords do not match",
  VALIDATION_PASSWORD_FORMAT_CONTIGUOUS_CHARACTERS:
    "Password should not contain 3 or more equal or sequential contiguous characters (e.g. 123, aaa)",
  VALIDATION_PASSWORD_FORMAT_USERNAME: "Password should not contain your email address",
  VALIDATION_PHONE_UK_LENGTH_RANGE: "Phone number isn't the correct length",
  VALIDATION_PHONE_UK_NO_LEADING_0: "Phone number is invalid",
  VALIDATION_PREFERENCE_KEY_IS_NOT_ALLOWED: "VALIDATION_PREFERENCE_KEY_IS_NOT_ALLOWED",
  VALIDATION_PREFERENCE_VALUE_IS_NOT_HEX_COLOR: "Colour code is not valid",
  VALIDATION_PROJECT_ACCESS_DENIED: "You don't have permission to update those details",
  VALIDATION_PROJECT_ISSUANCES_MISMATCHING_PROJECT: "VALIDATION_PROJECT_ISSUANCES_MISMATCHING_PROJECT",
  VALIDATION_PROJECT_ISSUANCE_VINTAGE_START_BEFORE_END_DATE:
    "The vintage start date must be before the vintage end date. Please amend these dates to continue",
  VALIDATION_QUANTITY_TYPE_INVALID: "Number of units is invald",
  VALIDATION_REFRESH_TOKEN_INVALID: "VALIDATION_REFRESH_TOKEN_INVALID",
  VALIDATION_REGEX: "Value in invalid",
  VALIDATION_ROLE_VALID: "Please choose a valid role",
  VALIDATION_SIGNATURE_NOT_VALID: "File is the wrong format",
  VALIDATION_UI_MAXIMUM_FILE_SIZE: "File must be less than {maximumSize}",
  VALIDATION_UI_MAXIMUM_NUMBER_OF_FILES: "Maximum number of files exceeded",
  VALIDATION_URL_INVALID: "URL is invalid",
  VALIDATION_USERNAME_ALREADY_IN_USE:
    "This email address is already in use. Try logging in to your account or use another email address",
  VALIDATION_FORMAT_EMAIL: "Email address is invalid",
};

export const getErrorMessageFromCode = (
  errorCode: string,
  parameters?: { [key: string]: string | undefined }
): string => {
  let message = validationErrorDictionary[errorCode];
  if (message === undefined) {
    message = errorCode;
    logError({
      error: `Could not find an error message for code '${errorCode}'`,
    });
  }
  if (message && parameters)
    Object.keys(parameters).forEach((key) => {
      if (key && parameters[key]) {
        let value = parameters[key]?.toString() || "";
        if (key === "property") {
          value = value.replace(/([A-Z])/g, " $1").toLowerCase();
        }
        message = message.replace(`{${key}}`, value);
      }
      message = message.trim();
      message = message.charAt(0).toUpperCase() + message.substring(1);
    });
  return message;
};
