import "./ReadonlyText.css";

import { useId } from "react";

interface ReadonlyTextProps {
  label?: string;
  value: string | null | undefined;
  textClass?: "body1" | "body2" | "body3" | "body4" | "link";
  noDataAvailable?: boolean;
}

export const ReadonlyText = ({
  label,
  value,
  textClass = "body1",
  noDataAvailable = false,
}: ReadonlyTextProps): JSX.Element => {
  const id = useId();

  return (
    <div className="ReadonlyText">
      {label ? (
        <label htmlFor={id} className="ReadonlyTextLabel">
          {label}
        </label>
      ) : null}
      <span id={id} className={`${(noDataAvailable && "ReadonlyNoDataAvailable ") || ""}${textClass}`}>
        {value}
      </span>
    </div>
  );
};
