import { SortColumn as RdgSortColumn } from "react-data-grid";

import { ResultData } from "../../../../service/Shared";
import { ColumnDefinition } from "../models/DataGrid.types";

export type sortFunction<T> = (v1: T, v2: T) => 0 | 1 | -1;

function sortString(str1: string, str2: string): 0 | 1 | -1 {
  if (str1 == null && str2 != null) return -1;
  if (str1 != null && str2 == null) return 1;
  if (str1 == null && str2 == null) return 0;
  const compare = str1.localeCompare(str2);
  if (compare === 0) return 0;
  if (compare > 0) return 1;
  return -1;
}

function sortNumber(n1: number, n2: number): 0 | 1 | -1 {
  const diff = n1 - n2;
  if (diff === 0) return 0;
  if (diff > 0) return 1;
  return -1;
}

function sortDate(d1: Date, d2: Date): 0 | 1 | -1 {
  if (d1 == null && d2 != null) return -1;
  if (d1 != null && d2 == null) return 1;
  if (d1 == null && d2 == null) return 0;
  return sortNumber(d1.getTime(), d2.getTime());
}

function sortBoolean(n1: boolean, n2: boolean): 0 | 1 | -1 {
  if (n1 === n2) return 0;
  if (n1) return 1;
  return -1;
}

export function getClientSideSortFunction<T>(dataType: string): sortFunction<T> {
  switch (dataType) {
    case "string":
      return sortString as unknown as sortFunction<T>;
    case "boolean":
      return sortBoolean as unknown as sortFunction<T>;
    case "number":
      return sortNumber as unknown as sortFunction<T>;
    case "Date":
      return sortDate as unknown as sortFunction<T>;
    default:
      throw new Error(`Couldn't get client side sort function; unexpected dataType ${dataType}`);
  }
}

export const rdgClientSideSort = (
  data: ResultData[],
  columnDefinitions: ColumnDefinition[],
  sortColumns: readonly RdgSortColumn[]
): ResultData[] => {
  if (sortColumns.length === 0) return data;
  // column definition for sort columns
  const sortColumnDefinitions = new Array<ColumnDefinition>(sortColumns.length);
  for (let i = 0; i < sortColumns.length; i++) {
    const sortColumnDefinition = columnDefinitions.find((cd) => cd.key === sortColumns[i].columnKey);
    if (!sortColumnDefinition)
      throw new Error(`Couldn't find ColumnDefinition for column key ${sortColumns[i]} in rdgClientSideSort`);
    sortColumnDefinitions[i] = sortColumnDefinition;
  }
  return [...data].sort((a, b) => {
    for (let i = 0; i < sortColumns.length; i++) {
      const columnDefinition = sortColumnDefinitions[i];
      const comparator = getClientSideSortFunction(columnDefinition.dataType);
      const compResult = comparator(a[columnDefinition.key], b[columnDefinition.key]);
      if (compResult !== 0) {
        return sortColumns[i].direction === "ASC" ? compResult : -compResult;
      }
    }
    return 0;
  });
};
