import "./Tooltip.css";

import Tooltip from "rc-tooltip";
import { ReactNode } from "react";

import { CloseDarkIcon, TooltipInfoLightIcon } from "../../../assets";
import { useTooltip } from "./useTooltip";

type TooltipPlacement = "bottomLeft" | "bottomRight";

export interface TooltipProps {
  placement?: TooltipPlacement;
  tooltipHeader: string;
  tooltipText: string;
  icon?: ReactNode;
  linkText?: string;
  fill?: boolean;
}

const tooltipContainer = (tooltipHeader: string, tooltipText: string, handleVisible: () => void): JSX.Element => {
  return (
    <div className="TooltipContainer">
      <div className="TooltipContainerIcon">
        <CloseDarkIcon onClick={handleVisible} />
      </div>
      <h3>{tooltipHeader}</h3>
      <div className="body1">{tooltipText}</div>
    </div>
  );
};

export const KanaTooltip = ({
  tooltipHeader,
  tooltipText,
  placement = "bottomLeft",
  icon = <TooltipInfoLightIcon />,
  linkText,
  fill = true,
}: TooltipProps): JSX.Element => {
  const { isVisible, handleVisibleChange } = useTooltip();

  return (
    <Tooltip
      placement={placement}
      visible={isVisible}
      onVisibleChange={(visible: boolean) => handleVisibleChange(visible)}
      overlayClassName="KanaTooltip"
      overlay={tooltipContainer(tooltipHeader, tooltipText, () => {
        handleVisibleChange(false);
      })}
    >
      <div className={`TooltipChild ${fill ? "TooltipFill" : ""} ${linkText ? "TooltipChildLink" : ""}`}>
        {linkText && fill ? <h3>{linkText}</h3> : <p>{linkText}</p>}
        {icon}
      </div>
    </Tooltip>
  );
};
