import { DeveloperProjectCardWithTabsTemplate } from "../../../../templates";
import { useProject } from "./useProject";

const Project = (): JSX.Element => {
  const { projectDetails, handleBackClick } = useProject();

  return (
    <DeveloperProjectCardWithTabsTemplate
      activeElement="Projects"
      pageTitle={projectDetails?.displayName}
      projectCode={projectDetails?.standard.displayName}
      projectStatus={projectDetails?.status}
      chartData={{
        "Pending issuance units (PIUs)": projectDetails?.cachedPiuQuantity ?? 0,
        "Verified carbon units (VCUs)": projectDetails?.cachedVcuQuantity ?? 0,
      }}
      tabsList={[
        {
          label: "Overview",
          route: `/d/projects/${projectDetails?.uuid}/overview`,
        },
        {
          label: "Details",
          route: `/d/projects/${projectDetails?.uuid}/details`,
        },
        {
          label: "Issuances",
          route: `/d/projects/${projectDetails?.uuid}/issuances`,
        },
        {
          label: "Documents",
          route: `/d/projects/${projectDetails?.uuid}/documents`,
        },
        {
          label: "Marketing settings",
          route: `/d/projects/${projectDetails?.uuid}/marketing-settings`,
        },
      ]}
      backButtonText="Back to projects"
      handleBackClick={handleBackClick}
    />
  );
};

export default Project;
